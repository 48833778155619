import { FC } from "react";

const Spreadsheet: FC<{
  fill?: string;
  width?: number;
  height?: number;
  [key: string]: any;
}> = ({ fill = "#ffffff", width = 16, height = 16, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M28.3333 32.0333H8.33333V11.6667H20V8.33333H8.33333C6.5 8.33333 5 9.83333 5 11.6667V31.6667C5 33.5 6.5 35 8.33333 35H28.3333C30.1667 35 31.6667 33.5 31.6667 31.6667V20H28.3333V32.0333Z"
          fill={fill}
        />
        <path
          d="M31.6668 3.33333H28.3335V8.33333H23.3335C23.3502 8.35 23.3335 11.6667 23.3335 11.6667H28.3335V16.65C28.3502 16.6667 31.6668 16.65 31.6668 16.65V11.6667H36.6668V8.33333H31.6668V3.33333Z"
          fill={fill}
        />
        <rect x="11.6665" y="15" width="13.3333" height="3.33333" fill={fill} />
        <path d="M11.6665 20V23.3333H24.9998V20H19.9998H11.6665Z" fill={fill} />
        <rect x="11.6665" y="25" width="13.3333" height="3.33333" fill={fill} />
      </g>
    </svg>
  );
};

export default Spreadsheet;
