const AttachFile = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4922 5.83537L6.07102 11.2565C5.02921 12.2983 3.34159 12.2983 2.29978 11.2565C1.25798 10.2147 1.25798 8.52709 2.29978 7.48528L8.19234 1.59272C8.84288 0.942187 9.89882 0.942187 10.5494 1.59272C11.1999 2.24326 11.1999 3.29921 10.5494 3.94975L5.59961 8.8995C5.34034 9.15877 4.91608 9.15877 4.6568 8.89949C4.39753 8.64022 4.39753 8.21596 4.6568 7.95669L9.13515 3.47834L8.42804 2.77124L3.9497 7.24958C3.29916 7.90012 3.29916 8.95606 3.9497 9.6066C4.60024 10.2571 5.65618 10.2571 6.30672 9.6066L11.2565 4.65685C12.2983 3.61505 12.2983 1.92742 11.2565 0.885618C10.2147 -0.156186 8.52704 -0.156186 7.48523 0.885618L1.59268 6.77817C0.159605 8.21124 0.159605 10.5306 1.59267 11.9636C3.02574 13.3967 5.34506 13.3967 6.77812 11.9636L12.1993 6.54247L11.4922 5.83537Z"
        fill="#B2A8BA"
      />
    </svg>
  );
};

export default AttachFile;
