import { FC, useCallback } from "react";
import { WarningCircleSm } from "../../icons";
import useStore from "../../store";
import { Button } from "../form";

const ResultStatus: FC = () => {
  const [isSaved, fetchReport] = useStore((state) => [
    state.isSaved(),
    state.fetchReport,
  ]);

  const handleClick = useCallback(async () => {
    await fetchReport();
  }, [fetchReport]);

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(270deg, #909EE7 0%, #579BCB 100%)`,
        transform: `translateY(${isSaved ? "-100%" : 0})`,
      }}
      className="fixed top-0 left-0 right-0 z-50 py-4 text-center font-helvetica text-[13px] font-bold text-white transition-transform duration-200 ease-in-out desktop:text-base"
    >
      <p className="flex items-center justify-center">
        <span className="mr-2">
          <WarningCircleSm
            fill="#fff"
            width={18}
            height={18}
            className="rotate-180"
          />
        </span>{" "}
        Results are now outdated. Please click{" "}
        <Button
          onClick={handleClick}
          size="small"
          className="mx-1 w-max bg-transparent font-helvetica text-[13px] font-bold underline desktop:text-base"
        >
          search
        </Button>{" "}
        again to refresh results.
      </p>
    </div>
  );
};

export default ResultStatus;
