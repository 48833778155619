import { FC, SVGAttributes } from "react";

const Arrow: FC<SVGAttributes<SVGSVGElement>> = ({
  fill = "currentColor",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16.000787px"
      height="14px"
      viewBox="0 0 16.000787 14"
      version="1.1"
      {...props}
    >
      <title>Group</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group" fill={fill} fillRule="nonzero">
          <path
            d="M15.8052594,7.49522246 L9.8051609,13.7948721 C9.54466231,14.068376 9.12231066,14.068376 8.86181208,13.7948721 C8.6013135,13.5213683 8.6013135,13.077931 8.86181208,12.8044272 L13.7243919,7.69996108 L0.666677606,7.69996108 C0.298481731,7.69996108 0,7.38657783 0,7 C0,6.61342217 0.298481731,6.30003892 0.666677606,6.30003892 L13.7243919,6.30003892 L8.86181208,1.19557278 C8.6013135,0.922068969 8.6013135,0.478631673 8.86181208,0.20512786 C9.12231066,-0.0683759532 9.54466231,-0.0683759532 9.8051609,0.20512786 L15.8052594,6.50477754 C15.9304461,6.63606802 16.000787,6.81422386 16.000787,7 C16.000787,7.18577614 15.9304461,7.36393198 15.8052594,7.49522246 Z"
            id="Path"
          />
        </g>
      </g>
    </svg>
  );
};

export default Arrow;
