import { AnimatePresence, motion } from "framer-motion";
import {
  ComponentProps,
  FC,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  IncomeForm,
  Income as IncomeComponent,
  incomeTypeSalaryWagesData,
  incomeTypeRentalData,
  incomeTypeOtherData,
  IIncome,
  IIncomeAttributes,
} from ".";
import {
  FinancialDetails,
  FinancialDetailsManager,
  FinancialDetailsTable,
} from "../financial";
import { useStore } from "..";
import { IApplicantParty } from "../applicant";
import { isNull } from "lodash";

type Category = "Salary/Wages" | "Rental Income" | "Other Income";

const Incomes: FC<{
  category: Category;
  onClose: () => void;
  applicantPartyId: IApplicantParty["_id"];
}> = ({ category, onClose, applicantPartyId }) => {
  const [allIncome, addFinancial] = useStore(
    useCallback(
      (state) => [
        state.getFinancial(applicantPartyId, "Income"),
        state.addFinancial,
      ],
      [applicantPartyId]
    ),
    (oldState, newState) => oldState[0].length === newState[0].length
  );

  const income = useMemo(() => {
    return allIncome.reduce<IIncome[]>((prev, current) => {
      if (category === "Salary/Wages") {
        if (
          incomeTypeSalaryWagesData.some(
            (value) => value["IncomeType.Code"] === current.IncomeType
          )
        ) {
          prev.push(current);
        }
      }

      if (category === "Rental Income") {
        if (
          incomeTypeRentalData.some(
            (value) => value["IncomeType.Code"] === current.IncomeType
          )
        ) {
          prev.push(current);
        }
      }

      if (category === "Other Income") {
        if (
          incomeTypeOtherData.some(
            (value) => value["IncomeType.Code"] === current.IncomeType
          )
        ) {
          prev.push(current);
        }
      }

      return prev;
    }, []);
  }, [allIncome, category]);

  const [isAdding, setIsAdding] = useState(income.length === 0);

  const formHeader = useMemo(
    () => `${category} ${income.length + 1}`,
    [income, category]
  );

  const handleSave = useCallback(
    (value: NonNullable<ComponentProps<typeof IncomeForm>["initialValue"]>) => {
      const attributesMap: IIncome["Attributes"] = new Map();
      const {
        ApplicantId,
        IncomeType,
        Frequency,
        Amount,
        AmountType,
        ...attributes
      } = value;

      Object.keys(attributes).forEach((key) => {
        const k = key as keyof IIncomeAttributes;
        if (isNull(attributes[k])) {
          attributesMap.delete(k);
          return;
        }

        attributesMap.set(k, {
          name: key,
          value: attributes[k],
        });
      });

      addFinancial(applicantPartyId, {
        type: "Income",
        value: {
          ApplicantId: value.ApplicantId,
          Frequency: value.Frequency,
          Amount: value.Amount,
          AmountType: value.AmountType,
          IncomeType: value.IncomeType,
          Attributes: attributesMap,
        },
      });
      setIsAdding(false);
    },
    [addFinancial, applicantPartyId]
  );

  const closeForm = useCallback(() => setIsAdding(false), []);

  return (
    <FinancialDetails header={category} onClose={onClose}>
      <FinancialDetailsTable isAdding={isAdding}>
        <AnimatePresence>
          {income.map((inc, index) => (
            <motion.div
              key={inc._id}
              initial="unmounted"
              animate="open"
              exit="unmounted"
              variants={{
                open: { opacity: 1, height: "auto" },
                unmounted: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <IncomeComponent
                applicantPartyId={applicantPartyId}
                incomeId={inc._id}
                category={category}
                formLabel={`${category} ${index + 1}`}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </FinancialDetailsTable>
      <FinancialDetailsManager
        isAdding={isAdding}
        buttonOnClick={() => setIsAdding(true)}
        buttonLabel={`Add ${category}`}
        hasData={income.length > 0}
      >
        <IncomeForm
          applicantPartyId={applicantPartyId}
          header={formHeader}
          category={category}
          onSave={handleSave}
          onCancel={closeForm}
        />
      </FinancialDetailsManager>
    </FinancialDetails>
  );
};

export default memo(Incomes);
