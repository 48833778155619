import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  ExpensesImportTransactionTable,
  expenseTypeData,
  IExpense,
  IExpenseTransaction,
} from ".";
import { Alert } from "../components/alert";
import { Button, InputCheckbox, Label } from "../components/form";
import { Search } from "../icons";
import { SectionLabel } from "../components/label";
import currency from "currency.js";

interface ExpenseItem extends IExpenseTransaction {
  checked: boolean;
}

type Props = {
  data: IExpenseTransaction[];
  onChange?: (expenses: IExpense[]) => void;
};

const ExpenseImportTable: FC<Props> = ({ data, onChange }) => {
  const [importedExpense, setImportedExpense] = useState<ExpenseItem[]>(
    data.map((item) => ({ ...item, checked: true }))
  );
  const [showDetail, setShowDetail] = useState<ExpenseItem | null>(null);

  const handleCheck = useCallback(
    (expense: ExpenseItem) => () => {
      expense.checked = !expense.checked;
      setImportedExpense((prevState) =>
        Array.from(new Set([...prevState, expense]))
      );
    },
    []
  );

  const allChecked = useMemo(
    () =>
      importedExpense.reduce((hasChecked, current) => {
        if (!current.checked) {
          return false;
        }

        return hasChecked;
      }, true),
    [importedExpense]
  );

  const hasChecked = useMemo(
    () =>
      importedExpense.reduce((hasChecked, current) => {
        if (current.checked) {
          return true;
        }

        return hasChecked;
      }, false),
    [importedExpense]
  );

  const handleCheckAll = useCallback(() => {
    setImportedExpense((prev) =>
      prev.map((item) => ({
        ...item,
        checked: !allChecked,
      }))
    );
  }, [allChecked]);

  useEffect(() => {
    const expenses = importedExpense.reduce<IExpense[]>((acc, current) => {
      if (!current.checked) return acc;

      acc.push(current.Expense);

      return acc;
    }, []);

    onChange?.(expenses);
  }, [importedExpense, onChange]);

  const expenseTypeName = useCallback(
    (expenseTypeCode: string) =>
      expenseTypeData.find(
        (exp) => exp["ExpenseType.Code"] === expenseTypeCode
      )?.["ExpenseType.Name"],
    []
  );

  return (
    <table className="mt-[19px] flex w-full flex-col">
      <thead className="block bg-white py-2 px-3">
        <tr className="grid grid-cols-[1fr_140px_40px] items-center">
          <th>
            <Label
              htmlFor="checkall"
              className="flex items-center gap-4 text-[11px] desktop:text-sm"
            >
              <InputCheckbox
                id="checkall"
                onChange={handleCheckAll}
                checked={hasChecked}
                className="h-[14px] w-[14px]"
                backgroundImage={!allChecked ? "/icons/dash.svg" : undefined}
              />
              Select All
            </Label>
          </th>
          <th>
            <SectionLabel className="w-max text-[11px] desktop:text-sm">
              Illion Data
            </SectionLabel>
          </th>
        </tr>
      </thead>
      <tbody className="mt-1 bg-white px-3">
        {importedExpense.map((expenseTransaction) => {
          const { Expense, Transaction, checked } = expenseTransaction;
          const { _id, Amount, ExpenseType, Frequency } = Expense;
          const expenseType = expenseTypeName(ExpenseType);
          const amount = currency(Amount, { precision: 2 }).format();
          const frequency = `p/${Frequency.charAt(0).toLowerCase()}`;

          return (
            <tr
              className="grid grid-cols-[1fr_140px_40px] items-center border-b border-[#C7BDCD]/50 py-2 last:border-transparent"
              key={_id}
            >
              <td className="min-w-0">
                <Label
                  htmlFor={`checkitem${_id}`}
                  className="flex w-max max-w-full items-center gap-4 text-[11px] desktop:text-sm"
                >
                  <InputCheckbox
                    id={`checkitem${_id}`}
                    checked={checked}
                    onChange={handleCheck(expenseTransaction)}
                    className="h-[14px] w-[14px]"
                  />
                  {expenseType}
                </Label>
              </td>
              <td className="min-w-0">
                <SectionLabel className="text-[11px] font-normal desktop:text-sm">
                  {amount} {frequency}
                </SectionLabel>
              </td>
              <td className="min-w-0">
                <Alert
                  open={showDetail?.Expense._id === Expense._id}
                  onClose={() => setShowDetail(null)}
                  className="translate-x-[24%] desktop:translate-x-[10.5%]"
                  below={
                    <Button
                      size="small"
                      withIcon={<Search fill="#42B4B4" />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDetail((prev) =>
                          prev?.Expense._id === Expense._id
                            ? null
                            : expenseTransaction
                        );
                      }}
                      className="bg-transparent"
                    />
                  }
                >
                  <SectionLabel className="text-[13px] desktop:text-sm">
                    {expenseType}
                  </SectionLabel>
                  <ExpensesImportTransactionTable transaction={Transaction} />
                </Alert>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ExpenseImportTable;
