import { FC, DetailedHTMLProps, TdHTMLAttributes } from "react";

const ResultFinancialTableData: FC<
  DetailedHTMLProps<
    TdHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  >
> = ({ children, className, ...props }) => {
  return (
    <td className={`first:text-left ${className ? className : ""}`} {...props}>
      {children}
    </td>
  );
};

export default ResultFinancialTableData;
