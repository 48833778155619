import { FC } from "react";
import { SectionLabel } from "../components/label";

const ApplicantFinancialSection: FC<{
  label: string;
}> = ({ label, children }) => {
  return (
    <section className="[&:first-of-type>header]:border-t-0">
      <header className="border-y border-[#CEC4D3] bg-[#F5F1F8] p-[10px]">
        <SectionLabel className="text-[13px] desktop:text-sm">
          {label}
        </SectionLabel>
      </header>
      <ul className="space-y-[10px] p-[10px]">{children}</ul>
    </section>
  );
};

export default ApplicantFinancialSection;
