import { FC, useCallback, useMemo } from "react";

const CircleBar: FC<{
  value: number;
}> = ({ value }) => {
  const valueAsText = useMemo(() => {
    if (value < 40) {
      return "LOW";
    }

    if (value < 70) {
      return "MED";
    }

    return "HIGH";
  }, [value]);

  const valueAsColor = useCallback(
    (segment: number) => {
      let color = "#D8D8D8";

      if (segment === 1 && value >= 0) {
        color = "#F18383";
      }

      if (segment === 2 && value >= 25) {
        color = "#F18383";
      }

      if (segment === 3 && value >= 40) {
        color = "#FFBC53";
      }

      if (segment === 4 && value >= 55) {
        color = "#FFBC53";
      }

      if (segment === 5 && value >= 70) {
        color = "#43B5B3";
      }

      if (segment === 6 && value >= 85) {
        color = "#43B5B3";
      }

      return color;
    },
    [value]
  );

  return (
    <div className="relative w-max">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="55px"
        height="34px"
        viewBox="0 0 55 34"
        version="1.1"
      >
        <g
          id="Errors"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity="0.899868278"
        >
          <g transform="translate(-1041.000000, -110.000000)">
            <g id="Group" transform="translate(64.000000, 52.000000)">
              <g
                id="Group-8"
                transform="translate(1004.500000, 75.000000) scale(-1, 1) translate(-1004.500000, -75.000000) translate(977.000000, 58.000000)"
              >
                <path
                  d="M54.0126613,20.4915043 C54.5957796,22.7142174 54.9074462,25.0460261 54.9074462,27.4493826 L54.9053763,27.6066696 C54.8994624,28.6337652 54.8367742,29.6475565 54.7205645,30.645087 C54.4659677,32.8296609 52.3088441,34.2653478 50.1842473,33.696513 C48.4369624,33.2284957 47.3212903,31.5465304 47.5270968,29.7495565 C47.5874194,29.2215957 47.6272833,28.6874815 47.6458153,28.1479625 L47.6613441,27.4493826 C47.6613441,25.8891275 47.4828442,24.37022 47.1464045,22.9109327 L47.0128763,22.3665304 L46.8541315,21.7919963 L46.798,21.6117299 L53.28897,18.1623391 C53.5635253,18.9237666 53.8053173,19.7007202 54.0126613,20.4915043 Z"
                  id="Path"
                  fill={valueAsColor(1)}
                />
                <path
                  d="M46.9351075,8.12821739 C48.6238441,9.83058261 50.0919892,11.7517304 51.2919355,13.8458348 C51.7463792,14.638883 52.1623281,15.4566145 52.537263,16.2964936 L46.1166547,19.7102097 C45.7941213,18.9384638 45.4249978,18.1908097 45.0136559,17.4702348 C44.2298566,16.0968319 43.2923231,14.8227097 42.2207848,13.674447 L41.8127151,13.2498 L41.3917014,12.8365188 C41.1624868,12.6179983 40.9281306,12.4048647 40.6888445,12.1972874 L44.6617917,6.07762514 C45.457151,6.71897085 46.2161198,7.4036869 46.9351075,8.12821739 Z"
                  id="Path"
                  fill={valueAsColor(2)}
                />
                <path
                  d="M34.5988441,0.944608696 C36.9537903,1.57996522 39.1842473,2.52073043 41.2449731,3.72285217 C41.8650251,4.08457253 42.4696737,4.46982513 43.0576931,4.87739611 L39.1083407,10.9577334 C38.6275017,10.6175714 38.1312331,10.2977664 37.6208871,9.9994 C36.2706601,9.209943 34.8225385,8.57232711 33.2979132,8.11084691 L32.7226344,7.94609565 L32.1592006,7.80228622 C31.1337778,7.55611301 30.0787613,7.38727311 29.0000623,7.30420983 L29.0000623,0.0432511219 C30.925499,0.151168064 32.7983385,0.459053523 34.5988441,0.944608696 Z"
                  id="Path"
                  fill={valueAsColor(3)}
                />
                <path
                  d="M26.9987753,0.00372994158 L26.999,7.25172994 L26.8481745,7.25389807 C25.4407044,7.29618931 24.0697655,7.48517837 22.7481576,7.80236408 L22.1848118,7.94609565 L21.6095233,8.11092478 C20.0848493,8.57256071 18.6365233,9.209943 17.2865591,9.9994 C17.0406261,10.1431812 16.7979546,10.2919408 16.5586984,10.445558 L12.6119485,4.36753657 C12.9568293,4.14506301 13.3069839,3.93009115 13.6621774,3.72285217 C15.5511761,2.62090725 17.5830445,1.73882283 19.7224795,1.10984712 L20.3086022,0.944608696 L20.8807376,0.796901178 C22.8460076,0.312096072 24.8937993,0.0382749161 26.9987753,0.00372994158 Z"
                  id="Path"
                  fill={valueAsColor(4)}
                />
                <path
                  d="M10.9709583,5.51239997 L14.9289633,11.6086956 C14.4388971,11.9961862 13.9671803,12.4058926 13.5154592,12.8365188 L13.0944355,13.2498 L12.6863661,13.6745248 C11.6148311,14.8229433 10.6773268,16.0968319 9.89379032,17.4702348 C9.48970535,18.1780973 9.12636223,18.912092 8.80787556,19.6693868 L2.387567,16.2569746 C2.7578851,15.4309587 3.16790201,14.6264393 3.61521505,13.8458348 C4.70607527,11.9421036 6.01858749,10.1813116 7.51698347,8.59811087 L7.97204301,8.12851304 L8.39940513,7.70708799 C9.21031275,6.92459163 10.069138,6.19141382 10.9709583,5.51239997 Z"
                  id="Path"
                  fill={valueAsColor(5)}
                />
                <path
                  d="M1.63370694,18.1193923 L8.11972139,21.5693557 L8.05310711,21.7919963 L8.05310711,21.7919963 L7.89427419,22.3665304 L7.76082393,22.9109327 C7.42454002,24.37022 7.24580645,25.8891275 7.24580645,27.4493826 L7.24994624,27.6066696 C7.25556452,28.3307217 7.29991935,29.0456087 7.38034946,29.7495565 C7.58586022,31.5465304 6.47018817,33.2282 4.72319892,33.696513 C2.59860215,34.2653478 0.441478495,32.8296609 0.186586022,30.645087 C0.0936182796,29.8470626 0.0349043011,29.0386313 0.0122608602,28.2213068 L1.8189894e-12,27.4493826 C1.8189894e-12,25.264513 0.257575758,23.1387739 0.742290945,21.1003755 L0.894489247,20.4915043 L1.05502193,19.9059541 C1.22795046,19.3017055 1.42110274,18.7059259 1.63370694,18.1193923 Z"
                  id="Path"
                  fill={valueAsColor(6)}
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div className="absolute bottom-1 w-full">
        <p
          className="text-center font-helvetica text-[12px] font-bold leading-[0.7em]"
          style={{
            color:
              valueAsText === "HIGH"
                ? "#28ABAB"
                : valueAsText === "MED"
                ? "#EDA83D"
                : "#E97474",
          }}
        >
          {valueAsText}
        </p>
      </div>
    </div>
  );
};

export default CircleBar;
