import { FC } from "react";

const ResultFinancialTableRow: FC = ({ children }) => {
  return (
    <tr className="grid grid-cols-[1fr_220px_220px_24px] border-b border-[#C7BDCD]/50 px-[11px] py-2 text-right font-helvetica text-[11px] text-[#6F5F7B] last:border-none desktop:text-xs">
      {children}
    </tr>
  );
};

export default ResultFinancialTableRow;
