import { FC, memo, useCallback, useMemo } from "react";
import {
  FormErrors,
  InputDate,
  InputNumber,
  InputSelect,
  InputSwitch,
  Label,
  Option,
  OptionGroup,
  useForm,
} from "../components/form";
import { Dollar } from "../icons";
import { FinancialForm, FinancialFormInputContainer } from "../financial";
import { Frequency, IApplicantParty } from "../applicant";
import { useStore } from "..";
import {
  incomeTypeOtherData,
  incomeTypeRentalData,
  incomeTypeSalaryWagesData,
  IIncome,
  IIncomeAttributes,
} from ".";
import { format, parse } from "date-fns";
import { decimalToPercentage } from "../util";
import shallow from "zustand/shallow";
import InfoRounded from "../icons/InfoRounded";

type Category = "Salary/Wages" | "Rental Income" | "Other Income";

type IncomeTypeOtherData = typeof incomeTypeOtherData[0];

interface IncomeTypeData extends Omit<IncomeTypeOtherData, "Group"> {
  Group: string | null;
}

type OptionsGroup = {
  label: string | null;
  options: {
    "ExpenseType.Name": string;
    "ExpenseType.Code": string;
  }[];
};

export type Value = Omit<IIncome, "_id" | "Attributes"> & {
  [K in keyof IIncomeAttributes]: IIncomeAttributes[K]["value"];
};

const frequency: {
  value: Frequency;
  label: string;
}[] = [
  {
    value: "Yearly",
    label: "Yearly",
  },
  {
    value: "HalfYearly",
    label: "Half-yearly",
  },
  {
    value: "Quarterly",
    label: "Quarterly",
  },
  {
    value: "Monthly",
    label: "Monthly",
  },
  {
    value: "Fortnightly",
    label: "Fortnightly",
  },
  {
    value: "Weekly",
    label: "Weekly",
  },
];

const propertyCountry = ["NZ", "Offshore"];
const boarderType = [
  {
    label: "All Inclusive",
    value: "AllInclusive",
  },
  {
    label: "Room Charge Only",
    value: "RoomChargeOnly",
  },
];
const KiwisaverContributions = [0.03, 0.04, 0.06, 0.08, 0.1];

const IncomeForm: FC<{
  header?: string;
  initialValue?: Value;
  category: Category;
  applicantPartyId: IApplicantParty["_id"];
  onSave: (value: Value) => void;
  onCancel: () => void;
}> = ({
  header,
  initialValue,
  applicantPartyId,
  category,
  onSave,
  onCancel,
}) => {
  const [applicants, kiwisaver] = useStore(
    useCallback(
      (state) => [
        state.ApplicantParties.get(applicantPartyId)?.Applicant,
        state
          .getFinancial(applicantPartyId, "Expense")
          .find((expense) => expense.ExpenseType === "Kiwisaver"),
      ],
      [applicantPartyId]
    ),
    shallow
  );

  // INCOME TYPE OPTIONS
  const IncomeTypeOptions = useMemo(() => {
    let incomeTypeOptionsData: IncomeTypeData[] = [];

    switch (category) {
      case "Salary/Wages":
        incomeTypeOptionsData = incomeTypeSalaryWagesData;
        break;
      case "Rental Income":
        incomeTypeOptionsData = incomeTypeRentalData;
        break;
      default:
        incomeTypeOptionsData = incomeTypeOtherData;
        break;
    }

    return incomeTypeOptionsData.reduce<OptionsGroup[]>((prev, current) => {
      const group = prev.find((v) => v.label === current.Group);
      const option = {
        "ExpenseType.Code": current["IncomeType.Code"],
        "ExpenseType.Name": current["IncomeType.Name"],
      };

      if (group) {
        group.options.push(option);
      } else {
        prev.push({
          label: current.Group,
          options: [option],
        });
      }

      return prev;
    }, []);
  }, [category]);

  const ApplicantId =
    applicants && applicants.size === 1
      ? applicants.get("applicant")?.ApplicantId
      : null;

  const { values, handleChange, handleSubmit, hasErrors } = useForm<Value>({
    initialValues: initialValue || {
      ApplicantId: ApplicantId || null,
      IncomeType: "",
      Amount: NaN,
      AmountType: category === "Salary/Wages" ? "Gross" : null,
      Frequency: "Yearly",
      BoarderIncomeType: null,
      PropertyBuildDate: null,
      PropertyCountry: null,
      IsTaxExempt: null,
      SalePurchaseDate: null,
      Kiwisaver: null,
      KiwisaverContribution: KiwisaverContributions[0],
    },
    validate: (values) => {
      const errors: FormErrors<Value> = {};

      if (!values.IncomeType) {
        errors.IncomeType = "Income type cannot be blank";
      }

      if (!values.Amount || values.Amount <= 0 || isNaN(values.Amount)) {
        errors.Amount = "Income amount must be atleast $1";
      }

      if (!values.Frequency) {
        errors.Frequency = "Frequency cannot be blank";
      }

      if (values.Kiwisaver && !values.KiwisaverContribution) {
        errors.KiwisaverContribution = "Kiwisaver Contribution is mandatory";
      }

      return errors;
    },
    validateOnMount: true,
    onSubmit: (values) => {
      const isRentalIncome = values.IncomeType === "RentalIncome";
      const isBoarderIncome = values.IncomeType === "BoarderIncome";
      const isOtherNetIncome = values.IncomeType === "OtherNetIncome";

      const saveValues: Value = values;

      if (!isRentalIncome) {
        saveValues.PropertyCountry = null;
        saveValues.PropertyBuildDate = null;
        saveValues.IsTaxExempt = null;
        saveValues.SalePurchaseDate = null;
      }

      if (!isBoarderIncome) {
        saveValues.BoarderIncomeType = null;
      }

      if (isOtherNetIncome) {
        saveValues.AmountType = "Net";
      }

      if (!values.Kiwisaver) {
        saveValues.KiwisaverContribution = null;
      }

      onSave(saveValues);
    },
  });

  return (
    <FinancialForm
      header={header}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isValid={!hasErrors}
    >
      <FinancialFormInputContainer>
        <Label htmlFor="name">Applicant</Label>
        <InputSelect
          initialValue={values.ApplicantId}
          onChange={(value) => handleChange("ApplicantId", value)}
        >
          <Option value={null}></Option>
          {Array.from(applicants?.values() || []).map((applicant) => (
            <Option key={applicant.ApplicantId} value={applicant.ApplicantId}>
              {applicant.Name}
            </Option>
          ))}
        </InputSelect>
      </FinancialFormInputContainer>
      <FinancialFormInputContainer>
        <Label htmlFor="type" className="required">
          Type
        </Label>
        <InputSelect
          initialValue={values.IncomeType}
          onChange={(value) => handleChange("IncomeType", value)}
          searchable={category === "Other Income"}
        >
          {IncomeTypeOptions.map((type) => {
            if (type.label) {
              return (
                <OptionGroup label={type.label} key={type.label}>
                  {type.options.map((option) => (
                    <Option
                      key={option["ExpenseType.Code"]}
                      value={option["ExpenseType.Code"]}
                    >
                      {option["ExpenseType.Name"]}
                    </Option>
                  ))}
                </OptionGroup>
              );
            }

            return type.options.map((option) => (
              <Option
                key={option["ExpenseType.Code"]}
                value={option["ExpenseType.Code"]}
              >
                {option["ExpenseType.Name"]}
              </Option>
            ));
          })}
        </InputSelect>
      </FinancialFormInputContainer>
      <FinancialFormInputContainer>
        <Label htmlFor="amount" className="required">
          {category === "Other Income"
            ? values.IncomeType === "OtherNetIncome"
              ? "Amount (Net)"
              : "Amount (Gross)"
            : "Amount"}
        </Label>
        <InputNumber
          id="amount"
          withIcon={<Dollar />}
          value={values.Amount}
          onChange={(value) => {
            handleChange("Amount", value);
          }}
          autonumeric
        />
      </FinancialFormInputContainer>
      {category === "Salary/Wages" && (
        <FinancialFormInputContainer>
          <Label htmlFor="AmountType" className="required">
            Amount Type
          </Label>
          <InputSelect
            initialValue={values.AmountType}
            onChange={(value) => handleChange("AmountType", value)}
          >
            <Option value="Gross">Gross</Option>
            <Option value="Net">Net</Option>
          </InputSelect>
        </FinancialFormInputContainer>
      )}
      <FinancialFormInputContainer>
        <Label htmlFor="frequency" className="required">
          Frequency
        </Label>
        <InputSelect
          initialValue={values.Frequency}
          onChange={(value) => handleChange("Frequency", value)}
        >
          {frequency.map((fre) => (
            <Option value={fre.value} key={fre.value}>
              {fre.label}
            </Option>
          ))}
        </InputSelect>
      </FinancialFormInputContainer>
      {category === "Salary/Wages" && values.IncomeType === "SalaryWages" && (
        <FinancialFormInputContainer>
          <Label htmlFor="Kiwisaver">Kiwisaver</Label>
          {!kiwisaver && (
            <InputSwitch
              id="Kiwisaver"
              value={values.Kiwisaver}
              onChange={(value) => handleChange("Kiwisaver", value)}
            >
              <InputSwitch.InputSwitchOption value={true}>
                Yes
              </InputSwitch.InputSwitchOption>
              <InputSwitch.InputSwitchOption value={false}>
                No
              </InputSwitch.InputSwitchOption>
            </InputSwitch>
          )}
          {kiwisaver && (
            <div className="flex items-start justify-start">
              <InfoRounded
                width={20}
                height={20}
                className="mr-2 mt-0.5 shrink-0"
              />
              <span className="font-helvetica text-[11px] italic text-[#6F5F7B] desktop:text-sm">
                Kiwisaver has already been entered in Expenses
              </span>
            </div>
          )}
        </FinancialFormInputContainer>
      )}
      {values.Kiwisaver && (
        <FinancialFormInputContainer>
          <Label htmlFor="KiwisaverContribution" className="required">
            Contribution
          </Label>
          <InputSelect
            initialValue={values.KiwisaverContribution}
            onChange={(value) => handleChange("KiwisaverContribution", value)}
          >
            {KiwisaverContributions.map((value) => (
              <Option value={value} key={value}>
                {decimalToPercentage(value)}
              </Option>
            ))}
          </InputSelect>
        </FinancialFormInputContainer>
      )}
      {category === "Rental Income" && values.IncomeType === "RentalIncome" && (
        <>
          <FinancialFormInputContainer>
            <Label htmlFor="propertyCountry">Property Country</Label>
            <InputSelect
              initialValue={values.PropertyCountry}
              onChange={(value) => handleChange("PropertyCountry", value)}
            >
              <Option value={null} />
              {propertyCountry.map((property) => (
                <Option key={property} value={property}>
                  {property}
                </Option>
              ))}
            </InputSelect>
          </FinancialFormInputContainer>
          <FinancialFormInputContainer>
            <Label htmlFor="propertyBuildDate">Property Build Date</Label>
            <InputDate
              id="propertyBuildDate"
              placeholder="DD / MM / YYYY"
              value={
                values.PropertyBuildDate
                  ? parse(values.PropertyBuildDate, "yyyy-MM-dd", new Date())
                  : undefined
              }
              onChange={(date) =>
                handleChange("PropertyBuildDate", format(date, "yyyy-MM-dd"))
              }
            />
          </FinancialFormInputContainer>
          <FinancialFormInputContainer>
            <Label htmlFor="SalePurchaseDate">Sale & Purchase Date</Label>
            <InputDate
              id="SalePurchaseDate"
              placeholder="DD / MM / YYYY"
              value={
                values.SalePurchaseDate
                  ? parse(values.SalePurchaseDate, "yyyy-MM-dd", new Date())
                  : undefined
              }
              onChange={(date) =>
                handleChange("SalePurchaseDate", format(date, "yyyy-MM-dd"))
              }
            />
          </FinancialFormInputContainer>
          <FinancialFormInputContainer>
            <Label htmlFor="taxExempt">Tax Exempt</Label>
            <InputSwitch
              id="taxExempt"
              value={values.IsTaxExempt}
              onChange={(value) => handleChange("IsTaxExempt", value)}
            >
              <InputSwitch.InputSwitchOption value={true}>
                Yes
              </InputSwitch.InputSwitchOption>
              <InputSwitch.InputSwitchOption value={false}>
                No
              </InputSwitch.InputSwitchOption>
            </InputSwitch>
          </FinancialFormInputContainer>
        </>
      )}
      {category === "Rental Income" && values.IncomeType === "BoarderIncome" && (
        <FinancialFormInputContainer>
          <Label htmlFor="boarderType">Boarder Type</Label>
          <InputSelect
            initialValue={values.BoarderIncomeType}
            onChange={(value) => handleChange("BoarderIncomeType", value)}
          >
            <Option value={null} />
            {boarderType.map((type) => (
              <Option key={type.value} value={type.value}>
                {type.label}
              </Option>
            ))}
          </InputSelect>
        </FinancialFormInputContainer>
      )}
    </FinancialForm>
  );
};

export default memo(IncomeForm);
