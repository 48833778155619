import { FC, SVGProps } from "react";

const LVRIcon: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#9A86A8",
  ...props
}) => {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>LVR</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 Z M11.59151,10.1393969 C10.2649809,10.1393969 9.1858872,11.139713 9.1858872,12.369401 C9.1858872,13.599089 10.2649809,14.5994051 11.59151,14.5994051 C12.9180391,14.5994051 13.9971328,13.599089 13.9971328,12.369401 C13.9971328,11.139713 12.9180391,10.1393969 11.59151,10.1393969 Z M13.0279909,4.40231485 L3.00171728,13.6966361 L3.97372636,14.5976852 L14,5.3033639 L13.0279909,4.40231485 Z M11.59151,11.413685 C12.1619863,11.413685 12.6224912,11.8405714 12.6224912,12.369401 C12.6224912,12.8982305 12.1619863,13.325117 11.59151,13.325117 C11.0210337,13.325117 10.5605288,12.8982305 10.5605288,12.369401 C10.5605288,11.8405714 11.0210337,11.413685 11.59151,11.413685 Z M5.40562281,4.40510067 C4.07909366,4.40510067 3,5.4054168 3,6.63510476 C3,7.86479273 4.07909366,8.86510886 5.40562281,8.86510886 C6.73215194,8.86510886 7.8112456,7.86479273 7.8112456,6.63510476 C7.8112456,5.4054168 6.73215194,4.40510067 5.40562281,4.40510067 Z M5.40562281,5.67938872 C5.97609906,5.67938872 6.436604,6.10627522 6.436604,6.63510476 C6.436604,7.1639343 5.97609906,7.5908208 5.40562281,7.5908208 C4.83514654,7.5908208 4.3746416,7.1639343 4.3746416,6.63510476 C4.3746416,6.10627522 4.83514654,5.67938872 5.40562281,5.67938872 Z"
          id="LVR"
          fill={fill}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export default LVRIcon;
