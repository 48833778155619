const Check = () => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.839 2.0715C13.839 2.3095 13.7557 2.51183 13.589 2.6785L5.9105 10.357C5.74383 10.5237 5.5415 10.607 5.3035 10.607C5.0655 10.607 4.86317 10.5237 4.6965 10.357L0.25 5.9105C0.0833333 5.74383 0 5.5415 0 5.3035C0 5.0655 0.0833333 4.86317 0.25 4.6965L1.4645 3.482C1.63117 3.31533 1.8335 3.232 2.0715 3.232C2.3095 3.232 2.51183 3.31533 2.6785 3.482L5.3035 6.116L11.1605 0.25C11.3272 0.0833333 11.5295 0 11.7675 0C12.0055 0 12.2078 0.0833333 12.3745 0.25L13.589 1.4645C13.7557 1.63117 13.839 1.8335 13.839 2.0715Z"
        fill="#2DBFC1"
      />
    </svg>
  );
};

export default Check;
