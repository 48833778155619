import { FC } from "react";
import { VersionHistory } from "../..";

const Header: FC = ({ children }) => {
  return (
    <header className="col-span-full flex max-w-full items-center justify-start">
      <h1 className="font-helvetica text-[21px] font-light leading-[25px] text-[#736878] desktop:text-3xl">
        {children}
      </h1>
      <VersionHistory />
    </header>
  );
};

export default Header;
