import { FC } from "react";

const Label: FC<{
  htmlFor: string;
  className?: string;
}> = ({ children, htmlFor, className }) => {
  return (
    <label
      htmlFor={htmlFor}
      className={`initial:w-full initial:cursor-pointer initial:font-helvetica initial:text-xs initial:text-[#6F5F7B] initial:desktop:text-sm ${className}`}
    >
      {children}
    </label>
  );
};

export default Label;
