import { FC } from "react";

const Location: FC<{
  fill?: string;
}> = ({ fill = "#9A86A8" }) => {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 12C4.5 12 0 6.98513 0 4.5C0 2.01472 2.01472 0 4.5 0C6.98528 0 9 2.01472 9 4.5C9 6.98513 4.5 12 4.5 12ZM4.5 2.625C3.46447 2.625 2.625 3.46447 2.625 4.5C2.625 5.53553 3.46447 6.375 4.5 6.375C5.53553 6.375 6.375 5.53553 6.375 4.5C6.375 3.46447 5.53553 2.625 4.5 2.625Z"
        fill={fill}
      />
    </svg>
  );
};

export default Location;
