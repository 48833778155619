import { FC } from "react";
import { twMerge } from "tailwind-merge";

const InputContainer: FC<{
  className?: string;
}> = ({ children, className }) => {
  return (
    <div
      className={twMerge(
        "flex w-full items-center gap-x-2 border-b border-[#DCD4DF] py-3 first:pt-0 last:pb-0",
        className
      )}
    >
      {children}
    </div>
  );
};

export default InputContainer;
