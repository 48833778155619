import { format, parse } from "date-fns";
import { FC, memo, useMemo } from "react";
import { LenderStatsLEM, LenderStatsLVR, LenderStatsServicing } from ".";
import LenderStatsDTI from "./LenderStatsDTI";
import { ILenderResult } from "./report";

const LenderStats: FC<{
  lenderResult: ILenderResult;
  spreadsheetImage?: string;
}> = ({ spreadsheetImage, lenderResult }) => {
  const lenderName = lenderResult.Lender.Name;
  const { ReleaseDate, VersionNumber, VersionReleaseNote } =
    lenderResult.Version;
  const releaseDate = useMemo(() => {
    if (!ReleaseDate) return null;
    try {
      const date = parse(ReleaseDate, "yyyy-MM-dd", new Date());

      return format(date, "dd MMM yyyy");
    } catch (error) {
      return "";
    }
  }, [ReleaseDate]);

  return (
    <section className="space-y-[11px] py-4">
      <LenderStatsServicing
        lenderResult={lenderResult}
        spreadsheetImage={spreadsheetImage}
      />
      <LenderStatsLVR lenderResult={lenderResult} />
      <LenderStatsDTI lenderResult={lenderResult} />
      <LenderStatsLEM lenderResult={lenderResult} />
      <footer className="mx-4 pt-[5px]">
        <p className="font-helvetica text-[10px] text-[#9A86A8] desktop:text-[13px]">
          <strong className="font-bold">
            {lenderName} version {VersionNumber}
          </strong>{" "}
          <span className="font-light italic">
            {ReleaseDate
              ? `(released ${releaseDate})`
              : "This information not available"}
          </span>
          {VersionReleaseNote && (
            <span className="ml-2 inline-block rounded-[100px] bg-gradient-to-b from-[#907AA0] to-[#A491B0] px-2 py-1 font-bold text-white">
              {VersionReleaseNote}
            </span>
          )}
        </p>
      </footer>
    </section>
  );
};

export default memo(LenderStats);
