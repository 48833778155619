import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { App } from "../app";
import PdfGenerator from "../app/pages/pdfGenerator/PdfGenerator";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/pdf" element={<PdfGenerator />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
