import "./App.css";
import { FC, useCallback, useEffect } from "react";
import {
  Layout,
  Header,
  SideBar,
  SidebarContent,
  SidebarFooter,
  ResultStatus,
} from "./components/layout";
import { Applicants } from "./applicant";
import { Securities } from "./security";
import ErrorBoundary from "./ErrorBoundary";
import { ResultManager } from "./result";
import useStore, { SearchSuccessEvent } from "./store";
import AuthModal from "./auth/AuthModal";
import {
  SearchButton,
  SearchValidation,
  SearchId,
  ResumeReport,
  LoadSession,
} from ".";
import { FinancialPosition } from "./financial";

const App: FC = () => {
  const [
    setupPreviewSettings,
    addEventListener,
    removeEventListener,
    setEnabledLenders,
  ] = useStore(
    useCallback(
      (state) => [
        state.setupPreviewSettings,
        state.addEventListener,
        state.removeEventListener,
        state.setEnabledLenders,
      ],
      []
    )
  );
  useEffect(() => {
    window.onbeforeunload = function () {
      return "You are about to refresh the page. All data will be lost.";
    };
    setupPreviewSettings();

    const searchParam = new URLSearchParams(window.location.search);
    const lendersQuery = searchParam.get("lenders");
    if (typeof lendersQuery === "string" && lendersQuery.length > 0) {
      setEnabledLenders(lendersQuery.replaceAll(" ", "").split(","));
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [setEnabledLenders, setupPreviewSettings]);

  useEffect(() => {
    function setFeedbackWidgetUserData(e: SearchSuccessEvent) {
      const FeedbackWidget = (window as any).FeedbackWidget;

      if (!FeedbackWidget) return;

      const report_id = e.Report.Id;

      FeedbackWidget.setUserCustomData({
        report_id,
      });
    }

    addEventListener("searchsuccess", setFeedbackWidgetUserData);

    return () => {
      removeEventListener("searchsuccess", setFeedbackWidgetUserData);
    };
  }, [addEventListener, removeEventListener]);

  return (
    <ErrorBoundary>
      <AuthModal />
      <ResultStatus />
      <ResumeReport />
      <LoadSession />
      <Layout>
        <Header>Mortgage Recommendation Engine</Header>
        <SideBar>
          <SidebarContent>
            <Applicants />
            <Securities />
            <FinancialPosition />
          </SidebarContent>
          <SidebarFooter>
            <SearchButton />
            <SearchId />
            <SearchValidation />
          </SidebarFooter>
        </SideBar>
        <ResultManager />
      </Layout>
    </ErrorBoundary>
  );
};

export default App;
