import { FC, ReactNode } from "react";
import { SectionLabel } from "../components/label";

const FinancialDetailsHeader: FC<{
  label: ReactNode;
  className?: string;
}> = ({ label, children, className }) => {
  return (
    <header className={className}>
      <SectionLabel className="text-[15px] text-[#7B5C8D] desktop:text-lg">
        {label}
      </SectionLabel>
      {children}
    </header>
  );
};

export default FinancialDetailsHeader;
