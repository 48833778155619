import currency from "currency.js";

export const currencyAbbreviation = (num: number) => {
  let text = "";
  let result = num;
  let precision = 0;

  if (num >= 1000) {
    text = "K";
    result = num / 1000;
  }

  if (num >= 1000000) {
    text = "M";
    result = num / 1000000;
  }

  // if it has decimal, show precision
  precision = result % 1 !== 0 ? 2 : 0;
  return currency(result, { precision }).format() + text;
};

const formater = {
  currencyAbbreviation,
};

export default formater;
