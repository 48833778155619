import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useStore from "../store";
import { ILoan } from "./Loan";
import { Button } from "../components/form";
import { Pencil, Trash } from "../icons";
import { Alert } from "../components/alert";
import { formatter } from "../util";
import { SecurityFormModal, propertyTypeOptionsData, usageTypeData } from ".";
import { currencyAbbreviation } from "../util/formater";

type Props = {
  LoanId: ILoan["LoanId"];
};

export default function Security({ LoanId }: Props) {
  const [deleting, setDeleting] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const [
    ApplicantParty,
    Loan,
    setLoanItem,
    setFinancialItem,
    addFinancial,
    deleteLoanItem,
    deleteFinancialItem,
    addEventListener,
  ] = useStore(
    useCallback(
      (state) => [
        Array.from(state.ApplicantParties.values())[0],
        state.Loans.get(LoanId),
        state.setLoanItem,
        state.setFinancialItem,
        state.addFinancial,
        state.deleteLoanItem,
        state.deleteFinancialItem,
        state.addEventListener,
      ],
      [LoanId]
    )
  );

  const Liability = useMemo(() => {
    if (!Loan) return;

    return Array.from(ApplicantParty.Liability.values()).find(
      (liability) => liability.LoanId === Loan.LoanId
    );
  }, [ApplicantParty.Liability, Loan]);

  const securityFormInitialValue = useRef<
    ComponentProps<typeof SecurityFormModal>["initialValue"]
  >({
    Loan,
    Liability,
  });

  function showModal() {
    setEditing(true);
  }
  function closeModal() {
    setEditing(false);
  }

  function deleteLoan() {
    if (Liability) {
      deleteFinancialItem(ApplicantParty._id, {
        type: "Liability",
        id: Liability._id,
      });
    }

    deleteLoanItem(LoanId);
    setDeleting(false);
  }

  const updateLoan: ComponentProps<typeof SecurityFormModal>["onSave"] = (
    value
  ) => {
    const { Loan: NewLoan, Liability: NewLiability } = value;
    if (!Loan || !NewLoan) return;

    setLoanItem(Loan._id, NewLoan);

    const previousLoanType = securityFormInitialValue.current?.Loan?.LoanType;

    switch (previousLoanType) {
      case "New":
        if (NewLoan.LoanType === "Existing") {
          addFinancial(ApplicantParty._id, {
            type: "Liability",
            value: {
              LoanId: Loan.LoanId,
              Lender: value.Liability?.Lender ?? null,
              Balance: value.Liability?.Balance ?? null,
              Limit: value.Liability?.Limit ?? null,
              InterestRate: value.Liability?.InterestRate ?? null,
              RepaymentAmount: value.Liability?.RepaymentAmount ?? null,
              RepaymentFrequency: value.Liability?.RepaymentFrequency ?? null,
              RepaymentType: value.Liability?.RepaymentType ?? null,
              TotalTermMonths: value.Liability?.TotalTermMonths ?? null,
              StartDate: value.Liability?.StartDate ?? null,
              IsRevolvingCredit: value.Liability?.IsRevolvingCredit ?? null,
              InterestOnlyTermMonths:
                value.Liability?.InterestOnlyTermMonths ?? null,
              ApplicantId: Array.from(ApplicantParty.Applicant.values())[0]
                .ApplicantId,
              LiabilityType: "HomeLoan",
            },
          });
        }
        break;
      case "Existing":
        if (Liability) {
          if (NewLoan.LoanType === "New" && Liability) {
            deleteFinancialItem(ApplicantParty._id, {
              id: Liability._id,
              type: "Liability",
            });
          } else if (NewLoan.LoanType === "Existing" && NewLiability) {
            setFinancialItem(ApplicantParty._id, {
              id: Liability._id,
              type: "Liability",
              payload: NewLiability,
            });
          }
        }
        break;
      default:
        throw Error("Unknown Loan Type");
    }
    securityFormInitialValue.current = {
      Loan: NewLoan,
      Liability: NewLiability,
    };
    closeModal();
  };

  useEffect(() => {
    if (!Liability) return;

    addEventListener("starteditfinancial", (e) => {
      if (e.type === "Liability" && e.financial._id === Liability._id) {
        showModal();
      }
    });
  }, [Liability, addEventListener]);

  if (!Loan) return null;

  const PropertyType = propertyTypeOptionsData.find(
    (propertyType) => propertyType.code === Loan.Security.PropertyType[0]
  );
  const UsageType = usageTypeData.find(
    (usageType) => usageType.code === Loan.Security.UsageType
  );

  const LoanAmount =
    Loan.LoanType === "New" || Loan.LendingAction === "Refinance"
      ? Loan.LoanAmount
      : Liability?.Limit;
  const TotalTermMonths =
    ((Loan.LoanType === "Existing" && Loan.LendingAction === "None"
      ? Liability?.TotalTermMonths
      : Loan.TotalTermMonths) || 0) % 12;
  const TotalTermYears = Math.floor(
    ((Loan.LoanType === "Existing" && Loan.LendingAction === "None"
      ? Liability?.TotalTermMonths
      : Loan.TotalTermMonths) || 0) / 12
  );
  const Term = `${TotalTermYears}yrs ${
    TotalTermMonths ? `${TotalTermMonths}mos` : ""
  }`;
  const TotalLoanAmount = (LoanAmount || 0) + (Loan.TopUpAmount || 0);
  const DepositAmount = Loan.Security.Value - TotalLoanAmount;
  let LVR = (TotalLoanAmount / Loan.Security.Value) * 100;
  if (LVR % 1 !== 0) {
    LVR = parseFloat(LVR.toFixed(2));
  }

  return (
    <>
      <SecurityFormModal
        title="Edit security"
        mode="edit"
        open={isEditing}
        initialValue={securityFormInitialValue.current}
        handleClose={closeModal}
        onDelete={deleteLoan}
        onSave={updateLoan}
      />
      <section className="rounded-[3px] bg-[#F7F4F9] p-[10px]">
        <header className="flex items-center">
          <ul className="flex items-center justify-start gap-1">
            {Loan.LoanType === "New" && (
              <li className="rounded-[3px] border border-accent bg-accent px-1 py-0.5 font-helvetica text-[8px] font-bold text-white desktop:text-[10px]">
                NEW
              </li>
            )}
            {Loan.LoanType === "Existing" && (
              <>
                <li className="rounded-[3px] border border-[#A391B0] px-1 py-0.5 font-helvetica text-[8px] font-bold text-[#A391B0] desktop:text-[10px]">
                  EXISTING
                </li>
                {Loan.LendingAction === "Refinance" && (
                  <li className="rounded-[3px] border border-[#ED9D49] bg-[#ED9D49] px-1 py-0.5 font-helvetica text-[8px] font-bold text-white desktop:text-[10px]">
                    REFINANCE
                  </li>
                )}
                {Loan.LendingAction === "TopUp" && (
                  <li className="rounded-[3px] border border-[#5B95FD] bg-[#5B95FD] px-1 py-0.5 font-helvetica text-[8px] font-bold text-white desktop:text-[10px]">
                    TOP-UP
                  </li>
                )}
              </>
            )}
          </ul>
          <Button
            className="ml-auto w-max bg-transparent p-2 text-accent underline"
            size="small"
            onClick={showModal}
          >
            <Pencil
              fill="#9B88AA"
              width={undefined}
              height={undefined}
              className="w-[10px] desktop:w-[14px]"
            />
          </Button>
          <Alert
            below={
              <Button
                className="-mr-2 w-max bg-transparent p-2 text-accent underline"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleting(true);
                }}
              >
                <Trash className="w-[10px] desktop:w-[14px]" />
              </Button>
            }
            open={deleting}
            backgroundColor="#1E1642"
            className="translate-y-1.5 text-left font-helvetica text-[11px] text-white shadow-none desktop:text-sm"
            arrowClassName="-translate-y-1 -translate-x-1"
            onClose={() => setDeleting(false)}
          >
            <p className="font-bold">Are you sure?</p>
            <p className="mt-[5px]">Deleting this item cannot be undone.</p>
            <div className="mt-3 flex items-center justify-end space-x-[28px]">
              <Button
                size="small"
                className="w-max bg-transparent text-white"
                onClick={() => setDeleting(false)}
              >
                <span className="font-helvetica text-[11px] leading-[1em] desktop:text-sm">
                  Cancel
                </span>
              </Button>
              <Button
                className="w-max bg-[#E13756] px-4 py-2 font-bold text-white"
                size="small"
                onClick={deleteLoan}
              >
                <span className="font-helvetica text-[11px] leading-[1em] desktop:text-sm">
                  Yes, delete
                </span>
              </Button>
            </div>
          </Alert>
        </header>
        <section className="mt-2.5 font-helvetica text-[15px] leading-[18px] text-[#83748E]">
          <span>
            {formatter.currencyAbbreviation(Loan.Security.Value)}
            {PropertyType && <> • {PropertyType.type}</>}
          </span>
          <br />
          {UsageType && <span>{UsageType.name}</span>}
        </section>
        <section className="mt-3 border border-[#CDC4D5] px-2.5 py-1.5 font-helvetica text-[13px] text-[#83748E]">
          <table className="w-max">
            <tbody>
              <tr>
                <td className="mr-3 block align-baseline font-bold">
                  {Loan.LoanType} Loan:
                </td>
                <td>{`${currencyAbbreviation(LoanAmount || 0)} / ${Term}`}</td>
              </tr>
              {Loan.LendingAction === "TopUp" && (
                <tr>
                  <td className="mr-3 block align-baseline font-bold">
                    Increase:
                  </td>
                  <td>{`${currencyAbbreviation(
                    Loan.TopUpAmount
                  )} (${currencyAbbreviation(TotalLoanAmount)})`}</td>
                </tr>
              )}
              <tr>
                <td className="mr-3 block align-baseline font-bold">
                  Deposit:
                </td>
                <td>{currencyAbbreviation(DepositAmount)}</td>
              </tr>
              <tr>
                <td className="mr-3 block align-baseline font-bold">LVR:</td>
                <td>{LVR}%</td>
              </tr>
            </tbody>
          </table>
        </section>
      </section>
    </>
  );
}
