import { FC, FormEventHandler } from "react";
import { FinancialFormFooter, FinancialFormHeader } from ".";

const FinancialForm: FC<{
  header?: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
  onCancel?: () => void;
  isValid?: boolean;
}> = ({ children, header, onSubmit, onCancel, isValid = true }) => {
  return (
    <form className="bg-white px-4 pt-4 pb-[25px]" onSubmit={onSubmit}>
      <FinancialFormHeader>{header}</FinancialFormHeader>
      <div className="mt-4 space-y-2 border-y border-[#C7BDCD]/50 pt-[21px] pb-[30px]">
        {children}
      </div>
      <FinancialFormFooter onCancel={onCancel} isValid={isValid} />
    </form>
  );
};

export default FinancialForm;
