import { FC } from "react";
import { Button } from "../components/form";
import { SectionLabel } from "../components/label";
import { WarningCircleLg } from "../icons";

const ExpenseImportError: FC<{
  onTryAgain?: () => void;
  onCancel?: () => void;
}> = ({ onTryAgain, onCancel }) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-6 py-4">
      <WarningCircleLg />
      <SectionLabel className="text-base desktop:text-xl">
        Oops, something is not right
      </SectionLabel>
      <p className="max-w-xs text-center font-helvetica text-[11px] text-[#6F5F7B] desktop:max-w-md desktop:text-base">
        Please make sure that the CSV files are directly from Illion, unchanged
        and try to upload again.
      </p>
      <div className="flex w-full items-center justify-center space-x-4">
        <Button
          size="medium"
          className="max-w-[10rem] border border-[#6F5F7B] bg-transparent py-2 text-[#6F5F7B] hover:bg-[#6F5F7B] hover:text-white"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          size="medium"
          className="max-w-[10rem] py-2"
          onClick={onTryAgain}
        >
          Try Again
        </Button>
      </div>
    </div>
  );
};

export default ExpenseImportError;
