import { Frequency } from "../applicant";

const frequencies: {
  code: Frequency;
  name: string;
  year: number;
}[] = [
  {
    code: "Weekly",
    name: "Weekly",
    year: 1 / 52,
  },
  {
    code: "Fortnightly",
    name: "Fortnightly",
    year: 1 / 26,
  },
  {
    code: "Monthly",
    name: "Monthly",
    year: 1 / 12,
  },
  {
    code: "Quarterly",
    name: "Quarterly",
    year: 1 / 4,
  },
  {
    code: "HalfYearly",
    name: "Half Yearly",
    year: 1 / 2,
  },
  {
    code: "Yearly",
    name: "Yearly",
    year: 1,
  },
];

function convertAmountToYearly(amount: number, currentFrequency: Frequency) {
  switch (currentFrequency) {
    case "Weekly":
      return amount * 52;
    case "Fortnightly":
      return amount * 26;
    case "Monthly":
      return amount * 12;
    case "Quarterly":
      return amount * 4;
    case "HalfYearly":
      return amount * 2;
    case "Yearly":
      return amount;
  }
}

function convertAmountByFrequency(params: {
  amount: number;
  currentFrequency: Frequency;
  targetFrequency: Frequency;
}) {
  const { amount, currentFrequency, targetFrequency } = params;

  const yearlyAmount = convertAmountToYearly(amount, currentFrequency);

  switch (targetFrequency) {
    case "Weekly":
      return yearlyAmount / 52;
    case "Fortnightly":
      return yearlyAmount / 26;
    case "Monthly":
      return yearlyAmount / 12;
    case "Quarterly":
      return yearlyAmount / 4;
    case "HalfYearly":
      return yearlyAmount / 2;
    case "Yearly":
      return yearlyAmount;
  }
}

export const frequencyUtils = {
  frequencies,
  convertAmountByFrequency,
};
