import { forwardRef, InputHTMLAttributes } from "react";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  withIcon?: JSX.Element;
  iconPosition?: "right" | "left";
  wrapperClassName?: string;
  getRef?: (ref: HTMLDivElement | null) => void;
};

const InputText = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      value,
      onChange,
      withIcon,
      className,
      wrapperClassName,
      iconPosition = "left",
      getRef = null,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={`initial:w-full ${wrapperClassName} ${
          withIcon ? "initial:relative initial:flex initial:items-center" : ""
        }`}
      >
        <input
          {...props}
          ref={ref}
          type="text"
          name={id}
          id={id}
          className={`initial:w-full initial:rounded-[3px] initial:border initial:border-[#C2B6CB] initial:bg-[#FDFBFF] initial:py-1 initial:px-2 initial:font-helvetica initial:text-xs initial:text-[#6F5F7B] initial:focus:outline-accent initial:desktop:text-sm  ${className} ${
            withIcon
              ? iconPosition === "left"
                ? "initial:pl-5"
                : "initial:pr-5"
              : ""
          }`}
          value={value}
          onChange={onChange}
        />
        {withIcon && (
          <span
            className={`absolute block ${
              iconPosition === "left" ? "left-2" : "right-2"
            }`}
          >
            {withIcon}
          </span>
        )}
      </div>
    );
  }
);

export default InputText;
