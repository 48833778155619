import { AnimatePresence, motion, Variants } from "framer-motion";
import { FC, ReactNode, useEffect, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { Triangle } from "../../icons";

const animationVariants: Variants = {
  close: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  },
};

const Alert: FC<{
  below: ReactNode;
  open?: boolean;
  onClose?: () => void;
  backgroundColor?: string;
  className?: string;
  arrowClassName?: string;
  wrapperClassName?: string;
  position?: "bottom" | "left" | "right";
}> = ({
  children,
  below,
  open = false,
  onClose,
  backgroundColor = "#ffffff",
  className: clsName,
  arrowClassName,
  wrapperClassName,
  position = "bottom",
}) => {
  useEffect(() => {
    if (!onClose) return;
    window.addEventListener("click", onClose);
    return () => {
      window.removeEventListener("click", onClose);
    };
  });

  const arrowClassNamePos = useMemo(() => {
    let className = "absolute top-full left-1/2 z-20 -translate-x-1/2";
    if (position === "left") {
      className =
        "absolute top-1/2 rotate-90 -translate-y-1/2 -translate-x-2 z-20";
    }
    if (position === "right") {
      className =
        "absolute top-1/2 right-0 -rotate-90 -translate-y-1/2 translate-x-2 z-20";
    }

    return twMerge(className, arrowClassName);
  }, [arrowClassName, position]);

  const classNamePos = useMemo(() => {
    let className =
      "absolute top-full right-1/2 z-10 min-w-[230px] translate-y-[10px] translate-x-4 rounded-[3px] px-3 py-[14px] shadow-[0px_2px_4px_rgba(0,_0,_0,_0.5)]";
    if (position === "left") {
      className =
        "absolute top-[calc(50%-12px)] right-[calc(100%+4px)] z-10 min-w-[230px] rounded-[3px] px-3 py-[14px] shadow-[0px_2px_4px_rgba(0,_0,_0,_0.5)]";
    }
    if (position === "right") {
      className =
        "absolute top-[calc(50%-12px)] left-[calc(100%+4px)] z-10 min-w-[230px] rounded-[3px] px-3 py-[14px] shadow-[0px_2px_4px_rgba(0,_0,_0,_0.5)]";
    }

    return twMerge(className, clsName);
  }, [clsName, position]);

  return (
    <div
      className={`initial:relative initial:h-max ${wrapperClassName}`}
      onClick={(e) => e.stopPropagation()}
    >
      {below}
      <AnimatePresence>
        {open && (
          <>
            <motion.span
              key="arrow"
              initial="close"
              exit="close"
              animate="open"
              variants={animationVariants}
              transition={{ duration: 0.2, ease: "easeInOut" }}
              className={arrowClassNamePos}
            >
              <Triangle width={16} height={12} fill={backgroundColor} />
            </motion.span>
            <motion.div
              key="content"
              initial="close"
              exit="close"
              animate="open"
              variants={animationVariants}
              transition={{ duration: 0.2, ease: "easeInOut" }}
              className={classNamePos}
              style={{
                backgroundColor,
              }}
            >
              {children}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Alert;
