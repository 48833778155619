import {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "./InputNumber.css";
import AutoNumeric from "autonumeric";
import currency from "currency.js";
import { isNull } from "lodash";
import { twMerge } from "tailwind-merge";

const InputNumber: FC<{
  id: string;
  onChange?: (value: number) => void;
  value?: number;
  className?: string;
  wrapperClassName?: string;
  min?: number;
  max?: number;
  buttons?: boolean;
  withIcon?: ReactNode;
  iconPosition?: "right" | "left";
  autonumeric?: boolean;
  autonumericOption?: AutoNumeric.Options;
  [key: string]: any;
}> = ({
  id,
  value: initialValue,
  onChange,
  className,
  min,
  max,
  buttons = false,
  wrapperClassName,
  withIcon,
  iconPosition = "left",
  autonumeric = false,
  autonumericOption,
  ...props
}) => {
  const [value, setValue] = useState<string>(
    typeof initialValue !== "undefined" && !isNaN(initialValue)
      ? initialValue.toString()
      : ""
  );
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (
      typeof initialValue === "undefined" ||
      isNaN(initialValue) ||
      isNull(initialValue)
    )
      return;

    if (autonumeric) {
      if (initialValue !== currency(value).value) {
        setValue(initialValue.toString());
      }
    } else {
      if (initialValue !== Number(value)) {
        setValue(initialValue.toString());
      }
    }
  }, [initialValue, value, autonumeric]);

  useEffect(() => {
    const input = inputRef.current;

    if (autonumeric && input) {
      const inputNumeric = new AutoNumeric(input, "float");

      if (!initialValue) {
        inputNumeric.clear();
      }

      return () => {
        inputNumeric.remove();
      };
    }
  }, [initialValue, autonumeric]);

  const increment = useCallback(() => {
    let newValue = Number(value) + 1;

    if (typeof max !== "undefined" && newValue >= max) {
      newValue = max;
    }

    setValue(newValue.toString());
    onChange && onChange(newValue);
  }, [value, max, onChange]);

  const decrement = useCallback(() => {
    let newValue = Number(value) - 1;

    if (typeof min !== "undefined" && newValue <= min) {
      newValue = min;
    }

    setValue(newValue.toString());
    onChange && onChange(newValue);
  }, [value, min, onChange]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.validity.valid) return;

      const value = e.target.value;
      let inputValue = autonumeric ? currency(value).value : Number(value);

      if (typeof min !== "undefined" && inputValue <= min) {
        inputValue = min;
      }

      if (typeof max !== "undefined" && inputValue >= max) {
        inputValue = max;
      }

      setValue(value);
      onChange?.(inputValue);
    },
    [autonumeric, max, min, onChange]
  );

  if (buttons) {
    return (
      <div
        className={`grid grid-cols-[1fr_1fr_1fr] rounded-[3px] border border-[#C2B6CB] bg-[#FDFBFF] font-helvetica text-xs text-[#6F5F7B] desktop:text-sm ${className}`}
      >
        <button
          type="button"
          className="flex items-center justify-center border-r border-[#C2B6CB] bg-transparent focus:outline-accent disabled:cursor-not-allowed"
          onClick={decrement}
          disabled={typeof min !== "undefined" ? Number(value) <= min : false}
        >
          <svg
            width="9"
            height="2"
            viewBox="0 0 9 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M8.14844 1.3125V0H0V1.3125H8.14844Z"
              fill="#6F5F7B"
            />
          </svg>
        </button>
        <input
          type="number"
          name={id}
          id={id}
          className="w-full bg-transparent px-2 py-1 text-center focus:outline-none"
          value={value}
          onChange={handleChange}
          min={min}
          max={max}
          autoComplete="off"
        />
        <button
          type="button"
          className="flex items-center justify-center border-l border-[#C2B6CB] bg-transparent focus:outline-accent disabled:cursor-not-allowed"
          onClick={increment}
          disabled={typeof max !== "undefined" ? Number(value) >= max : false}
        >
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M4.74219 8.16406V4.74219H8.14844V3.42969H4.74219V0H3.40625V3.42969H0V4.74219H3.40625V8.16406H4.74219Z"
              fill="#6F5F7B"
            />
          </svg>
        </button>
      </div>
    );
  }

  return (
    <div
      className={`initial:w-full ${wrapperClassName} ${
        withIcon ? "initial:relative initial:flex initial:items-center" : ""
      }`}
    >
      <input
        ref={inputRef}
        type="text"
        name={id}
        id={id}
        className={twMerge(
          `peer w-full rounded-[3px] border border-[#C2B6CB] bg-[#FDFBFF] px-2 py-1 font-helvetica text-xs text-[#6F5F7B] focus:outline-accent disabled:cursor-not-allowed disabled:opacity-50 desktop:text-sm`,
          className,
          withIcon && iconPosition === "left" ? "pl-5" : "",
          withIcon && iconPosition === "right" ? "pr-5" : ""
        )}
        pattern="^-?[0-9]?[0-9,]*(\.\d*)?$"
        value={value}
        onChange={handleChange}
        autoComplete="off"
        {...props}
      />
      {withIcon && (
        <span
          className={`absolute block peer-disabled:opacity-60 ${
            iconPosition === "left" ? "left-2" : "right-2"
          }`}
        >
          {withIcon}
        </span>
      )}
    </div>
  );
};

export default InputNumber;
