import { FC, useState, useCallback, useEffect } from "react";
import shallow from "zustand/shallow";
import { useStore } from "..";
import { Result, NoResult, ResultLoading, LoadingProgress } from ".";
import { twMerge } from "tailwind-merge";

const ResultManager: FC = () => {
  const [addEventListener, removeEventsListener] = useStore(
    useCallback(
      (state) => [state.addEventListener, state.removeEventListener],
      []
    ),
    shallow
  );

  const [showResult, setShowResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] =
    useState<string>("Loading... 0%");

  useEffect(() => {
    const timer: NodeJS.Timeout[] = [];
    const loadingTime = 10; // seconds

    const searchStart = () => {
      setShowResult(false);
      setIsLoading(true);
      setLoadingProgress("Loading... 0%");

      let delay = 0;
      for (let i = 1; i <= loadingTime; i++) {
        delay = i * 1000;

        timer.push(
          setTimeout(
            () =>
              setLoadingProgress(
                `Loading... ${Math.floor((i / loadingTime) * 100)}%`
              ),
            delay
          )
        );
      }

      timer.push(
        setTimeout(() => setLoadingProgress(`Finishing up`), delay + 2000)
      );
    };
    const searchSuccess = () => {
      setIsLoading(false);
      setShowResult(true);
      timer.forEach((timeout) => clearTimeout(timeout));
    };

    addEventListener("searchstart", searchStart);
    addEventListener("searchsuccess", searchSuccess);

    return () => {
      removeEventsListener("searchstart", searchStart);
      removeEventsListener("searchsuccess", searchSuccess);
      timer.forEach((timeout) => clearTimeout(timeout));
    };
  }, [addEventListener, removeEventsListener]);

  return (
    <section
      className={twMerge(
        "max-w-full rounded-[3px] p-4",
        showResult || isLoading ? "bg-white" : "bg-[#F8F7FA]"
      )}
    >
      {isLoading && (
        <ResultLoading>
          <LoadingProgress>{loadingProgress}</LoadingProgress>
        </ResultLoading>
      )}
      {!isLoading && showResult && <Result />}
      {!isLoading && !showResult && <NoResult />}
    </section>
  );
};

export default ResultManager;
