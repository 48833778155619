import { FC } from "react";
import { twMerge } from "tailwind-merge";

const FinancialDetailsTableColumn: FC<{
  className?: string;
}> = ({ children, className }) => {
  return (
    <p
      className={twMerge(
        "font-helvetica text-[11px] text-[#6F5F7B] desktop:text-[15px]",
        className
      )}
    >
      {children}
    </p>
  );
};

export default FinancialDetailsTableColumn;
