import { HTMLMotionProps, motion } from "framer-motion";
import { FC, PropsWithChildren, useMemo } from "react";
import { twMerge } from "tailwind-merge";

type Props = PropsWithChildren<
  HTMLMotionProps<"button"> & {
    withIcon?: JSX.Element;
    size?: "large" | "medium" | "small";
  }
>;

const Button: FC<Props> = ({
  children,
  className,
  withIcon,
  size,
  ...props
}) => {
  const sizeStyle = useMemo(() => {
    if (size === "medium") {
      return "initial:px-3 initial:py-[5px]";
    }

    if (size === "small") {
      return "initial:p-0";
    }

    if (size === "large") {
      return "initial:px-3 initial:py-[9px]";
    }

    return "initial:p-3";
  }, [size]);

  return (
    <motion.button
      whileTap={{
        scale: 0.95,
      }}
      className={twMerge(
        "flex w-full items-center justify-center gap-2 rounded-[3px] bg-secondary_accent font-helvetica text-[11px] text-white transition-colors duration-200 focus:outline-secondary_accent disabled:cursor-not-allowed disabled:bg-[#C2B6CB] desktop:text-base",
        sizeStyle,
        className
      )}
      {...props}
    >
      {withIcon}
      {children}
    </motion.button>
  );
};

export default Button;
