import { EmailAuthProvider } from "firebase/auth";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "../components/modal";
import { ui } from "../firebase";
import "firebaseui/dist/firebaseui.css";
import useSearchParams from "../util/hooks/useSearchParams";
import useStore from "../store";

const AuthModal: FC = () => {
  const { get } = useSearchParams();
  const [isOpen, setIsOpen] = useState(get("admin_login") === "true");
  const currentAdmin = useStore(useCallback((state) => state.currentAdmin, []));

  const loginElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loginElement = loginElementRef.current;
    if (!loginElement) return;

    ui.start(loginElement, {
      signInOptions: [EmailAuthProvider.PROVIDER_ID],
      callbacks: {
        signInSuccessWithAuthResult: () => true,
      },
    });
  }, []);

  useEffect(() => {
    if (currentAdmin?.uid) {
      const timeout = setTimeout(() => setIsOpen(false), 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [currentAdmin?.uid]);

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      containerClassName={!currentAdmin?.uid ? "bg-transparent" : "py-4 w-max"}
      closeWithBackdrop
    >
      {!currentAdmin?.uid && <div ref={loginElementRef} />}
      {currentAdmin?.uid && (
        <div className="text-center font-helvetica text-sm text-[#7B5C8D] desktop:text-base">
          You already logged in{" "}
          {currentAdmin.email ? (
            <>
              as <span className="text-accent">{currentAdmin.email}</span>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </Modal>
  );
};

export default AuthModal;
