import { FC, useCallback, useState } from "react";
import { Modal } from "../components/modal";
import { Incomes } from "../income";
import { Liabilities } from "../liabilities";
import { ExpensesContainer, IExpense } from "../expenses";
import { IApplicantParty } from "../applicant";
import { twMerge } from "tailwind-merge";
import useStore from "../store";

type IncomeModalCategory = {
  applicantPartyId: IApplicantParty["_id"];
  type: "Salary/Wages" | "Rental Income" | "Other Income";
};

type LiabilitiesModalCategory = {
  applicantPartyId: IApplicantParty["_id"];
  type: "Credit Cards" | "Liabilities";
};

type ExpensesModalCategory = {
  applicantPartyId: IApplicantParty["_id"];
  type: string;
  label: string;
};

export type OpenedModal =
  | {
      category: "income";
      options: IncomeModalCategory;
    }
  | {
      category: "liabilities";
      options: LiabilitiesModalCategory;
    }
  | {
      category: "expenses";
      options: ExpensesModalCategory;
    };

type ExpensesContainerState = "UPLOAD" | "IMPORT" | "SHOW" | "LOADING";
export type ExpenseView = "grid" | "list";

type Props = {
  onClose: () => void;
  openedModal: OpenedModal | null;
};

const FinancialModal: FC<Props> = ({ onClose, openedModal }) => {
  const { category, options } = openedModal || {};

  const hasDuplicateExpenseType = useStore(
    useCallback(
      (store) => {
        if (!options?.applicantPartyId) return false;
        const applicantParty = store.ApplicantParties.get(
          options?.applicantPartyId
        );
        if (!applicantParty) return false;

        const expenses = applicantParty.Expense;
        const expenseGroup: { [x: IExpense["ExpenseType"]]: IExpense } = {};

        let hasDuplicate = false;
        expenses.forEach((expense) => {
          if (hasDuplicate) return;
          hasDuplicate = expense.ExpenseType in expenseGroup;
          expenseGroup[expense.ExpenseType] = expense;
        });

        return hasDuplicate;
      },
      [options]
    )
  );

  const [expensesContainerState, setExpensesContainerState] =
    useState<ExpensesContainerState>();
  const [expenseView, setExpenseView] = useState<ExpenseView>("grid");

  const handleChangeExpenseModal = useCallback(
    (modal) => setExpensesContainerState(modal),
    []
  );

  return (
    <Modal
      onClose={onClose}
      open={openedModal ? true : false}
      containerClassName={twMerge(
        `max-w-[650px] desktop:max-w-[747px]`,
        expensesContainerState === "IMPORT" ? "bg-[#F6F4F7]" : ""
      )}
      withCloseButton={["IMPORT", "UPLOAD"].includes(
        expensesContainerState || ""
      )}
    >
      {category && options && (
        <>
          {category === "income" && (
            <Incomes
              category={options.type}
              applicantPartyId={options.applicantPartyId}
              onClose={onClose}
            />
          )}
          {category === "liabilities" && (
            <Liabilities
              category={options.type}
              applicantPartyId={options.applicantPartyId}
              onClose={onClose}
            />
          )}
          {category === "expenses" && (
            <ExpensesContainer
              expenseView={hasDuplicateExpenseType ? "list" : expenseView}
              onChangeExpenseView={setExpenseView}
              applicantPartyId={options.applicantPartyId}
              onClose={onClose}
              onChangeModal={handleChangeExpenseModal}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default FinancialModal;
