import currency from "currency.js";
import { FC, useMemo, useState } from "react";
import { ResultLendersDetails } from ".";
import { Button } from "../components/form";
import { SectionLabel } from "../components/label";
import { Modal } from "../components/modal";
import { DollarRectangle, Search } from "../icons";
import { decimalToPercentage } from "../util";
import { ILenderResult } from "./report";

const LenderStatsLEM: FC<{
  lenderResult: ILenderResult;
}> = ({ lenderResult }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen((isOpen) => !isOpen);
  const closeModal = () => setIsModalOpen(false);

  const { Resources, Result, Lender } = lenderResult;
  const { LEM } = Result || {};

  const resource = useMemo(
    () => Resources.find((resource) => resource.Reference === "Result.LEM"),
    [Resources]
  );

  const totalLEMAmount = LEM?.reduce(
    (total, LEMItem) => total + (LEMItem.Amount || 0),
    0
  );

  return (
    <div className="mx-4 space-y-12 bg-white px-[14px] py-[11px]">
      <Modal
        open={isModalOpen}
        withCloseButton
        onClose={closeModal}
        containerClassName="max-w-[70%] w-max"
      >
        {resource && (
          <>
            <SectionLabel>{Lender.Name} Low Equity Margin</SectionLabel>
            {resource.Type === "Image" && (
              <img
                src={resource.URL}
                alt={`${Lender.Name} Low Equity Margin`}
                className="mt-[27px] w-full rounded-[3px] border border-[#CDC3D2]"
              />
            )}
          </>
        )}
      </Modal>
      <ResultLendersDetails
        title={`LEM: ${
          totalLEMAmount ? currency(totalLEMAmount).format() : "N/A"
        }`}
        icon={<DollarRectangle />}
      >
        {LEM && LEM.length > 0 && (
          <table width="100%">
            <thead>
              <tr className="bg-[#EEE9F1] font-helvetica text-xs font-bold text-[#83748E]">
                <th className="w-[30%] p-0 py-[17px]"></th>
                <th className="w-[20%] p-0 py-[17px]">Assessed</th>
                <th className="w-[20%] p-0 py-[17px]">Margin</th>
                <th className="w-[20%] p-0 py-[17px] text-right">LEM Amount</th>
                <th className="w-[10%] p-0 py-[17px]"></th>
              </tr>
            </thead>
            <tbody>
              {LEM.map((LEMItem, index) => (
                <tr
                  key={index}
                  className="group bg-[#F7F4F9] text-xs text-[#857791]"
                >
                  <td className="p-0 pl-[14px]">
                    <span className="inline-block w-full border-b border-[#E5DFE8] py-3 pl-[11px] font-bold group-last:border-b-0">
                      {LEMItem.Label}
                    </span>
                  </td>
                  <td className="p-0">
                    <span className="inline-block w-full border-b border-[#E5DFE8] py-3 text-center group-last:border-b-0">
                      {decimalToPercentage(LEMItem.Assessed)} LVR
                    </span>
                  </td>
                  <td className="p-0">
                    <span className="inline-block w-full border-b border-[#E5DFE8] py-3 text-center group-last:border-b-0">
                      {LEMItem.Margin
                        ? `${decimalToPercentage(LEMItem.Margin)} p.a.`
                        : `null`}
                    </span>
                  </td>
                  <td className="p-0">
                    <span className="inline-block w-full border-b border-[#E5DFE8] py-3 text-right group-last:border-b-0">
                      {typeof LEMItem?.Amount === "number"
                        ? currency(LEMItem.Amount).format()
                        : `null`}
                    </span>
                  </td>
                  <td className="p-0 pr-[14px]">
                    <span className="inline-block w-full border-b border-[#E5DFE8] py-3 pr-[11px] text-right text-transparent group-last:border-b-0">
                      x
                      {resource && (
                        <Button
                          size="small"
                          withIcon={<Search />}
                          className="ml-auto inline-block h-full w-min bg-transparent align-middle"
                          onClick={toggleModal}
                        />
                      )}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </ResultLendersDetails>
    </div>
  );
};

export default LenderStatsLEM;
