import { motion, Transition } from "framer-motion";
import { FC, MouseEventHandler, PropsWithChildren, useCallback } from "react";
import { Button } from "../components/form";
import { Plus } from "../icons";

const transition: Transition = {
  duration: 0.2,
  ease: "easeInOut",
};

const ApplicantsManager: FC<
  PropsWithChildren<{
    isAdding?: boolean;
    hasApplicant?: boolean;
    onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  }>
> = ({ children, isAdding = false, hasApplicant = false, onButtonClick }) => {
  const handleButtonClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (!hasApplicant) return onButtonClick?.(event);
    },
    [hasApplicant, onButtonClick]
  );

  return (
    <>
      <motion.section
        initial={false}
        animate={{
          opacity: isAdding ? 1 : 0,
          height: isAdding ? "auto" : 0,
          marginTop: isAdding ? 8 : 0,
          pointerEvents: isAdding ? "initial" : "none",
        }}
        transition={transition}
      >
        {children}
      </motion.section>
      <motion.footer
        initial={false}
        animate={{
          opacity: !isAdding && !hasApplicant ? 1 : 0,
          height: !isAdding && !hasApplicant ? "auto" : 0,
          marginTop: !isAdding && !hasApplicant ? 16 : 0,
          pointerEvents: !isAdding && !hasApplicant ? "initial" : "none",
        }}
        transition={transition}
      >
        <Button withIcon={<Plus fill="#ffffff" />} onClick={handleButtonClick}>
          Add applicant
        </Button>
      </motion.footer>
    </>
  );
};

export default ApplicantsManager;
