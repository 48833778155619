import currency from "currency.js";
import { FC, useCallback, useLayoutEffect, useRef, useState } from "react";
import { ITransaction } from ".";

const ExpensesImportTransactionTable: FC<{
  transaction: ITransaction[];
}> = ({ transaction }) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const [scrollTop, setScrollTop] = useState(0);

  const isTableBodyScrollable = useCallback(() => {
    if (!tableBodyRef.current) return false;
    const { scrollHeight, clientHeight } = tableBodyRef.current;

    return scrollHeight > clientHeight;
  }, []);

  useLayoutEffect(() => {
    const tableBody = tableBodyRef.current;

    if (tableRef.current) {
      tableRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }

    const scroll = () => {
      setScrollTop(tableBodyRef.current!.scrollTop);
    };

    if (tableBody) {
      tableBodyRef.current.addEventListener("scroll", scroll);
    }

    return () => {
      tableBody?.removeEventListener("scroll", scroll);
    };
  }, []);

  useLayoutEffect(() => {
    if (isTableBodyScrollable() && tableBodyRef.current) {
      tableBodyRef.current.classList.add(
        "relative",
        "after:block",
        "after:pointer-events-none",
        "after:fixed",
        "after:bottom-3",
        "after:left-0",
        "after:right-0",
        "after:h-20",
        "after:bg-gradient-to-t",
        "after:from-white",
        "after:transition-opacity",
        "after:duration-[1000ms]"
      );

      if (scrollTop < 1) {
        tableBodyRef.current.classList.remove("after:opacity-0");
        tableBodyRef.current.classList.add("after:opacity-1");
      } else {
        tableBodyRef.current.classList.remove("after:opacity-1");
        tableBodyRef.current.classList.add("after:opacity-0");
      }
    }
  }, [isTableBodyScrollable, scrollTop]);

  return (
    <table
      className="mt-[17px] flex max-h-[372px] w-full min-w-[746px] flex-col"
      ref={tableRef}
    >
      <thead className="block bg-gradient-to-b from-[#907AA0]/10 to-[#A491B0]/10 py-2 px-3">
        <tr className="grid grid-cols-[1fr_1.8fr_1.2fr_4fr_1fr] items-center text-left font-helvetica text-[11px] font-bold text-[#6F5F7B] desktop:text-xs">
          <th>Date</th>
          <th>Acct Name</th>
          <th>Acct Holder</th>
          <th>Description</th>
          <th className="text-right">Amount</th>
        </tr>
      </thead>
      <tbody className="custom-scrollable" ref={tableBodyRef}>
        {transaction.map(
          (
            { _id, AccountHolder, AccountName, Amount, Date, Description },
            index,
            transactionsArray
          ) => (
            <tr
              key={_id}
              className="grid grid-cols-[1fr_1.8fr_1.2fr_4fr_1fr] items-center border-b border-[#C7BDCD]/50 py-2 px-3 font-helvetica text-[11px] text-[#6F5F7B] desktop:text-xs"
            >
              <td className="font-bold">
                {index === 0 || transactionsArray[index - 1].Date !== Date
                  ? Date
                  : ""}
              </td>
              <td>{AccountName}</td>
              <td>{AccountHolder}</td>
              <td>{Description}</td>
              <td className="text-right">{currency(Amount).format()}</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

export default ExpensesImportTransactionTable;
