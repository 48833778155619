import { FC, ReactNode } from "react";
import { SectionLabel } from "../components/label";
import { Progress } from "../components/progress";

let tmp: number[] = [];
for (let i = 0; i < 25; i++) {
  tmp.push(i);
}

const ExpensesImportLoading: FC<{
  progress: number;
  label: string | ReactNode;
}> = ({ progress, label }) => {
  return (
    <div className="grid h-[190px] grid-cols-1 grid-rows-2">
      <div className="row-start-2 flex flex-col items-center">
        <div className="w-full max-w-[360px]">
          <Progress value={progress} height={6} />
        </div>
        <SectionLabel className="mt-6">{label}</SectionLabel>
      </div>
    </div>
  );
};

export default ExpensesImportLoading;
