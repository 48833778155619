import { AnimatePresence, motion } from "framer-motion";
import { FC, memo, useCallback, useMemo, useState } from "react";
import {
  LiabilityForm,
  LiabilityTypeCreditCardsData,
  LiabilityTypeLiabilitiesData,
} from ".";
import {
  FinancialDetailsTableColumn,
  FinancialDetailsTableRow,
} from "../financial";
import { useStore, IModelId } from "..";
import shallow from "zustand/shallow";
import currency from "currency.js";
import { LinkIcon } from "../icons";

type Category = "Credit Cards" | "Liabilities";

const LiabilityComponent: FC<{
  applicantPartyId: IModelId;
  id: IModelId;
  category: Category;
  formLabel: string;
}> = ({ applicantPartyId, id, category, formLabel }) => {
  const [isEditing, setIsEditing] = useState(false);

  const [
    getFinancialItem,
    setFinancialItem,
    deleteFinancialItem,
    getEventsListener,
  ] = useStore(
    useCallback(
      (state) => [
        state.getFinancialItem,
        state.setFinancialItem,
        state.deleteFinancialItem,
        state.getEventsListener,
      ],
      []
    ),
    shallow
  );

  const liability = getFinancialItem(applicantPartyId, {
    type: "Liability",
    id,
  });

  const liabilityLabel = useMemo(() => {
    if (category === "Credit Cards") {
      return LiabilityTypeCreditCardsData.find(
        (value) => value["LiabilityType.Code"] === liability?.LiabilityType
      )?.["LiabilityType.Name"];
    }

    return LiabilityTypeLiabilitiesData.find(
      (value) => value["LiabilityType.Code"] === liability?.LiabilityType
    )?.["LiabilityType.Name"];
  }, [category, liability?.LiabilityType]);

  const isLinked = liability?.LoanId;

  const openForm = useCallback(() => setIsEditing(true), []);
  const closeForm = useCallback(() => setIsEditing(false), []);

  const handleForm = useCallback(
    (value) => {
      setFinancialItem(applicantPartyId, {
        type: "Liability",
        id: id,
        payload: value,
      });
      closeForm();
    },
    [applicantPartyId, closeForm, id, setFinancialItem]
  );

  const handleDelete = useCallback(() => {
    deleteFinancialItem(applicantPartyId, {
      type: "Liability",
      id,
    });
  }, [applicantPartyId, deleteFinancialItem, id]);

  const handleEdit = () => {
    if (!isLinked) {
      openForm();
    }

    getEventsListener("starteditfinancial").forEach((eventListener) => {
      eventListener.listener({
        event: "starteditfinancial",
        type: "Liability",
        financial: liability!,
      });
    });
  };

  const numWithCurrency = useCallback((num: number | null) => {
    if (!num) return "";
    return currency(num, { precision: 2 }).format();
  }, []);

  return (
    <AnimatePresence>
      {liability && (
        <motion.div
          variants={{
            exit: {
              height: 0,
            },
            mounted: {
              height: "auto",
            },
          }}
          initial={false}
          exit="exit"
          animate="mounted"
          transition={{
            ease: "easeInOut",
            duration: 0.2,
          }}
          className={!isEditing ? "border-b border-b-[#C7BDCD]/50" : ""}
        >
          <FinancialDetailsTableRow
            isEditing={isEditing}
            onDelete={handleDelete}
            onEdit={handleEdit}
            deleteButton={!isLinked}
          >
            <FinancialDetailsTableColumn>
              <div className="flex items-center gap-3">
                {liabilityLabel}
                {isLinked && (
                  <div className="flex items-center gap-1">
                    <span>
                      <LinkIcon width={19} height={19} fill="#6F5F7B" />
                    </span>
                    <span>Security {liability.LoanId}</span>
                  </div>
                )}
              </div>
            </FinancialDetailsTableColumn>
            <FinancialDetailsTableColumn>
              <span className="block whitespace-nowrap text-left">
                {liability.Limit ? (
                  <>Limit - {numWithCurrency(liability.Limit)}</>
                ) : (
                  <>Balance - {numWithCurrency(liability.Balance)}</>
                )}
              </span>
            </FinancialDetailsTableColumn>
          </FinancialDetailsTableRow>
          <AnimatePresence>
            {isEditing && (
              <motion.div
                initial={{
                  height: 0,
                  marginTop: 0,
                  overflowY: "hidden",
                }}
                exit={{
                  height: 0,
                  marginTop: 0,
                  overflowY: "hidden",
                }}
                animate={{
                  height: "auto",
                  overflowY: "initial",
                  marginTop: 4,
                }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.2,
                }}
              >
                <LiabilityForm
                  applicantPartyId={applicantPartyId}
                  header={formLabel}
                  category={category}
                  initialValue={liability}
                  onSave={handleForm}
                  onCancel={closeForm}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default memo(LiabilityComponent);
