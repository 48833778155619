import { FC } from "react";

const CheckCircle: FC<{
  fill?: string;
  className?: string;
}> = ({ fill = "#9A86A8", className }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM10.1199 5.7449L6.6199 9.2449C6.4834 9.3814 6.3042 9.45 6.125 9.45C5.9458 9.45 5.7666 9.3814 5.6301 9.2449L3.8801 7.4949C3.6064 7.2212 3.6064 6.7788 3.8801 6.5051C4.1538 6.2314 4.5962 6.2314 4.8699 6.5051L6.125 7.7602L9.1301 4.7551C9.4038 4.4814 9.8462 4.4814 10.1199 4.7551C10.3936 5.0288 10.3936 5.4712 10.1199 5.7449Z"
        fill={fill}
        className={className}
      />
    </svg>
  );
};

export default CheckCircle;
