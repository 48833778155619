const DollarRectangle = () => {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Main" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Results" transform="translate(-383.000000, -2988.000000)">
          <g id="icon-lem" transform="translate(383.000000, 2988.000000)">
            <polygon id="Path" points="0 0 22 0 22 22 0 22"></polygon>
            <path
              d="M18.3333333,4.66666667 L3.66666667,4.66666667 C2.64916667,4.66666667 1.8425,5.4825 1.8425,6.5 L1.83333333,17.5 C1.83333333,18.5175 2.64916667,19.3333333 3.66666667,19.3333333 L18.3333333,19.3333333 C19.3508333,19.3333333 20.1666667,18.5175 20.1666667,17.5 L20.1666667,6.5 C20.1666667,5.4825 19.3508333,4.66666667 18.3333333,4.66666667 Z M11,10.1666667 L7.33333333,10.1666667 L7.33333333,11.0833333 L10.0833333,11.0833333 C10.5875,11.0833333 11,11.4958333 11,12 L11,14.75 C11,15.2541667 10.5875,15.6666667 10.0833333,15.6666667 L9.16666667,15.6666667 L9.16666667,16.5833333 L7.33333333,16.5833333 L7.33333333,15.6666667 L5.5,15.6666667 L5.5,13.8333333 L9.16666667,13.8333333 L9.16666667,12.9166667 L6.41666667,12.9166667 C5.9125,12.9166667 5.5,12.5041667 5.5,12 L5.5,9.25 C5.5,8.74583333 5.9125,8.33333333 6.41666667,8.33333333 L7.33333333,8.33333333 L7.33333333,7.41666667 L9.16666667,7.41666667 L9.16666667,8.33333333 L11,8.33333333 L11,10.1666667 Z M14.6666667,15.8958333 L12.8333333,14.0625 L16.5,14.0625 L14.6666667,15.8958333 Z M12.8333333,10.1666667 L14.6666667,8.33333333 L16.5,10.1666667 L12.8333333,10.1666667 Z"
              id="Shape"
              fill="#9A86A8"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DollarRectangle;
