import {
  FC,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  useMemo,
} from "react";
import InputText from "./InputText";
import { Date as DateIcon } from "../../icons";
import { useLilius } from "use-lilius";
import * as DateFns from "date-fns";
import { Alert } from "../alert";
import Button from "./Button";
import Chevron from "../../icons/Chevron";
import InputSelect, { Option } from "./InputSelect";
// import { IMask, IMaskMixin } from "react-imask";
// import { IMaskInputProps } from "react-imask/dist/mixin";

const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// const MIN_YEAR = 1900;
// const MAX_YEAR = 4000;
// const imaskFormat = "dd/mm/yyyy";
// const iMaskOpt: IMaskInputProps = {
//   mask: Date,
//   pattern: imaskFormat,
//   autofix: true,
//   eager: true,
//   placeholder: "DD/MM/YYYY",
//   format: function (date: Date) {
//     return format(date, imaskFormat);
//   },
//   parse: function (str: string) {
//     return parse(str, imaskFormat, new Date());
//   },
//   blocks: {
//     yyyy: {
//       mask: IMask.MaskedRange,
//       from: MIN_YEAR,
//       to: MAX_YEAR,
//     },
//     mm: {
//       mask: IMask.MaskedRange,
//       from: 1,
//       to: 12,
//     },
//     dd: {
//       mask: IMask.MaskedRange,
//       from: 1,
//       to: 31,
//     },
//   },
// };

type Props = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "value" | "onChange"
> & {
  value?: Date;
  onChange?: (date: Date) => void;
  wrapperClassName?: string;
};

// const MaskedInputText = IMaskMixin(({ inputRef, ...props }) => (
//   <InputText {...props} ref={inputRef} />
// ));

const InputDate: FC<Props> = ({
  value,
  onChange,
  wrapperClassName,
  ...props
}) => {
  const {
    calendar,
    clearSelected,
    inRange,
    isSelected,
    select,
    selected,
    setViewing,
    toggle,
    viewing,
    viewMonth,
    viewYear,
    viewNextMonth,
    viewPreviousMonth,
  } = useLilius();

  const [inputTextValue, setInputTextValue] = useState<string>(
    value && DateFns.isValid(value) ? DateFns.format(value, "dd/MM/yyyy") : ""
  );
  const key = useRef<string>();
  const [isOpen, setIsOpen] = useState(false);
  const isMounted = useRef(false);

  // When the input field loses focus, we need to parse
  // the input to set the date. While doing this, we also do some
  // assumptions for the user and fix mistakes.
  const handleBlurInputText = () => {
    // If the input is empty, we should just go ahead and
    // clear the current selection.
    if (typeof inputTextValue === "undefined" || inputTextValue === "") {
      clearSelected();
      return;
    }

    const parts = inputTextValue.split("/");
    const partsAsNumber = parts.map((p) => parseInt(p, 10));

    // Make sure the day is within the valid range
    // of days (1 - last day of the given month). If no
    // day is given, default to the first day.
    if (parts.length < 1) {
      parts[0] = "01";
    } else if (partsAsNumber[0] < 1) {
      parts[0] = "01";
    } else if (
      partsAsNumber[0] > DateFns.getDate(DateFns.lastDayOfMonth(viewing))
    ) {
      parts[0] = `${DateFns.getDate(DateFns.lastDayOfMonth(viewing))}`;
    }

    // Make sure the month is within the valid range
    // of months (1 - 12). If no month is given, default
    // to the one we're looking at.
    if (parts.length < 2) {
      parts[1] = `${DateFns.getMonth(viewing)}`;
    } else if (partsAsNumber[1] < 1) {
      parts[1] = "01";
    } else if (partsAsNumber[1] > 12) {
      parts[1] = "12";
    }

    // If no year is given, default to the one we're looking at.
    // If the user passes in 2 digits, append them to the first 2 digits
    // of the year we're looking at. Example: `12` becomes `2012` if we're
    // looking at any year between 2000 and 2999.
    if (parts.length < 3) {
      parts[2] = `${DateFns.getYear(viewing)}`;
    } else if (partsAsNumber[2] > 9 && partsAsNumber[2] < 100) {
      parts[2] = `${
        Math.round(DateFns.getYear(viewing) / 1000) * 1000 + partsAsNumber[2]
      }`;
    }

    const parsed = DateFns.parse(parts.join("/"), "dd/MM/yyyy", new Date());

    if (DateFns.isValid(parsed)) {
      select(parsed, true);
      onChange && onChange(parsed);
    } else if (selected.length > 0) {
      setInputTextValue(DateFns.format(selected[0], "dd/MM/yyyy"));
      onChange && onChange(selected[0]);
    } else {
      setInputTextValue("");
    }
  };

  const handleDayClicked = useCallback(
    (day: Date) => (e: MouseEvent<HTMLDivElement>) => {
      toggle(day, true);
      onChange && onChange(day);
    },
    [onChange, toggle]
  );

  // initial value
  useEffect(() => {
    if (isMounted.current) return;

    isMounted.current = true;

    if (!value) return;

    select(value, true);
  }, [select, value]);

  // When the selection is changed, we want to update the input field
  // and the currently viewed month to match.
  useEffect(() => {
    setInputTextValue(
      selected.length > 0 && DateFns.isValid(selected[0])
        ? DateFns.format(selected[0], "dd/MM/yyyy")
        : ""
    );
    setViewing(
      selected.length > 0 && DateFns.isValid(selected[0])
        ? selected[0]
        : new Date()
    );
  }, [selected, setViewing]);

  const viewableYears = useMemo(() => {
    const currentYear = DateFns.getYear(viewing);
    const years: number[] = [];

    let min = currentYear - 50;
    let max = currentYear + 50;

    // if (min < MIN_YEAR) {
    //   min = MIN_YEAR;
    // }

    // if (max > MAX_YEAR) {
    //   max = MAX_YEAR;
    // }

    for (let year = min; year <= max; year++) {
      years.push(year);
    }

    return years;
  }, [viewing]);

  return (
    <Alert
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="min-w-[373px] translate-x-1/2 p-4"
      wrapperClassName={wrapperClassName}
      arrowClassName="hidden"
      below={
        <div className="flex space-x-1.5">
          {/* <MaskedInputText
            {...iMaskOpt}
            value={inputTextValue}
            onBlur={handleBlurInputText}
            onAccept={setInputTextValue}
            autoComplete="off"
          /> */}
          {/* <div className="flex items-center rounded-[3px] border border-[#C2B6CB] py-1 px-2 font-helvetica text-xs text-[#6F5F7B] desktop:text-sm">
            <input
              type="text"
              name="date"
              id="date"
              className="w-5 focus:outline-none"
              autoComplete="off"
            />
            <input
              type="text"
              name="month"
              id="month"
              className="w-5 focus:outline-none"
              autoComplete="off"
            />
            <input
              type="text"
              name="year"
              id="year"
              className="w-full focus:outline-none"
              autoComplete="off"
            />
          </div> */}
          <InputText
            placeholder={"DD/MM/YYYY"}
            maxLength={10}
            value={inputTextValue}
            onChange={(e) => {
              let value = e.target.value.replace(/[^\d/]+/g, "");
              const currentKey = key.current;

              if (currentKey === "/") return;

              if (currentKey !== "Backspace") {
                if (value.length === 2) {
                  value += "/";
                } else if (value.length === 5) {
                  value += "/";
                }

                if (value.length === 3 && !value.includes("/")) {
                  value = [value.substring(0, 2), value.substring(2)].join("/");
                }

                const monthYear = value.substring(3);
                if (value.length === 6 && !monthYear.includes("/")) {
                  value = [value.substring(0, 5), value.substring(5)].join("/");
                }
              }

              setInputTextValue(value);
            }}
            onBlur={handleBlurInputText}
            onKeyDown={(e) => (key.current = e.key)}
          />
          <Button
            type="button"
            withIcon={
              <DateIcon
                width={16}
                height={16}
                fill="currentColor"
                className="fill-current text-accent"
              />
            }
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen((prev) => !prev);
            }}
            size="small"
            className="box-border w-10 border border-accent bg-[#FDFBFF]"
          />
        </div>
      }
    >
      <div onClick={(e) => e.stopPropagation()}>
        <header className="flex items-center justify-between border-b border-[#C7BDCD]/50 pb-4 font-helvetica text-[#6F5F7B]">
          <Button
            size="medium"
            className="w-min p-[5px]"
            type="button"
            onClick={(e) => {
              viewPreviousMonth();
            }}
          >
            <Chevron
              className="fill-current text-[#C2B6CB]"
              width={16}
              height={16}
            />
          </Button>
          <div className="flex items-center space-x-4">
            <InputSelect
              initialValue={MONTHS[DateFns.getMonth(viewing)]}
              className="w-[100px]"
              onChange={(value) => viewMonth(MONTHS.indexOf(value))}
            >
              {MONTHS.map((month) => (
                <Option key={month} value={month}>
                  {month}
                </Option>
              ))}
            </InputSelect>
            <InputSelect
              className="w-[100px]"
              initialValue={DateFns.getYear(viewing)}
              onChange={(value) => viewYear(value)}
              searchable
            >
              {viewableYears.map((years) => (
                <Option key={years} value={years}>
                  {years}
                </Option>
              ))}
            </InputSelect>
          </div>
          <Button
            size="medium"
            className="w-min p-[5px]"
            type="button"
            onClick={(e) => {
              viewNextMonth();
            }}
          >
            <Chevron
              className="rotate-180 fill-current text-[#C2B6CB]"
              width={16}
              height={16}
            />
          </Button>
        </header>
        <section className="text-center font-helvetica text-xs text-[#6F5F7B] desktop:text-sm">
          <header className="grid grid-cols-7 items-center space-x-0.5 text-[11px] font-bold text-[#6F5F7B] desktop:text-xs">
            {calendar[0][0].map((day) => (
              <div key={`${day}`} className="mb-2 mt-3 grid place-items-center">
                <span>{DAYS[DateFns.getDay(day)].substring(0, 3)}</span>
              </div>
            ))}
          </header>
          <main className="mt-1 space-y-0.5">
            {calendar[0].map((week) => (
              <div
                className="grid grid-cols-7 items-center gap-x-0.5"
                key={`week-${week}`}
              >
                {week.map((day) => (
                  <div
                    key={`day-${day}`}
                    className="relative h-0 pb-[calc(10/12*100%)]"
                  >
                    <div
                      onClick={handleDayClicked(day)}
                      className={`absolute bottom-0 left-0 right-0 top-0 grid cursor-pointer place-items-center ${
                        isSelected(day)
                          ? "bg-secondary_accent font-bold text-white"
                          : `bg-[#F5F1F8] ${
                              !inRange(
                                day,
                                DateFns.startOfMonth(viewing),
                                DateFns.endOfMonth(viewing)
                              )
                                ? "text-[#6F5F7B]/40"
                                : ""
                            }`
                      }`}
                    >
                      <span>{DateFns.format(day, "dd")}</span>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </main>
        </section>
      </div>
    </Alert>
  );
};

export default InputDate;
