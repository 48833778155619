import { FC, SVGProps } from "react";

const DollarCircle: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#9A86A8",
  ...props
}) => {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g id="Main" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Results" transform="translate(-384.000000, -797.000000)">
          <g id="icon-servicing" transform="translate(384.000000, 797.000000)">
            <rect id="Rectangle" x="0" y="0" width="22" height="22"></rect>
            <path
              d="M11,1.83333333 C5.94,1.83333333 1.83333333,5.94 1.83333333,11 C1.83333333,16.06 5.94,20.1666667 11,20.1666667 C16.06,20.1666667 20.1666667,16.06 20.1666667,11 C20.1666667,5.94 16.06,1.83333333 11,1.83333333 Z M11.8066667,16.28 L11.8066667,17.4166667 L10.2025,17.4166667 L10.2025,16.2341667 C9.52416667,16.0691667 8.01166667,15.5283333 7.43416667,13.5208333 L8.94666667,12.9066667 C9.00166667,13.1083333 9.47833333,14.8225 11.1466667,14.8225 C11.9991667,14.8225 12.9616667,14.3825 12.9616667,13.3466667 C12.9616667,12.4666667 12.32,12.0083333 10.8716667,11.4858333 C9.86333333,11.1283333 7.80083333,10.5416667 7.80083333,8.45166667 C7.80083333,8.36 7.81,6.25166667 10.2025,5.73833333 L10.2025,4.58333333 L11.8066667,4.58333333 L11.8066667,5.72 C13.4933333,6.01333333 14.1075,7.36083333 14.245,7.76416667 L12.7966667,8.37833333 C12.6958333,8.0575 12.2558333,7.15 11.055,7.15 C10.4133333,7.15 9.39583333,7.48916667 9.39583333,8.42416667 C9.39583333,9.295 10.1841667,9.625 11.8158333,10.1658333 C14.0158333,10.9266667 14.575,12.045 14.575,13.3283333 C14.575,15.7391667 12.2833333,16.1975 11.8066667,16.28 Z"
              id="Shape"
              fill={fill}
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DollarCircle;
