import {
  ChangeEventHandler,
  CSSProperties,
  FC,
  useEffect,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";

const InputCheckbox: FC<{
  id?: string;
  checked?: boolean;
  onChange?: (checked?: boolean) => void;
  className?: string;
  backgroundImage?: string;
  style?: CSSProperties;
  isDisabled?: boolean;
}> = ({
  id,
  checked,
  onChange,
  className,
  backgroundImage = "/icons/check.svg",
  style,
  isDisabled,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsChecked(e.target.checked);
    onChange?.(e.target.checked);
  };

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
  }, [checked, isChecked]);

  return (
    <input
      type="checkbox"
      name={id}
      id={id}
      className={twMerge(
        "h-3 w-3 cursor-pointer appearance-none rounded-[3px] border border-[#CAC3CE] bg-white transition duration-200",
        "checked:border-accent checked:bg-accent focus:outline-none",
        className,
        isDisabled ? "cursor-default opacity-50" : ""
      )}
      style={{
        backgroundImage: `url("${backgroundImage}")`,
        backgroundSize: "80%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        ...style,
      }}
      checked={isChecked || false}
      onChange={handleChange}
      disabled={isDisabled}
    />
  );
};

export default InputCheckbox;
