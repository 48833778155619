import { FC } from "react";
import { SectionLabel } from "../components/label";

const ResultLendersTable: FC = ({ children }) => {
  return (
    <table className="w-full border border-b-0 border-[#C2B6CB]">
      <thead>
        <tr className="border-b border-[#C2B6CB] bg-gradient-to-t from-[#F7EFFA] to-[#fff]">
          <th></th>
          <th className="col-start-2 border-l border-[#DCD4DF] p-0 py-2 text-center">
            <SectionLabel className="text-[11px] desktop:text-sm">
              Servicing
            </SectionLabel>
          </th>
          <th className="border-l border-[#DCD4DF] p-0 py-2 text-center">
            <SectionLabel className="text-[11px] desktop:text-sm">
              LVR
            </SectionLabel>
          </th>
          <th className="border-l border-[#DCD4DF] p-0 py-2 text-center">
            <SectionLabel className="text-[11px] desktop:text-sm">
              DTI
            </SectionLabel>
          </th>
          <th className="border-l border-[#DCD4DF] p-0 py-2 text-center" />
        </tr>
      </thead>
      {children}
    </table>
  );
};

export default ResultLendersTable;
