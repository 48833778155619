import { useEffect, useRef, useState } from "react";

const useSearchParams = () => {
  const searchRef = useRef(window.location.search);
  const [searchParams, setSearchParams] = useState<URLSearchParams>(
    new URLSearchParams(searchRef.current)
  );

  useEffect(() => {
    const onLoad = () => {
      const newSeach = window.location.search;
      if (searchRef.current === newSeach) return;

      setSearchParams(new URLSearchParams(newSeach));
      searchRef.current = newSeach;
    };

    window.addEventListener("load", onLoad);
    return () => {
      window.removeEventListener("load", onLoad);
    };
  }, []);

  const get = (name: string) => {
    return searchParams.get(name);
  };

  const set = (name: string, value: string) => {
    const url = window.location.href;
    const prevSearchValue = searchParams.get(name);

    if (prevSearchValue === value) return;

    window.location.href = url.replace(
      `${name}=${prevSearchValue}`,
      `${name}=${value}`
    );
  };

  return {
    get,
    set,
  };
};

export default useSearchParams;
