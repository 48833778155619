import { FC, ReactNode } from "react";
import { Percentage } from "../icons";
import { SectionLabel } from "../components/label";
import { twMerge } from "tailwind-merge";

const ResultLendersDetails: FC<{
  title: string;
  icon?: ReactNode;
  pass?: boolean;
  className?: string;
}> = ({ title, children, icon, pass, className }) => {
  return (
    <div>
      <div className={`flex w-max items-center space-x-2 rounded-[3px]`}>
        {icon || (
          <Percentage
            fill={
              typeof pass !== "undefined"
                ? pass
                  ? "#42B4B4"
                  : "#FF6E6E"
                : undefined
            }
          />
        )}
        <SectionLabel
          className={`text-xs desktop:text-sm ${
            typeof pass !== "undefined"
              ? pass
                ? "text-accent"
                : "text-[#FF6E6E]"
              : "text-[#9A86A8]"
          }`}
        >
          {title}
        </SectionLabel>
      </div>
      <div className={twMerge(children && "mt-3", className)}>{children}</div>
    </div>
  );
};

export default ResultLendersDetails;
