import { useEffect, useState } from "react";

export interface DebounceOption<V> {
  onChange?: (value: V) => void;
}

const useDebounce = <V>(
  value: V,
  delay: number,
  options?: DebounceOption<V>
) => {
  const [debouncedDevalue, setDebouncedDevalue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedDevalue(value);

      if (!options) return;

      if (options.onChange) {
        options.onChange(value);
      }
    }, delay);
    return () => clearTimeout(timeout);
  }, [value, delay, options]);

  return debouncedDevalue;
};

export default useDebounce;
