import { FC } from "react";
import * as DateFns from "date-fns";
import style from "../pdf-style.module.scss";
import cx from "classnames";

const Header: FC<{ requestId: any }> = (props) => {
  return (
    <table className={style["header-container"]}>
      <tbody>
        <tr>
          <td>
            <div>
              <p className={style["header-title"]}>Mortgage</p>
              <p className={cx(style["header-title"], style["highlight"])}>
                Recommendation
              </p>
              <p className={style["header-title"]}>Engine</p>
            </div>
          </td>
          <td align="right">
            <div>
              <p
                className={style["header-info"]}
                style={{ marginBottom: "5px" }}
              >
                Request ID: <b>{props.requestId}</b>
              </p>
              {/* <p className={style["header-info"]}>
                Version: <b>1</b>
              </p> */}
              <p className={style["header-info"]}>
                Date Generated:{" "}
                <b>
                  {DateFns.isValid(new Date())
                    ? DateFns.format(new Date(), "d MMM yyyy")
                    : ""}
                </b>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Header;
