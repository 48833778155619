import {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "./DepositeSlider.css";

const DepositeSlider: FC<{
  initialValue: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
}> = ({ initialValue, min, max, onChange, step }) => {
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const v = value - min;
      const valuePercentage = (v / (max - min)) * 100;
      inputRef.current.style.backgroundSize = `${valuePercentage}% 100%`;
    }
  }, [max, min, value]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const value = Number(e.target.value);

      setValue(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <div className="relative w-full">
      <input
        className="deposite-slider"
        type="range"
        name="deposit_amount"
        id="deposit_amount"
        value={value}
        min={min}
        max={max}
        onChange={handleChange}
        step={step}
        ref={inputRef}
      />
      <div className="deposite-slider-mark">
        <span className="absolute left-[-1px]">|</span>
        <span className="opacity-0">|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span>|</span>
        <span className="opacity-0">|</span>
        <span className="absolute right-[-1px]">|</span>
      </div>
    </div>
  );
};

export default DepositeSlider;
