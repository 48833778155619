import { FC, SVGProps } from "react";

const NotApplicable: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#C5B7CF",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="21px"
      height="10px"
      viewBox="0 0 21 10"
      version="1.1"
      {...props}
    >
      <g
        id="Errors"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Top-Level-Errors-Copy"
          transform="translate(-1068.000000, -644.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g
            id="110826_n_a_not-applicable_icon"
            transform="translate(1068.000000, 644.000000)"
          >
            <path
              d="M5.47224889,6.15345435 L5.41908311,6.15345435 L3.70874703,3.54623801 L1.61997466,0.467931871 L0,0.467931871 L0,9.45245808 L1.78896225,9.45245808 L1.78896225,3.79347228 L1.86656797,3.79347228 L3.35153624,6.06218039 L5.63164419,9.45245808 L7.26121114,9.45245808 L7.26121114,0.467931871 L5.47224889,0.467931871 L5.47224889,6.15345435 Z M7.88945517,10 L9.20757881,10 L12.5133986,0 L11.1804273,0 L7.88945517,10 Z M17.6985931,0.467931871 L15.8702922,0.467931871 L12.7079486,9.45245808 L14.6149267,9.45245808 L15.2027606,7.59972903 L18.3385724,7.59972903 L18.8870167,9.45245808 L20.8333333,9.45245808 L17.6985931,0.467931871 Z M15.6863549,6.1014506 L16.050964,4.9150928 L16.7450785,2.58067967 L16.7961014,2.58067967 L17.5029716,4.95441395 L17.854825,6.10150154 L15.6863549,6.1014506 L15.6863549,6.1014506 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NotApplicable;
