import { FC } from "react";

const Car: FC<{
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
}> = ({ fill = "#9B88AA", width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66667 0.333252C10.1067 0.333252 10.48 0.613252 10.6133 1.00659L12 4.99992V10.3333C12 10.6999 11.7 10.9999 11.3333 10.9999H10.6667C10.3 10.9999 10 10.6999 10 10.3333V9.66658H2V10.3333C2 10.6999 1.7 10.9999 1.33333 10.9999H0.666667C0.3 10.9999 0 10.6999 0 10.3333V4.99992L1.38667 1.00659C1.52667 0.613252 1.89333 0.333252 2.33333 0.333252H9.66667ZM1.33333 6.66659C1.33333 7.21992 1.78 7.66659 2.33333 7.66659C2.88667 7.66659 3.33333 7.21992 3.33333 6.66659C3.33333 6.11325 2.88667 5.66659 2.33333 5.66659C1.78 5.66659 1.33333 6.11325 1.33333 6.66659ZM9.66667 7.66659C9.11333 7.66659 8.66667 7.21992 8.66667 6.66659C8.66667 6.11325 9.11333 5.66659 9.66667 5.66659C10.22 5.66659 10.6667 6.11325 10.6667 6.66659C10.6667 7.21992 10.22 7.66659 9.66667 7.66659ZM2.33333 1.33325L1.33333 4.33325H10.6667L9.66667 1.33325H2.33333Z"
        fill={fill}
      />
    </svg>
  );
};

export default Car;
