import { FC, SVGProps } from "react";

const LenderBlank: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#C2B6CB",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="38px"
      height="21px"
      viewBox="0 0 38 21"
      version="1.1"
      {...props}
    >
      <g id="Main" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Results" transform="translate(-332.000000, -654.000000)">
          <g id="Group-14" transform="translate(332.000000, 654.000000)">
            <rect
              id="Rectangle"
              stroke="#D9D5DE"
              fill="#F3F1F6"
              x="0.5"
              y="0.5"
              width="37"
              height="20"
              rx="2"
            />
            <g id="Group-13-Copy" transform="translate(10.000000, 1.000000)">
              <rect id="Rectangle" x="0" y="0" width="18" height="18" />
              <rect
                id="Rectangle"
                fill={fill}
                fillRule="nonzero"
                x="3"
                y="8"
                width="2"
                height="5"
              />
              <rect
                id="Rectangle"
                fill={fill}
                fillRule="nonzero"
                x="8"
                y="8"
                width="2"
                height="5"
              />
              <rect
                id="Rectangle"
                fill={fill}
                fillRule="nonzero"
                x="2"
                y="14"
                width="15"
                height="2"
              />
              <rect
                id="Rectangle"
                fill={fill}
                fillRule="nonzero"
                x="13"
                y="8"
                width="2"
                height="5"
              />
              <polygon
                id="Path"
                fill={fill}
                fillRule="nonzero"
                points="9.5 2 2 5.57142857 2 7 17 7 17 5.57142857"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LenderBlank;
