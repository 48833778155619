import { FC } from "react";

const SidebarContent: FC = ({ children }) => {
  return (
    <section className="space-y-[17px] rounded-[3px] bg-white px-4 py-5 shadow-[0_1px_1px_0_rgba(189,187,191,0.65)]">
      {children}
    </section>
  );
};

export default SidebarContent;
