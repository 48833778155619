import { FC, useState } from "react";
import { Button } from "../components/form";
import { Pencil, Trash } from "../icons";
import { Alert } from "../components/alert";
import { twMerge } from "tailwind-merge";

const FinancialDetailsTableRow: FC<{
  isEditing?: boolean;
  className?: string;
  onEdit: () => void;
  onDelete: () => void;
  deleteButton?: boolean;
}> = ({
  children,
  isEditing = false,
  className,
  onEdit,
  onDelete,
  deleteButton = true,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <div
      className={twMerge(
        "relative grid grid-cols-[2fr_1.5fr_2fr] py-1.5",
        isEditing && "opacity-50",
        className
      )}
    >
      {children}
      <div className="absolute bottom-0 right-[-11px] top-0 flex items-center space-x-1">
        <Button
          className="ml-auto h-full w-max bg-transparent px-2 text-accent underline disabled:cursor-default disabled:bg-transparent"
          size="small"
          onClick={onEdit}
          disabled={isEditing}
          withIcon={<Pencil />}
        >
          <span className="font-helvetica text-[11px] leading-[1em] desktop:text-[15px]">
            Edit
          </span>
        </Button>
        <Alert
          below={
            <Button
              className={twMerge(
                "h-6 w-8 bg-transparent text-accent underline disabled:cursor-default disabled:bg-transparent",
                deleteButton ? "" : "invisible"
              )}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleting((isDeleting) => !isDeleting);
              }}
              disabled={isEditing}
            >
              <Trash className="w-[10px] desktop:w-[14px]" />
            </Button>
          }
          open={isDeleting}
          backgroundColor="#1E1642"
          className="translate-y-1 text-left font-helvetica text-[11px] text-white shadow-none desktop:text-sm"
          arrowClassName="-translate-x-2 -translate-y-1.5"
          onClose={() => setIsDeleting(false)}
        >
          <p className="font-bold">Are you sure?</p>
          <p className="mt-[5px]">Deleting this item cannot be undone.</p>
          <div className="mt-3 flex items-center space-x-[28px]">
            <Button
              className="w-max bg-transparent font-bold text-[#E13756]"
              size="small"
              onClick={onDelete}
            >
              <span className="font-helvetica text-[11px] leading-[1em] desktop:text-sm">
                Yes, delete
              </span>
            </Button>
            <Button
              size="small"
              className="w-max bg-transparent text-white"
              onClick={() => setIsDeleting(false)}
            >
              <span className="font-helvetica text-[11px] leading-[1em] desktop:text-sm">
                Cancel
              </span>
            </Button>
          </div>
        </Alert>
      </div>
    </div>
  );
};

export default FinancialDetailsTableRow;
