import { FC } from "react";
import style from "../pdf-style.module.scss";
import cx from "classnames";

const TableDetails: FC<{
  title: string;
  data: Array<any>;
}> = (props) => {
  return (
    <table
      className={cx(style["table-details"], style["table"])}
      style={{ marginBottom: "20px" }}
    >
      <tbody>
        <tr className={style["table-details-header"]}>
          <td className={style["table-details-head"]} align="left" colSpan={2}>
            <p className={style["title"]}>{props.title}</p>
          </td>
        </tr>
        {props.data.map((_item, idx) => (
          <tr key={idx}>
            <td align="left">
              <p>{_item.title}</p>
            </td>
            <td align="left">
              <p>{_item.value}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableDetails;
