import {
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export type FormErrors<Values> = {
  [K in keyof Values]?: string;
};

type Props<T> = {
  initialValues: T;
  onChange?: (values: T, errors: FormErrors<T>) => T | void;
  onSubmit?: (values: T, errors: FormErrors<T>) => void;
  validate?: (values: T) => FormErrors<T> | undefined;
  validateOnMount?: boolean;
};

const useForm = <T extends object>({
  initialValues,
  onChange,
  onSubmit,
  validate,
  validateOnMount = false,
}: Props<T>) => {
  const isMounted = useRef(validateOnMount);
  const initialValuesRef = useRef(initialValues);

  const [values, setValues] = useState<T>(initialValues);

  const errors: FormErrors<T> = useMemo(() => {
    if (!validate || !isMounted.current) return {};

    return validate(values) ?? {};
  }, [validate, values]);
  const errorsRef = useRef(errors);
  const hasErrors = Object.values(errors).some(Boolean);

  useEffect(() => {
    initialValuesRef.current = initialValues;
    errorsRef.current = errors;
    if (!isMounted.current) {
      isMounted.current = true;
    }
  });

  const handleChange = <Key extends keyof T>(name: Key, payload: T[Key]) => {
    const newValues = { ...values, [name]: payload } as T;
    const errors = validate?.(newValues) ?? {};

    if (onChange) {
      const changes = onChange(newValues, errors);

      if (changes) {
        setValues(changes);
        return;
      }

      setValues(newValues);
      return;
    }

    setValues(newValues);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    onSubmit && onSubmit(values, errors);
  };

  const resetValues = useCallback(
    (values?: T) => {
      let newValues = values ? values : initialValuesRef.current;
      if (onChange) {
        const changes = onChange(newValues, errorsRef.current);

        if (changes) {
          setValues(changes);
          return;
        }
      }

      setValues(newValues);
    },
    [onChange]
  );

  return {
    errors,
    hasErrors,
    values,
    handleChange,
    handleSubmit,
    resetValues,
  };
};

export default useForm;
