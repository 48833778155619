import { FC } from "react";
import { InputContainer } from "../components/form";
import { twMerge } from "tailwind-merge";

const FinancialFormInputContainer: FC<{
  className?: string;
}> = ({ children, className }) => {
  return (
    <InputContainer
      className={twMerge(
        "grid grid-cols-3 gap-0 border-transparent py-0",
        className
      )}
    >
      {children}
    </InputContainer>
  );
};

export default FinancialFormInputContainer;
