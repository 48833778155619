import { FC } from "react";
import currency from "currency.js";
import { decimalToPercentage } from "../../../util";
import style from "../pdf-style.module.scss";

const Overview: FC<{
  loanAmount: number;
  lvr: number;
}> = (props) => {
  return (
    <div className={style["overview"]} style={{ marginBottom: "20px" }}>
      <table>
        <tbody>
          <tr>
            <td
              style={{
                width: "30%",
              }}
              align="left"
            >
              <p>TOTAL LOAN AMOUNT</p>
              <p className={style["small"]}>vs Lender Approval</p>
            </td>
            <td
              style={{
                width: "35%",
                paddingBottom: "6px",
              }}
              align="left"
              valign="middle"
            >
              <div
                style={{
                  display: "inline-block",
                  marginLeft: "10px",
                  backgroundColor: "#42B4B4",
                }}
                className={style["types"]}
              ></div>
              <p
                style={{
                  display: "inline-block",
                }}
                className={style["type"]}
              >
                Approval
              </p>
              <div
                style={{
                  display: "inline-block",
                  marginLeft: "10px",
                  backgroundColor: "#f18383",
                }}
                className={style["types"]}
              ></div>
              <p
                style={{
                  display: "inline-block",
                }}
                className={style["type"]}
              >
                Decline
              </p>
            </td>
            <td
              style={{
                border: 0,
                width: "35%",
                padding: "4px 10px",
                paddingBottom: "6px",
              }}
              align="right"
            >
              <span className={style["amount"]}>
                Amount:
                <span style={{ color: "#579bcb", marginLeft: "2px" }}>
                  {currency(props.loanAmount, {
                    precision: 0,
                  }).format()}
                </span>
              </span>
              <span className={style["amount"]}>
                LVR:
                <span style={{ color: "#579bcb", marginLeft: "2px" }}>
                  {typeof props.lvr === "number"
                    ? decimalToPercentage(props.lvr)
                    : props.lvr}
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Overview;
