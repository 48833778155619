import { AnimatePresence, motion } from "framer-motion";
import { FC, useCallback } from "react";
import useStore from "./store";
import { Info } from "./icons";

const SearchValidation: FC = () => {
  const validation = useStore(useCallback((state) => state.isValid(), []));

  if (validation.valid) return null;

  return (
    <AnimatePresence>
      {validation.warnings.map((warning) => (
        <motion.div
          key={warning}
          initial={{
            height: 0,
          }}
          exit={{
            height: 0,
          }}
          animate={{
            height: "auto",
          }}
          className="overflow-hidden"
          transition={{
            duration: 0.2,
            ease: "easeInOut",
          }}
        >
          <p
            style={{
              fontStyle: "oblique",
            }}
            className="mt-[17px] flex gap-[11px] font-helvetica text-[11px] text-[#8E729F] first:mt-[22px] desktop:text-sm"
          >
            <span>
              <Info width={24} height={24} fill="#CDC4D5" />
            </span>
            {warning}
          </p>
        </motion.div>
      ))}
    </AnimatePresence>
  );
};

export default SearchValidation;
