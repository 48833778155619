import { FC } from "react";

const Dollar: FC<{
  fill?: string;
}> = ({ fill = "#9A86A8" }) => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.74023 3.98828C4.70117 3.5625 4.60352 3.25 4.44727 3.05078C4.30273 2.86328 4.07031 2.76367 3.75 2.75195V4.99609C4.67578 5.26953 5.35156 5.5957 5.77734 5.97461C6.20312 6.35352 6.41602 6.90234 6.41602 7.62109C6.41602 8.35156 6.16992 8.95898 5.67773 9.44336C5.18555 9.92383 4.54297 10.1777 3.75 10.2051V11.3828H3.19922V10.2051C2.24609 10.1387 1.55664 9.88086 1.13086 9.43164C0.708984 8.98242 0.498047 8.3125 0.498047 7.42188H2.07422C2.09375 7.86328 2.17773 8.20508 2.32617 8.44727C2.47461 8.68945 2.76562 8.85156 3.19922 8.93359V6.43164C1.51953 6.06055 0.679688 5.24219 0.679688 3.97656C0.679688 3.23438 0.90625 2.64062 1.35938 2.19531C1.81641 1.75 2.42969 1.51172 3.19922 1.48047V0.701172H3.75V1.48047C4.5625 1.54688 5.17188 1.78711 5.57812 2.20117C5.98828 2.61133 6.22461 3.20703 6.28711 3.98828H4.74023ZM3.75 8.93359C4.05469 8.91797 4.30664 8.8125 4.50586 8.61719C4.70898 8.41797 4.81055 8.13086 4.81055 7.75586C4.81055 7.19727 4.45703 6.81055 3.75 6.5957V8.93359ZM3.19922 2.75195C2.89844 2.77148 2.6543 2.87109 2.4668 3.05078C2.2832 3.22656 2.19141 3.43945 2.19141 3.68945C2.19141 3.99023 2.26953 4.23438 2.42578 4.42188C2.58594 4.60938 2.84375 4.75 3.19922 4.84375V2.75195Z"
        fill={fill}
      />
    </svg>
  );
};

export default Dollar;
