import { FC, useEffect, useCallback, useState, useRef } from "react";
import shallow from "zustand/shallow";
import { useStore } from ".";
import { Modal } from "./components/modal";
import { useSearchParams } from "./util/hooks";
import { SearchSuccessEvent } from "./store";

const useResumeReport = () => {
  const [resumeReport, currentAdmin] = useStore(
    useCallback((state) => [state.resumeReport, state.currentAdmin], []),
    shallow
  );
  const { get } = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const calledRef = useRef(0);

  useEffect(() => {
    const reportID = get("report_id");
    if (!reportID || calledRef.current > 0) return;

    resumeReport(currentAdmin, reportID, (status) => {
      calledRef.current++;
      if (status === "Done") {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    });
  }, [currentAdmin, get, resumeReport]);

  return {
    isLoading,
  };
};

const ResumeReport: FC = () => {
  const { isLoading } = useResumeReport();
  const [addEventListener, removeEventListener] = useStore(
    useCallback(
      (state) => [state.addEventListener, state.removeEventListener],
      []
    )
  );

  useEffect(() => {
    function updateReportIDQuery(e: SearchSuccessEvent) {
      const queries = new URLSearchParams(window.location.search);
      const prevReportId = queries.get("report_id");
      const newReportId = e.Report.Id;

      if (!prevReportId || !newReportId) return;

      queries.set("report_id", newReportId);

      const newURL = `${window.location.pathname}?${queries.toString()}`;
      window.history.replaceState(null, "", newURL);
    }

    addEventListener("searchsuccess", updateReportIDQuery);

    return () => {
      removeEventListener("searchsuccess", updateReportIDQuery);
    };
  }, [addEventListener, removeEventListener]);

  return (
    <Modal open={isLoading} containerClassName="w-max py-2">
      Resuming report
    </Modal>
  );
};

export default ResumeReport;
