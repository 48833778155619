import { FC } from "react";

const ResultFinancialTable: FC<{
  withoutHead?: boolean;
  borderBottom?: boolean;
}> = ({ children, withoutHead = false, borderBottom = true }) => {
  return (
    <table className="block">
      {!withoutHead && (
        <thead
          style={{
            backgroundImage:
              "linear-gradient(to bottom, rgb(144 122 160 / 10%), rgb(164 145 176 / 10%))",
          }}
          className="block px-[10px] py-2"
        >
          <tr className="grid grid-cols-[1fr_220px_220px_24px] text-right font-helvetica text-[11px] font-bold text-[#6F5F7B] desktop:text-xs">
            <th className="text-left">Type</th>
            <th>Entered</th>
            <th>Assessed</th>
          </tr>
        </thead>
      )}
      <tbody
        className={`block ${
          borderBottom ? "border-b-2 border-[#C7BDCD]/70" : ""
        }`}
      >
        {children}
      </tbody>
    </table>
  );
};

export default ResultFinancialTable;
