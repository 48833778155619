import { FC } from "react";

const Add: FC<{
  fill?: string;
  className?: string;
}> = ({ fill = "#000000", className = "" }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4H6V0C6.25 0.14 6.11 0 6 0H4C3.89 0 3.75 0.14 4 0V4H0C0.14 3.75 0 3.89 0 4V6C0 6.11 0.14 6.25 0 6H4V10C3.75 9.86 3.89 10 4 10H6C6.11 10 6.25 9.86 6 10V6H10C9.86 6.25 10 6.11 10 6V4C9.86 3.75 10 3.89 10 4Z"
        fill={fill}
      />
    </svg>
  );
};

export default Add;
