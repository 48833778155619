import { FC, SVGProps } from "react";

const DollarBag: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#9A86A8",
  ...props
}) => {
  return (
    <svg
      width="17px"
      height="19px"
      viewBox="0 0 17 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g id="Main" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Results"
          transform="translate(-386.000000, -2877.000000)"
          fill={fill}
        >
          <g id="icon-deposit" transform="translate(386.000000, 2877.000000)">
            <path
              d="M16.3629116,3.58373432 C16.1477339,3.34306108 15.7559363,3.30517733 15.4891408,3.49793877 C14.1744422,4.44336122 12.6613573,4.51745738 12.1159502,4.50965778 C12.1159502,4.50965778 11.7801237,5.04281588 12.2515246,5.625 C13.0618615,5.61831463 14.7503225,5.45842292 16.2677608,4.3670366 C16.5351781,4.17483227 16.5780893,3.82329334 16.3629116,3.58373432"
              id="Fill-1"
            ></path>
            <path
              d="M12.1855807,1.2978678 C10.3481192,1.2978678 10.2189567,0 9.08071173,0 C7.94184579,0 7.73506152,1.27803838 5.97584276,1.27803838 C4.73389517,1.27803838 2.25,-0.507249467 2.25,0.638379531 C2.25,1.47121535 4.216624,3.46183369 5.29215061,4.48848614 L5.95783452,4.48848614 C5.96404426,4.48848614 5.970254,4.48720682 5.97708471,4.48720682 L10.0811005,4.49104478 C10.0842054,4.49104478 10.1605852,4.49872068 10.2909897,4.5 C11.8272788,2.8196162 12.8071755,1.2978678 12.1855807,1.2978678"
              id="Fill-3"
            ></path>
            <path
              d="M7.921875,15.279293 L7.921875,15.640835 C7.921875,15.9669896 7.648875,16.2306263 7.3125,16.2306263 C6.97551563,16.2306263 6.703125,15.9669896 6.703125,15.640835 L6.703125,15.2686767 C5.65317188,15.0062196 4.875,14.0837861 4.875,12.9867744 C4.875,12.6612096 5.14739063,12.3969831 5.484375,12.3969831 C5.82075,12.3969831 6.09375,12.6612096 6.09375,12.9867744 C6.09375,13.6379039 6.63975,14.1663569 7.3125,14.1663569 C7.89932813,14.1663569 8.53125,13.8437411 8.53125,13.1342222 C8.53125,12.9490277 8.53125,12.3834179 7.1784375,12.0885223 C7.17539063,12.0873427 7.17295313,12.0861631 7.17051563,12.0861631 C5.17359375,11.6414605 4.875,10.5208572 4.875,9.89037032 C4.875,8.82284816 5.6281875,7.97944667 6.703125,7.74588933 L6.703125,7.38375751 C6.703125,7.05878253 6.97551563,6.79396626 7.3125,6.79396626 C7.648875,6.79396626 7.921875,7.05878253 7.921875,7.38375751 L7.921875,7.75355662 C8.83715625,7.98062625 9.55378125,8.70606949 9.71648438,9.64501716 C9.7719375,9.96645339 9.54829688,10.2707857 9.2161875,10.3250465 C8.88103125,10.3787175 8.56964063,10.1610845 8.51479688,9.84082785 C8.41607813,9.2716793 7.9096875,8.85823563 7.3125,8.85823563 C6.7250625,8.85823563 6.09375,9.18085144 6.09375,9.89037032 C6.09375,10.074975 6.09375,10.6382256 7.44839063,10.9378396 C7.45082813,10.9378396 7.45265625,10.9390192 7.45570313,10.9401987 C9.45140625,11.3790034 9.75,12.5031456 9.75,13.1342222 C9.75,14.2035137 8.9968125,15.0457356 7.921875,15.279293 M9.85176563,5.63738562 C9.83409375,5.6385652 9.81398438,5.6385652 9.79753125,5.6385652 C9.61167188,5.6385652 9.4794375,5.62971833 9.4160625,5.625 L6.63853125,5.625 L5.48559375,5.625 L4.78785938,5.625 C3.3125625,6.95910781 0,10.4665964 0,15.0510438 C0,16.6800472 1.36378125,18 3.046875,18 L11.578125,18 C13.2612188,18 14.625,16.6800472 14.625,15.0510438 C14.625,10.4837003 11.3362031,6.98446883 9.85176563,5.63738562"
              id="Fill-5"
            ></path>
            <path
              d="M15.2687812,5.86536875 C14.7445312,5.08855625 13.8732187,4.62505625 12.9372187,4.62505625 C12.6267187,4.62505625 12.3747187,4.87761875 12.3747187,5.18755625 C12.3747187,5.49861875 12.6267187,5.75005625 12.9372187,5.75005625 C13.4991562,5.75005625 14.0217187,6.02905625 14.3367187,6.49480625 C14.4452812,6.65624375 14.6230312,6.74230625 14.8035937,6.74230625 C14.9115937,6.74230625 15.0212812,6.71136875 15.1174687,6.64668125 C15.3750937,6.47174375 15.4431562,6.12243125 15.2687812,5.86536875"
              id="Fill-7"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DollarBag;
