import { FC, SVGProps } from "react";

const InfoRounded: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#AA9AB8",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      {...props}
    >
      <g
        id="Kiwisaver"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Design---Salary" transform="translate(-579.000000, -329.000000)">
          <g id="Group-3" transform="translate(391.000000, 39.000000)">
            <g
              id="icon-info-small"
              transform="translate(172.000000, 290.000000)"
            >
              <g transform="translate(16.727273, 0.272727)">
                <circle
                  id="Oval"
                  fill={fill}
                  cx="8.27272727"
                  cy="8.72727273"
                  r="8"
                />
                <path
                  d="M8.27272727,4.03761755 C7.58713241,4.03761755 7.03134796,4.593402 7.03134796,5.27899687 C7.03134796,5.96459173 7.58713241,6.52037618 8.27272727,6.52037618 C8.95832213,6.52037618 9.51410658,5.96459173 9.51410658,5.27899687 C9.51410658,4.593402 8.95832213,4.03761755 8.27272727,4.03761755 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                />
                <path
                  d="M8.27272727,8.03761755 C7.5830721,8.03761755 7.03134796,8.58934169 7.03134796,9.27899687 L7.03134796,12.5893417 C7.03134796,13.2789969 7.5830721,13.830721 8.27272727,13.830721 C8.96238245,13.830721 9.51410658,13.2789969 9.51410658,12.5893417 L9.51410658,9.27899687 C9.51410658,8.58934169 8.96238245,8.03761755 8.27272727,8.03761755 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InfoRounded;
