import { useCallback, useMemo, useState } from "react";
import { ApplicantsManager, ApplicantsForm, ApplicantParty } from ".";
import { SectionLabel } from "../components/label";
import { useStore } from "..";
import { AnimatePresence, motion } from "framer-motion";

export default function Applicants() {
  const [isAdding, setIsAdding] = useState(true);
  const openForm = useCallback(() => setIsAdding(true), []);
  const closeForm = useCallback(() => setIsAdding(false), []);

  const initialValue = useMemo(
    () => ({
      name: "",
      partner: "",
      dependents: 0,
      vehicles: 0,
    }),
    []
  );

  const [ApplicantParties, addApplicantParties, addApplicants] = useStore(
    useCallback(
      (state) => [
        Array.from(state.ApplicantParties.values()),
        state.addApplicantParties,
        state.addApplicants,
      ],
      []
    ),
    (oldState, newState) => oldState[0].length === newState[0].length
  );

  const handleSave = useCallback(
    (value) => {
      const applicantParty = addApplicantParties({
        Applicant: new Map(),
        Expense: new Map(),
        Income: new Map(),
        Liability: new Map(),
        NumberOfDependents: value.dependents ?? 0,
        NumberOfVehicles: value.vehicles ?? 0,
      });

      if (applicantParty && value.name) {
        addApplicants(applicantParty._id, {
          status: "applicant",
          Name: value.name,
        });
        if (value.partner) {
          addApplicants(applicantParty._id, {
            status: "spouse",
            Name: value.partner,
          });
        }
      }

      closeForm();
    },
    [addApplicantParties, addApplicants, closeForm]
  );

  return (
    <section>
      <SectionLabel className="leading-[1.75rem] tracking-[-0.5px] desktop:text-base desktop:leading-[1.75rem]">
        APPLICANTS
      </SectionLabel>
      <section className="space-y-2">
        <AnimatePresence>
          {ApplicantParties.map((applicantParty) => (
            <motion.div
              key={applicantParty._id}
              initial="unmounted"
              animate="open"
              exit="unmounted"
              variants={{
                open: { opacity: 1, height: "auto" },
                unmounted: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
              className="first:mt-2"
            >
              <ApplicantParty applicantPartyId={applicantParty._id} />
            </motion.div>
          ))}
        </AnimatePresence>
      </section>
      <ApplicantsManager
        hasApplicant={ApplicantParties.length > 0}
        isAdding={isAdding && ApplicantParties.length === 0}
        onButtonClick={openForm}
      >
        <ApplicantsForm
          initialValue={initialValue}
          onCancel={closeForm}
          onSave={handleSave}
        />
      </ApplicantsManager>
    </section>
  );
}
