import { FC, SVGAttributes } from "react";

const Chevron: FC<SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      focusable="false"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="none"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="48"
        d="M328 112L184 256l144 144"
      ></path>
    </svg>
  );
};

export default Chevron;
