import { FC } from "react";

const FinancialDetailsTable: FC<{
  isAdding?: boolean;
}> = ({ children, isAdding = false }) => {
  return (
    <div className={isAdding ? "pointer-events-none opacity-50" : ""}>
      {children}
    </div>
  );
};

export default FinancialDetailsTable;
