import { Lender, ResultLendersTable } from ".";
import { Building, LenderCheck } from "../icons";
import { SectionLabel } from "../components/label";
import useStore from "../store";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { DebounceOption, useDebounce } from "../util/hooks";
import shallow from "zustand/shallow";
import { ILoan } from "../security";
import { twMerge } from "tailwind-merge";

const ResultLenders = () => {
  const [activeLenders, fetchSpreadsheetFiles, savedState] = useStore(
    useCallback(
      (state) => [
        state.activeLenders,
        state.fetchSpreadsheetFiles,
        state.savedState,
        state.Loans,
      ],
      []
    ),
    shallow
  );

  const callCountRef = useRef(0);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const debounceOption = useMemo(
    () =>
      ({
        onChange: (value) => {
          if (!value || !activeLenders?.LoanAmount || !savedState) return;

          if (callCountRef.current < 1) {
            callCountRef.current++;
            return;
          }

          timeoutRef.current = setTimeout(() => {
            const savedNewLoans = Array.from(savedState.NewLoans.values());
            const NewLoan = {
              ...savedNewLoans[0],
              LoanAmount: activeLenders.LoanAmount,
            } as ILoan;

            fetchSpreadsheetFiles({
              Details: {
                Loans: new Map<number, ILoan>([[NewLoan._id, NewLoan]]),
                ApplicantParties: savedState.ApplicantParties,
              },
            });
          }, 2500);
        },
      } as DebounceOption<typeof activeLenders>),
    [activeLenders?.LoanAmount, fetchSpreadsheetFiles, savedState]
  );

  const { Fail, Pass } = useDebounce(activeLenders, 500, debounceOption) || {};

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [activeLenders]);

  return (
    <>
      {Pass && Pass.length > 0 && (
        <section className="mt-[26px]">
          <header className="flex items-center">
            <LenderCheck />
            <SectionLabel className="ml-[10px] text-xs desktop:text-sm">
              Lenders that may approve
            </SectionLabel>
            <div className="ml-2 flex h-6 w-6 items-center justify-center rounded-full bg-accent p-2">
              <span
                className={twMerge(
                  "text-center font-helvetica text-xs font-bold leading-[8px] text-white",
                  Pass.length.toString().length > 2 ? "text-[10px]" : "text-xs"
                )}
              >
                {Pass.length}
              </span>
            </div>
          </header>
          <article className="mt-[22px]">
            <ResultLendersTable>
              {Pass.map((lenderResult, index) => (
                <Lender
                  index={index}
                  key={lenderResult.Lender.Id}
                  lenderResult={lenderResult}
                />
              ))}
            </ResultLendersTable>
          </article>
        </section>
      )}
      {Fail && Fail.length > 0 && (
        <section className="mt-[26px]">
          <header className="flex items-center">
            <Building />
            <SectionLabel className="ml-[10px] text-xs desktop:text-sm">
              Lenders that may decline
            </SectionLabel>
            <div className="ml-2 flex h-6 w-6 items-center justify-center rounded-full bg-[#F18383] p-2">
              <span
                className={twMerge(
                  "text-center font-helvetica text-xs font-bold leading-[8px] text-white",
                  Fail.length.toString().length > 2 ? "text-[10px]" : "text-xs"
                )}
              >
                {Fail.length}
              </span>
            </div>
          </header>
          <article className="mt-[22px]">
            <ResultLendersTable>
              {Fail.map((lenderResult, index) => (
                <Lender
                  key={lenderResult.Lender.Id}
                  index={index}
                  lenderResult={lenderResult}
                />
              ))}
            </ResultLendersTable>
          </article>
        </section>
      )}
    </>
  );
};

export default ResultLenders;
