import { FC, memo } from "react";
import { Button } from "../components/form";
import { CheckCircle } from "../icons";

const ApplicantSubSection: FC<{
  label: string;
  onClick?: () => void;
  checked?: boolean;
}> = ({ onClick, label, checked = false }) => {
  return (
    <Button
      className="w-max bg-transparent text-[13px] text-accent underline desktop:text-sm"
      size="small"
      withIcon={
        <CheckCircle
          className={`fill-current ${
            checked ? "text-accent" : "text-[#9A86A8] opacity-20"
          }`}
          fill="current"
        />
      }
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default memo(
  ApplicantSubSection,
  (prev, next) => prev.checked === next.checked
);
