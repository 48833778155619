import { FC, SVGAttributes } from "react";

const ZoomIn: FC<SVGAttributes<SVGSVGElement>> = ({
  fill = "#FFFFFF",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      {...props}
    >
      <g
        id="Image-Viewer"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="viewer-scrollbars"
          transform="translate(-482.000000, -45.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-3" transform="translate(462.000000, 40.000000)">
            <g id="zoom-in" transform="translate(19.000000, 4.000000)">
              <path d="M10.8333333,5.83333333 L9.16666667,5.83333333 L9.16666667,9.16666667 L5.83333333,9.16666667 L5.83333333,10.8333333 L9.16666667,10.8333333 L9.16666667,14.1666667 L10.8333333,14.1666667 L10.8333333,10.8333333 L14.1666667,10.8333333 L14.1666667,9.16666667 L10.8333333,9.16666667 L10.8333333,5.83333333 Z M10,1.66666667 C5.4,1.66666667 1.66666667,5.4 1.66666667,10 C1.66666667,14.6 5.4,18.3333333 10,18.3333333 C14.6,18.3333333 18.3333333,14.6 18.3333333,10 C18.3333333,5.4 14.6,1.66666667 10,1.66666667 Z M10,16.6666667 C6.325,16.6666667 3.33333333,13.675 3.33333333,10 C3.33333333,6.325 6.325,3.33333333 10,3.33333333 C13.675,3.33333333 16.6666667,6.325 16.6666667,10 C16.6666667,13.675 13.675,16.6666667 10,16.6666667 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ZoomIn;
