import { FC, SVGProps } from "react";

const Save: FC<SVGProps<SVGSVGElement>> = ({ fill = "#42B4B4", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      {...props}
    >
      <g
        id="Image-Viewer"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Default"
          transform="translate(-209.000000, -267.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <path
            d="M221.444444,267 L210.777778,267 C209.791111,267 209,267.8 209,268.777778 L209,281.222222 C209,282.2 209.791111,283 210.777778,283 L223.222222,283 C224.2,283 225,282.2 225,281.222222 L225,270.555556 L221.444444,267 Z M217,281.222222 C215.524444,281.222222 214.333333,280.031111 214.333333,278.555556 C214.333333,277.08 215.524444,275.888889 217,275.888889 C218.475556,275.888889 219.666667,277.08 219.666667,278.555556 C219.666667,280.031111 218.475556,281.222222 217,281.222222 Z M219.666667,272.333333 L210.777778,272.333333 L210.777778,268.777778 L219.666667,268.777778 L219.666667,272.333333 Z"
            id="icon-save"
          />
        </g>
      </g>
    </svg>
  );
};

export default Save;
