import { FC, memo, useCallback, useMemo, useState } from "react";
import { ApplicantsForm } from ".";
import { motion } from "framer-motion";
import { useStore, IModelId } from "..";
import shallow from "zustand/shallow";
import { SectionLabel } from "../components/label";
import { Baby, Car, Pencil, Trash } from "../icons";
import { Button } from "../components/form";
import { Alert } from "../components/alert";

type modeType = "edit" | "preview";

const ApplicantParty: FC<{
  applicantPartyId: IModelId;
}> = ({ applicantPartyId }) => {
  const [mode, setMode] = useState<modeType>("preview");
  const [deleting, setDeleting] = useState(false);

  const openForm = useCallback(() => setMode("edit"), []);
  const closeForm = useCallback(() => setMode("preview"), []);

  const {
    applicant,
    spouse,
    dependents,
    vehicles,
    setApplicantParty,
    deleteApplicantParties,
    addApplicants,
    setApplicant,
    deleteApplicant,
  } = useStore(
    useCallback(
      (state) => {
        const applicantParty = state.ApplicantParties.get(applicantPartyId);

        return {
          applicant: applicantParty?.Applicant.get("applicant"),
          spouse: applicantParty?.Applicant.get("spouse"),
          vehicles: applicantParty?.NumberOfVehicles,
          dependents: applicantParty?.NumberOfDependents,
          setApplicantParty: state.setApplicantParty,
          deleteApplicantParties: state.deleteApplicantParties,
          addApplicants: state.addApplicants,
          setApplicant: state.setApplicant,
          deleteApplicant: state.deleteApplicant,
        };
      },
      [applicantPartyId]
    ),
    shallow
  );

  const initialValue = useMemo(
    () => ({
      name: applicant?.Name || "",
      partner: spouse?.Name || "",
      dependents: dependents || 0,
      vehicles: vehicles || 0,
    }),
    [applicant, dependents, spouse, vehicles]
  );

  const handleDelete = useCallback(
    () => deleteApplicantParties(applicantPartyId),
    [applicantPartyId, deleteApplicantParties]
  );
  const handleSave = useCallback(
    ({ name, partner, dependents, vehicles }) => {
      if (name) {
        if (applicant) {
          setApplicant(applicantPartyId, {
            status: "applicant",
            Name: name,
          });
        } else {
          addApplicants(applicantPartyId, {
            Name: name,
            status: "applicant",
          });
        }
      }
      if (!spouse && partner) {
        addApplicants(applicantPartyId, {
          Name: partner,
          status: "spouse",
        });
      }

      if (spouse?.Name) {
        if (partner) {
          setApplicant(applicantPartyId, {
            status: "spouse",
            Name: partner,
          });
        } else {
          deleteApplicant(applicantPartyId, "spouse");
        }
      }

      setApplicantParty(applicantPartyId, {
        NumberOfDependents: dependents,
        NumberOfVehicles: vehicles,
      });

      closeForm();
    },
    [
      addApplicants,
      applicant,
      applicantPartyId,
      closeForm,
      deleteApplicant,
      setApplicant,
      setApplicantParty,
      spouse,
    ]
  );

  return (
    <>
      <motion.div
        initial={false}
        animate={{
          height: mode === "preview" ? "auto" : 0,
          overflowY: mode === "preview" ? "visible" : "hidden",
        }}
        transition={{ duration: 0.2, ease: "easeInOut" }}
      >
        <div className="flex items-start rounded-[3px] bg-[#F7F4F9] p-[10px]">
          <div className="ml-2">
            <SectionLabel className="desktop:text-sm">
              <span className="block leading-[1.2em]">
                {spouse
                  ? `${applicant?.Name} & ${spouse?.Name}`
                  : applicant?.Name}
              </span>
            </SectionLabel>
            <div className="mt-1 flex items-center gap-x-4 font-helvetica text-sm text-[#6F5F7B] desktop:mt-1.5 desktop:text-base">
              <span className="flex items-center gap-x-1 desktop:gap-x-1.5">
                <Baby className="w-4 desktop:w-5" />
                {dependents}
              </span>
              <span className="flex items-center gap-x-1 desktop:gap-x-1.5">
                <Car className="w-3 desktop:w-4" />
                {vehicles}
              </span>
            </div>
          </div>
          <Button
            className="ml-auto mt-[-4.5px] w-max bg-transparent p-2 text-accent underline"
            size="small"
            onClick={openForm}
          >
            <Pencil
              fill="#9B88AA"
              width={undefined}
              height={undefined}
              className="w-[10px] desktop:w-[14px]"
            />
          </Button>
          <Alert
            below={
              <Button
                className="-mr-2 mt-[-4.5px] w-max bg-transparent p-2 text-accent underline"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleting(true);
                }}
              >
                <Trash className="w-[10px] desktop:w-[14px]" />
              </Button>
            }
            open={deleting}
            backgroundColor="#1E1642"
            className="translate-y-1.5 text-left font-helvetica text-[11px] text-white shadow-none desktop:text-sm"
            arrowClassName="-translate-y-1 -translate-x-1"
            onClose={() => setDeleting(false)}
          >
            <p className="font-bold">Are you sure?</p>
            <p className="mt-[5px]">Deleting this item cannot be undone.</p>
            <div className="mt-3 flex items-center justify-end space-x-[28px]">
              <Button
                size="small"
                className="w-max bg-transparent text-white"
                onClick={() => setDeleting(false)}
              >
                <span className="font-helvetica text-[11px] leading-[1em] desktop:text-sm">
                  Cancel
                </span>
              </Button>
              <Button
                className="w-max bg-[#E13756] px-4 py-2 font-bold text-white"
                size="small"
                onClick={handleDelete}
              >
                <span className="font-helvetica text-[11px] leading-[1em] desktop:text-sm">
                  Yes, delete
                </span>
              </Button>
            </div>
          </Alert>
        </div>
      </motion.div>
      <motion.div
        initial={false}
        animate={{
          height: mode === "edit" ? "auto" : 0,
          overflowY: mode === "edit" ? "visible" : "hidden",
        }}
        transition={{ duration: 0.2, ease: "easeInOut" }}
      >
        <ApplicantsForm
          key={JSON.stringify(initialValue)}
          initialValue={initialValue}
          onSave={handleSave}
          onCancel={closeForm}
        />
      </motion.div>
    </>
  );
};

export default memo(ApplicantParty);
