import { AnimatePresence, motion } from "framer-motion";
import { FC } from "react";
import { Button } from "../components/form";
import { Plus } from "../icons";

const FinancialDetailsManager: FC<{
  isAdding?: boolean;
  buttonLabel?: string;
  buttonOnClick?: () => void;
  hasData?: boolean;
}> = ({
  children,
  isAdding = false,
  buttonLabel,
  buttonOnClick,
  hasData = false,
}) => {
  return (
    <AnimatePresence>
      {isAdding ? (
        <motion.div
          key="form"
          initial={{
            height: !hasData ? "auto" : 0,
            marginTop: 0,
            overflowY: "hidden",
          }}
          exit={{
            height: 0,
            marginTop: 0,
            overflowY: "hidden",
          }}
          animate={{
            height: "auto",
            marginTop: hasData ? 12 : 0,
            overflowY: "visible",
          }}
          transition={{
            ease: "easeInOut",
            duration: 0.2,
          }}
        >
          {children}
        </motion.div>
      ) : (
        <motion.div
          key="button"
          initial={{
            height: !hasData ? "auto" : 0,
          }}
          exit={{
            height: 0,
          }}
          animate={{
            height: "auto",
          }}
          transition={{
            ease: "easeInOut",
            duration: 0.2,
          }}
          className="overflow-y-hidden"
        >
          <Button
            className={`w-max bg-transparent font-bold text-accent desktop:text-sm ${
              hasData ? "mt-3" : ""
            }`}
            size="small"
            withIcon={
              <span className="rounded-full bg-accent p-[3px]">
                <Plus fill="white" />
              </span>
            }
            onClick={buttonOnClick}
          >
            {buttonLabel}
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FinancialDetailsManager;
