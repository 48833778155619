import { FC } from "react";

const Close: FC<{
  fill?: string;
}> = ({ fill = "#CDC3D2" }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.10391 5.94617L11.2539 9.09617L9.15391 11.1962L6.00391 8.04617L2.85391 11.1962L0.753906 9.09617L3.90391 5.94617L0.753906 2.79617L2.85391 0.696167L6.00391 3.84617L9.15391 0.696167L11.2539 2.79617L8.10391 5.94617Z"
        fill={fill}
      />
    </svg>
  );
};

export default Close;
