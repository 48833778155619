import { FC } from "react";
import { Button } from "../components/form";

const FinancialFormFooter: FC<{
  onCancel?: () => void;
  isValid?: boolean;
}> = ({ onCancel, isValid = true }) => {
  return (
    <footer className="mt-[22px] flex items-center space-x-2">
      <Button
        type="button"
        size="medium"
        className="max-w-[83px] border border-transparent bg-transparent text-[#7B5C8D] hover:border-[#7B5C8D] desktop:text-sm"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        size="medium"
        className="max-w-[83px] disabled:cursor-not-allowed disabled:bg-[#C2B6CB] desktop:text-sm"
        disabled={!isValid}
      >
        Save
      </Button>
    </footer>
  );
};

export default FinancialFormFooter;
