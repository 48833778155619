import { FC } from "react";

const NoResult: FC<{
  label?: string;
}> = ({ label = "Enter applicant and security details to get started" }) => {
  return (
    <figure className="flex h-full w-full flex-col items-center pt-[121px]">
      <img src="/images/home_loan_v2.svg" alt="home loan" />
      <figcaption className="mt-8">
        <p className="text-center font-helvetica text-sm text-[#7B5C8D] opacity-50 desktop:text-lg">
          {label}
        </p>
      </figcaption>
    </figure>
  );
};

export default NoResult;
