import { FC, useCallback, useMemo, useRef, useState } from "react";
import {
  ExpensesImportTable,
  ExpensesImportTransactionTable,
  IExpense,
  IExpenseTransaction,
} from ".";
import { Button } from "../components/form";
import { SectionLabel } from "../components/label";
import { Info } from "../icons";
import { Alert } from "../components/alert";

const ExpensesImport: FC<{
  expensesTransaction: IExpenseTransaction[];
  onImport?: (expenses: IExpense[]) => void;
}> = ({ expensesTransaction, onImport }) => {
  const [showExcluded, setShowExcluded] = useState(false);

  const filteredExpenses = useMemo(
    () =>
      expensesTransaction.filter(
        ({ Expense }) => Expense.ExpenseType !== "Unknown"
      ),
    [expensesTransaction]
  );

  const unknownExpenses = useMemo(
    () =>
      expensesTransaction.filter(
        ({ Expense }) => Expense.ExpenseType === "Unknown"
      ),
    [expensesTransaction]
  );

  const importedExpense = useRef<IExpense[]>([]);

  const handleImport = useCallback(
    () => onImport?.(importedExpense.current),
    [onImport]
  );

  return (
    <section>
      <header>
        <SectionLabel className="text-[15px] desktop:text-lg">
          Please choose expenses to use
        </SectionLabel>
      </header>
      {unknownExpenses.length >= 0 && unknownExpenses[0]?.Transaction.length && (
        <section className="mt-4 flex items-center justify-between rounded-[3px] bg-[#64B980] p-[14px]">
          <div className="flex items-center space-x-2">
            <Info />
            <SectionLabel className="text-xs font-normal text-white desktop:text-sm">
              {unknownExpenses[0]?.Transaction.length} transactions are excluded
              from the list
            </SectionLabel>
          </div>
          <Alert
            open={showExcluded}
            onClose={() => setShowExcluded(false)}
            className="translate-x-1/4 desktop:translate-x-[13.5%]"
            below={
              <Button
                size="small"
                className="bg-transparent font-bold underline"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowExcluded((prev) => !prev);
                }}
              >
                Show me
              </Button>
            }
          >
            <SectionLabel className="text-[13px] desktop:text-sm">
              Excluded Transaction
            </SectionLabel>
            <p className="font-helvetica text-[11px] text-[#6F5F7B] desktop:text-xs">
              These are transactions not classified as expenses and/or
              random/unclassified transactions.
            </p>
            <ExpensesImportTransactionTable
              transaction={unknownExpenses[0]?.Transaction || []}
            />
          </Alert>
        </section>
      )}
      <section>
        <ExpensesImportTable
          data={filteredExpenses}
          onChange={(expenses) => (importedExpense.current = expenses)}
        />
      </section>
      <footer className="mt-[20px]">
        <Button onClick={handleImport} className="font-bold">
          Import Selected
        </Button>
      </footer>
    </section>
  );
};

export default ExpensesImport;
