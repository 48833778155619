import { FC, SVGProps } from "react";

const LenderCheck: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#6F5F7B",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="28px"
      height="24px"
      viewBox="0 0 28 24"
      version="1.1"
      {...props}
    >
      <g
        id="Errors"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Overview" transform="translate(-356.000000, -561.000000)">
          <g id="Group-13" transform="translate(356.000000, 561.000000)">
            <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            <path
              d="M21.5,10.9285714 C18.4245714,10.9285714 15.9285714,13.4245714 15.9285714,16.5 C15.9285714,19.5754286 18.4245714,22.0714286 21.5,22.0714286 C24.5754286,22.0714286 27.0714286,19.5754286 27.0714286,16.5 C27.0714286,13.4245714 24.5754286,10.9285714 21.5,10.9285714 Z M20.3857143,19.2857143 L17.6,16.5 L18.3855714,15.7144286 L20.3857143,17.709 L24.6144286,13.4802857 L25.4,14.2714286 L20.3857143,19.2857143 Z"
              id="Shape"
              fill={fill}
              fillRule="nonzero"
            />
            <rect
              id="Rectangle"
              fill={fill}
              fillRule="nonzero"
              x="4"
              y="10"
              width="3"
              height="7"
            />
            <rect
              id="Rectangle"
              fill={fill}
              fillRule="nonzero"
              x="10.5"
              y="10"
              width="3"
              height="7"
            />
            <rect
              id="Rectangle"
              fill={fill}
              fillRule="nonzero"
              x="2"
              y="19"
              width="12"
              height="3"
            />
            <polygon
              id="Path"
              fill={fill}
              fillRule="nonzero"
              points="12 1 2 6 2 8 22 8 22 6"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LenderCheck;
