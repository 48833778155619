import { FC, SVGProps } from "react";

const LEMIcon: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#9A86A8",
  ...props
}) => {
  return (
    <svg
      width="18.3333334px"
      height="14.6666666px"
      viewBox="0 0 18.3333334 14.6666666"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>LEM</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="04-copy"
          transform="translate(-83.833333, -679.666667)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(83.833333, 679.666667)">
            <path
              d="M16.5,0 L1.83333334,0 C0.81583334,0 0.00916667,0.81583333 0.00916667,1.83333333 L0,12.8333333 C0,13.8508333 0.81583334,14.6666666 1.83333334,14.6666666 L16.5,14.6666666 C17.5175,14.6666666 18.3333334,13.8508333 18.3333334,12.8333333 L18.3333334,1.83333333 C18.3333334,0.81583333 17.5175,0 16.5,0 Z M9.16666667,5.50000003 L5.5,5.50000003 L5.5,6.41666663 L8.24999997,6.41666663 C8.75416667,6.41666663 9.16666667,6.82916663 9.16666667,7.33333333 L9.16666667,10.0833333 C9.16666667,10.5875 8.75416667,11 8.24999997,11 L7.33333334,11 L7.33333334,11.9166666 L5.5,11.9166666 L5.5,11 L3.66666667,11 L3.66666667,9.16666663 L7.33333334,9.16666663 L7.33333334,8.25 L4.58333334,8.25 C4.07916667,8.25 3.66666667,7.83750003 3.66666667,7.33333333 L3.66666667,4.58333333 C3.66666667,4.07916666 4.07916667,3.66666666 4.58333334,3.66666666 L5.5,3.66666666 L5.5,2.75 L7.33333334,2.75 L7.33333334,3.66666666 L9.16666667,3.66666666 L9.16666667,5.50000003 Z M12.8333334,11.2291666 L11,9.39583333 L14.6666667,9.39583333 L12.8333334,11.2291666 Z M11,5.50000003 L12.8333334,3.66666666 L14.6666667,5.50000003 L11,5.50000003 Z"
              id="LEM"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LEMIcon;
