import { SectionLabel } from "../components/label";
import { AnimatePresence, motion } from "framer-motion";
import useStore from "../store";
import { ComponentProps, FC, useCallback, useState } from "react";
import { Button } from "../components/form";
import { Plus } from "../icons";
import { Security, SecurityFormModal } from ".";
import { decimalToPercentage } from "../util";

const Securities: FC = () => {
  const [isAdding, setAdding] = useState(false);
  const showModal = () => setAdding(true);
  const closeModal = () => setAdding(false);

  const [ApplicantParty, Loans, addLoan, addFinancial] = useStore(
    useCallback(
      (state) => [
        Array.from(state.ApplicantParties.values())[0],
        Array.from(state.Loans.values()),
        state.addLoan,
        state.addFinancial,
      ],
      []
    )
  );
  const hasApplicant = typeof ApplicantParty !== "undefined";

  const addSecurity: ComponentProps<typeof SecurityFormModal>["onSave"] = (
    value
  ) => {
    if (!value.Loan) return;

    const newLoan = addLoan(value.Loan);

    if (
      newLoan &&
      newLoan.LoanType === "Existing" &&
      newLoan.LendingAction !== null &&
      value.Liability
    ) {
      addFinancial(ApplicantParty._id, {
        type: "Liability",
        value: {
          Lender: value.Liability?.Lender ?? null,
          Balance: value.Liability?.Balance ?? null,
          Limit: value.Liability?.Limit ?? null,
          InterestRate: value.Liability?.InterestRate ?? null,
          RepaymentAmount: value.Liability?.RepaymentAmount ?? null,
          RepaymentFrequency: value.Liability?.RepaymentFrequency ?? null,
          RepaymentType: value.Liability?.RepaymentType ?? null,
          TotalTermMonths: value.Liability?.TotalTermMonths ?? null,
          StartDate: value.Liability?.StartDate ?? null,
          IsRevolvingCredit: value.Liability?.IsRevolvingCredit ?? null,
          InterestOnlyTermMonths:
            value.Liability?.InterestOnlyTermMonths ?? null,
          ApplicantId: Array.from(ApplicantParty.Applicant.values())[0]
            .ApplicantId,
          LiabilityType: "HomeLoan",
          LoanId: newLoan.LoanId,
        },
      });
    }

    closeModal();
  };

  function calculateCombinedLVR() {
    const includedLoans = Loans.filter(
      (loan) =>
        loan.LoanType === "New" ||
        (loan.LoanType === "Existing" &&
          (loan.LendingAction === "Refinance" ||
            loan.LendingAction === "TopUp"))
    );

    if (includedLoans.length < 2) return 0;

    const totalLoanAmount = includedLoans.reduce(
      (amount, loan) => amount + loan.LoanAmount + (loan.TopUpAmount || 0),
      0
    );

    const totalSecurityValue = includedLoans.reduce(
      (value, loan) => value + loan.Security.Value,
      0
    );

    return totalLoanAmount / totalSecurityValue;
  }

  const combinedLVR = calculateCombinedLVR();

  return (
    <section>
      <header className="flex items-center justify-between">
        <SectionLabel className="leading-[1.75rem] tracking-[-0.5px] desktop:text-base desktop:leading-[1.75rem]">
          SECURITIES
        </SectionLabel>
        {combinedLVR > 0 && (
          <span className="text-[13px] text-[#6F5F7B]">
            {decimalToPercentage(combinedLVR, 1)} Combined LVR
          </span>
        )}
      </header>
      <main className="mt-2 space-y-[15px]">
        <AnimatePresence>
          {Loans.map((loan) => (
            <motion.div
              key={loan._id}
              initial={{ height: 0, opacity: 0 }}
              exit={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <Security LoanId={loan.LoanId} />
            </motion.div>
          ))}
        </AnimatePresence>
      </main>
      <footer className="mt-4">
        <AnimatePresence>
          {hasApplicant && (
            <>
              <SecurityFormModal
                title="Add security"
                open={isAdding}
                handleClose={closeModal}
                onSave={addSecurity}
              />
              {Loans.length < 1 ? (
                <Button onClick={showModal} withIcon={<Plus fill="#ffffff" />}>
                  Add Security
                </Button>
              ) : (
                <Button
                  onClick={showModal}
                  className="justify-start bg-transparent px-0 py-0 font-bold text-accent"
                >
                  <span
                    style={{ borderWidth: "1.5px" }}
                    className="rounded-full border-accent p-[4px]"
                  >
                    <Plus fill="#42b4b4" />
                  </span>
                  Add Security
                </Button>
              )}
            </>
          )}
          {!hasApplicant && (
            <motion.div
              animate={{
                height: !hasApplicant ? "60px" : 0,
                opacity: !hasApplicant ? 1 : 0,
                padding: !hasApplicant ? "10px" : 0,
              }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
              className="grid place-items-center rounded-[3px] bg-[#F7F4F9] text-center text-[13px] font-bold text-[#83748E]/50 desktop:text-sm"
            >
              <span>Add Applicant to get started</span>
            </motion.div>
          )}
        </AnimatePresence>
      </footer>
    </section>
  );
};

export default Securities;
