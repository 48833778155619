import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Button } from "./components/form";
import { twMerge } from "tailwind-merge";
import { Modal } from "./components/modal";
import { SectionLabel } from "./components/label";
import { Sparkle } from "./icons";
import useStore, { Version } from "./store";
import * as DateFns from "date-fns";
import { AnimatePresence, motion } from "framer-motion";

const VersionItem: FC<{
  version: Version;
  lenderLogo?: string;
}> = ({ version, lenderLogo }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="grid grid-cols-[115px_1fr]">
      <aside>
        <p className="mx-4 mt-2 whitespace-nowrap text-right font-helvetica text-xs text-[#6F5F7B] desktop:text-sm">
          {DateFns.format(version.date, "dd MMM yyyy")}
        </p>
      </aside>
      <section className="rounded-md border border-[#E3DEE6] px-4 pb-2 pt-[9px]">
        {version.lender && (
          <header className="flex items-center justify-start">
            <img
              className="mr-[7px]"
              src={lenderLogo}
              width={38}
              height={21}
              alt={`${version.lender} logo`}
            />
            <p className="font-helvetica text-xs font-bold text-[#6F5F7B] desktop:text-sm">
              {version.lender}
            </p>
          </header>
        )}
        <div className="mt-2 flex items-center">
          <p className="font-helvetica text-xs text-[#6F5F7B] desktop:text-sm">
            {version.title}
          </p>
          {version.details && (
            <>
              <div className="ml-2 flex items-center">
                ·{" "}
                <Button
                  onClick={() => setIsOpen((open) => !open)}
                  className="w-max bg-transparent py-0 px-2 text-xs text-accent underline hover:bg-transparent desktop:text-sm"
                >
                  {isOpen ? "Hide details" : "Show details"}
                </Button>
              </div>
            </>
          )}
        </div>
        <AnimatePresence>
          {version.details && isOpen && (
            <motion.p
              initial={{
                height: 0,
                marginTop: 0,
              }}
              exit={{
                height: 0,
                marginTop: 0,
              }}
              animate={{
                height: "auto",
                marginTop: 12,
              }}
              transition={{
                duration: 0.2,
                ease: "easeInOut",
              }}
              className="overflow-hidden font-helvetica text-xs leading-[17px] text-[#6F5F7B] desktop:text-sm"
            >
              {version.details}
            </motion.p>
          )}
        </AnimatePresence>
      </section>
    </section>
  );
};

const VersionHistory: FC = () => {
  const [hasNew, setHasNew] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  const versionListElRef = useRef<HTMLUListElement>(null);
  const isMountedRef = useRef(false);

  const [loadVersionHistory, versionHistory, lenderLogos, loadLenderLogos] =
    useStore(
      useCallback(
        (store) => [
          store.loadVersionHistory,
          store.versionHistory,
          store.lenderLogos,
          store.loadLenderLogos,
        ],
        []
      )
    );

  useEffect(() => {
    if (versionHistory.length > 0) return;

    Promise.allSettled([loadVersionHistory(), loadLenderLogos()])
      .then(() => setIsLoading(false))
      .catch((error) => {
        setError(error);
      });
  }, [versionHistory.length, loadVersionHistory, loadLenderLogos]);

  useEffect(() => {
    if (isMountedRef.current || isLoading) return;
    isMountedRef.current = true;

    const lastOpen = window.localStorage.getItem(
      "mre_last_open_version_history"
    );

    if (typeof lastOpen === "undefined" || lastOpen == null) {
      setHasNew(true);
      return;
    }

    if (versionHistory.length < 1) {
      setHasNew(false);
      return;
    }

    const latestUpdateDate = versionHistory[0].date;
    const lastOpenDate = DateFns.parseISO(lastOpen);

    setHasNew(DateFns.compareDesc(lastOpenDate, latestUpdateDate) >= 0);
  }, [versionHistory, isLoading]);

  const getLenderLogo = useCallback(
    (lenderCode) =>
      lenderLogos.find((lender) => lender.code === lenderCode.toLowerCase())
        ?.logo,
    [lenderLogos]
  );

  useLayoutEffect(() => {
    if (!isOpen) return;
    const versionListWrapperEL = versionListElRef.current;
    if (!versionListWrapperEL) return;

    const { scrollHeight, clientHeight } = versionListWrapperEL;

    if (scrollHeight <= clientHeight) return;

    versionListWrapperEL.classList.add(
      "after:block",
      "after:pointer-events-none",
      "after:fixed",
      "after:bottom-4",
      "after:left-0",
      "after:right-0",
      "after:h-20",
      "after:bg-gradient-to-t",
      "after:from-white",
      "after:transition-opacity",
      "after:duration-[500ms]"
    );

    function onScroll() {
      if (!versionListWrapperEL) return;

      if (versionListWrapperEL.scrollTop < 1) {
        versionListWrapperEL.classList.remove("after:opacity-0");
        versionListWrapperEL.classList.add("after:opacity-1");
      } else {
        versionListWrapperEL.classList.remove("after:opacity-1");
        versionListWrapperEL.classList.add("after:opacity-0");
      }
    }

    versionListWrapperEL.addEventListener("scroll", onScroll);

    return () => {
      versionListWrapperEL.removeEventListener("scroll", onScroll);
    };
  }, [isOpen]);

  return (
    <>
      {!isLoading && (
        <Button
          onClick={() =>
            setIsOpen((open) => {
              const next = !open;

              if (next) {
                window.localStorage.setItem(
                  "mre_last_open_version_history",
                  new Date().toISOString()
                );
                setHasNew(false);
              }

              return next;
            })
          }
          className={twMerge(
            "mt-0.5 ml-4 w-max rounded-full font-helvetica",
            hasNew
              ? "bg-accent  px-2 text-white"
              : "bg-transparent text-accent underline hover:bg-transparent"
          )}
          size="medium"
          withIcon={hasNew ? <Sparkle /> : undefined}
        >
          {hasNew ? "See what's new" : "Version history"}
        </Button>
      )}
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        containerClassName={`w-[640px] max-w-[640px] px-8 pt-6`}
        closeBtnClassname="right-8 translate-x-full top-6 z-10"
        withCloseButton
        closeWithBackdrop
      >
        <section className="relative min-h-[200px]">
          {!error && !isLoading && (
            <>
              <header>
                <SectionLabel className="text-lg desktop:text-xl">
                  What's new
                </SectionLabel>
                <p className="mt-1 font-helvetica text-[13px] text-[#6F5F7B] desktop:text-base">
                  New updates and improvements on lender calculators
                </p>
              </header>
              <ul
                ref={versionListElRef}
                className="relative mt-[18px] max-h-[70vh] w-[calc(100%+14px)] overflow-y-auto pr-[14px]"
              >
                {versionHistory.map((version, index) => (
                  <li key={index} className="mt-2 first:mt-0">
                    <VersionItem
                      version={version}
                      lenderLogo={getLenderLogo(version.lender)}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
          {isLoading && !error && (
            <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-[#6F5F7B]/90">
              loading...
            </p>
          )}
          {!isLoading && error && (
            <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-[#6F5F7B]/90">
              {error.message || "Error getting version history"}
            </p>
          )}
        </section>
      </Modal>
    </>
  );
};

export default VersionHistory;
