import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SectionLabel } from "../components/label";
import useStore from "../store";
import { AnimatePresence, motion } from "framer-motion";
import {
  ApplicantFinancial,
  ApplicantFinancialSection,
  IApplicantParty,
} from "../applicant";
import { FinancialModal } from ".";
import {
  IIncome,
  incomeTypeOtherData,
  incomeTypeRentalData,
  incomeTypeSalaryWagesData,
} from "../income";
import {
  ILiability,
  LiabilityTypeCreditCardsData,
  LiabilityTypeLiabilitiesData,
} from "../liabilities";
import { Alert } from "../components/alert";

type OpenedModal = ComponentProps<typeof FinancialModal>["openedModal"];

export default function FinancialPosition() {
  const [openedModal, setOpenedModal] = useState<OpenedModal>(null);
  const [ApplicantParties, Loans, addEventListener] = useStore(
    useCallback(
      (state) => [state.ApplicantParties, state.Loans, state.addEventListener],
      []
    )
  );
  const [showLinkedLiabilityAlert, setShowlinkedLiabilityAlert] =
    useState(false);

  const applicantParty: IApplicantParty = Array.from(
    ApplicantParties.values()
  )[0];
  const hasApplicant = typeof applicantParty !== "undefined";
  const hasSecurity = Loans.size > 0;
  const isDisabled = !hasApplicant || !hasSecurity;

  const hasIncome = useCallback(
    (category: "Salary/Wages" | "Rental Income" | "Other Income") => {
      const income = Array.from<IIncome>(applicantParty?.Income.values() || []);

      return income.some((inc) => {
        if (category === "Salary/Wages") {
          return incomeTypeSalaryWagesData.some(
            (type) => type["IncomeType.Code"] === inc.IncomeType
          );
        }

        if (category === "Rental Income") {
          return incomeTypeRentalData.some(
            (type) => type["IncomeType.Code"] === inc.IncomeType
          );
        }

        return incomeTypeOtherData.some(
          (type) => type["IncomeType.Code"] === inc.IncomeType
        );
      });
    },
    [applicantParty?.Income]
  );

  const hasLiabilities = useCallback(
    (category: "Credit Cards" | "Liabilities") => {
      const liabilities = Array.from(applicantParty?.Liability.values() || []);

      return liabilities.some((lia) => {
        if (category === "Credit Cards") {
          return LiabilityTypeCreditCardsData.some(
            (type) => type["LiabilityType.Code"] === lia.LiabilityType
          );
        }

        return LiabilityTypeLiabilitiesData.some(
          (type) => type["LiabilityType.Code"] === lia.LiabilityType
        );
      });
    },
    [applicantParty?.Liability]
  );

  const hasExpenses = useMemo(
    () =>
      applicantParty?.Expense?.size ? applicantParty.Expense.size > 0 : false,
    [applicantParty?.Expense.size]
  );

  // MODAL HANDLER
  const handleModal = useCallback(
    (openedModal: OpenedModal) => () => setOpenedModal(openedModal),
    []
  );

  const closeModal = useCallback(() => setOpenedModal(null), []);

  useEffect(() => {
    addEventListener("searchstart", () => {
      closeModal();
    });
  }, [addEventListener, closeModal]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    addEventListener("addedfinancial", (e) => {
      if (
        e.type === "Liability" &&
        (e.financial as unknown as ILiability).LoanId
      ) {
        setShowlinkedLiabilityAlert(true);

        timeout = setTimeout(() => {
          setShowlinkedLiabilityAlert(false);
        }, 1500);
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [addEventListener]);

  useEffect(() => {
    addEventListener("starteditfinancial", (e) => {
      if (e.type === "Liability") {
        if ((e.financial as unknown as ILiability).LoanId) {
          closeModal();
        }
      }
    });
  }, [Loans, addEventListener, closeModal]);

  return (
    <section>
      <FinancialModal openedModal={openedModal} onClose={closeModal} />
      <SectionLabel className="mb-4 leading-[1.75rem] tracking-[-0.5px] desktop:text-base desktop:leading-[1.75rem]">
        FINANCIAL POSITION
      </SectionLabel>
      <AnimatePresence>
        {!isDisabled && (
          <section className="rounded-[3px] border border-[#CDC3D2]/[78%]">
            <ApplicantFinancialSection label="Income">
              <ApplicantFinancial
                label="Salary/Wages"
                onClick={handleModal({
                  category: "income",
                  options: {
                    applicantPartyId: applicantParty._id,
                    type: "Salary/Wages",
                  },
                })}
                checked={hasIncome("Salary/Wages")}
              />
              <ApplicantFinancial
                label="Rental Income"
                onClick={handleModal({
                  category: "income",
                  options: {
                    applicantPartyId: applicantParty._id,
                    type: "Rental Income",
                  },
                })}
                checked={hasIncome("Rental Income")}
              />
              <ApplicantFinancial
                label="Other Income"
                onClick={handleModal({
                  category: "income",
                  options: {
                    applicantPartyId: applicantParty._id,
                    type: "Other Income",
                  },
                })}
                checked={hasIncome("Other Income")}
              />
            </ApplicantFinancialSection>
            <ApplicantFinancialSection label="Outgoings">
              <ApplicantFinancial
                label="Credit Cards"
                onClick={handleModal({
                  category: "liabilities",
                  options: {
                    applicantPartyId: applicantParty._id,
                    type: "Credit Cards",
                  },
                })}
                checked={hasLiabilities("Credit Cards")}
              />
              <ApplicantFinancial
                label="Expenses"
                onClick={handleModal({
                  category: "expenses",
                  options: {
                    applicantPartyId: applicantParty._id,
                    type: "Expenses",
                    label: "Expenses",
                  },
                })}
                checked={hasExpenses}
              />
              <Alert
                open={showLinkedLiabilityAlert}
                position="right"
                wrapperClassName="w-max"
                backgroundColor="#1E1642"
                arrowClassName="transform translate-x-4"
                className="top-1/2 -translate-y-1/2 translate-x-2 transform py-2 text-left font-helvetica text-[13px] text-white shadow-none desktop:text-sm"
                below={
                  <ApplicantFinancial
                    label="Liabilities"
                    onClick={handleModal({
                      category: "liabilities",
                      options: {
                        applicantPartyId: applicantParty._id,
                        type: "Liabilities",
                      },
                    })}
                    checked={hasLiabilities("Liabilities")}
                  />
                }
              >
                Existing loan added as a liability
              </Alert>
            </ApplicantFinancialSection>
          </section>
        )}
        {isDisabled && (
          <motion.div
            animate={{
              height: isDisabled ? "60px" : 0,
              opacity: isDisabled ? 1 : 0,
              padding: isDisabled ? "10px" : 0,
            }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
            className="grid place-items-center rounded-[3px] bg-[#F7F4F9] text-center text-[13px] font-bold text-[#83748E]/50 desktop:text-sm"
          >
            <span>
              {hasApplicant
                ? "Add Security to get started"
                : "Add Applicant & Security to get started"}
            </span>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
}
