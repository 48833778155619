import { FC, SVGAttributes } from "react";

const WarningCircleLg: FC<SVGAttributes<SVGSVGElement>> = ({
  fill = "#FF6363",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88px"
      height="88px"
      viewBox="0 0 88 88"
      version="1.1"
      {...props}
    >
      <title>Warning</title>
      <g
        id="illion-import-flow"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="E" transform="translate(-676.000000, -63.000000)" fill={fill}>
          <g id="Group-3" transform="translate(676.000000, 63.000000)">
            <path
              d="M44,0 C68.300529,0 88,19.699471 88,44 C88,68.300529 68.300529,88 44,88 C19.699471,88 0,68.300529 0,44 C0,19.699471 19.699471,0 44,0 Z M41.2727897,62.4155293 C37.3113067,63.7795773 35.2056696,68.0967732 36.5697176,72.0582562 C37.9337656,76.0197393 42.2509615,78.1253764 46.2124445,76.7613284 C50.1739275,75.3972804 52.2795647,71.0800845 50.9155167,67.1186015 C49.5514687,63.1571184 45.2342728,61.0514813 41.2727897,62.4155293 Z M41.0338045,13.1479492 C38.7399469,14.4663394 37.3715606,16.9845408 37.480494,19.6870027 L38.6873668,49.6277331 C38.7573209,51.3656842 39.6309884,52.9794651 41.0419668,53.9769945 C42.4529452,54.9745239 44.2339623,55.2375441 45.8419423,54.6858539 C47.8471738,53.9953973 49.2480803,52.1597889 49.4172104,50.0007292 L51.7203496,20.1822055 C51.9282438,17.4907615 50.6539452,14.8806585 48.4113584,13.4045097 C46.1687715,11.928361 43.3276621,11.829559 41.0338045,13.1479492 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WarningCircleLg;
