import { FC, useEffect, useRef } from "react";

const SideBar: FC = ({ children }) => {
  const sidebarRef = useRef<HTMLElement>(null);
  const isHovering = useRef(false);
  const prevScrollY = useRef<number>(0);

  // // useEffect(() => {
  // //   const sidebar = sidebarRef.current;
  // //   if (!sidebar) return;

  // //   function handleScroll() {
  // //     const sidebar = sidebarRef.current;
  // //     const isHover = isHovering.current;
  // //     const isScrollUp = prevScrollY.current > window.scrollY;
  // //     const updateScroll = () => (prevScrollY.current = window.scrollY);

  // //     if (!sidebar) return updateScroll();

  // //     if (isHover) {
  // //       const newTranslation =
  // //         (parseInt(sidebar.style.transform) || 0) +
  // //         (window.scrollY - prevScrollY.current);
  // //       sidebar.style.transform = `translateY(${newTranslation}px)`;
  // //     }

  // //     updateScroll();
  // //   }

  // //   function handleMouseEnter() {
  // //     isHovering.current = true;
  // //   }

  // //   function handleMouseLeave() {
  // //     isHovering.current = false;
  // //   }

  // //   window.addEventListener("scroll", handleScroll);
  // //   sidebar.addEventListener("mouseenter", handleMouseEnter);
  // //   sidebar.addEventListener("mouseleave", handleMouseLeave);

  // //   return () => {
  // //     window.removeEventListener("scroll", handleScroll);
  // //     sidebar.removeEventListener("mouseenter", handleMouseEnter);
  // //     sidebar.removeEventListener("mouseleave", handleMouseLeave);
  // //   };
  // // }, []);

  useEffect(() => {
    const sidebar = sidebarRef.current;

    if (!sidebar) return;

    function handleMouseOver(this: HTMLElement, e: MouseEvent) {
      isHovering.current = true;
    }

    function handleMouseLeave(this: HTMLElement, e: MouseEvent) {
      isHovering.current = false;
    }

    function handleScroll(this: Window) {
      const sidebar = sidebarRef.current;
      if (!sidebar) return;

      const scrollUp = this.scrollY < prevScrollY.current;
      const offsetTop = sidebar.offsetTop;
      const isAbsolute = sidebar.style.position === "absolute";

      const { top, bottom } = sidebar.getBoundingClientRect();
      if (scrollUp) {
        if (top >= 0) {
          sidebar.style.top = "0px";
          sidebar.style.position = "sticky";
        }

        prevScrollY.current = this.scrollY;
        return;
      } else {
        // if (isAbsolute && bottom <= this.innerHeight - 16) {
        //   sidebar.style.top = `${
        //     parseInt(sidebar.style.top) + (window.scrollY - prevScrollY.current)
        //   }px`;
        // }
      }

      if (!isHovering.current) {
        prevScrollY.current = this.scrollY;
        return;
      }

      if (offsetTop <= 0) {
        prevScrollY.current = this.scrollY;
        return;
      }

      if (!isAbsolute && bottom >= window.innerHeight) {
        sidebar.style.top = `${offsetTop}px`;
        sidebar.style.position = "absolute";
      }

      prevScrollY.current = this.scrollY;
    }

    sidebar.addEventListener("mouseenter", handleMouseOver);
    sidebar.addEventListener("mouseleave", handleMouseLeave);
    window.addEventListener("scroll", handleScroll);

    return () => {
      sidebar.removeEventListener("mouseenter", handleMouseOver);
      sidebar.removeEventListener("mouseleave", handleMouseLeave);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="relative max-w-full">
      <aside className="sticky top-0 block h-max" ref={sidebarRef}>
        {children}
      </aside>
    </div>
  );
};

export default SideBar;
