import { FC, SVGAttributes } from "react";

const ZoomReset: FC<SVGAttributes<SVGSVGElement>> = ({
  fill = "#42B4B4",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16px"
      height="19px"
      viewBox="0 0 16 19"
      version="1.1"
      {...props}
    >
      <g
        id="Image-Viewer"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Default"
          transform="translate(-209.000000, -199.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g
            id="restart_alt_black_24dp"
            transform="translate(205.000000, 197.000000)"
          >
            <g id="icon-reset" transform="translate(4.000000, 2.000000)">
              <path
                d="M8,3 L8,0 L4,4 L8,8 L8,5 C11.31,5 14,7.69 14,11 C14,13.97 11.83,16.43 9,16.91 L9,18.93 C12.95,18.44 16,15.08 16,11 C16,6.58 12.42,3 8,3 Z"
                id="Path"
              />
              <path
                d="M2,11 C2,9.35 2.67,7.85 3.76,6.76 L2.34,5.34 C0.9,6.79 0,8.79 0,11 C0,15.08 3.05,18.44 7,18.93 L7,16.91 C4.17,16.43 2,13.97 2,11 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ZoomReset;
