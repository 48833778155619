import { FC } from "react";

const FinancialDetailsContent: FC = ({ children }) => {
  return (
    <div className="mt-6 bg-[#F5F1F8] py-[17px] px-[14px]">{children}</div>
    // <div className="px-[14px] bg-[#F5F1F8] mt-6">{children}</div>
  );
};

export default FinancialDetailsContent;
