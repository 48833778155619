import {
  Children,
  cloneElement,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Triangle } from "../../icons";
import { isEqual } from "lodash";
import "./InputSelect.css";
import { AnimatePresence, motion } from "framer-motion";
import InputText from "./InputText";

type Value = any;

type Selected = {
  value?: Value;
  label?: string;
};

const InputSelect: FC<{
  onChange?: (value: Value) => void;
  initialValue?: Value;
  className?: string;
  border?: string;
  formatButtonLabel?: (value?: string) => string | undefined;
  searchable?:
    | boolean
    | {
        placeholder?: string;
      };
}> = ({
  children,
  onChange,
  initialValue,
  className,
  border = "border border-[#C2B6CB]",
  formatButtonLabel,
  searchable,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [selected, setSelected] = useState<Selected | undefined>({
    label: "",
    value: "",
  });

  const selectedLabel = useMemo(
    () =>
      formatButtonLabel ? formatButtonLabel(selected?.label) : selected?.label,
    [formatButtonLabel, selected?.label]
  );

  const [search, setSearch] = useState(selectedLabel);

  const handleItemClick = useCallback(
    (selected: Selected) => () => {
      setIsOpen((isOpen) => !isOpen);
      setSelected(selected);
      setSearch("");
      inputTextRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
      onChange && onChange(selected.value!);
    },
    [setIsOpen, onChange]
  );

  const inputTextRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const options = Children.map(
      Children.toArray(children).filter(Boolean),
      (child: any) => {
        if (child.props.label) {
          const option = Children.map(
            child.props.children,
            (c: ReactElement) => {
              const { children: label, value } = c.props;
              let match = isEqual(value, initialValue);

              if (match) {
                setSelected({ value, label });
              } else if (
                typeof initialValue !== "undefined" &&
                (!initialValue || initialValue === null)
              ) {
                setSelected({ value: "", label: "" });
              }

              if (
                search &&
                !`${label}`.toLowerCase()?.includes(search.toLowerCase())
              ) {
                return null;
              }

              return cloneElement(c, {
                selected: match,
                onClick: handleItemClick({
                  label,
                  value,
                }),
              });
            }
          );

          if (option.length === 0) return null;

          return cloneElement(child, {
            children: option,
          });
        }

        const {
          children: label,
          value,
        }: { children: Selected["label"]; value: Selected["value"] } =
          child.props;
        let match = isEqual(value, initialValue);

        if (match) {
          setSelected({ value, label });
        } else if (
          typeof initialValue !== "undefined" &&
          (!initialValue || initialValue === null)
        ) {
          setSelected({ value: "", label: "" });
        }

        if (
          search &&
          !`${label}`.toLowerCase()?.includes(search.toLowerCase())
        ) {
          return null;
        }

        return cloneElement(child, {
          selected: match,
          onClick: handleItemClick({
            label,
            value,
          }),
        });
      }
    );

    setOptions(options);
  }, [children, handleItemClick, initialValue, search]);

  useEffect(() => {
    const windowListener = (e: MouseEvent) => {
      if (e.target === inputTextRef.current || e.target === buttonRef.current)
        return;

      setIsOpen(false);
    };

    window.addEventListener("click", windowListener);

    return () => {
      window.removeEventListener("click", windowListener);
    };
  }, []);

  useEffect(() => {
    const inputText = inputTextRef.current;
    if (isOpen && searchable && inputText) {
      inputText.focus();
    }
  }, [isOpen, searchable]);

  return (
    <div className={`initial:relative initial:w-full ${className}`}>
      {!searchable && (
        <button
          ref={buttonRef}
          type="button"
          className={`relative flex w-full items-center rounded-[3px] bg-[#FDFBFF] px-2 py-1 text-left font-helvetica text-[11px] focus:outline-accent desktop:text-sm ${border} ${
            selectedLabel ? "text-[#6F5F7B]" : "text-transparent"
          }`}
          onClick={(e) => {
            setIsOpen((prevIsOpen) => !prevIsOpen);
          }}
          style={{
            wordBreak: "break-word",
          }}
        >
          {selectedLabel || "-"}
          <Triangle className="absolute right-[7px] rotate-180" />
        </button>
      )}
      {searchable && (
        <>
          {!isOpen && (
            <button
              type="button"
              className={`relative flex w-full items-center rounded-[3px] bg-[#FDFBFF] px-2 py-1 text-left font-helvetica text-xs focus:outline-accent desktop:text-sm ${border} ${
                selectedLabel ? "text-[#6F5F7B]" : "text-transparent"
              }`}
              onClick={(e) => {
                setIsOpen(true);
              }}
              style={{
                wordBreak: "break-word",
              }}
            >
              {selectedLabel || "-"}
              <Triangle className="absolute right-[7px] block rotate-180" />
            </button>
          )}
          {isOpen && (
            <InputText
              ref={inputTextRef}
              id="select_search"
              value={isOpen ? search : selectedLabel || ""}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={
                typeof searchable === "object" ? searchable.placeholder : ""
              }
              onFocus={() => setIsOpen(true)}
              withIcon={<Triangle className="rotate-180" />}
              iconPosition="right"
              autoComplete="off"
            />
          )}
        </>
      )}
      <AnimatePresence>
        {isOpen && (
          <motion.ul
            role="listbox"
            className="custom-scrollable 200ms absolute z-10 mb-4 max-h-[400px] w-max min-w-full rounded bg-[#F5F1F8] py-[17px] shadow-[0px_2px_4px_rgba(0,_0,_0,_0.5)] transition-all ease-in-out"
            initial={{
              opacity: 0,
            }}
            exit={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{ duration: 0.1, ease: "easeInOut" }}
            style={{
              overflowX: "hidden",
            }}
          >
            {options}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export const Option: FC<{
  value: Value;
  onClick?: () => void;
  selected?: boolean;
}> = ({ children, onClick, selected = false }) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const current = ref.current;

    if (!current || !selected) return;
    let parent = current.parentElement;

    if (!parent) return;
    if (parent.attributes.getNamedItem("role")?.value !== "listbox") {
      parent = parent.parentElement?.parentElement || null;
    }

    if (parent?.clientHeight === parent?.scrollHeight) return;

    current.scrollIntoView({ block: "center" });
  }, [selected]);

  return (
    <button
      ref={ref}
      role="option"
      aria-selected={selected}
      type="button"
      onClick={onClick}
      className={`option block w-full px-[20px] py-1.5 text-left font-helvetica text-xs leading-[1em] desktop:text-sm desktop:leading-[1em] ${
        selected
          ? "bg-accent text-white"
          : "bg-transparent hover:bg-gray-300/30 focus:bg-gray-300/30 focus:outline-none"
      } ${children ? "text-[#6F5F7B]" : "text-transparent"}`}
    >
      {children ?? "-"}
    </button>
  );
};

export const OptionGroup: FC<{
  label: string;
}> = ({ label, children }) => {
  return (
    <div className="option-group mt-3 first:mt-0" aria-label="option-group">
      <span className="block px-[20px] font-helvetica text-xs text-[#9B90A4] desktop:text-sm">
        {label}
      </span>
      <div className="mt-1.5">{children}</div>
    </div>
  );
};

export default InputSelect;
