import { toFixed } from "./number";

export const deepMapToArray = (map: Map<any, any>): any[] => {
  return Array.from(map.values()).map((item) => {
    if (item instanceof Map) {
      return deepMapToArray(item);
    }

    if (typeof item === "object") {
      const obj: typeof item = {};

      Object.keys(item).forEach((objkey) => {
        const key = objkey as keyof typeof obj;
        const attr = item[key];

        if (attr instanceof Map) {
          obj[key] = deepMapToArray(attr);
          return;
        }

        obj[key] = attr;
      });

      if (obj._id) {
        delete obj._id;
      }

      return obj;
    }

    return item;
  });
};

export const decimalToPercentage = (num: number, decimalDigit = 2) => {
  return `${toFixed(num * 100, decimalDigit)}%`;
};

const module = {
  deepMapToArray,
  decimalToPercentage,
};

export default module;
