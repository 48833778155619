import { useEffect, useMemo, useState } from "react";
import { SectionLabel } from "../components/label";
import { Modal } from "../components/modal";
import {
  Button,
  FormErrors,
  InputContainer,
  InputDate,
  InputNumber,
  InputSelect,
  InputSwitch,
  InputText,
  Label,
  Option,
  useForm,
} from "../components/form";
import { Dollar, Info, Location, Percent, Trash } from "../icons";
import { ILoan, propertyTypeOptionsData, usageTypeData } from ".";
import { ISecurity } from "./Loan";
import { ILiability, LendersData } from "../liabilities";
import { Frequency } from "../applicant";
import { format, parse } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { Alert } from "../components/alert";
import { toFixed } from "../util";

type Props = {
  open: boolean;
  title: string;
  initialValue?: Partial<OutputValues>;
  mode?: "add" | "edit";
  handleClose?: () => void;
  onDelete?: () => void;
  onSave?: (value: OutputValues) => void;
};

type OutputValues = {
  Loan: Omit<ILoan, "LoanId" | "_id" | "_LiabilityId">;
  Liability?: Omit<
    ILiability,
    "_id" | "ApplicantId" | "LiabilityType" | "LoanId"
  >;
};

type Values = Omit<ILoan, "_id" | "LoanId" | "Security"> &
  Omit<ISecurity, "PropertyType"> & {
    PropertyType: ISecurity["PropertyType"][0];
    PropertySubType: ISecurity["PropertyType"][1];
    PropertySubSubType: ISecurity["PropertyType"][2];
    TotalTermYears?: number;
    InterestOnlyTermYears?: number;
    SecurityType: ISecurity["SecurityType"];
    Liability?: Partial<Omit<ILiability, "_id">> & {
      InterestOnlyTermYears?: number | null;
      TotalTermYears?: number | null;
    };
  };

const frequency: {
  value: Frequency;
  label: string;
}[] = [
  {
    value: "Yearly",
    label: "Yearly",
  },
  {
    value: "HalfYearly",
    label: "Half-yearly",
  },
  {
    value: "Quarterly",
    label: "Quarterly",
  },
  {
    value: "Monthly",
    label: "Monthly",
  },
  {
    value: "Fortnightly",
    label: "Fortnightly",
  },
  {
    value: "Weekly",
    label: "Weekly",
  },
];

const defaultValues = {
  Value: NaN,
  UsageType: null,
  PropertyType: null,
  PropertySubType: null,
  PropertySubSubType: null,
  SecurityType: "Proposed",
  Location: null,
  IsRBNZExempt: false,
  Lender: null,
  LoanType: "New",
  TopUpAmount: NaN,
  LendingAction: null,
  LoanAmount: NaN,
  TotalTermYears: NaN,
  TotalTermMonths: 12 * 30,
  InterestOnlyTermYears: NaN,
  InterestOnlyTermMonths: NaN,
  LiabilityType: "HomeLoan",
  Liability: {
    LiabilityType: "HomeLoan",
    RepaymentAmount: NaN,
    RepaymentFrequency: "Monthly",
    Limit: null,
    Balance: null,
    InterestRate: null,
    RepaymentType: null,
    StartDate: null,
    TotalTermYears: NaN,
    TotalTermMonths: NaN,
    InterestOnlyTermYears: NaN,
    InterestOnlyTermMonths: NaN,
    Lender: null,
    IsRevolvingCredit: null,
  },
} as const;

export default function SecurityFormModal({
  open,
  title,
  initialValue,
  handleClose,
  onSave,
  mode = "add",
  onDelete,
}: Props) {
  const [deleting, setDeleting] = useState(false);

  const { handleChange, values, resetValues, hasErrors } = useForm<Values>({
    initialValues: {
      Value: initialValue?.Loan?.Security.Value ?? defaultValues.Value,
      UsageType:
        initialValue?.Loan?.Security.UsageType ?? defaultValues.UsageType,
      PropertyType:
        initialValue?.Loan?.Security.PropertyType[0] ??
        defaultValues.PropertyType,
      PropertySubType:
        initialValue?.Loan?.Security.PropertyType[1] ??
        defaultValues.PropertySubSubType,
      PropertySubSubType:
        initialValue?.Loan?.Security.PropertyType[2] ??
        defaultValues.PropertySubSubType,
      SecurityType:
        initialValue?.Loan?.Security.SecurityType ?? defaultValues.SecurityType,
      Location: initialValue?.Loan?.Security.Location ?? defaultValues.Location,
      IsRBNZExempt:
        initialValue?.Loan?.Security.IsRBNZExempt ?? defaultValues.IsRBNZExempt,
      Lender: initialValue?.Loan?.Lender ?? defaultValues.Lender,
      LoanType: initialValue?.Loan?.LoanType ?? defaultValues.LoanType,
      TopUpAmount: initialValue?.Loan?.TopUpAmount ?? defaultValues.TopUpAmount,
      LendingAction:
        initialValue?.Loan?.LendingAction ?? defaultValues.LendingAction,
      LoanAmount: initialValue?.Loan?.LoanAmount ?? defaultValues.LoanAmount,
      TotalTermYears: Math.floor(
        (initialValue?.Loan?.TotalTermMonths || defaultValues.TotalTermMonths) /
          12
      ),
      TotalTermMonths:
        (initialValue?.Loan?.TotalTermMonths || defaultValues.TotalTermMonths) %
        12,
      InterestOnlyTermYears: Math.floor(
        (initialValue?.Loan?.InterestOnlyTermMonths ?? 0) / 12
      ),
      InterestOnlyTermMonths:
        (initialValue?.Loan?.InterestOnlyTermMonths ?? 0) % 12,
      LiabilityType: defaultValues.LiabilityType,
      Liability: {
        ...defaultValues.Liability,
        ...initialValue?.Liability,
        InterestRate: initialValue?.Liability?.InterestRate
          ? toFixed(initialValue.Liability.InterestRate * 100)
          : undefined,
        TotalTermYears: Math.floor(
          (initialValue?.Liability?.TotalTermMonths ||
            defaultValues.TotalTermMonths) / 12
        ),
        TotalTermMonths:
          (initialValue?.Liability?.TotalTermMonths ||
            defaultValues.TotalTermMonths) % 12,
        InterestOnlyTermYears: Math.floor(
          (initialValue?.Liability?.InterestOnlyTermMonths ?? 0) / 12
        ),
        InterestOnlyTermMonths:
          (initialValue?.Liability?.InterestOnlyTermMonths ?? 0) % 12,
      },
    },
    validate: (values) => {
      const errors: FormErrors<Values> = {};

      if (!values.SecurityType) {
        errors.SecurityType = "Security type can't be empty";
      }

      if (!values.Value) {
        errors.Value = "Security Value can't be empty or zero";
      }

      if (!values.LoanType) {
        errors.LoanType = "Loan Type can't be empty";
      }

      if (values.LoanType === "New") {
        if (!values.LoanAmount) {
          errors.LoanAmount = "Loan Amount can't be empty or zero";
        }
        if (!values.TotalTermMonths && !values.TotalTermYears) {
          errors.TotalTermMonths = "Loan Term can't be empty or zero";
        }
      } else if (values.LoanType === "Existing") {
        if (!values.LendingAction) {
          errors.LendingAction = "Lending Action can't be empty";
        }

        if (values.LendingAction === "None") {
          if (!values.Liability?.RepaymentAmount) {
            errors.Liability = "Repayments can't be empty or zero";
          }
          if (!values.Liability?.Limit) {
            errors.LoanAmount = "Loan Amount can't be empty or zero";
          }
          if (!values.Liability?.Balance) {
            errors.Liability = "Balance can't be empty or zero";
          }
          if (!values.Liability?.InterestRate) {
            errors.Liability = "Interest rate can't be empty or zero";
          }
          if (
            !values.Liability?.TotalTermMonths &&
            !values.Liability?.TotalTermYears
          ) {
            errors.TotalTermMonths = "Loan Term can't be empty or zero";
          }
        } else if (values.LendingAction === "Refinance") {
          if (!values.LoanAmount) {
            errors.LoanAmount = "Loan Amount can't be empty or zero";
          }
          if (!values?.TotalTermMonths && !values?.TotalTermYears) {
            errors.TotalTermMonths = "New Loan Term can't be empty or zero";
          }
        } else if (values.LendingAction === "TopUp") {
          if (!values.LoanAmount && !values.Liability?.Limit) {
            errors.LoanAmount = "Original Loan Amount can't be empty or zero";
          }
          if (!values.TopUpAmount) {
            errors.TopUpAmount = "Proposed Increaase can't be empty or zero";
          }
          if (
            !values.Liability?.TotalTermMonths &&
            !values.Liability?.TotalTermYears
          ) {
            errors.TotalTermMonths =
              "Original Loan Term can't be empty or zero";
          }
          if (!values.TotalTermMonths && !values.TotalTermYears) {
            errors.TotalTermMonths = "New Loan Term can't be empty or zero";
          }
          if (values.Lender) {
            if (!values.Liability?.InterestRate) {
              errors.Liability = "Current Interest Rate can't be empty or zero";
            }
            if (!values.Liability?.RepaymentAmount) {
              errors.Liability = " Current Repayments can't be empty or zero";
            }
            if (!values.Liability?.Balance) {
              errors.Liability = "Current Balance Rate can't be empty or zero";
            }
          }
        }
      }

      return errors;
    },
    validateOnMount: true,
  });

  useEffect(() => {
    if (!open) {
      resetValues();
    }
  }, [open, resetValues]);

  const propertyTypeOptions = propertyTypeOptionsData.filter(
    ({ sub_type_of }) => sub_type_of === 0
  );
  const propertySubTypeOptions = useMemo(() => {
    const selected = propertyTypeOptionsData.find(
      (type) => type.code === values.PropertyType
    );

    const propertySubTypeOptions = propertyTypeOptionsData.filter(
      (type) => type.sub_type_of === selected?.id
    );

    if (
      !propertySubTypeOptions.some(
        (type) => type.code === values.PropertySubType
      )
    ) {
      handleChange("PropertySubType", null);
    }

    return propertySubTypeOptions;
    // ADDING values.PropertySubType CAUSING INFINITE RERENDER
    // WE DON'T NEED THAT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.PropertyType]);

  const propertySubSubTypeOptions = useMemo(() => {
    const selected = propertyTypeOptionsData.find(
      (type) => type.code === values.PropertySubType
    );
    const propertySubSubTypeOptions = propertyTypeOptionsData.filter(
      (type) => type.sub_type_of === selected?.id
    );

    if (
      !propertySubSubTypeOptions.some(
        (type) => type.code === values.PropertySubSubType
      )
    ) {
      handleChange("PropertySubSubType", null);
    }

    return propertySubSubTypeOptions;
    // ADDING values.PropertySubType CAUSING INFINITE RERENDER
    // WE DON'T NEED THAT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.PropertySubType]);

  function handleSave() {
    if (!onSave) return;
    const cleanData = cleanUpData(values);
    onSave(cleanData);
  }

  return (
    <Modal containerClassName="max-w-[981px]" open={open}>
      <section>
        <header>
          <SectionLabel className="text-[15px] text-[#7B5C8D] desktop:text-lg">
            {title}
          </SectionLabel>
        </header>
        <main className="mt-[23px]">
          <form>
            <div className="grid grid-cols-2 gap-[20px]">
              <section className="space-y-3 bg-[#F5F1F8] px-[15px] py-[25px]">
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="" className="required">
                    Security Type
                  </Label>
                  <InputSwitch
                    value={values.SecurityType}
                    onChange={(value) => handleChange("SecurityType", value)}
                    className="min-w-[242px]"
                  >
                    <InputSwitch.InputSwitchOption value="Proposed">
                      PROPOSED
                    </InputSwitch.InputSwitchOption>
                    <InputSwitch.InputSwitchOption value="Existing">
                      EXISTING
                    </InputSwitch.InputSwitchOption>
                  </InputSwitch>
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="Value" className="required">
                    Value
                  </Label>
                  <InputNumber
                    id="Value"
                    buttons={false}
                    value={values.Value}
                    onChange={(value) => handleChange("Value", value)}
                    withIcon={<Dollar />}
                    className="min-w-[242px]"
                    autonumeric
                  />
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="UsageType">Usage</Label>
                  <InputSelect
                    className="min-w-[242px]"
                    initialValue={values.UsageType}
                    onChange={(value) => handleChange("UsageType", value)}
                  >
                    <Option value={null} />
                    {usageTypeData.map((usage) => (
                      <Option value={usage.code} key={usage.id}>
                        {usage.name}
                      </Option>
                    ))}
                  </InputSelect>
                </InputContainer>
                <InputContainer className="items-start gap-0 border-transparent py-0">
                  <Label htmlFor="propertyType" className="mt-1">
                    Property Type
                  </Label>
                  <div className="min-w-[242px] space-y-3">
                    <InputSelect
                      initialValue={values.PropertyType}
                      onChange={(value) => handleChange("PropertyType", value)}
                    >
                      <Option value={null} />
                      {propertyTypeOptions.map((type) => (
                        <Option value={type.code} key={type.id}>
                          {type.type}
                        </Option>
                      ))}
                    </InputSelect>
                    {propertySubTypeOptions.length > 0 && (
                      <InputSelect
                        onChange={(value) =>
                          handleChange("PropertySubType", value)
                        }
                        initialValue={values.PropertySubType}
                      >
                        <Option value={null}></Option>
                        {propertySubTypeOptions.map((type) => (
                          <Option value={type.code} key={type.id}>
                            {type.type}
                          </Option>
                        ))}
                      </InputSelect>
                    )}
                    {propertySubSubTypeOptions.length > 0 && (
                      <InputSelect
                        onChange={(value) =>
                          handleChange("PropertySubSubType", value)
                        }
                        initialValue={values.PropertySubSubType}
                      >
                        <Option value={null}></Option>
                        {propertySubSubTypeOptions.map((type) => (
                          <Option value={type.code} key={type.id}>
                            {type.type}
                          </Option>
                        ))}
                      </InputSelect>
                    )}
                  </div>
                </InputContainer>
                <InputContainer className="hidden gap-0 border-transparent py-0">
                  <Label htmlFor="Location">Location</Label>
                  <InputText
                    id="Location"
                    withIcon={<Location />}
                    className="min-w-[242px]"
                    value={values.Location ?? ""}
                    onChange={(e) => handleChange("Location", e.target.value)}
                  />
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="IsRBNZExempt">RBNZ Exempt</Label>
                  <InputSwitch
                    id="IsRBNZExempt"
                    value={values.IsRBNZExempt}
                    onChange={(value) => handleChange("IsRBNZExempt", value)}
                    className="min-w-[242px]"
                  >
                    <InputSwitch.InputSwitchOption value={true}>
                      Yes
                    </InputSwitch.InputSwitchOption>
                    <InputSwitch.InputSwitchOption value={false}>
                      No
                    </InputSwitch.InputSwitchOption>
                  </InputSwitch>
                </InputContainer>
              </section>
              <SecurityFormModal.LoanDetails
                initialValue={initialValue}
                values={values}
                handleChange={handleChange}
                resetValues={resetValues}
              />
            </div>
            <div className="mt-[21px] flex items-center justify-center gap-4">
              {mode === "edit" && (
                <Alert
                  below={
                    <Button
                      type="button"
                      size="large"
                      className="w-[173px] border border-[#EF8B85] bg-[#EF8B85] disabled:border-[#C2B6CB]"
                      onClick={() => setDeleting(true)}
                      withIcon={
                        <Trash
                          fill="#fff"
                          className="w-[12px] desktop:w-[14px]"
                        />
                      }
                      disabled={hasErrors}
                    >
                      Delete Security
                    </Button>
                  }
                  open={deleting}
                  backgroundColor="#1E1642"
                  wrapperClassName="mr-auto"
                  className="translate-y-1.5 text-left font-helvetica text-[11px] text-white shadow-none desktop:text-sm"
                  arrowClassName="-translate-y-1 -translate-x-1"
                  onClose={() => setDeleting(false)}
                >
                  <p className="font-bold">Are you sure?</p>
                  <p className="mt-[5px]">
                    Deleting this item cannot be undone.
                  </p>
                  <div className="mt-3 flex items-center justify-end space-x-[28px]">
                    <Button
                      type="button"
                      size="small"
                      className="w-max bg-transparent text-white"
                      onClick={() => setDeleting(false)}
                    >
                      <span className="font-helvetica text-[11px] leading-[1em] desktop:text-sm">
                        Cancel
                      </span>
                    </Button>
                    <Button
                      type="button"
                      className="w-max bg-[#E13756] px-4 py-2 font-bold text-white"
                      size="small"
                      onClick={() => {
                        onDelete && onDelete();
                        setDeleting(false);
                        handleClose && handleClose();
                      }}
                    >
                      <span className="font-helvetica text-[11px] leading-[1em] desktop:text-sm">
                        Yes, delete
                      </span>
                    </Button>
                  </div>
                </Alert>
              )}
              <Button
                type="button"
                size="large"
                className="w-[173px] border border-secondary_accent bg-transparent text-secondary_accent"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="button"
                size="large"
                className="w-[173px] border border-secondary_accent disabled:border-[#C2B6CB]"
                onClick={handleSave}
                disabled={hasErrors}
              >
                Save and Close
              </Button>
            </div>
          </form>
        </main>
      </section>
    </Modal>
  );
}

SecurityFormModal.LoanDetails = function LoanDetails<T extends Values>({
  initialValue,
  values,
  handleChange,
  resetValues,
}: {
  initialValue: Props["initialValue"];
  values: T;
  handleChange: <K extends keyof Values>(key: K, payload: Values[K]) => void;
  resetValues: (newValues?: T) => void;
}) {
  const [showMore, setShowMore] = useState(false);
  const [showLoanTypeWarning, setShowLoanTypeWarning] = useState(false);

  const TotalNewLoanAmount =
    (values.Liability?.Limit || 0) + values.TopUpAmount;

  function handleLoanTypeChange(value: ILoan["LoanType"]) {
    if (initialValue?.Loan?.LoanType === "Existing" && value === "New") {
      setShowLoanTypeWarning(true);
      return;
    }
    handleChange("LoanType", value);
  }

  function handleLendingActionChange(value: Values["LendingAction"]) {
    const newValues: Values = { ...values, LendingAction: value };

    switch (values.LendingAction) {
      case null:
        if (
          newValues.LendingAction === "None" ||
          newValues.LendingAction === "TopUp"
        ) {
          newValues.Liability = {
            ...newValues.Liability,
            Limit: newValues.LoanAmount,
          };
        }
        break;
      case "None":
        if (newValues.LendingAction === "Refinance") {
          newValues.LoanAmount = newValues.Liability?.Limit || NaN;
        }
        break;
      case "Refinance":
        if (
          newValues.LendingAction === "None" ||
          newValues.LendingAction === "TopUp"
        ) {
          newValues.Liability = {
            ...newValues.Liability,
            Limit: newValues.LoanAmount,
          };
        }
        break;
    }

    resetValues(newValues as any);
  }

  function removeLinkedLiability() {
    handleChange("LoanType", "New");
    setShowLoanTypeWarning(false);
  }

  return (
    <>
      <section className="space-y-3 bg-[#F5F1F8] px-[15px] py-[25px]">
        <InputContainer className="relative gap-0 border-transparent py-0">
          <Label htmlFor="" className="required">
            Loan Type
          </Label>
          <Alert
            open={showLoanTypeWarning}
            onClose={() => setShowLoanTypeWarning(false)}
            backgroundColor="#1E1642"
            wrapperClassName="min-w-[242px]"
            className="right-0 w-[400px] translate-x-0 translate-y-[10px] text-left font-helvetica text-[11px] text-white shadow-none desktop:text-sm"
            below={
              <InputSwitch
                value={values.LoanType}
                onChange={handleLoanTypeChange}
              >
                <InputSwitch.InputSwitchOption value="New">
                  NEW
                </InputSwitch.InputSwitchOption>
                <InputSwitch.InputSwitchOption value="Existing">
                  EXISTING
                </InputSwitch.InputSwitchOption>
              </InputSwitch>
            }
          >
            <div className="flex items-center gap-3">
              <span>
                <Info
                  width={48}
                  height={48}
                  fill="currentColor"
                  className="text-[#699AC8]"
                />
              </span>
              <p>
                Changing this loan from from 'Existing' to 'New' will remove the
                linked liability.
              </p>
            </div>
            <div className="mt-3 flex items-center justify-end space-x-[28px]">
              <Button
                type="button"
                size="small"
                className="w-max bg-transparent text-white"
                onClick={() => setShowLoanTypeWarning(false)}
              >
                <span className="font-helvetica text-[11px] leading-[1em] desktop:text-sm">
                  Cancel
                </span>
              </Button>
              <Button
                type="button"
                className="w-[100px] px-4 py-2"
                size="small"
                onClick={removeLinkedLiability}
              >
                <span className="font-helvetica text-[11px] font-bold leading-[1em] desktop:text-sm">
                  OK
                </span>
              </Button>
            </div>
          </Alert>
        </InputContainer>
        {values.LoanType === "New" && (
          <>
            <InputContainer className="gap-0 border-transparent py-0">
              <Label htmlFor="LoanAmount" className="required">
                Loan Amount
              </Label>
              <InputNumber
                id="LoanAmount"
                buttons={false}
                value={values.LoanAmount}
                onChange={(value) => handleChange("LoanAmount", value)}
                withIcon={<Dollar />}
                className="min-w-[242px]"
                autonumeric
              />
            </InputContainer>
            <InputContainer className="gap-0 border-transparent py-0">
              <Label htmlFor="LoanTermYears" className="required">
                Loan Term
              </Label>
              <div className="grid min-w-[242px] grid-cols-2 gap-2">
                <div className="relative">
                  <InputNumber
                    id="LoanTermYears"
                    value={values.TotalTermYears}
                    onChange={(value) => handleChange("TotalTermYears", value)}
                    buttons={false}
                    placeholder="0"
                    pattern="^[0-9]*$"
                  />
                  <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                    YRS
                  </span>
                </div>
                <div className="relative">
                  <InputNumber
                    id="LoanTermMonths"
                    value={values.TotalTermMonths ?? undefined}
                    onChange={(value) => handleChange("TotalTermMonths", value)}
                    buttons={false}
                    placeholder="0"
                    pattern="^[0-9]*$"
                  />
                  <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                    MOS
                  </span>
                </div>
              </div>
            </InputContainer>
            <InputContainer className="gap-0 border-transparent py-0">
              <Label htmlFor="InterestOnlyTermYears">Interest-Only Term</Label>
              <div className="grid min-w-[242px] grid-cols-2 gap-2">
                <div className="relative">
                  <InputNumber
                    id="InterestOnlyTermYears"
                    value={values.InterestOnlyTermYears}
                    onChange={(value) =>
                      handleChange("InterestOnlyTermYears", value)
                    }
                    buttons={false}
                    placeholder="0"
                    pattern="^[0-9]*$"
                  />
                  <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                    YRS
                  </span>
                </div>
                <div className="relative">
                  <InputNumber
                    id="InterestOnlyTermMonths"
                    value={values.InterestOnlyTermMonths ?? undefined}
                    onChange={(value) =>
                      handleChange("InterestOnlyTermMonths", value)
                    }
                    buttons={false}
                    placeholder="0"
                    pattern="^[0-9]*$"
                  />
                  <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                    MOS
                  </span>
                </div>
              </div>
            </InputContainer>
          </>
        )}
        {values.LoanType === "Existing" && (
          <>
            <InputContainer className="gap-0 border-transparent py-0">
              <Label htmlFor="LendingAction" className="required">
                Lending Action
              </Label>
              <InputSelect
                className="min-w-[242px]"
                initialValue={values.LendingAction}
                onChange={handleLendingActionChange}
              >
                <Option value={"None"}>None</Option>
                <Option value={"Refinance"}>Refinance</Option>
                <Option value={"TopUp"}>Top-Up</Option>
              </InputSelect>
            </InputContainer>
            {values.LendingAction === null && (
              <>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="LoanAmount" className="required">
                    Loan Amount
                  </Label>
                  <InputNumber
                    id="LoanAmount"
                    buttons={false}
                    value={values.LoanAmount}
                    onChange={(value) => handleChange("LoanAmount", value)}
                    withIcon={<Dollar />}
                    className="min-w-[242px]"
                    autonumeric
                  />
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="LoanTermYears" className="required">
                    Loan Term
                  </Label>
                  <div className="grid min-w-[242px] grid-cols-2 gap-2">
                    <div className="relative">
                      <InputNumber
                        id="LoanTermYears"
                        value={values.TotalTermYears}
                        onChange={(value) =>
                          handleChange("TotalTermYears", value)
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        YRS
                      </span>
                    </div>
                    <div className="relative">
                      <InputNumber
                        id="LoanTermMonths"
                        value={values.TotalTermMonths ?? undefined}
                        onChange={(value) =>
                          handleChange("TotalTermMonths", value)
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        MOS
                      </span>
                    </div>
                  </div>
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="InterestOnlyTermYears">
                    Interest-Only Term
                  </Label>
                  <div className="grid min-w-[242px] grid-cols-2 gap-2">
                    <div className="relative">
                      <InputNumber
                        id="InterestOnlyTermYears"
                        value={values.InterestOnlyTermYears}
                        onChange={(value) =>
                          handleChange("InterestOnlyTermYears", value)
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        YRS
                      </span>
                    </div>
                    <div className="relative">
                      <InputNumber
                        id="InterestOnlyTermMonths"
                        value={values.InterestOnlyTermMonths ?? undefined}
                        onChange={(value) =>
                          handleChange("InterestOnlyTermMonths", value)
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        MOS
                      </span>
                    </div>
                  </div>
                </InputContainer>
              </>
            )}
            {values.LendingAction === "None" && (
              <>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="LendingAction" className="required">
                    Repayments
                  </Label>
                  <div className="flex min-w-[242px] items-center rounded-[3px] border border-[#C2B6CB]">
                    <InputNumber
                      id="RepaymentAmount"
                      withIcon={<Dollar />}
                      value={values.Liability?.RepaymentAmount || undefined}
                      className="rounded-none border-transparent border-r-[#C2B6CB] focus:!outline-none"
                      onChange={(value) => {
                        handleChange("Liability", {
                          ...values.Liability,
                          RepaymentAmount: value,
                        });
                      }}
                      autonumeric
                    />
                    <InputSelect
                      initialValue={values.Liability?.RepaymentFrequency}
                      onChange={(value) =>
                        handleChange("Liability", {
                          ...values.Liability,
                          RepaymentFrequency: value,
                        })
                      }
                      className="w-[50px]"
                      border="border-0"
                      formatButtonLabel={(value) => value?.charAt(0)}
                    >
                      <Option value={null}></Option>
                      {frequency.map((freq) => (
                        <Option value={freq.value} key={freq.value}>
                          {freq.label}
                        </Option>
                      ))}
                    </InputSelect>
                  </div>
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="Limit" className="required">
                    Loan Amount
                  </Label>
                  <InputNumber
                    id="Limit"
                    buttons={false}
                    value={values.Liability?.Limit ?? NaN}
                    onChange={(value) =>
                      handleChange("Liability", {
                        ...values.Liability,
                        Limit: value,
                      })
                    }
                    withIcon={<Dollar />}
                    className="min-w-[242px]"
                    autonumeric
                  />
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="Balance" className="required">
                    Balance
                  </Label>
                  <InputNumber
                    id="Balance"
                    buttons={false}
                    value={values.Liability?.Balance ?? NaN}
                    onChange={(value) =>
                      handleChange("Liability", {
                        ...values.Liability,
                        Balance: value,
                      })
                    }
                    withIcon={<Dollar />}
                    className="min-w-[242px]"
                    autonumeric
                  />
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="Lender">Lender</Label>
                  <InputSelect
                    initialValue={values.Liability?.Lender}
                    onChange={(value) =>
                      handleChange("Liability", {
                        ...values.Liability,
                        Lender: value,
                      })
                    }
                    className="min-w-[242px]"
                    searchable={{ placeholder: "Search Lender" }}
                  >
                    <Option value={null}></Option>
                    {LendersData.map((lender) => (
                      <Option value={lender.Code} key={lender.Code}>
                        {lender.Name}
                      </Option>
                    ))}
                  </InputSelect>
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="RepaymentType">Repayment Type</Label>
                  <InputSelect
                    initialValue={values.Liability?.RepaymentType}
                    onChange={(value) =>
                      handleChange("Liability", {
                        ...values.Liability,
                        RepaymentType: value,
                      })
                    }
                    className="min-w-[242px]"
                  >
                    <Option value={null}></Option>
                    {[
                      {
                        value: "PrincipalInterest",
                        label: "Principal & Interest",
                      },
                      {
                        value: "InterestOnly",
                        label: "Interest-Only",
                      },
                    ].map((type) => (
                      <Option value={type.value} key={type.value}>
                        {type.label}
                      </Option>
                    ))}
                  </InputSelect>
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="InterestRate" className="required">
                    Interest Rate
                  </Label>
                  <InputNumber
                    id="InterestRate"
                    className="min-w-[242px]"
                    withIcon={<Percent className="opacity-50" />}
                    iconPosition="right"
                    value={values.Liability?.InterestRate || undefined}
                    onChange={(value) =>
                      handleChange("Liability", {
                        ...values.Liability,
                        InterestRate: value,
                      })
                    }
                  />
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="StartDate">Start Date</Label>
                  <InputDate
                    id="StartDate"
                    wrapperClassName="min-w-[242px]"
                    placeholder="DD / MM / YYYY"
                    value={
                      values.Liability?.StartDate
                        ? parse(
                            values.Liability.StartDate,
                            "yyyy-MM-dd",
                            new Date()
                          )
                        : undefined
                    }
                    onChange={(date) =>
                      handleChange("Liability", {
                        ...values.Liability,
                        StartDate: format(date, "yyyy-MM-dd"),
                      })
                    }
                  />
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="LoanTermYears" className="required">
                    Loan Term
                  </Label>
                  <div className="grid min-w-[242px] grid-cols-2 gap-2">
                    <div className="relative">
                      <InputNumber
                        id="LoanTermYears"
                        value={values.Liability?.TotalTermYears ?? undefined}
                        onChange={(value) =>
                          handleChange("Liability", {
                            ...values.Liability,
                            TotalTermYears: value,
                          })
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        YRS
                      </span>
                    </div>
                    <div className="relative">
                      <InputNumber
                        id="LoanTermMonths"
                        value={values.Liability?.TotalTermMonths ?? undefined}
                        onChange={(value) =>
                          handleChange("Liability", {
                            ...values.Liability,
                            TotalTermMonths: value,
                          })
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        MOS
                      </span>
                    </div>
                  </div>
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="InterestOnlyTermYears">
                    Interest-Only Term
                  </Label>
                  <div className="grid min-w-[242px] grid-cols-2 gap-2">
                    <div className="relative">
                      <InputNumber
                        id="InterestOnlyTermYears"
                        value={values.Liability?.InterestOnlyTermYears ?? 0}
                        onChange={(value) =>
                          handleChange("Liability", {
                            ...values.Liability,
                            InterestOnlyTermYears: value,
                          })
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        YRS
                      </span>
                    </div>
                    <div className="relative">
                      <InputNumber
                        id="InterestOnlyTermMonths"
                        value={
                          values.Liability?.InterestOnlyTermMonths ?? undefined
                        }
                        onChange={(value) =>
                          handleChange("Liability", {
                            ...values.Liability,
                            InterestOnlyTermMonths: value,
                          })
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        MOS
                      </span>
                    </div>
                  </div>
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="IsRevolvingCredit">
                    Resolving Credit / Redraw Facility
                  </Label>
                  <InputSwitch
                    id="IsRevolvingCredit"
                    value={values.Liability?.IsRevolvingCredit}
                    onChange={(value) =>
                      handleChange("Liability", {
                        ...values.Liability,
                        IsRevolvingCredit: value,
                      })
                    }
                    className="min-w-[242px]"
                  >
                    <InputSwitch.InputSwitchOption value={true}>
                      Yes
                    </InputSwitch.InputSwitchOption>
                    <InputSwitch.InputSwitchOption value={false}>
                      No
                    </InputSwitch.InputSwitchOption>
                  </InputSwitch>
                </InputContainer>
              </>
            )}
            {values.LendingAction === "Refinance" && (
              <>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="LoanAmount" className="required">
                    Loan Amount
                  </Label>
                  <InputNumber
                    id="LoanAmount"
                    buttons={false}
                    value={
                      !isNaN(values.LoanAmount)
                        ? values.LoanAmount
                        : values.Liability?.Limit || undefined
                    }
                    onChange={(value) => {
                      const newValue = {
                        ...values,
                        LoanAmount: value,
                        Liability: { ...values.Liability, Limit: value },
                      };
                      resetValues(newValue);
                    }}
                    withIcon={<Dollar />}
                    className="min-w-[242px]"
                    autonumeric
                  />
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="OriginalLoanTermYears">
                    Original Loan Term
                  </Label>
                  <div className="grid min-w-[242px] grid-cols-2 gap-2">
                    <div className="relative">
                      <InputNumber
                        id="OriginalLoanTermYears"
                        value={values.Liability?.TotalTermYears ?? undefined}
                        onChange={(value) =>
                          resetValues({
                            ...values,
                            Liability: {
                              ...values.Liability,
                              TotalTermYears: value,
                            },
                            TotalTermYears: value,
                          })
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        YRS
                      </span>
                    </div>
                    <div className="relative">
                      <InputNumber
                        id="OriginalLoanTermMonths"
                        value={values.Liability?.TotalTermMonths ?? undefined}
                        onChange={(value) =>
                          resetValues({
                            ...values,
                            Liability: {
                              ...values.Liability,
                              TotalTermMonths: value,
                            },
                            TotalTermMonths: value,
                          })
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        MOS
                      </span>
                    </div>
                  </div>
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="LoanTermYears" className="required">
                    New Loan Term
                  </Label>
                  <div className="grid min-w-[242px] grid-cols-2 gap-2">
                    <div className="relative">
                      <InputNumber
                        id="LoanTermYears"
                        value={values.TotalTermYears}
                        onChange={(value) =>
                          handleChange("TotalTermYears", value)
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        YRS
                      </span>
                    </div>
                    <div className="relative">
                      <InputNumber
                        id="LoanTermMonths"
                        value={values.TotalTermMonths ?? undefined}
                        onChange={(value) =>
                          handleChange("TotalTermMonths", value)
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        MOS
                      </span>
                    </div>
                  </div>
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="InterestOnlyTermYears">New IO Term</Label>
                  <div className="grid min-w-[242px] grid-cols-2 gap-2">
                    <div className="relative">
                      <InputNumber
                        id="InterestOnlyTermYears"
                        value={values.InterestOnlyTermYears ?? 0}
                        onChange={(value) =>
                          handleChange("InterestOnlyTermYears", value)
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        YRS
                      </span>
                    </div>
                    <div className="relative">
                      <InputNumber
                        id="InterestOnlyTermMonths"
                        value={values.InterestOnlyTermMonths ?? undefined}
                        onChange={(value) =>
                          handleChange("InterestOnlyTermMonths", value)
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        MOS
                      </span>
                    </div>
                  </div>
                </InputContainer>
                <InputContainer className="justify-end gap-0 border-transparent py-0">
                  <Label
                    htmlFor="ShowMore"
                    className="w-[242px] font-bold text-accent"
                  >
                    {showMore ? "Show Less" : "Show More"}...
                  </Label>
                  <input
                    checked={showMore}
                    onChange={() => setShowMore(!showMore)}
                    type="checkbox"
                    className="hidden"
                    id="ShowMore"
                    name="ShowMore"
                  />
                </InputContainer>
                <AnimatePresence>
                  {showMore && (
                    <motion.div
                      initial={{
                        height: 0,
                        opacity: 0,
                      }}
                      animate={{
                        height: "auto",
                        opacity: 1,
                      }}
                      exit={{ height: 0, opacity: 0, marginTop: 0 }}
                      transition={{ duration: 0.2, ease: "linear" }}
                      className="!mt-0 space-y-2"
                    >
                      <SectionLabel className="!text-[13px] desktop:!text-sm">
                        Existing Loan Details
                      </SectionLabel>
                      <InputContainer className="gap-0 border-transparent py-0">
                        <Label htmlFor="Lender">Lender</Label>
                        <InputSelect
                          initialValue={values.Lender}
                          onChange={(value) => {
                            const newValue = {
                              ...values,
                              Lender: value,
                              Liability: { ...values.Liability, Lender: value },
                            };
                            resetValues(newValue);
                          }}
                          className="min-w-[242px]"
                          searchable={{ placeholder: "Search Lender" }}
                        >
                          <Option value={null}></Option>
                          {LendersData.map((lender) => (
                            <Option value={lender.Code} key={lender.Code}>
                              {lender.Name}
                            </Option>
                          ))}
                        </InputSelect>
                      </InputContainer>
                      <InputContainer className="gap-0 border-transparent py-0">
                        <Label htmlFor="LendingAction">Repayments</Label>
                        <div className="flex min-w-[242px] items-center rounded-[3px] border border-[#C2B6CB]">
                          <InputNumber
                            id="RepaymentAmount"
                            withIcon={<Dollar />}
                            value={
                              values.Liability?.RepaymentAmount || undefined
                            }
                            className="rounded-none border-transparent border-r-[#C2B6CB] focus:!outline-none"
                            onChange={(value) => {
                              handleChange("Liability", {
                                ...values.Liability,
                                RepaymentAmount: value,
                              });
                            }}
                            autonumeric
                          />
                          <InputSelect
                            initialValue={values.Liability?.RepaymentFrequency}
                            onChange={(value) =>
                              handleChange("Liability", {
                                ...values.Liability,
                                RepaymentFrequency: value,
                              })
                            }
                            className="w-[50px]"
                            border="border-0"
                            formatButtonLabel={(value) => value?.charAt(0)}
                          >
                            <Option value={null}></Option>
                            {frequency.map((freq) => (
                              <Option value={freq.value} key={freq.value}>
                                {freq.label}
                              </Option>
                            ))}
                          </InputSelect>
                        </div>
                      </InputContainer>
                      <InputContainer className="gap-0 border-transparent py-0">
                        <Label htmlFor="Balance">Balance</Label>
                        <InputNumber
                          id="Balance"
                          buttons={false}
                          value={values.Liability?.Balance ?? NaN}
                          onChange={(value) =>
                            handleChange("Liability", {
                              ...values.Liability,
                              Balance: value,
                            })
                          }
                          withIcon={<Dollar />}
                          className="min-w-[242px]"
                          autonumeric
                        />
                      </InputContainer>
                      <InputContainer className="gap-0 border-transparent py-0">
                        <Label htmlFor="InterestRate">Interest Rate</Label>
                        <InputNumber
                          id="InterestRate"
                          className="min-w-[242px]"
                          withIcon={<Percent className="opacity-50" />}
                          iconPosition="right"
                          value={values.Liability?.InterestRate || undefined}
                          onChange={(value) =>
                            handleChange("Liability", {
                              ...values.Liability,
                              InterestRate: value,
                            })
                          }
                        />
                      </InputContainer>
                      <InputContainer className="gap-0 border-transparent py-0">
                        <Label htmlFor="RepaymentType">Repayment Type</Label>
                        <InputSelect
                          initialValue={values.Liability?.RepaymentType}
                          onChange={(value) =>
                            handleChange("Liability", {
                              ...values.Liability,
                              RepaymentType: value,
                            })
                          }
                          className="min-w-[242px]"
                        >
                          <Option value={null}></Option>
                          {[
                            {
                              value: "PrincipalInterest",
                              label: "Principal & Interest",
                            },
                            {
                              value: "InterestOnly",
                              label: "Interest-Only",
                            },
                          ].map((type) => (
                            <Option value={type.value} key={type.value}>
                              {type.label}
                            </Option>
                          ))}
                        </InputSelect>
                      </InputContainer>
                      <InputContainer className="gap-0 border-transparent py-0">
                        <Label htmlFor="StartDate">Start Date</Label>
                        <InputDate
                          id="StartDate"
                          wrapperClassName="min-w-[242px]"
                          placeholder="DD / MM / YYYY"
                          value={
                            values.Liability?.StartDate
                              ? parse(
                                  values.Liability.StartDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                )
                              : undefined
                          }
                          onChange={(date) =>
                            handleChange("Liability", {
                              ...values.Liability,
                              StartDate: format(date, "yyyy-MM-dd"),
                            })
                          }
                        />
                      </InputContainer>
                      <InputContainer className="gap-0 border-transparent py-0">
                        <Label htmlFor="InterestOnlyTermYears">
                          Interest-Only Term
                        </Label>
                        <div className="grid min-w-[242px] grid-cols-2 gap-2">
                          <div className="relative">
                            <InputNumber
                              id="InterestOnlyTermYears"
                              value={
                                values.Liability?.InterestOnlyTermYears ?? 0
                              }
                              onChange={(value) =>
                                handleChange("Liability", {
                                  ...values.Liability,
                                  InterestOnlyTermYears: value,
                                })
                              }
                              buttons={false}
                              placeholder="0"
                              pattern="^[0-9]*$"
                            />
                            <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                              YRS
                            </span>
                          </div>
                          <div className="relative">
                            <InputNumber
                              id="InterestOnlyTermMonths"
                              value={
                                values.Liability?.InterestOnlyTermMonths ??
                                undefined
                              }
                              onChange={(value) =>
                                handleChange("Liability", {
                                  ...values.Liability,
                                  InterestOnlyTermMonths: value,
                                })
                              }
                              buttons={false}
                              placeholder="0"
                              pattern="^[0-9]*$"
                            />
                            <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                              MOS
                            </span>
                          </div>
                        </div>
                      </InputContainer>
                      <InputContainer className="gap-0 border-transparent py-0">
                        <Label htmlFor="IsRevolvingCredit">
                          Resolving Credit / Redraw Facility
                        </Label>
                        <InputSwitch
                          id="IsRevolvingCredit"
                          value={values.Liability?.IsRevolvingCredit}
                          onChange={(value) =>
                            handleChange("Liability", {
                              ...values.Liability,
                              IsRevolvingCredit: value,
                            })
                          }
                          className="min-w-[242px]"
                        >
                          <InputSwitch.InputSwitchOption value={true}>
                            Yes
                          </InputSwitch.InputSwitchOption>
                          <InputSwitch.InputSwitchOption value={false}>
                            No
                          </InputSwitch.InputSwitchOption>
                        </InputSwitch>
                      </InputContainer>
                    </motion.div>
                  )}
                </AnimatePresence>
              </>
            )}
            {values.LendingAction === "TopUp" && (
              <>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="LoanAmount" className="required">
                    Original Loan Amount
                  </Label>
                  <InputNumber
                    id="LoanAmount"
                    buttons={false}
                    value={
                      !isNaN(values.LoanAmount)
                        ? values.LoanAmount
                        : values.Liability?.Limit || undefined
                    }
                    onChange={(value) => {
                      resetValues({
                        ...values,
                        LoanAmount: value,
                        Liability: { ...values.Liability, Limit: value },
                      });
                    }}
                    withIcon={<Dollar />}
                    className="min-w-[242px]"
                    autonumeric
                  />
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="TopUpAmount" className="required">
                    Proposed Increase
                  </Label>
                  <InputNumber
                    id="TopUpAmount"
                    buttons={false}
                    value={values.TopUpAmount}
                    onChange={(value) => {
                      handleChange("TopUpAmount", value);
                    }}
                    withIcon={<Dollar />}
                    className="min-w-[242px]"
                    autonumeric
                  />
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="NewLoanTotal">New Loan Total</Label>
                  <InputNumber
                    key={TotalNewLoanAmount}
                    id="NewLoanTotal"
                    buttons={false}
                    value={TotalNewLoanAmount}
                    withIcon={<Dollar />}
                    className="min-w-[242px]"
                    disabled
                    autonumeric
                  />
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="OriginalLoanTermYears" className="required">
                    Original Loan Term
                  </Label>
                  <div className="grid min-w-[242px] grid-cols-2 gap-2">
                    <div className="relative">
                      <InputNumber
                        id="OriginalLoanTermYears"
                        value={values.Liability?.TotalTermYears ?? undefined}
                        onChange={(value) =>
                          resetValues({
                            ...values,
                            Liability: {
                              ...values.Liability,
                              TotalTermYears: value,
                            },
                            TotalTermYears: value,
                          })
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        YRS
                      </span>
                    </div>
                    <div className="relative">
                      <InputNumber
                        id="OriginalLoanTermMonths"
                        value={values.Liability?.TotalTermMonths ?? undefined}
                        onChange={(value) =>
                          resetValues({
                            ...values,
                            Liability: {
                              ...values.Liability,
                              TotalTermMonths: value,
                            },
                            TotalTermMonths: value,
                          })
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        MOS
                      </span>
                    </div>
                  </div>
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="LoanTermYears" className="required">
                    New Loan Term
                  </Label>
                  <div className="grid min-w-[242px] grid-cols-2 gap-2">
                    <div className="relative">
                      <InputNumber
                        id="LoanTermYears"
                        value={values.TotalTermYears}
                        onChange={(value) =>
                          handleChange("TotalTermYears", value)
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        YRS
                      </span>
                    </div>
                    <div className="relative">
                      <InputNumber
                        id="LoanTermMonths"
                        value={values.TotalTermMonths ?? undefined}
                        onChange={(value) =>
                          handleChange("TotalTermMonths", value)
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        MOS
                      </span>
                    </div>
                  </div>
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="InterestOnlyTermYears">New IO Term</Label>
                  <div className="grid min-w-[242px] grid-cols-2 gap-2">
                    <div className="relative">
                      <InputNumber
                        id="InterestOnlyTermYears"
                        value={values.InterestOnlyTermYears ?? 0}
                        onChange={(value) =>
                          handleChange("InterestOnlyTermYears", value)
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        YRS
                      </span>
                    </div>
                    <div className="relative">
                      <InputNumber
                        id="InterestOnlyTermMonths"
                        value={values.InterestOnlyTermMonths ?? undefined}
                        onChange={(value) =>
                          handleChange("InterestOnlyTermMonths", value)
                        }
                        buttons={false}
                        placeholder="0"
                        pattern="^[0-9]*$"
                      />
                      <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                        MOS
                      </span>
                    </div>
                  </div>
                </InputContainer>
                <InputContainer className="gap-0 border-transparent py-0">
                  <Label htmlFor="Lender">Current Lender</Label>
                  <InputSelect
                    initialValue={values.Lender ?? null}
                    onChange={(value) => {
                      const newValue = {
                        ...values,
                        Lender: value,
                        Liability: { ...values.Liability, Lender: value },
                      };
                      resetValues(newValue);
                    }}
                    className="min-w-[242px]"
                    searchable={{ placeholder: "Search Lender" }}
                  >
                    <Option value={null}></Option>
                    {LendersData.map((lender) => (
                      <Option value={lender.Code} key={lender.Code}>
                        {lender.Name}
                      </Option>
                    ))}
                  </InputSelect>
                </InputContainer>
                {values.Lender === null && (
                  <>
                    <InputContainer className="justify-end gap-0 border-transparent py-0">
                      <Label
                        htmlFor="ShowMore"
                        className="w-[242px] font-bold text-accent"
                      >
                        {showMore ? "Show Less" : "Show More"}...
                      </Label>
                      <input
                        checked={showMore}
                        onChange={() => setShowMore(!showMore)}
                        type="checkbox"
                        className="hidden"
                        id="ShowMore"
                        name="ShowMore"
                      />
                    </InputContainer>
                    <AnimatePresence>
                      {showMore && (
                        <motion.div
                          initial={{
                            height: 0,
                            opacity: 0,
                          }}
                          animate={{
                            height: "auto",
                            opacity: 1,
                          }}
                          exit={{ height: 0, opacity: 0, marginTop: 0 }}
                          transition={{ duration: 0.2, ease: "linear" }}
                          className="!mt-0 space-y-2"
                        >
                          <SectionLabel className="!text-[13px] desktop:!text-sm">
                            Existing Loan Details
                          </SectionLabel>
                          <InputContainer className="gap-0 border-transparent py-0">
                            <Label htmlFor="LendingAction">Repayments</Label>
                            <div className="flex min-w-[242px] items-center rounded-[3px] border border-[#C2B6CB]">
                              <InputNumber
                                id="RepaymentAmount"
                                withIcon={<Dollar />}
                                value={
                                  values.Liability?.RepaymentAmount || undefined
                                }
                                className="rounded-none border-transparent border-r-[#C2B6CB] focus:!outline-none"
                                onChange={(value) => {
                                  handleChange("Liability", {
                                    ...values.Liability,
                                    RepaymentAmount: value,
                                  });
                                }}
                                autonumeric
                              />
                              <InputSelect
                                initialValue={
                                  values.Liability?.RepaymentFrequency
                                }
                                onChange={(value) =>
                                  handleChange("Liability", {
                                    ...values.Liability,
                                    RepaymentFrequency: value,
                                  })
                                }
                                className="w-[50px]"
                                border="border-0"
                                formatButtonLabel={(value) => value?.charAt(0)}
                              >
                                <Option value={null}></Option>
                                {frequency.map((freq) => (
                                  <Option value={freq.value} key={freq.value}>
                                    {freq.label}
                                  </Option>
                                ))}
                              </InputSelect>
                            </div>
                          </InputContainer>
                          <InputContainer className="gap-0 border-transparent py-0">
                            <Label htmlFor="Balance">Balance</Label>
                            <InputNumber
                              id="Balance"
                              buttons={false}
                              value={values.Liability?.Balance ?? NaN}
                              onChange={(value) =>
                                handleChange("Liability", {
                                  ...values.Liability,
                                  Balance: value,
                                })
                              }
                              withIcon={<Dollar />}
                              className="min-w-[242px]"
                              autonumeric
                            />
                          </InputContainer>
                          <InputContainer className="gap-0 border-transparent py-0">
                            <Label htmlFor="InterestRate">Interest Rate</Label>
                            <InputNumber
                              id="InterestRate"
                              className="min-w-[242px]"
                              withIcon={<Percent className="opacity-50" />}
                              iconPosition="right"
                              value={values.Liability?.InterestRate || 0}
                              onChange={(value) =>
                                handleChange("Liability", {
                                  ...values.Liability,
                                  InterestRate: value,
                                })
                              }
                            />
                          </InputContainer>
                          <InputContainer className="gap-0 border-transparent py-0">
                            <Label htmlFor="RepaymentType">
                              Repayment Type
                            </Label>
                            <InputSelect
                              initialValue={values.Liability?.RepaymentType}
                              onChange={(value) =>
                                handleChange("Liability", {
                                  ...values.Liability,
                                  RepaymentType: value,
                                })
                              }
                              className="min-w-[242px]"
                            >
                              <Option value={null}></Option>
                              {[
                                {
                                  value: "PrincipalInterest",
                                  label: "Principal & Interest",
                                },
                                {
                                  value: "InterestOnly",
                                  label: "Interest-Only",
                                },
                              ].map((type) => (
                                <Option value={type.value} key={type.value}>
                                  {type.label}
                                </Option>
                              ))}
                            </InputSelect>
                          </InputContainer>
                          <InputContainer className="gap-0 border-transparent py-0">
                            <Label htmlFor="StartDate">Start Date</Label>
                            <InputDate
                              id="StartDate"
                              wrapperClassName="min-w-[242px]"
                              placeholder="DD / MM / YYYY"
                              value={
                                values.Liability?.StartDate
                                  ? parse(
                                      values.Liability.StartDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    )
                                  : undefined
                              }
                              onChange={(date) =>
                                handleChange("Liability", {
                                  ...values.Liability,
                                  StartDate: format(date, "yyyy-MM-dd"),
                                })
                              }
                            />
                          </InputContainer>
                          <InputContainer className="gap-0 border-transparent py-0">
                            <Label htmlFor="InterestOnlyTermYears">
                              Interest-Only Term
                            </Label>
                            <div className="grid min-w-[242px] grid-cols-2 gap-2">
                              <div className="relative">
                                <InputNumber
                                  id="InterestOnlyTermYears"
                                  value={
                                    values.Liability?.InterestOnlyTermYears ?? 0
                                  }
                                  onChange={(value) =>
                                    handleChange("Liability", {
                                      ...values.Liability,
                                      InterestOnlyTermYears: value,
                                    })
                                  }
                                  buttons={false}
                                  placeholder="0"
                                  pattern="^[0-9]*$"
                                />
                                <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                                  YRS
                                </span>
                              </div>
                              <div className="relative">
                                <InputNumber
                                  id="InterestOnlyTermMonths"
                                  value={
                                    values.Liability?.InterestOnlyTermMonths ??
                                    undefined
                                  }
                                  onChange={(value) =>
                                    handleChange("Liability", {
                                      ...values.Liability,
                                      InterestOnlyTermMonths: value,
                                    })
                                  }
                                  buttons={false}
                                  placeholder="0"
                                  pattern="^[0-9]*$"
                                />
                                <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                                  MOS
                                </span>
                              </div>
                            </div>
                          </InputContainer>
                          <InputContainer className="gap-0 border-transparent py-0">
                            <Label htmlFor="IsRevolvingCredit">
                              Resolving Credit / Redraw Facility
                            </Label>
                            <InputSwitch
                              id="IsRevolvingCredit"
                              value={values.Liability?.IsRevolvingCredit}
                              onChange={(value) =>
                                handleChange("Liability", {
                                  ...values.Liability,
                                  IsRevolvingCredit: value,
                                })
                              }
                              className="min-w-[242px]"
                            >
                              <InputSwitch.InputSwitchOption value={true}>
                                Yes
                              </InputSwitch.InputSwitchOption>
                              <InputSwitch.InputSwitchOption value={false}>
                                No
                              </InputSwitch.InputSwitchOption>
                            </InputSwitch>
                          </InputContainer>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </>
                )}
                {values.Lender !== null && (
                  <>
                    <InputContainer className="gap-0 border-transparent py-0">
                      <Label htmlFor="InterestRate" className="required">
                        Current Interest Rate
                      </Label>
                      <InputNumber
                        id="InterestRate"
                        className="min-w-[242px]"
                        withIcon={<Percent className="opacity-50" />}
                        iconPosition="right"
                        value={values.Liability?.InterestRate || undefined}
                        onChange={(value) =>
                          handleChange("Liability", {
                            ...values.Liability,
                            InterestRate: value,
                          })
                        }
                      />
                    </InputContainer>
                    <InputContainer className="gap-0 border-transparent py-0">
                      <Label htmlFor="LendingAction" className="required">
                        Current Repayments
                      </Label>
                      <div className="flex min-w-[242px] items-center rounded-[3px] border border-[#C2B6CB]">
                        <InputNumber
                          id="RepaymentAmount"
                          withIcon={<Dollar />}
                          value={values.Liability?.RepaymentAmount || undefined}
                          className="rounded-none border-transparent border-r-[#C2B6CB] focus:!outline-none"
                          onChange={(value) => {
                            handleChange("Liability", {
                              ...values.Liability,
                              RepaymentAmount: value,
                            });
                          }}
                          autonumeric
                        />
                        <InputSelect
                          initialValue={values.Liability?.RepaymentFrequency}
                          onChange={(value) =>
                            handleChange("Liability", {
                              ...values.Liability,
                              RepaymentFrequency: value,
                            })
                          }
                          className="w-[50px]"
                          border="border-0"
                          formatButtonLabel={(value) => value?.charAt(0)}
                        >
                          <Option value={null}></Option>
                          {frequency.map((freq) => (
                            <Option value={freq.value} key={freq.value}>
                              {freq.label}
                            </Option>
                          ))}
                        </InputSelect>
                      </div>
                    </InputContainer>
                    <InputContainer className="gap-0 border-transparent py-0">
                      <Label htmlFor="Balance" className="required">
                        Current Balance
                      </Label>
                      <InputNumber
                        id="Balance"
                        buttons={false}
                        value={values.Liability?.Balance ?? NaN}
                        onChange={(value) =>
                          handleChange("Liability", {
                            ...values.Liability,
                            Balance: value,
                          })
                        }
                        withIcon={<Dollar />}
                        className="min-w-[242px]"
                        autonumeric
                      />
                    </InputContainer>
                    <InputContainer className="justify-end gap-0 border-transparent py-0">
                      <Label
                        htmlFor="ShowMore"
                        className="w-[242px] font-bold text-accent"
                      >
                        {showMore ? "Show Less" : "Show More"}...
                      </Label>
                      <input
                        checked={showMore}
                        onChange={() => setShowMore(!showMore)}
                        type="checkbox"
                        className="hidden"
                        id="ShowMore"
                        name="ShowMore"
                      />
                    </InputContainer>
                    <AnimatePresence>
                      {showMore && (
                        <motion.div
                          initial={{
                            height: 0,
                            opacity: 0,
                          }}
                          animate={{
                            height: "auto",
                            opacity: 1,
                          }}
                          exit={{ height: 0, opacity: 0, marginTop: 0 }}
                          transition={{ duration: 0.2, ease: "linear" }}
                          className="!mt-0 space-y-2"
                        >
                          <SectionLabel className="!text-[13px] desktop:!text-sm">
                            Existing Loan Details
                          </SectionLabel>
                          <InputContainer className="gap-0 border-transparent py-0">
                            <Label htmlFor="RepaymentType">
                              Repayment Type
                            </Label>
                            <InputSelect
                              initialValue={values.Liability?.RepaymentType}
                              onChange={(value) =>
                                handleChange("Liability", {
                                  ...values.Liability,
                                  RepaymentType: value,
                                })
                              }
                              className="min-w-[242px]"
                            >
                              <Option value={null}></Option>
                              {[
                                {
                                  value: "PrincipalInterest",
                                  label: "Principal & Interest",
                                },
                                {
                                  value: "InterestOnly",
                                  label: "Interest-Only",
                                },
                              ].map((type) => (
                                <Option value={type.value} key={type.value}>
                                  {type.label}
                                </Option>
                              ))}
                            </InputSelect>
                          </InputContainer>
                          <InputContainer className="gap-0 border-transparent py-0">
                            <Label htmlFor="StartDate">Start Date</Label>
                            <InputDate
                              id="StartDate"
                              wrapperClassName="min-w-[242px]"
                              placeholder="DD / MM / YYYY"
                              value={
                                values.Liability?.StartDate
                                  ? parse(
                                      values.Liability.StartDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    )
                                  : undefined
                              }
                              onChange={(date) =>
                                handleChange("Liability", {
                                  ...values.Liability,
                                  StartDate: format(date, "yyyy-MM-dd"),
                                })
                              }
                            />
                          </InputContainer>
                          <InputContainer className="gap-0 border-transparent py-0">
                            <Label htmlFor="InterestOnlyTermYears">
                              Interest-Only Term
                            </Label>
                            <div className="grid min-w-[242px] grid-cols-2 gap-2">
                              <div className="relative">
                                <InputNumber
                                  id="InterestOnlyTermYears"
                                  value={
                                    values.Liability?.InterestOnlyTermYears ?? 0
                                  }
                                  onChange={(value) =>
                                    handleChange("Liability", {
                                      ...values.Liability,
                                      InterestOnlyTermYears: value,
                                    })
                                  }
                                  buttons={false}
                                  placeholder="0"
                                  pattern="^[0-9]*$"
                                />
                                <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                                  YRS
                                </span>
                              </div>
                              <div className="relative">
                                <InputNumber
                                  id="InterestOnlyTermMonths"
                                  value={
                                    values.Liability?.InterestOnlyTermMonths ??
                                    undefined
                                  }
                                  onChange={(value) =>
                                    handleChange("Liability", {
                                      ...values.Liability,
                                      InterestOnlyTermMonths: value,
                                    })
                                  }
                                  buttons={false}
                                  placeholder="0"
                                  pattern="^[0-9]*$"
                                />
                                <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                                  MOS
                                </span>
                              </div>
                            </div>
                          </InputContainer>
                          <InputContainer className="gap-0 border-transparent py-0">
                            <Label htmlFor="IsRevolvingCredit">
                              Resolving Credit / Redraw Facility
                            </Label>
                            <InputSwitch
                              id="IsRevolvingCredit"
                              value={values.Liability?.IsRevolvingCredit}
                              onChange={(value) =>
                                handleChange("Liability", {
                                  ...values.Liability,
                                  IsRevolvingCredit: value,
                                })
                              }
                              className="min-w-[242px]"
                            >
                              <InputSwitch.InputSwitchOption value={true}>
                                Yes
                              </InputSwitch.InputSwitchOption>
                              <InputSwitch.InputSwitchOption value={false}>
                                No
                              </InputSwitch.InputSwitchOption>
                            </InputSwitch>
                          </InputContainer>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </>
                )}
              </>
            )}
          </>
        )}
      </section>
    </>
  );
};

function cleanUpData(values: Values) {
  const data: OutputValues = {
    Loan: {
      LoanType: values.LoanType,
      Lender: defaultValues.Lender,
      LendingAction: defaultValues.LendingAction,
      LiabilityType: defaultValues.LiabilityType,
      LoanAmount: defaultValues.LoanAmount,
      TopUpAmount: defaultValues.TopUpAmount,
      TotalTermMonths: defaultValues.TotalTermMonths,
      InterestOnlyTermMonths: defaultValues.InterestOnlyTermMonths,
      Security: {
        Value: values.Value,
        IsRBNZExempt: values.IsRBNZExempt,
        Location: values.Location,
        PropertyType: [
          values.PropertyType,
          values.PropertySubType,
          values.PropertySubSubType,
        ],
        SecurityType: values.SecurityType,
        UsageType: values.UsageType,
      },
    },
  };

  if (values.LoanType === "New") {
    data.Loan.LoanAmount = values.LoanAmount;
    data.Loan.TotalTermMonths =
      (values.TotalTermYears ?? 0) * 12 + (values.TotalTermMonths ?? 0);
    data.Loan.InterestOnlyTermMonths =
      (values.InterestOnlyTermYears ?? 0) * 12 +
      (values.InterestOnlyTermMonths ?? 0);
  } else if (values.LoanType === "Existing" && values.Liability) {
    data.Loan.LendingAction = values.LendingAction;
    data.Liability = {
      ...defaultValues.Liability,
      ...values.Liability,
      InterestRate: values.Liability.InterestRate
        ? toFixed(values.Liability.InterestRate / 100)
        : null,
      TotalTermMonths:
        (values.Liability.TotalTermYears ?? 0) * 12 +
        (values.Liability.TotalTermMonths ?? 0),
      InterestOnlyTermMonths:
        (values.Liability.InterestOnlyTermYears ?? 0) * 12 +
        (values.Liability.InterestOnlyTermMonths ?? 0),
    };

    switch (values.LendingAction) {
      case "Refinance":
        data.Loan.LoanAmount = values.LoanAmount;
        data.Loan.TotalTermMonths =
          (values.TotalTermYears ?? 0) * 12 + (values.TotalTermMonths ?? 0);
        data.Loan.InterestOnlyTermMonths =
          (values.InterestOnlyTermYears ?? 0) * 12 +
          (values.InterestOnlyTermMonths ?? 0);
        data.Loan.Lender = values.Lender;
        break;
      case "TopUp":
        data.Loan.LoanAmount = values.LoanAmount;
        data.Loan.TopUpAmount = values.TopUpAmount;
        data.Loan.TotalTermMonths =
          (values.TotalTermYears ?? 0) * 12 + (values.TotalTermMonths ?? 0);
        data.Loan.InterestOnlyTermMonths =
          (values.InterestOnlyTermYears ?? 0) * 12 +
          (values.InterestOnlyTermMonths ?? 0);
        data.Loan.Lender = values.Lender;
        break;
    }
  }

  return data;
}
