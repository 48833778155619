import produce from "immer";
import create, { GetState, SetState, StoreApi } from "zustand";
import {
  IApplicant,
  IApplicantParty,
  IApplicantPartyFinancial,
} from "./applicant";
import { ILoan } from "./security";
import { deepMapToArray, generateId, toFixed } from "./util";
import {
  ILenderResult,
  IReport,
  ILoanAmountApproval,
  ISpreadsheetPreviewSetting,
  ISetting,
  ISpreadsheetFile,
} from "./result";
import axios from "axios";
import { IAdmin } from "./auth";
import { collection, getDocs, setDoc, doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";
import { isEqual } from "lodash";
import { FUNCTIONS_BASE_URL } from "../constant";
import { IIncome, IIncomeAttribute, IIncomeAttributes } from "./income";
import { IExpense } from "./expenses";
import { ILiability } from "./liabilities";
import * as DateFns from "date-fns";

export type StoreSlice<T extends object, E extends object = T> = (
  set: SetState<E extends T ? E : E & T>,
  get: GetState<E extends T ? E : E & T>,
  api: StoreApi<E extends T ? E : E & T>
) => T;

export interface IModel {
  readonly _id: number;
}

export type IModelId = IModel["_id"];

export type IApplicantPartiesSlice = {
  ApplicantParties: Map<IModelId, IApplicantParty>;
  addApplicantParties: (
    applicantParty: Omit<IApplicantParty, "_id">
  ) => IApplicantParty | undefined;
  deleteApplicantParties: (id: IApplicantParty["_id"]) => void;
  setApplicantParty: (
    id: IApplicantParty["_id"],
    value: Partial<Omit<IApplicantParty, "_id">>
  ) => void;
  addApplicants: (
    applicantPartyId: IModelId,
    payload: {
      status: "applicant" | "spouse";
      Name: IApplicant["Name"];
    }
  ) => void;
  getApplicant: (
    applicantPartyId: IModelId,
    status: "applicant" | "spouse"
  ) => IApplicant | undefined;
  getApplicantById: (
    applicantPartyId: IModelId,
    applicantId: IModelId
  ) => IApplicant | undefined;
  setApplicant: (
    applicantPartyId: IModelId,
    queryPayload: {
      status: "applicant" | "spouse";
      Name: IApplicant["Name"];
    }
  ) => void;
  deleteApplicant: (
    applicantPartyId: IModelId,
    status: "applicant" | "spouse"
  ) => void;
  addFinancial: <F extends keyof IApplicantPartyFinancial>(
    applicantPartyId: IModelId,
    financial: {
      type: F;
      value: Omit<IApplicantPartyFinancial[F], "_id">;
    }
  ) => IApplicantPartyFinancial[F] | undefined;
  getFinancial: <F extends keyof IApplicantPartyFinancial>(
    applicantPartyId: IModelId,
    type: F
  ) => IApplicantPartyFinancial[F][];
  getFinancialItem: <F extends keyof IApplicantPartyFinancial>(
    applicantPartyId: IModelId,
    financial: {
      type: F;
      id: IModelId;
    }
  ) => IApplicantPartyFinancial[F] | undefined;
  setFinancial: <F extends keyof IApplicantPartyFinancial>(
    applicantPartyId: IModelId,
    financial: {
      type: F;
      payload: IApplicantPartyFinancial[F][];
    }
  ) => void;
  setFinancialItem: <F extends keyof IApplicantPartyFinancial>(
    applicantPartyId: IModelId,
    financial: {
      type: F;
      id: IModelId;
      payload: Partial<Omit<IApplicantPartyFinancial[F], "_id">>;
    }
  ) => void;
  deleteFinancialItem: <F extends keyof IApplicantPartyFinancial>(
    applicantPartyId: IModelId,
    financial: {
      type: F;
      id: IModelId;
    }
  ) => void;
};

export type ILoansSlice = {
  Loans: Map<IModelId, ILoan>;
  addLoan: (loan: Omit<ILoan, "_id" | "LoanId">) => ILoan | undefined;
  setLoanItem: (id: IModelId, payload: Partial<ILoan>) => void;
  deleteLoanItem: (id: IModelId) => void;
};

export type IReportSlice = {
  Report: IReport;
  fetchReport: (options?: { Lenders?: string | null }) => Promise<void | {
    Report: IReport;
  }>;
  loanAmountApproval: ILoanAmountApproval[];
  activeLenders: ILoanAmountApproval | null;
  setActiveLenders: (Id: ILoanAmountApproval["Id"]) => void;
};

export type IAdminSlice = {
  currentAdmin: Nullable<IAdmin>;
  setCurrentAdmin: (admin: Nullable<IAdmin>) => void;
};

export type ISpreadsheetPreviewSettingsSlice = {
  previewSettings: Map<string, ISpreadsheetPreviewSetting>;
  setupPreviewSettings: () => Promise<void>;
  getPreviewSetting: (
    lenderKey: string,
    sectionID: string
  ) => ISpreadsheetPreviewSetting | undefined;
  setPreviewSetting: (
    lenderKey: string,
    sectionID: string,
    setting: ISetting
  ) => Promise<void>;
};

export type ISpreadsheetFilesSlice = {
  spreadsheetFiles: Map<ISpreadsheetFile["_id"], ISpreadsheetFile>;
  setSpreadsheetFile: (payload: Omit<ISpreadsheetFile, "_id">) => void;
  getSpreadsheetFile: (
    id: ISpreadsheetFile["_id"]
  ) => ISpreadsheetFile | undefined;
  clearSpreadsheetFiles: () => void;
  fetchSpreadsheetFiles: (options?: {
    Details?: {
      ApplicantParties?: IApplicantPartiesSlice["ApplicantParties"];
      Loans?: ILoansSlice["Loans"];
    };
  }) => void;
};

export type Save = {
  ApplicantParties: Map<IModelId, IApplicantParty>;
  NewLoans: Map<IModelId, ILoan>;
};

export interface EventListener {
  event: string;
}

export interface SearchStartEvent extends EventListener {
  event: "searchstart";
}

export interface SearchSuccessEvent extends EventListener {
  event: "searchsuccess";
  Report: IReport;
}

export interface AddingFinancialEvent<
  type extends keyof IApplicantPartyFinancial
> extends EventListener {
  event: "addingfinancial";
  type: type;
  financial: Omit<IApplicantPartyFinancial[type], "_id"> & {
    readonly _id?: number;
  };
}

export interface AddedFinancialEvent<
  type extends keyof IApplicantPartyFinancial
> extends EventListener {
  event: "addedfinancial";
  type: type;
  financial: Omit<IApplicantPartyFinancial[type], "_id"> & {
    readonly _id?: number;
  };
}

export interface StartEditFinancialEvent<
  type extends keyof IApplicantPartyFinancial
> {
  event: "starteditfinancial";
  type: type;
  financial: Omit<IApplicantPartyFinancial[type], "_id"> & {
    readonly _id?: number;
  };
}

export interface AddedSecurityEvent extends EventListener {
  event: "addedsecurity";
  loan: ReturnType<ILoansSlice["addLoan"]>;
}

export type EventsListener = {
  searchstart: (event: SearchStartEvent) => void;
  searchsuccess: (event: SearchSuccessEvent) => void;
  addingfinancial: <type extends keyof IApplicantPartyFinancial>(
    event: AddingFinancialEvent<type>
  ) => AddingFinancialEvent<type>["financial"] | void;
  addedfinancial: <type extends keyof IApplicantPartyFinancial>(
    event: AddedFinancialEvent<type>
  ) => AddedFinancialEvent<type>["financial"] | void;
  starteditfinancial: <type extends keyof IApplicantPartyFinancial>(
    event: StartEditFinancialEvent<type>
  ) => StartEditFinancialEvent<type>["financial"] | void;
  addedsecurity: (event: AddedSecurityEvent) => void;
};

export interface Version {
  date: Date;
  title: string;
  lender?: string;
  version?: string;
  details?: string;
}

export type IVersionHistorySlice = {
  versionHistory: Version[];
  loadVersionHistory: () => Promise<void>;
};

export interface LenderLogo {
  name: string;
  code: string;
  logo: string;
}

export type ILenderLogosSlice = {
  lenderLogos: LenderLogo[];
  loadLenderLogos: () => Promise<void>;
};

export type ISearchValidation = {
  isValid: () => {
    valid: boolean;
    warnings: string[];
  };
};

export type IRootSlice = IApplicantPartiesSlice &
  ILoansSlice &
  IReportSlice &
  IAdminSlice &
  ISpreadsheetFilesSlice &
  ISpreadsheetPreviewSettingsSlice &
  IVersionHistorySlice &
  ILenderLogosSlice &
  ISearchValidation & {
    savedState?: Save;
    setSavedState: () => void;
    isSaved: () => boolean | "never";
    enabledLenders: string[];
    setEnabledLenders: (lenders: string[]) => void;
    eventsListener: Array<{
      name: keyof EventsListener;
      listener: EventsListener[keyof EventsListener];
    }>;
    addEventListener: <E extends keyof EventsListener>(
      name: E,
      listener: EventsListener[E]
    ) => void;
    getEventsListener: <E extends keyof EventsListener>(
      name: E
    ) => { name: E; listener: EventsListener[E] }[];
    removeEventListener: <E extends keyof EventsListener>(
      name: E,
      listener: EventsListener[E]
    ) => void;
    usersnapApi: any;
    setUsersnapApi: (api: any) => void;
    resumeReport: (
      admin: IAdmin | null,
      reportID: string,
      callback?: (
        status:
          | "Fetching Resources"
          | "Inserting data"
          | "Getting Result"
          | "Done"
      ) => void
    ) => void;
    loadSession: (
      sessionID: string,
      callback?: (
        status:
          | "Fetching Resources"
          | "Inserting data"
          | "Getting Result"
          | "Done"
      ) => void
    ) => void;
  };

const createApplicantPartiesSlice: StoreSlice<IApplicantPartiesSlice> = (
  set,
  get
) => ({
  ApplicantParties: new Map<number, IApplicantParty>(),
  addApplicantParties: (applicantParty) => {
    let newApplicantParty: IApplicantParty | undefined;
    set(
      produce((state: IApplicantPartiesSlice) => {
        const id = get().ApplicantParties.size + 1;

        newApplicantParty = { ...applicantParty, _id: id };
        state.ApplicantParties.set(id, newApplicantParty);
      })
    );

    return newApplicantParty;
  },
  deleteApplicantParties: (id) => {
    set(
      produce((state: IApplicantPartiesSlice) => {
        state.ApplicantParties.delete(id);
      })
    );
  },
  setApplicantParty: (id, value) => {
    set(
      produce((state: IApplicantPartiesSlice) => {
        const prevApplicantParty = state.ApplicantParties.get(id);
        const applicantParty = {
          ...prevApplicantParty,
          ...value,
          _id: id,
        } as IApplicantParty;
        state.ApplicantParties.set(id, applicantParty);
      })
    );
  },
  addApplicants: (applicantPartyId, { Name, status }) => {
    set(
      produce((state: IApplicantPartiesSlice) => {
        const applicantParty = state.ApplicantParties.get(applicantPartyId);
        const id = (applicantParty?.Applicant.size || 0) + 1;

        applicantParty?.Applicant.set(status, {
          ApplicantId: id,
          Name,
        });
      })
    );
  },
  getApplicant: (applicantPartyId, status) => {
    const applicantParty = get().ApplicantParties.get(applicantPartyId);

    return applicantParty?.Applicant.get(status);
  },
  getApplicantById: (applicantPartyId, applicantId) => {
    const applicantParty = get().ApplicantParties.get(applicantPartyId);

    return Array.from(applicantParty?.Applicant.values() ?? []).find(
      (applicant) => applicant.ApplicantId === applicantId
    );
  },
  setApplicant: (applicantPartyId, { Name, status }) => {
    set(
      produce((state: IApplicantPartiesSlice) => {
        const applicants =
          state.ApplicantParties?.get(applicantPartyId)?.Applicant;
        const applicant = applicants?.get(status);

        if (applicants && applicant) {
          applicants.set(status, {
            ApplicantId: applicant.ApplicantId,
            Name,
          });
        }
      })
    );
  },
  deleteApplicant: (applicantPartyId, applicantId) => {
    set(
      produce((state: IApplicantPartiesSlice) => {
        const applicants =
          state.ApplicantParties.get(applicantPartyId)?.Applicant;

        applicants?.delete(applicantId);
      })
    );
  },
  addFinancial: (applicantPartyId, financial) => {
    const globalGet = get as GetState<IRootSlice>;
    let newFinancial: any;
    set(
      produce((state: IApplicantPartiesSlice) => {
        const applicantParty = state.ApplicantParties.get(applicantPartyId);

        if (applicantParty) {
          const id = generateId();
          newFinancial = {
            ...financial.value,
            _id: id,
          } as any;

          applicantParty[financial.type].set(id, newFinancial);
          globalGet()
            .getEventsListener("addedfinancial")
            .forEach((eventListener) => {
              eventListener.listener({
                event: "addedfinancial",
                type: financial.type,
                financial: newFinancial,
              });
            });
        }
      })
    );
    return newFinancial;
  },
  getFinancial: (applicantPartyId, type) => {
    const applicantParty = get().ApplicantParties.get(applicantPartyId);
    if (!applicantParty) {
      return [];
    }

    return Array.from(applicantParty[type].values() as any);
  },
  getFinancialItem: (applicantPartyId, financial) => {
    const applicantParty = get().ApplicantParties.get(applicantPartyId);

    if (!applicantParty) return;
    const applicantPartyFinancial = applicantParty[financial.type];

    return applicantPartyFinancial?.get(
      financial.id
    ) as IApplicantPartyFinancial[typeof financial.type];
  },
  setFinancial: (applicantPartyId, { payload, type }) => {
    set(
      produce((state: IApplicantPartiesSlice) => {
        const applicantParty = state.ApplicantParties.get(applicantPartyId);

        if (!applicantParty) return;
        applicantParty[type].clear();

        payload.forEach((item: IModel) => {
          const _id = generateId();

          applicantParty[type].set(_id, {
            ...item,
            _id,
          } as any);
        });
      })
    );
  },
  setFinancialItem: (applicantPartyId, financial) => {
    set(
      produce((state: IApplicantPartiesSlice) => {
        const applicantParty = state.ApplicantParties.get(applicantPartyId);

        if (!applicantParty) return;

        const f = applicantParty[financial.type].get(financial.id) as any;

        if (f) {
          applicantParty[financial.type].set(f._id, {
            ...f,
            ...financial.payload,
            _id: f._id,
          });
        }
      })
    );
  },
  deleteFinancialItem: (applicantPartyId, financial) => {
    set(
      produce((state: IApplicantPartiesSlice) => {
        const applicantParty = state.ApplicantParties.get(applicantPartyId);

        if (!applicantParty) return;

        applicantParty[financial.type].delete(financial.id);
      })
    );
  },
});

const createNewLoansSlice: StoreSlice<ILoansSlice> = (set, get) => ({
  Loans: new Map<IModelId, ILoan>(),
  addLoan: (loan) => {
    const globalGet = get as GetState<IRootSlice>;

    let newLoan: ILoan | undefined;
    set(
      produce((state: ILoansSlice) => {
        let id = state.Loans.size + 1;
        if (state.Loans.has(id)) {
          id = state.Loans.get(id)!._id + 1;
        }
        newLoan = { ...loan, _id: id, LoanId: id };
        state.Loans.set(newLoan._id, newLoan);
      })
    );
    globalGet()
      .getEventsListener("addedsecurity")
      .forEach((eventlistener) => {
        eventlistener.listener({
          event: "addedsecurity",
          loan: newLoan,
        });
      });
    return newLoan;
  },
  setLoanItem: (id, payload) => {
    set(
      produce((state: ILoansSlice) => {
        const loan = state.Loans.get(id);

        const newLoan = {
          ...loan,
          ...payload,
          _id: id,
        } as ILoan;

        state.Loans.set(id, newLoan);
      })
    );
  },
  deleteLoanItem: (id) => {
    set(
      produce((state: ILoansSlice) => {
        state.Loans.delete(id);
      })
    );
  },
});

const createReportSlice: StoreSlice<IReportSlice> = (set, get) => {
  const globalGet = get as GetState<IRootSlice>;

  return {
    Report: {
      Id: null,
      Accuracy: 0,
      Cases: [],
    },
    fetchReport: async () => {
      if (!globalGet().isValid().valid) return;

      globalGet().setSavedState();
      set(
        produce((state: IReportSlice) => {
          state.Report.Cases = [];
        })
      );

      globalGet()
        .getEventsListener("searchstart")
        .forEach((event) => {
          event.listener({ event: event.name });
        });
      globalGet().clearSpreadsheetFiles();
      globalGet().fetchSpreadsheetFiles();

      const payload = {
        Details: {
          Loan: deepMapToArray(globalGet().Loans),
          ApplicantParty: deepMapToArray(globalGet().ApplicantParties),
        },
        Lenders: globalGet().enabledLenders.join(","),
      };
      console.log(payload);

      try {
        const res = await axios.post(
          `${FUNCTIONS_BASE_URL}/httpsEngineGenerateReportV2`,
          payload
        );

        console.log(res);

        if (!res.data || res.status !== 200) {
          throw Error("Error fetching report");
        }

        const Report = res.data.Report;
        const loanAmountApproval = (Report as IReport).Cases.reduce<
          ILoanAmountApproval[]
        >((acc, { Id, LenderResult, LoanAmount }) => {
          const { Pass, Fail } = LenderResult.reduce<{
            Pass: ILenderResult[];
            Fail: ILenderResult[];
          }>(
            (a, current) => {
              const { Result, Errors } = current;

              if (!Result) {
                a.Fail.push(current);
                return a;
              }

              const { UMI, LVR, DTI, Servicing } = Result;
              const isLVRFail = LVR.reduce(
                (isFail, lvrItem) => isFail || lvrItem.Status === "Fail",
                false
              );
              if (
                (!Servicing || Servicing.Type === "N/A"
                  ? UMI.Status === "Fail"
                  : Servicing.Status === "Fail") ||
                isLVRFail ||
                DTI.Status === "Fail" ||
                Errors.some((error) => error.Reference === "Top")
              ) {
                a.Fail.push(current);
                return a;
              }

              a.Pass.push(current);
              return a;
            },
            {
              Pass: [],
              Fail: [],
            }
          );

          acc.push({
            Id,
            LoanAmount,
            Pass,
            Fail: Fail.sort((a) => {
              if (!a.Errors.some((error) => error.Reference === "Top"))
                return -1;

              return 0;
            }),
          });

          return acc;
        }, []);

        const isMultipleSecurities = Report.Cases.length === 1;
        const newLoan = Array.from(globalGet().Loans.values())[0];
        const activeLenders = isMultipleSecurities
          ? loanAmountApproval[0]
          : loanAmountApproval.find(
              ({ LoanAmount }) => LoanAmount === newLoan?.LoanAmount
            );

        set(
          produce((state: IReportSlice) => {
            state.Report = Report;
            state.loanAmountApproval = loanAmountApproval;

            if (activeLenders) {
              state.activeLenders = activeLenders;
            }
          })
        );

        globalGet()
          .getEventsListener("searchsuccess")
          .forEach((event) => {
            event.listener({ event: event.name, Report: Report });
          });
        // return Report;
      } catch (error) {
        console.log(error);
      }
    },
    loanAmountApproval: [],
    activeLenders: null,
    setActiveLenders: (id) => {
      set(
        produce((state: IReportSlice) => {
          state.activeLenders =
            globalGet().loanAmountApproval.find(
              (loanAmountApproval) => loanAmountApproval.Id === id
            ) || null;
        })
      );
    },
  };
};

const createAdminSlice: StoreSlice<IAdminSlice> = (set, get) => ({
  currentAdmin: null,
  setCurrentAdmin(admin) {
    set(
      produce((state: IAdminSlice) => {
        state.currentAdmin = admin;
      })
    );
  },
});

const createSpreadsheetPreviewSettingsSlice: StoreSlice<
  ISpreadsheetPreviewSettingsSlice
> = (set, get) => ({
  previewSettings: new Map(),
  setupPreviewSettings: async () => {
    try {
      const previewSettings: ISpreadsheetPreviewSettingsSlice["previewSettings"] =
        new Map();
      const colRef = collection(db, "spreadsheet_preview_settings");
      const docSnap = await getDocs(colRef);

      docSnap.forEach((doc) => {
        if (doc.exists()) {
          const lenderKey = doc.id.split("_")[0];
          const sectionID = doc.id.split("_")[1];
          const setting = doc.data() as ISetting;

          previewSettings.set(`${lenderKey}_${sectionID}`, {
            lenderKey,
            sectionID,
            setting,
          });
        }
      });

      set(
        produce((state: ISpreadsheetPreviewSettingsSlice) => {
          state.previewSettings = previewSettings;
        })
      );
    } catch (err) {
      console.log(err);
    }
  },
  getPreviewSetting: (lenderKey, sectionID) => {
    return get().previewSettings.get(`${lenderKey}_${sectionID}`);
  },
  setPreviewSetting: async (lenderKey, sectionID, setting) => {
    try {
      await setDoc(
        doc(db, "spreadsheet_preview_settings", `${lenderKey}_${sectionID}`),
        setting
      );
    } catch (err) {
      console.log(err);
    } finally {
      set(
        produce((state: ISpreadsheetPreviewSettingsSlice) => {
          state.previewSettings.set(`${lenderKey}_${sectionID}`, {
            lenderKey,
            sectionID,
            setting,
          });
        })
      );
    }
  },
});

const createSpreadsheetFilesSlice: StoreSlice<ISpreadsheetFilesSlice> = (
  set,
  get
) => ({
  spreadsheetFiles: new Map(),
  setSpreadsheetFile: (payload) => {
    set(
      produce((state: ISpreadsheetFilesSlice) => {
        const id = `${payload.Lender.Key}_${payload.LoanAmount}`;
        state.spreadsheetFiles.set(id, {
          ...payload,
          _id: id,
        });
      })
    );
  },
  getSpreadsheetFile: (id) => {
    return get().spreadsheetFiles.get(id);
  },
  clearSpreadsheetFiles() {
    set(
      produce((state: ISpreadsheetFilesSlice) => {
        state.spreadsheetFiles = new Map();
      })
    );
  },
  fetchSpreadsheetFiles({ Details } = {}) {
    const globalGet = get as GetState<IRootSlice>;

    const ApplicantParties =
      Details?.ApplicantParties || globalGet().ApplicantParties;
    const Loans = Details?.Loans || globalGet().Loans;

    // check multiple securities
    const LoanAmount =
      Loans.size > 1 ? 0 : Array.from(Loans.values())[0].LoanAmount;

    setTimeout(() => {
      axios
        .request({
          method: "post",
          url: `${FUNCTIONS_BASE_URL}/httpsEngineGenerateFiles`,
          data: {
            Details: {
              Loan: deepMapToArray(Loans),
              ApplicantParty: deepMapToArray(ApplicantParties),
            },
            Lenders: globalGet().enabledLenders.join(","),
          },
        })
        .then(({ data }) => {
          const files = (data.Files || []) as Omit<
            ISpreadsheetFile,
            "_id" | "LoanAmount"
          >[];
          files.forEach((file) => {
            get().setSpreadsheetFile({
              FileName: file.FileName,
              Lender: file.Lender,
              DownloadURL: file.DownloadURL,
              LoanAmount: LoanAmount,
            });
          });
        })
        .catch((err) => console.log(err));
    }, 2500);
  },
});

const createVersionHistorySlice: StoreSlice<IVersionHistorySlice> = (
  set,
  get
) => ({
  versionHistory: [],
  loadVersionHistory: async () => {
    try {
      type VersionResponse = {
        [k in keyof Omit<Version, "date">]: Version[k];
      } & { date: string };

      const result = await axios.get<VersionResponse[]>(
        `${FUNCTIONS_BASE_URL}/httpsEngineVersionHistoryGet`
      );
      const versionHistory = result.data
        .map((history) => ({
          ...history,
          date: DateFns.parse(history.date, "yyyy-MM-dd", new Date()),
        }))
        .sort((a, b) => DateFns.compareDesc(a.date, b.date));
      set(
        produce((state: IVersionHistorySlice) => {
          state.versionHistory = versionHistory;
        })
      );
      Promise.resolve();
    } catch (error) {
      Promise.reject(error);
    }
  },
});

const createLenderLogosSlice: StoreSlice<ILenderLogosSlice> = (set, get) => ({
  lenderLogos: [],
  loadLenderLogos: async () => {
    try {
      const result = await axios.get<LenderLogo[]>(
        `${FUNCTIONS_BASE_URL}/httpsLenderListDetailsGet`
      );
      const logos = result.data;

      set(
        produce((state: ILenderLogosSlice) => {
          state.lenderLogos = logos;
        })
      );
      Promise.resolve();
    } catch (error) {
      Promise.reject(error);
    }
  },
});

const createSearchValidationSlice: StoreSlice<ISearchValidation> = (
  set,
  get
) => ({
  isValid: () => {
    const globalGet = get as GetState<IRootSlice>;

    let valid = true;
    const warnings: string[] = [];

    const applicantParties = globalGet().ApplicantParties;
    const loans = globalGet().Loans;

    if (applicantParties.size === 0 || loans.size === 0) {
      valid = false;
      warnings.push(
        "Please enter the applicant, security, and financial details to search"
      );
    } else {
      const applicantParty = Array.from(applicantParties.values())[0];

      if (
        applicantParty.Income.size === 0 // no income added
      ) {
        valid = false;
        warnings.push(
          "Please enter the applicant, security, and financial details to search"
        );
      }
    }

    if (loans.size > 0) {
      let containNewLending = false;
      Array.from(loans.values()).forEach(function (loan) {
        containNewLending =
          containNewLending ||
          loan.LoanType !== "Existing" ||
          loan.LendingAction !== "None";
      });

      if (!containNewLending) {
        valid = false;
        warnings.push("At lease one security must contain new lending");
      }
    }

    return {
      valid,
      warnings,
    };
  },
});

const createRootSlice = (
  set: SetState<any>,
  get: GetState<any>,
  api: StoreApi<any>
): IRootSlice => ({
  ...createApplicantPartiesSlice(set, get, api),
  ...createNewLoansSlice(set, get, api),
  ...createReportSlice(set, get, api),
  ...createAdminSlice(set, get, api),
  ...createSpreadsheetPreviewSettingsSlice(set, get, api),
  ...createSpreadsheetFilesSlice(set, get, api),
  ...createVersionHistorySlice(set, get, api),
  ...createLenderLogosSlice(set, get, api),
  ...createSearchValidationSlice(set, get, api),
  enabledLenders: [],
  setEnabledLenders: (lenders) => {
    set(
      produce((state: IRootSlice) => {
        state.enabledLenders = lenders;
      })
    );
  },
  savedState: undefined,
  setSavedState: () => {
    set(
      produce((state: IRootSlice) => {
        state.savedState = {
          ApplicantParties: state.ApplicantParties,
          NewLoans: state.Loans,
        };
      })
    );
  },
  isSaved: () => {
    const globalGet = get as GetState<IRootSlice>;
    const savedState = globalGet().savedState;
    if (!savedState) {
      return "never";
    }

    const currentState: Save = {
      ApplicantParties: globalGet().ApplicantParties,
      NewLoans: globalGet().Loans,
    };

    return isEqual(savedState, currentState);
  },
  eventsListener: [],
  addEventListener(name, listener) {
    set(
      produce((state: IRootSlice) => {
        state.eventsListener.push({
          name,
          listener,
        });
      })
    );
  },
  // @ts-ignore
  getEventsListener: (name) => {
    return (get as GetState<IRootSlice>)().eventsListener.filter((events) =>
      isEqual(events.name, name)
    );
  },
  removeEventListener(name, listener) {
    set(
      produce((state: IRootSlice) => {
        state.eventsListener = state.eventsListener.filter(
          (eventListener) =>
            eventListener.name !== name && eventListener.listener !== listener
        );
      })
    );
  },
  usersnapApi: null,
  setUsersnapApi: (api) => {
    set(
      produce((state: IRootSlice) => {
        state.usersnapApi = api;
      })
    );
  },
  resumeReport: async (admin, reportID, callback) => {
    const globalGet = get as GetState<IRootSlice>;
    if (!admin) return;

    set(
      produce((state: IRootSlice) => {
        state.ApplicantParties.clear();
        state.Loans.clear();
        state.Report = {
          Accuracy: 0,
          Cases: [],
          Id: null,
        };
      })
    );

    try {
      callback && callback("Fetching Resources");
      const docRef = doc(db, "generate_report_log", reportID);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw Error(`request id (${reportID}) doesn't exist in the database`);
      }

      const reportLog = docSnap.data() as {
        input: string;
        ip: string;
        result: string;
        time: {
          seconds: number;
          nanoseconds: number;
        };
      };

      if (!reportLog || !reportLog.input) return;
      const input = JSON.parse(reportLog.input).Details;

      if (!input) return;
      console.log({ input });

      callback && callback("Inserting data");
      input.ApplicantParty.forEach((applicantParty: any) => {
        const newApplicantParty = globalGet().addApplicantParties({
          Applicant: new Map(),
          Expense: new Map(),
          Income: new Map(),
          Liability: new Map(),
          NumberOfDependents: applicantParty.NumberOfDependents,
          NumberOfVehicles: applicantParty.NumberOfVehicles,
        });

        if (!newApplicantParty) return;
        const newApplicantPartyID = newApplicantParty._id;

        applicantParty.Applicant.forEach((applicant: IApplicant) => {
          globalGet().addApplicants(newApplicantPartyID, {
            Name: applicant.Name,
            status: applicant.ApplicantId === 1 ? "applicant" : "spouse",
          });
        });

        applicantParty.Income.forEach(
          (
            income: Omit<
              IIncome,
              "Attributes" & { Attributes: IIncomeAttribute[] }
            >
          ) => {
            const attributesMap: IIncome["Attributes"] = new Map();

            income.Attributes.forEach((attribute) => {
              // temporary fix for old BoarderIncomeType
              // remove spaces from the value
              if (
                attribute.name === "BoarderIncomeType" &&
                typeof attribute.value === "string"
              ) {
                attributesMap.set(attribute.name as keyof IIncomeAttributes, {
                  ...attribute,
                  value: attribute.value.replaceAll(" ", ""),
                });
                return;
              }

              attributesMap.set(
                attribute.name as keyof IIncomeAttributes,
                attribute
              );
            });

            globalGet().addFinancial(newApplicantPartyID, {
              type: "Income",
              value: {
                ...income,
                Attributes: attributesMap,
              },
            });
          }
        );

        applicantParty.Expense.forEach((expense: IExpense) => {
          globalGet().addFinancial(newApplicantPartyID, {
            type: "Expense",
            value: {
              ...expense,
            },
          });
        });

        applicantParty.Liability.forEach((liability: ILiability) => {
          if (liability.InterestRate) {
            liability.InterestRate = toFixed(liability.InterestRate);
          }

          globalGet().addFinancial(newApplicantPartyID, {
            type: "Liability",
            value: {
              ...liability,
            },
          });
        });
      });

      (input.Loan || input.NewLoan).forEach((Loan: ILoan) => {
        const newLoan = globalGet().addLoan({
          ...Loan,
        });

        if (!newLoan) return;

        globalGet().setLoanItem(newLoan._id, {
          ...newLoan,
        });
      });

      callback && callback("Getting Result");
      await globalGet().fetchReport();
      callback && callback("Done");
    } catch (error) {
      console.log(error);
    }
  },
  loadSession: async (sessionID, callback) => {
    const globalGet = get as GetState<IRootSlice>;

    set(
      produce((state: IRootSlice) => {
        state.ApplicantParties.clear();
        state.Loans.clear();
        state.Report = {
          Accuracy: 0,
          Cases: [],
          Id: null,
        };
        state.enabledLenders = [];
      })
    );

    try {
      callback && callback("Fetching Resources");
      const docRef = doc(db, "sessions", sessionID);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) return;

      const sessionData = docSnap.data() as {
        myCRM: {
          userID: string;
        };
        data: any;
        origin?: string;
        ip: string;
        created: string;
      };

      if (!sessionData || !sessionData.data) return;
      const data = JSON.parse(sessionData.data);
      const input = data.Details;
      globalGet().setEnabledLenders(data.Lenders || []);

      if (!input) return;
      console.log({ data });

      callback && callback("Inserting data");
      input.ApplicantParty.forEach((applicantParty: any) => {
        const newApplicantParty = globalGet().addApplicantParties({
          Applicant: new Map(),
          Expense: new Map(),
          Income: new Map(),
          Liability: new Map(),
          NumberOfDependents: applicantParty.NumberOfDependents,
          NumberOfVehicles: applicantParty.NumberOfVehicles,
        });

        if (!newApplicantParty) return;
        const newApplicantPartyID = newApplicantParty._id;

        applicantParty.Applicant.forEach((applicant: IApplicant) => {
          globalGet().addApplicants(newApplicantPartyID, {
            Name: applicant.Name,
            status: applicant.ApplicantId === 1 ? "applicant" : "spouse",
          });
        });

        applicantParty.Income.forEach(
          (
            income: Omit<
              IIncome,
              "Attributes" & { Attributes: IIncomeAttribute[] }
            >
          ) => {
            const attributesMap: IIncome["Attributes"] = new Map();

            income.Attributes.forEach((attribute) => {
              attributesMap.set(
                attribute.name as keyof IIncomeAttributes,
                attribute
              );
            });

            globalGet().addFinancial(newApplicantPartyID, {
              type: "Income",
              value: {
                ...income,
                Attributes: attributesMap,
              },
            });
          }
        );

        applicantParty.Expense.forEach((expense: IExpense) => {
          globalGet().addFinancial(newApplicantPartyID, {
            type: "Expense",
            value: {
              ...expense,
            },
          });
        });

        applicantParty.Liability.forEach((liability: ILiability) => {
          if (liability.InterestRate) {
            liability.InterestRate = toFixed(liability.InterestRate);
          }

          globalGet().addFinancial(newApplicantPartyID, {
            type: "Liability",
            value: {
              ...liability,
              StartDate: liability.StartDate?.split("T")?.[0] || null, // just in case the time specified from the date. e.g "2024-02-05T00:00:00"
            },
          });
        });
      });

      input.Loan.forEach((Loan: ILoan) => {
        const newLoan = globalGet().addLoan({
          ...Loan,
        });

        if (!newLoan) return;

        globalGet().setLoanItem(newLoan._id, {
          ...newLoan,
        });
      });

      callback && callback("Done");

      const validity = globalGet().isValid();
      if (validity.valid) {
        await globalGet().fetchReport();
      }
    } catch (error) {
      console.log(error);
    }
  },
});

const useStore = create(createRootSlice);

export default useStore;
