import { SectionLabel } from "../components/label";
import { CircleBar } from "../components/circlebar";
import useStore from "../store";
import { useCallback, useState } from "react";
import PdfExportOptionsModal from "../pdf/PdfExportOptionsModal";
import { Button } from "../components/form";
import Pdf from "../icons/Pdf";

const ResultHeader = () => {
  const [showPdfExportOption, setShowPdfExportOption] = useState(false);

  const accuracy = useStore(
    useCallback((state) => state.Report.Accuracy * 100, [])
  );

  return (
    <header className="flex items-center justify-between">
      <SectionLabel className="text-base desktop:text-lg">
        Search Result
      </SectionLabel>
      <div className="flex items-end space-x-3">
        <CircleBar value={accuracy} />
        <SectionLabel className="mb-1.5 whitespace-nowrap text-xs leading-[0.7em] desktop:text-sm desktop:leading-[0.7em]">
          Accuracy Rating
        </SectionLabel>
        <Button
          type="button"
          onClick={() => setShowPdfExportOption(true)}
          className={`group flex items-center justify-center space-x-[5px] rounded-[5px] border border-[#CDC3D2] bg-[#43357F] px-3 py-[6px] font-helvetica text-[11px] text-white transition-colors duration-200 ease-in-out disabled:cursor-not-allowed disabled:opacity-60 disabled:hover:bg-transparent disabled:hover:text-[#6F5F7B] desktop:text-xs`}
        >
          <Pdf />
          Export PDF
        </Button>
      </div>
      {showPdfExportOption && (
        <PdfExportOptionsModal onClose={() => setShowPdfExportOption(false)} />
      )}
    </header>
  );
};

export default ResultHeader;
