import { FC, ReactNode } from "react";
import { FinancialDetailsContent, FinancialDetailsHeader } from ".";
import { Button } from "../components/form";

const FinancialDetails: FC<{
  header: string;
  onClose: () => void;
  nodeOnHeader?: ReactNode;
  footer?: ReactNode;
}> = ({ header, onClose, children, nodeOnHeader, footer }) => {
  return (
    <section>
      <FinancialDetailsHeader
        className="flex items-center"
        label={
          <span className="flex items-center space-x-3">
            <span className="block">{header}</span>
          </span>
        }
      >
        {nodeOnHeader}
      </FinancialDetailsHeader>
      <FinancialDetailsContent>{children}</FinancialDetailsContent>
      <footer className="mt-[21px]">
        {footer && footer}
        {!footer && (
          <Button size="large" className="mx-auto w-[173px]" onClick={onClose}>
            Close
          </Button>
        )}
      </footer>
    </section>
  );
};

export default FinancialDetails;
