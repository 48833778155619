import { useCallback } from "react";
import useStore from "./store";

const SearchId = () => {
  const searchId = useStore(useCallback((state) => state.Report.Id, []));

  if (!searchId) return null;

  return (
    <span className="inline-block font-helvetica text-[10px] text-[#6F5F7B]/70 desktop:text-xs">
      Request ID: {searchId}
    </span>
  );
};

export default SearchId;
