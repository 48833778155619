import { FC, SVGProps } from "react";

const DTIIcon: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#9A86A8",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      {...props}
    >
      <title>icon-DTI</title>
      <g
        id="Errors"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Overview" transform="translate(-387.000000, -2459.000000)">
          <g id="Group" transform="translate(64.000000, 52.000000)">
            <g id="icon-DTI" transform="translate(323.000000, 2407.000000)">
              <circle
                id="Oval-Copy"
                stroke={fill}
                fill={fill}
                cx="8"
                cy="8"
                r="7.5"
              />
              <circle id="Oval" fill="#FFFFFF" cx="8" cy="5" r="2" />
              <circle id="Oval-Copy-5" fill="#FFFFFF" cx="8" cy="11" r="2" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DTIIcon;
