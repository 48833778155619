import { ChangeEvent, FC, useCallback, useState } from "react";
import { DropZone } from "../components/drag-and-drop-file";
import { Button } from "../components/form";
import { Spreadsheet } from "../icons";
import { SectionLabel } from "../components/label";
import ExpensesUploadFile from "./ExpensesUploadFile";
import { generateId } from "../util";
import { AnimatePresence, motion, Transition, Variants } from "framer-motion";

type Illion = {
  id: number;
  name: string;
  content: string | null;
};

const transition: Transition = {
  ease: "easeInOut",
  duration: 0.2,
};

const animationListVariants: Variants = {
  mounted: {
    height: "auto",
  },
  unmounted: {
    height: 0,
  },
};

const readFilesToIllions = (
  files: File[],
  onLoad: (illion: Illion) => void
) => {
  files.forEach((file) => {
    const reader = new FileReader();

    reader.addEventListener("load", (e) => {
      onLoad({
        id: generateId(),
        name: file.name,
        content: e.target?.result?.toString() || null,
      });
    });

    reader.readAsText(file);
  });
};

const ExpensesUpload: FC<{
  initialIllion?: Illion[];
  onButtonContinueClick?: (illions: Illion[]) => void;
}> = ({ initialIllion = [], onButtonContinueClick }) => {
  const [illions, setIllions] = useState(initialIllion);
  const [onZone, setOnZone] = useState(false);

  const handleButton = useCallback(() => {
    onButtonContinueClick?.(illions);
  }, [illions, onButtonContinueClick]);

  const handleDelete = useCallback(
    (illionId: Illion["id"]) => () => {
      setIllions((prev) => prev.filter((ill) => ill.id !== illionId));
    },
    []
  );

  const setOnZoneFalse = useCallback(() => setOnZone(false), []);
  const setOnZoneTrue = useCallback(() => setOnZone(true), []);

  const handleDrop = useCallback((files: File[]) => {
    setOnZone(false);

    readFilesToIllions(files, (illion) => {
      setIllions((prev) => [...prev, illion]);
    });
  }, []);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files: File[] = [];

    if (e.target.files) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files.item(i);
        if (file) files.push(file);
      }
    }

    readFilesToIllions(files, (illion) => {
      setIllions((prev) => [...prev, illion]);
    });

    e.target.value = "";
  }, []);

  return (
    <section>
      <header>
        <SectionLabel className="text-[15px] desktop:text-lg">
          Upload CSV
        </SectionLabel>
      </header>
      <div className="mt-[27px] grid grid-cols-2">
        <form>
          <label
            htmlFor="upload_expense"
            className="relative block h-0 cursor-pointer pb-[calc(12/16*100%)]"
          >
            <DropZone
              className="absolute bottom-0 left-0 right-0 top-0"
              onDragIn={setOnZoneTrue}
              onDragOut={setOnZoneFalse}
              onDrop={handleDrop}
            >
              {onZone ? (
                <div
                  className="grid h-full place-items-center rounded-[3px] border border-dashed border-[#42B4B4]"
                  onMouseLeave={() => setOnZone(false)}
                >
                  <SectionLabel className="pointer-events-none text-center text-xs text-[#42B4B4] desktop:text-sm">
                    Drop here to upload
                  </SectionLabel>
                </div>
              ) : (
                <div
                  className="flex h-full flex-col items-center justify-center bg-[#F5F1F8]"
                  onMouseEnter={() => setOnZone(true)}
                  onMouseLeave={() => setOnZone(false)}
                >
                  <Spreadsheet
                    width={40}
                    height={40}
                    fill="#6F5F7B"
                    opacity={0.5}
                  />
                  <SectionLabel className="pointer-events-none text-center">
                    <span className="text-xs text-[#42B4B4] desktop:text-base">
                      Click to select CSVs to upload
                    </span>
                    <span className="block text-[11px] font-normal desktop:text-sm">
                      or drag and drop it here
                    </span>
                  </SectionLabel>
                </div>
              )}
            </DropZone>
          </label>
          <input
            type="file"
            name="upload_expense"
            id="upload_expense"
            className="hidden"
            accept=".csv"
            multiple
            onChange={handleInputChange}
          />
        </form>
        <motion.div
          initial={false}
          animate={{
            opacity: illions.length > 0 ? 1 : 0,
            display: illions.length > 0 ? "block" : "none",
          }}
          transition={transition}
          className="pl-[20px]"
        >
          <SectionLabel className="text-[11px] desktop:text-sm">
            Uploaded Files
          </SectionLabel>
          <ul className="mt-2">
            <AnimatePresence>
              {illions.map((illion) => (
                <motion.li
                  className="overflow-y-hidden"
                  key={illion.id}
                  initial="unmounted"
                  exit="unmounted"
                  animate="mounted"
                  transition={transition}
                  variants={animationListVariants}
                >
                  <ExpensesUploadFile
                    illion={illion}
                    onDelete={handleDelete(illion.id)}
                  />
                </motion.li>
              ))}
            </AnimatePresence>
          </ul>
        </motion.div>
        <motion.div
          initial={false}
          animate={{
            opacity: illions.length === 0 ? 1 : 0,
            display: illions.length === 0 ? "grid" : "none",
          }}
          transition={transition}
          className="place-items-center"
        >
          <p className="text-center font-helvetica text-xs text-[#B2A8BA] desktop:text-sm">
            Please upload a file to continue
          </p>
        </motion.div>
      </div>
      <AnimatePresence>
        {illions.length > 0 && (
          <motion.footer
            initial="unmounted"
            exit="unmounted"
            animate="mounted"
            transition={transition}
            variants={animationListVariants}
            className="overflow-y-hidden"
          >
            <Button
              className="mx-auto mt-[31px] max-w-[173px] py-[10px] font-bold"
              onClick={handleButton}
            >
              Save and Continue
            </Button>
          </motion.footer>
        )}
      </AnimatePresence>
    </section>
  );
};
export default ExpensesUpload;
