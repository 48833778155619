import { AnimatePresence, motion } from "framer-motion";
import {
  ComponentProps,
  FC,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  IncomeForm,
  incomeTypeOtherData,
  incomeTypeRentalData,
  incomeTypeSalaryWagesData,
  IIncome,
  IIncomeAttributes,
  Value,
} from ".";
import {
  FinancialDetailsTableColumn,
  FinancialDetailsTableRow,
} from "../financial";
import useStore, { IModelId } from "../store";
import shallow from "zustand/shallow";
import currency from "currency.js";
import { isNull } from "lodash";

type Category = "Salary/Wages" | "Rental Income" | "Other Income";

const IncomeComponent: FC<{
  applicantPartyId: IModelId;
  incomeId: IModelId;
  formLabel?: string;
  category: Category;
}> = ({ applicantPartyId, incomeId, formLabel, category }) => {
  const [isEditing, setIsEditing] = useState(false);

  const [
    getApplicantById,
    getFinancialItem,
    setFinancialItem,
    deleteFinancialItem,
  ] = useStore(
    useCallback((state) => {
      return [
        state.getApplicantById,
        state.getFinancialItem,
        state.setFinancialItem,
        state.deleteFinancialItem,
      ];
    }, []),
    shallow
  );

  const income = getFinancialItem(applicantPartyId, {
    type: "Income",
    id: incomeId,
  });
  const applicantName = useMemo(() => {
    if (!income?.ApplicantId) return;

    return getApplicantById(applicantPartyId, income?.ApplicantId)?.Name;
  }, [applicantPartyId, getApplicantById, income?.ApplicantId]);

  const incomeTypeLabel = useMemo(() => {
    if (category === "Salary/Wages") {
      return incomeTypeSalaryWagesData.find(
        (type) => type["IncomeType.Code"] === income?.IncomeType
      )?.["IncomeType.Name"];
    }
    if (category === "Rental Income") {
      return incomeTypeRentalData.find(
        (type) => type["IncomeType.Code"] === income?.IncomeType
      )?.["IncomeType.Name"];
    }

    return incomeTypeOtherData.find(
      (type) => type["IncomeType.Code"] === income?.IncomeType
    )?.["IncomeType.Name"];
  }, [category, income?.IncomeType]);

  const formInitialValue = useMemo(() => {
    const { Attributes, _id, ...incomeProps } = income || {};

    return {
      ...incomeProps,
      BoarderIncomeType:
        (Attributes?.get("BoarderIncomeType")?.value as string) || null,
      PropertyBuildDate:
        (Attributes?.get("PropertyBuildDate")?.value as string) || null,
      PropertyCountry:
        (Attributes?.get("PropertyCountry")?.value as string) || null,
      SalePurchaseDate:
        (Attributes?.get("SalePurchaseDate")?.value as string) || null,
      IsTaxExempt: (Attributes?.get("IsTaxExempt")?.value as boolean) ?? null,
      Kiwisaver: (Attributes?.get("Kiwisaver")?.value as boolean) ?? null,
      KiwisaverContribution:
        (Attributes?.get("KiwisaverContribution")?.value as number) || null,
    } as Value;
  }, [income]);

  const handleSave = useCallback(
    (value: NonNullable<ComponentProps<typeof IncomeForm>["initialValue"]>) => {
      const attributesMap: IIncome["Attributes"] = new Map();
      const {
        ApplicantId,
        IncomeType,
        Frequency,
        Amount,
        AmountType,
        ...attributes
      } = value;

      Object.keys(attributes).forEach((key) => {
        const k = key as keyof IIncomeAttributes;
        if (isNull(attributes[k])) {
          attributesMap.delete(k);
          return;
        }

        attributesMap.set(k, {
          name: key,
          value: attributes[k],
        });
      });

      if (value)
        setFinancialItem(applicantPartyId, {
          type: "Income",
          id: incomeId,
          payload: {
            ApplicantId: value.ApplicantId,
            Frequency: value.Frequency,
            Amount: value.Amount,
            AmountType: value.AmountType,
            IncomeType: value.IncomeType,
            Attributes: attributesMap,
          },
        });
      setIsEditing(false);
    },
    [applicantPartyId, incomeId, setFinancialItem]
  );

  const handleDelete = useCallback(() => {
    deleteFinancialItem(applicantPartyId, {
      type: "Income",
      id: incomeId,
    });
  }, [applicantPartyId, deleteFinancialItem, incomeId]);

  const closeForm = useCallback(() => setIsEditing(false), []);
  const openForm = useCallback(() => setIsEditing(true), []);

  return (
    <AnimatePresence>
      {income && (
        <motion.div
          key={`income#${income._id}`}
          variants={{
            exit: {
              height: 0,
            },
            mounted: {
              height: "auto",
            },
          }}
          initial={false}
          exit="exit"
          animate="mounted"
          transition={{
            ease: "easeInOut",
            duration: 0.2,
          }}
          className={!isEditing ? "border-b border-b-[#C7BDCD]/50" : ""}
        >
          <FinancialDetailsTableRow
            isEditing={isEditing}
            onDelete={handleDelete}
            onEdit={openForm}
          >
            <FinancialDetailsTableColumn>
              {applicantName || "-"}
            </FinancialDetailsTableColumn>
            <FinancialDetailsTableColumn>
              <span className="block whitespace-nowrap text-center">
                <span>{incomeTypeLabel ? `${incomeTypeLabel} -` : ""}</span>{" "}
                <span>
                  {currency(income.Amount, { precision: 2 }).format()}
                </span>{" "}
                <span>{income.Frequency}</span>
              </span>
            </FinancialDetailsTableColumn>
          </FinancialDetailsTableRow>
          <AnimatePresence>
            {isEditing && (
              <motion.div
                key={`income-form#${income._id}`}
                initial={{
                  height: 0,
                  marginTop: 0,
                  overflowY: "hidden",
                }}
                exit={{
                  height: 0,
                  marginTop: 0,
                  overflowY: "hidden",
                }}
                animate={{
                  height: "auto",
                  marginTop: 4,
                  overflowY: "visible",
                }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.2,
                }}
              >
                <IncomeForm
                  applicantPartyId={applicantPartyId}
                  header={formLabel}
                  category={category}
                  initialValue={formInitialValue}
                  onSave={handleSave}
                  onCancel={closeForm}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default memo(IncomeComponent);
