import { FC, SVGAttributes } from "react";

const Date: FC<SVGAttributes<SVGSVGElement>> = ({
  fill = "#9A86A8",
  ...props
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 1.5H9V0.5H8V1.5H4V0.5H3V1.5H2.5C1.945 1.5 1.505 1.95 1.505 2.5L1.5 9.5C1.5 10.05 1.945 10.5 2.5 10.5H9.5C10.05 10.5 10.5 10.05 10.5 9.5V2.5C10.5 1.95 10.05 1.5 9.5 1.5ZM9.5 4V9.5H2.5V4H9.5ZM6 5H3.5V7.5H6V5Z"
        fill={fill}
      />
    </svg>
  );
};

export default Date;
