import { FC, useCallback } from "react";
import {
  DepositeAmount,
  LoanAmountApproval,
  ResultHeader,
  ResultLenders,
} from ".";
import useStore from "../store";
import { Info } from "../icons";

const Result: FC = () => {
  const [isMultipleLoans] = useStore(
    useCallback((state) => [state.Report.Cases.length === 1], [])
  );

  return (
    <>
      <ResultHeader />
      <section className="mt-[18px] space-y-[15px] rounded-[3px] bg-[#E4EDF7] p-4">
        {isMultipleLoans && (
          <div className="flex items-center">
            <span className="mr-3 inline-block">
              <Info fill="#6093C7" />
            </span>
            <p className="font-helvetica text-[13px] font-bold text-[#6093C7] desktop:text-sm">
              The results graph is not available for scenarios with multiple
              securities.{" "}
              <a href="/" className="hidden underline">
                Why is this?
              </a>
            </p>
          </div>
        )}
        {!isMultipleLoans && (
          <>
            <LoanAmountApproval />
            <DepositeAmount />
          </>
        )}
      </section>
      <ResultLenders />
    </>
  );
};

export default Result;
