import { AnimatePresence, motion } from "framer-motion";
import { FC, useCallback, useMemo, useState } from "react";
import {
  LiabilityForm,
  LiabilityComponent,
  LiabilityTypeCreditCardsData,
  LiabilityTypeLiabilitiesData,
} from ".";
import {
  FinancialDetails,
  FinancialDetailsManager,
  FinancialDetailsTable,
} from "../financial";
import { useStore, IModelId } from "..";

type Category = "Credit Cards" | "Liabilities";

const Liabilities: FC<{
  category: Category;
  applicantPartyId: IModelId;
  onClose: () => void;
}> = ({ applicantPartyId, category, onClose }) => {
  const [allLiabilities, addFinancial] = useStore(
    useCallback(
      (state) => [
        state.getFinancial(applicantPartyId, "Liability"),
        state.addFinancial,
      ],
      [applicantPartyId]
    ),
    (oldState, newState) => oldState[0].length === newState[0].length
  );
  const liabilities = useMemo(() => {
    if (category === "Credit Cards") {
      return allLiabilities.filter(
        (l) =>
          LiabilityTypeCreditCardsData.filter(
            (v) => v["LiabilityType.Code"] === l.LiabilityType
          ).length > 0
      );
    }

    return allLiabilities.filter(
      (l) =>
        LiabilityTypeLiabilitiesData.filter(
          (v) => v["LiabilityType.Code"] === l.LiabilityType
        ).length > 0
    );
  }, [allLiabilities, category]);

  const [isAdding, setIsAdding] = useState(liabilities.length === 0);

  const headerForm = useMemo(() => {
    return `${category} ${liabilities.length + 1}`;
  }, [liabilities.length, category]);

  const openForm = useCallback(() => setIsAdding(true), []);
  const closeForm = useCallback(() => setIsAdding(false), []);

  const handleSave = useCallback(
    (value) => {
      addFinancial(applicantPartyId, {
        type: "Liability",
        value,
      });
      closeForm();
    },
    [addFinancial, applicantPartyId, closeForm]
  );

  return (
    <FinancialDetails header={category} onClose={onClose}>
      <FinancialDetailsTable isAdding={isAdding}>
        <AnimatePresence>
          {liabilities.map((liability, index) => (
            <motion.div
              key={liability._id}
              initial="unmounted"
              animate="open"
              exit="unmounted"
              variants={{
                open: { opacity: 1, height: "auto" },
                unmounted: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
              className="[&>div>div]:first:pt-0"
            >
              <LiabilityComponent
                applicantPartyId={applicantPartyId}
                id={liability._id}
                category={category}
                formLabel={`${category} ${index + 1}`}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </FinancialDetailsTable>
      <FinancialDetailsManager
        isAdding={isAdding}
        buttonLabel={`Add ${category}`}
        buttonOnClick={openForm}
        hasData={liabilities.length > 0}
      >
        <LiabilityForm
          applicantPartyId={applicantPartyId}
          header={headerForm}
          category={category}
          onSave={handleSave}
          onCancel={closeForm}
        />
      </FinancialDetailsManager>
    </FinancialDetails>
  );
};

export default Liabilities;
