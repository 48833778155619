import { FC } from "react";

const Layout: FC = ({ children }) => {
  return (
    <div>
      <main className="mx-auto mb-40 mt-10 grid min-w-[1280px] max-w-[1408px] grid-cols-[1.8fr_5fr] gap-x-4 gap-y-[19px] px-16">
        {children}
      </main>
    </div>
  );
};

export default Layout;
