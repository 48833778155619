import { FC, SVGProps } from "react";

const WarningBubble: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#F18931",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      {...props}
    >
      <title>icon-warning</title>
      <g
        id="Errors"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Overview"
          transform="translate(-413.000000, -977.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(64.000000, 52.000000)">
            <path
              d="M364.666667,925.666667 L351.333333,925.666667 C350.416667,925.666667 349.675,926.416667 349.675,927.333333 L349.666667,942.333333 L353,939 L364.666667,939 C365.583333,939 366.333333,938.25 366.333333,937.333333 L366.333333,927.333333 C366.333333,926.416667 365.583333,925.666667 364.666667,925.666667 Z M358.833333,935.666667 L357.166667,935.666667 L357.166667,934 L358.833333,934 L358.833333,935.666667 Z M358.833333,932.333333 L357.166667,932.333333 L357.166667,929 L358.833333,929 L358.833333,932.333333 Z"
              id="icon-warning"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WarningBubble;
