import { FC } from "react";

const Trash: FC<{
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
}> = ({ fill = "#9B88AA", width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.375 3.125V9.375C9.375 9.72018 9.09518 10 8.75 10H1.25C0.904822 10 0.625 9.72018 0.625 9.375V3.125H0V2.5C0 2.15482 0.279822 1.875 0.625 1.875H3.125V0.625C3.125 0.279822 3.40482 0 3.75 0H6.25C6.59518 0 6.875 0.279822 6.875 0.625V1.875H9.375C9.72018 1.875 10 2.15482 10 2.5V3.125H9.375ZM5.625 1.25H4.375V1.875H5.625V1.25ZM8.125 3.125H1.875V8.75H8.125V3.125ZM4.375 7.5H3.125V4.375H4.375V7.5ZM6.875 7.5H5.625V4.375H6.875V7.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default Trash;
