import { FC, SVGProps } from "react";

const UMIIcon: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#9A86A8",
  ...props
}) => {
  return (
    <svg
      width="18.3333334px"
      height="18.3333334px"
      viewBox="0 0 18.3333334 18.3333334"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>UMI</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M9.16666667,0 C4.10666667,0 0,4.10666667 0,9.16666667 C0,14.2266667 4.10666667,18.3333334 9.16666667,18.3333334 C14.2266667,18.3333334 18.3333334,14.2266667 18.3333334,9.16666667 C18.3333334,4.10666667 14.2266667,0 9.16666667,0 Z M9.97333337,14.4466667 L9.97333337,15.5833334 L8.36916667,15.5833334 L8.36916667,14.4008334 C7.69083334,14.2358334 6.17833334,13.695 5.60083334,11.6875 L7.11333334,11.0733334 C7.16833334,11.275 7.645,12.9891667 9.31333337,12.9891667 C10.1658334,12.9891667 11.1283334,12.5491667 11.1283334,11.5133334 C11.1283334,10.6333334 10.4866667,10.175 9.03833337,9.65249997 C8.03,9.29499997 5.9675,8.70833337 5.9675,6.61833334 C5.9675,6.52666667 5.97666667,4.41833334 8.36916667,3.905 L8.36916667,2.75 L9.97333337,2.75 L9.97333337,3.88666667 C11.66,4.18 12.2741667,5.5275 12.4116667,5.93083334 L10.9633334,6.545 C10.8625,6.22416667 10.4225,5.31666667 9.22166667,5.31666667 C8.57999997,5.31666667 7.5625,5.65583334 7.5625,6.59083334 C7.5625,7.46166667 8.35083337,7.79166667 9.98249997,8.33249997 C12.1825,9.09333337 12.7416667,10.2116667 12.7416667,11.495 C12.7416667,13.9058334 10.45,14.3641667 9.97333337,14.4466667 Z"
          id="UMI"
          fill={fill}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export default UMIIcon;
