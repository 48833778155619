import { FC } from "react";
import { SectionLabel } from "../components/label";

const FinancialFormHeader: FC = ({ children }) => {
  return (
    <header>
      <SectionLabel className="text-[11px] desktop:text-sm">
        {children}
      </SectionLabel>
    </header>
  );
};

export default FinancialFormHeader;
