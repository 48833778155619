import { FC, useEffect, useCallback, useState, useRef } from "react";
import shallow from "zustand/shallow";
import { useStore } from ".";
import { Modal } from "./components/modal";
import { useSearchParams } from "./util/hooks";

const useLoadSession = () => {
  const [loadSession] = useStore(
    useCallback((state) => [state.loadSession], []),
    shallow
  );
  const { get } = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const calledRef = useRef(0);

  useEffect(() => {
    const sessionID = get("session_id");

    if (!sessionID || calledRef.current > 0) return;
    loadSession(sessionID, (status) => {
      calledRef.current++;
      if (status === "Done") {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    });
  }, [get, loadSession]);

  return {
    isLoading,
  };
};

const LoadSession: FC = () => {
  const { isLoading } = useLoadSession();

  return (
    <Modal open={isLoading} containerClassName="w-max py-2">
      Loading session
    </Modal>
  );
};

export default LoadSession;
