import { FC, useCallback, useLayoutEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Close } from "../../icons";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import { twMerge } from "tailwind-merge";

export const rootModal = document.getElementById("root-modal");

type BackdropAnimation = {
  initial?: MotionProps["initial"];
  animate?: MotionProps["animate"];
  exit?: MotionProps["exit"];
  transition?: MotionProps["transition"];
};

const Modal: FC<{
  open: boolean;
  withCloseButton?: boolean;
  onClose?: () => void;
  containerClassName?: string;
  closeWithBackdrop?: boolean;
  backdropColor?: string;
  backdropAnimation?: BackdropAnimation;
  backdropClassName?: string;
  overrideContainer?: boolean;
  closeBtnClassname?: string;
}> = ({
  children,
  open,
  withCloseButton = false,
  closeWithBackdrop = false,
  backdropAnimation,
  backdropClassName,
  backdropColor = "#1B1439",
  onClose,
  containerClassName,
  overrideContainer = false,
  closeBtnClassname,
}) => {
  const backgroundRef = useRef<HTMLDivElement>(null);

  const backgroundClickListener = useCallback(
    (e: MouseEvent) => {
      if (e.target === backgroundRef.current) {
        onClose && onClose();
      }
    },
    [onClose]
  );

  useLayoutEffect(() => {
    const backgroundRefCurrent = backgroundRef.current;
    if (closeWithBackdrop) {
      backgroundRefCurrent?.addEventListener("click", backgroundClickListener);
    }

    return () => {
      backgroundRefCurrent?.removeEventListener(
        "click",
        backgroundClickListener
      );
    };
  }, [backgroundClickListener, closeWithBackdrop, open]);

  return createPortal(
    <AnimatePresence exitBeforeEnter>
      {open && (
        <motion.div
          initial={{ backgroundColor: `${backdropColor}00` }}
          animate={{ backgroundColor: `${backdropColor}CC` }}
          exit={{ backgroundColor: `${backdropColor}00` }}
          transition={{ ease: "easeInOut", duration: 0.2 }}
          {...backdropAnimation}
          key="backdrop"
          className={twMerge(
            `fixed top-0 left-0 bottom-0 right-0 z-10 overflow-y-scroll`,
            backdropClassName
          )}
          ref={backgroundRef}
        >
          {!overrideContainer && (
            <motion.div
              className={twMerge(
                `relative mx-auto my-10 w-full max-w-[498px] rounded-[3px] bg-white px-[18px] pt-[18px] pb-[30px]`,
                containerClassName
              )}
              initial={{ y: "100vh" }}
              animate={{ y: 0 }}
              exit={{ y: "100vh" }}
              transition={{ duration: 0.2 }}
              key="modal"
            >
              {withCloseButton && (
                <button
                  className={twMerge(
                    "absolute right-[18px] top-[18px]",
                    closeBtnClassname
                  )}
                  onClick={() => onClose && onClose()}
                >
                  <Close />
                </button>
              )}
              {children}
            </motion.div>
          )}
          {overrideContainer && children}
        </motion.div>
      )}
    </AnimatePresence>,
    rootModal!
  );
};

export default Modal;
