import { FC } from "react";

const Baby: FC<{
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
}> = ({ fill = "#9B88AA", width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.66671" cy="5.00008" r="0.833333" fill={fill} />
      <circle cx="6.33333" cy="5.00008" r="0.833333" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2933 6.44C15.32 6.3 15.3333 6.15333 15.3333 6C15.3333 5.84667 15.32 5.7 15.2933 5.56C15.1266 4.55333 14.3866 3.73333 13.42 3.44667C13.0666 2.7 12.5666 2.04667 11.96 1.50667C10.9066 0.566667 9.51996 0 7.99996 0C6.47996 0 5.09329 0.566667 4.03996 1.50667C3.42663 2.04667 2.92663 2.70667 2.57996 3.44667C1.61329 3.73333 0.873293 4.54667 0.706626 5.56C0.679959 5.7 0.666626 5.84667 0.666626 6C0.666626 6.15333 0.679959 6.3 0.706626 6.44C0.873293 7.44667 1.61329 8.26667 2.57996 8.55333C2.92663 9.29333 3.42663 9.94667 4.02663 10.48C5.07996 11.4267 6.47329 12 7.99996 12C9.52663 12 10.92 11.4267 11.98 10.48C12.58 9.94667 13.08 9.28667 13.4266 8.55333C14.3866 8.26667 15.1266 7.45333 15.2933 6.44ZM12.6666 7.33333C12.6231 7.33333 12.5825 7.32766 12.5409 7.32186L12.5409 7.32186C12.5188 7.31877 12.4965 7.31565 12.4733 7.31333C12.34 7.76 12.1466 8.17333 11.9 8.55333C11.0666 9.82667 9.63329 10.6667 7.99996 10.6667C6.36663 10.6667 4.93329 9.82667 4.09996 8.55333C3.85329 8.17333 3.65996 7.76 3.52663 7.31333C3.50346 7.31565 3.48109 7.31877 3.45898 7.32186C3.41745 7.32766 3.37679 7.33333 3.33329 7.33333C2.59996 7.33333 1.99996 6.73333 1.99996 6C1.99996 5.26667 2.59996 4.66667 3.33329 4.66667C3.37679 4.66667 3.41745 4.67234 3.45898 4.67814C3.48109 4.68123 3.50346 4.68435 3.52663 4.68667C3.65996 4.24 3.85329 3.82667 4.09996 3.44667C4.93329 2.17333 6.36663 1.33333 7.99996 1.33333C9.63329 1.33333 11.0666 2.17333 11.9 3.44667C12.1466 3.82667 12.34 4.24 12.4733 4.68667C12.4965 4.68435 12.5188 4.68123 12.5409 4.67814L12.5409 4.67814C12.5825 4.67234 12.6231 4.66667 12.6666 4.66667C13.4 4.66667 14 5.26667 14 6C14 6.73333 13.4 7.33333 12.6666 7.33333ZM7.99996 9.33333C6.65996 9.33333 5.50663 8.51333 4.99996 7.33333H11C10.4933 8.51333 9.33996 9.33333 7.99996 9.33333Z"
        fill={fill}
      />
    </svg>
  );
};

export default Baby;
