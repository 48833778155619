import { FC, SVGProps } from "react";

const Building: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="2" y="9" width="3" height="7" fill="#6F5F7B" />
      <rect x="8.5" y="9" width="3" height="7" fill="#6F5F7B" />
      <rect y="18" width="20" height="3" fill="#6F5F7B" />
      <rect x="15" y="9" width="3" height="7" fill="#6F5F7B" />
      <path d="M10 0L0 5V7H20V5L10 0Z" fill="#6F5F7B" />
    </svg>
  );
};

export default Building;
