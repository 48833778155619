import currency from "currency.js";
import { AnimatePresence, motion } from "framer-motion";
import { FC, memo, useCallback, useMemo, useState } from "react";
import shallow from "zustand/shallow";
import { ExpenseForm, expenseTypeData } from ".";
import {
  FinancialDetailsTableColumn,
  FinancialDetailsTableRow,
} from "../financial";
import useStore, { IModelId } from "../store";

const Expense: FC<{
  applicantPartyId: IModelId;
  id: IModelId;
  formLabel?: string;
}> = ({ applicantPartyId, id, formLabel }) => {
  const [isEditing, setIsEditing] = useState(false);

  const [getFinancialItem, setFinancialItem, deleteFinancialItem] = useStore(
    useCallback(
      (state) => [
        state.getFinancialItem,
        state.setFinancialItem,
        state.deleteFinancialItem,
      ],
      []
    ),
    shallow
  );

  const expense = getFinancialItem(applicantPartyId, { type: "Expense", id });

  const expenseLabel = useMemo(
    () =>
      expenseTypeData.find(
        (exp) => exp["ExpenseType.Code"] === expense?.ExpenseType
      )?.["ExpenseType.Name"],
    [expense?.ExpenseType]
  );

  const openForm = useCallback(() => setIsEditing(true), []);
  const closeForm = useCallback(() => setIsEditing(false), []);

  const handleSave = useCallback(
    (value) => {
      setFinancialItem(applicantPartyId, {
        type: "Expense",
        id,
        payload: value,
      });
      closeForm();
    },
    [applicantPartyId, closeForm, id, setFinancialItem]
  );

  const handleDelete = useCallback(() => {
    deleteFinancialItem(applicantPartyId, {
      type: "Expense",
      id,
    });
  }, [applicantPartyId, deleteFinancialItem, id]);

  return (
    <AnimatePresence>
      {expense && (
        <motion.div
          variants={{
            exit: {
              height: 0,
            },
            mounted: {
              height: "auto",
            },
          }}
          initial={false}
          exit="exit"
          animate="mounted"
          transition={{
            ease: "easeInOut",
            duration: 0.2,
          }}
          className={!isEditing ? "border-b border-b-[#C7BDCD]/50" : ""}
        >
          <FinancialDetailsTableRow
            isEditing={isEditing}
            onEdit={openForm}
            onDelete={handleDelete}
            className="grid-cols-12"
          >
            <FinancialDetailsTableColumn className="col-span-4">
              {expenseLabel || "-"}
            </FinancialDetailsTableColumn>
            <FinancialDetailsTableColumn className="col-span-3 col-start-6 flex items-center space-x-1 text-right font-bold">
              <span className="block text-right font-bold">
                {currency(expense.Amount, { precision: 2 }).format()}
              </span>
              <span>{expense.Frequency}</span>
            </FinancialDetailsTableColumn>
          </FinancialDetailsTableRow>
          <AnimatePresence>
            {isEditing && (
              <motion.div
                initial={{
                  height: 0,
                  marginTop: 0,
                  overflowY: "hidden",
                }}
                exit={{
                  height: 0,
                  marginTop: 0,
                  overflowY: "hidden",
                }}
                animate={{
                  height: "auto",
                  overflowY: "initial",
                  marginTop: 4,
                }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.2,
                }}
              >
                <ExpenseForm
                  initialValue={expense}
                  header={formLabel || expenseLabel || ""}
                  onCancel={closeForm}
                  onSave={handleSave}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default memo(Expense);
