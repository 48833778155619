import { FC, SVGAttributes } from "react";

const WarningCircleSm: FC<SVGAttributes<SVGSVGElement>> = ({
  fill = "#F75454",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      version="1.1"
      {...props}
    >
      <title>Warning</title>
      <g
        id="illion-import-flow"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="A"
          transform="translate(-717.000000, -176.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <path
            d="M724,176.333333 C720.32,176.333333 717.333333,179.32 717.333333,183 C717.333333,186.68 720.32,189.666667 724,189.666667 C727.68,189.666667 730.666667,186.68 730.666667,183 C730.666667,179.32 727.68,176.333333 724,176.333333 Z M724.666667,186.333333 L723.333333,186.333333 L723.333333,185 L724.666667,185 L724.666667,186.333333 Z M724.666667,183.666667 L723.333333,183.666667 L723.333333,179.666667 L724.666667,179.666667 L724.666667,183.666667 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
};

export default WarningCircleSm;
