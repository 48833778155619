import { useCallback } from "react";
import shallow from "zustand/shallow";
import { LoanAmountApprovalBar } from ".";
import { SectionLabel } from "../components/label";
import useStore from "../store";

const LoanAmountApproval = () => {
  const [LoanAmountApproval, activeLenders, setActiveLenders] = useStore(
    useCallback(
      (state) => [
        state.loanAmountApproval,
        state.activeLenders,
        state.setActiveLenders,
      ],
      []
    ),
    shallow
  );

  return (
    <section className="rounded-[3px] bg-white px-6 py-4">
      <header className="flex items-center justify-between">
        <div>
          <SectionLabel className="text-xs text-[#607D8B] desktop:text-sm">
            Total Loan Amount
          </SectionLabel>
          <p className="font-helvetica text-[10px] text-[#607D8B] desktop:text-xs">
            vs. Lender approval
          </p>
        </div>
        <div className="flex items-center space-x-4 font-helvetica text-xs text-[#607D8B] desktop:text-sm">
          <p>
            {" "}
            <span className="mr-1.5 inline-block h-[14px] w-[14px] rounded-[3px] bg-[#42B4B4] align-text-top" />{" "}
            Approve
          </p>
          <p>
            {" "}
            <span className="mr-1.5 inline-block h-[14px] w-[14px] rounded-[3px] bg-[#F18383] align-text-top" />{" "}
            Decline
          </p>
        </div>
      </header>
      <div className="mt-[9px]">
        {LoanAmountApproval.length > 0 && (
          <LoanAmountApprovalBar
            loanAmountApproval={LoanAmountApproval}
            activeLenders={activeLenders}
            onBarClick={(d) => setActiveLenders(d.Id)}
          />
        )}
      </div>
    </section>
  );
};

export default LoanAmountApproval;
