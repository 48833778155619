import { motion } from "framer-motion";
import { FC } from "react";

const Collapse: FC<{
  collapse?: boolean;
}> = ({ collapse = false, children }) => {
  return (
    <motion.div
      initial={false}
      animate={{ height: collapse ? 0 : "auto" }}
      transition={{ duration: 0.2, ease: "easeInOut" }}
      className="overflow-y-hidden"
    >
      {children}
    </motion.div>
  );
};

export default Collapse;
