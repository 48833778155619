import { FC, memo, useCallback, useMemo } from "react";
import { expenseTypeData, IExpense, expenseCategoryOrderData } from ".";
import { FinancialForm, FinancialFormInputContainer } from "../financial";
import {
  FormErrors,
  InputNumber,
  InputSelect,
  Label,
  Option,
  OptionGroup,
  useForm,
} from "../components/form";
import { Dollar } from "../icons";
import useStore from "../store";
import { Frequency } from "../applicant";

type Value = Omit<IExpense, "_id">;

type Props = {
  header: string;
  initialValue?: Value;
  onSave: (value: Value) => void;
  onCancel: () => void;
};

export type OptionsGroupsTypes = {
  label: string;
  options: {
    "ExpenseType.Name": string;
    "ExpenseType.Code": string;
  }[];
};

const frequency: {
  value: Frequency;
  label: string;
}[] = [
  {
    value: "Yearly",
    label: "Yearly",
  },
  {
    value: "HalfYearly",
    label: "Half-yearly",
  },
  {
    value: "Quarterly",
    label: "Quarterly",
  },
  {
    value: "Monthly",
    label: "Monthly",
  },
  {
    value: "Fortnightly",
    label: "Fortnightly",
  },
  {
    value: "Weekly",
    label: "Weekly",
  },
];

const ExpenseForm: FC<Props> = ({ header, initialValue, onSave, onCancel }) => {
  const addingfinancialEvents = useStore(
    useCallback((state) => state.getEventsListener("addingfinancial"), [])
  );

  const optionsGroups = useMemo(() => {
    const result = expenseTypeData.reduce((previous, current) => {
      const group = previous.find((group) => group.label === current.Group);
      const option = {
        "ExpenseType.Name": current["ExpenseType.Name"],
        "ExpenseType.Code": current["ExpenseType.Code"],
      } as OptionsGroupsTypes["options"][0];

      if (group) {
        group.options.push(option);
      } else {
        previous.push({
          label: current.Group,
          options: [option],
        });
      }

      return previous;
    }, [] as OptionsGroupsTypes[]);

    const orderedResult = expenseCategoryOrderData.order
      .map((group) => result.find((groupItem) => groupItem.label === group))
      .filter(Boolean) as OptionsGroupsTypes[];
    return orderedResult;
  }, []);

  const { values, handleChange, handleSubmit, hasErrors } = useForm<Value>({
    initialValues: initialValue || {
      Amount: NaN,
      Frequency: "Monthly",
      ExpenseType: "",
      ApplicantId: null,
    },
    validate: (values) => {
      const errors: FormErrors<Value> = {};

      if (!values.ExpenseType) {
        errors.ExpenseType = "Expense Type cannot be blank";
      }

      if (!values.Amount || isNaN(values.Amount) || values.Amount <= 0) {
        errors.Amount = "Expense amount must be greater than zero";
      }

      if (!values.Frequency) {
        errors.Frequency = "Expense Frequency cannot be blank";
      }

      return errors;
    },
    onSubmit: onSave,
    validateOnMount: true,
    onChange: (values) => {
      let newValues = values;
      addingfinancialEvents.forEach(({ name, listener }) => {
        const value = listener({
          event: name,
          financial: values,
          type: "Expense",
        });
        if (value) {
          newValues = value;
        }
      });
      return newValues;
    },
  });

  return (
    <FinancialForm
      header={header}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isValid={!hasErrors}
    >
      <FinancialFormInputContainer>
        <Label htmlFor="type" className="required">
          Type
        </Label>
        <InputSelect
          initialValue={values.ExpenseType}
          onChange={(value) => handleChange("ExpenseType", value)}
          searchable={{ placeholder: "Search expense" }}
        >
          {optionsGroups.map((group) => (
            <OptionGroup key={group.label} label={group.label}>
              {group.options.map((option) => (
                <Option
                  value={option["ExpenseType.Code"]}
                  key={option["ExpenseType.Code"]}
                >
                  {option["ExpenseType.Name"]}
                </Option>
              ))}
            </OptionGroup>
          ))}
        </InputSelect>
      </FinancialFormInputContainer>
      <FinancialFormInputContainer>
        <Label htmlFor="amount" className="required">
          Amount
        </Label>
        <InputNumber
          id="amount"
          withIcon={<Dollar />}
          value={values.Amount}
          onChange={(value) => {
            handleChange("Amount", value);
          }}
          autonumeric
        />
      </FinancialFormInputContainer>
      <FinancialFormInputContainer>
        <Label htmlFor="frequency" className="required">
          Frequency
        </Label>
        <InputSelect
          initialValue={values.Frequency}
          onChange={(value) => handleChange("Frequency", value)}
        >
          {frequency.map((fre) => (
            <Option value={fre.value} key={fre.value}>
              {fre.label}
            </Option>
          ))}
        </InputSelect>
      </FinancialFormInputContainer>
    </FinancialForm>
  );
};

export default memo(ExpenseForm);
