import { FC } from "react";

const Search: FC<{
  fill?: string;
}> = ({ fill = "#9A86A8" }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.30744 5.07726C8.30744 4.18778 7.99138 3.42698 7.35927 2.79486C6.72715 2.16274 5.96635 1.84668 5.07686 1.84668C4.18737 1.84668 3.42657 2.16274 2.79445 2.79486C2.16234 3.42698 1.84628 4.18778 1.84628 5.07726C1.84628 5.96675 2.16234 6.72755 2.79445 7.35967C3.42657 7.99179 4.18737 8.30785 5.07686 8.30785C5.96635 8.30785 6.72715 7.99179 7.35927 7.35967C7.99138 6.72755 8.30744 5.96675 8.30744 5.07726ZM11.9996 11.0769C11.9996 11.327 11.9082 11.5433 11.7254 11.7258C11.5426 11.9083 11.3263 11.9997 11.0765 12C10.8169 12 10.6006 11.9086 10.4275 11.7258L7.9541 9.25966C7.09369 9.8557 6.13461 10.1537 5.07686 10.1537C4.38929 10.1537 3.73186 10.0203 3.10459 9.75353C2.47732 9.48674 1.93647 9.12613 1.48203 8.67169C1.02759 8.21726 0.666981 7.6764 0.400188 7.04913C0.133396 6.42186 0 5.76444 0 5.07686C0 4.38929 0.133396 3.73186 0.400188 3.10459C0.666981 2.47732 1.02759 1.93647 1.48203 1.48203C1.93647 1.02759 2.47732 0.666981 3.10459 0.400188C3.73186 0.133396 4.38929 0 5.07686 0C5.76444 0 6.42186 0.133396 7.04913 0.400188C7.6764 0.666981 8.21726 1.02759 8.67169 1.48203C9.12613 1.93647 9.48674 2.47732 9.75353 3.10459C10.0203 3.73186 10.1537 4.38929 10.1537 5.07686C10.1537 6.13461 9.8557 7.09369 9.25966 7.9541L11.7331 10.4275C11.911 10.6055 12 10.8218 12 11.0765L11.9996 11.0769Z"
        fill={fill}
      />
    </svg>
  );
};

export default Search;
