import { DragEventHandler, FC, useMemo } from "react";

type handleDrag = {
  onDragOver: DragEventHandler<HTMLDivElement>;
  onDragEnter: DragEventHandler<HTMLDivElement>;
  onDragLeave: DragEventHandler<HTMLDivElement>;
  onDrop: DragEventHandler<HTMLDivElement>;
};

type Props = {
  className?: string;
  onDragIn?: () => void;
  onDrag?: () => void;
  onDragOut?: () => void;
  onDrop?: (Files: File[]) => void;
};

const DropZone: FC<Props> = ({
  children,
  className,
  onDrag,
  onDragIn,
  onDragOut,
  onDrop,
}) => {
  const handleDrag = useMemo(
    () =>
      ({
        onDragOver: (e) => {
          e.preventDefault();
          e.stopPropagation();
          onDrag?.();
        },
        onDragEnter: (e) => {
          e.preventDefault();
          e.stopPropagation();
          onDragIn?.();
        },
        onDragLeave: (e) => {
          e.preventDefault();
          e.stopPropagation();
          onDragOut?.();
        },
        onDrop: (e) => {
          e.preventDefault();
          e.stopPropagation();

          const files: File[] = [];

          if (e.dataTransfer.files) {
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
              const file = e.dataTransfer.files.item(i);
              if (file) files.push(file);
            }
          }

          onDrop?.(files);

          e.dataTransfer.clearData();
        },
      } as handleDrag),
    [onDrag, onDragIn, onDragOut, onDrop]
  );

  return (
    <div {...handleDrag} className={className}>
      {children}
    </div>
  );
};

export default DropZone;
