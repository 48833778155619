import { FC } from "react";

let tmp: number[] = [];
for (let i = 0; i < 100; i++) {
  tmp.push(i);
}

const Progress: FC<{
  value: number;
  height?: number | string;
}> = ({ value, height = "4px" }) => {
  return (
    <div className="my-px -translate-y-1/2 overflow-hidden rounded-full border border-[#7B5C8D]/40 p-px">
      <div
        className={`overflow-hidden transition-all duration-200 ease-in-out ${
          value === 100 ? "rounded-full" : "rounded-l-full"
        }`}
        style={{
          width: `${value}%`,
        }}
      >
        <div
          className="relative flex w-full items-center overflow-hidden bg-accent"
          style={{
            height,
          }}
        >
          {tmp.map((item) => (
            <span
              key={item}
              className="absolute block h-[400%] w-1.5 rotate-[24deg] bg-white opacity-[0.48] mix-blend-soft-light"
              style={{
                left: `${item * 12}px`,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Progress;
