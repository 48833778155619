import { FC } from "react";

const SectionTitle: FC<{
  title: string;
  no: number;
}> = (props) => {
  return (
    <div
      style={{
        marginBottom: "20px",
        marginTop: "20px",
        verticalAlign: "bottom",
        width: "100%",
      }}
    >
      <p
        style={{
          display: "inline-block",
          fontFamily: "Helvetica, 'Lato', Arial",
          fontSize: "17px",
          color: "#ffffff",
          lineHeight: "17px",
          fontWeight: "700",
          margin: 0,
          background: "#1d1c1d",
          borderRadius: "50%",
          paddingTop: "2px",
          paddingBottom: "6px",
          paddingLeft: "8px",
          paddingRight: "10px",
          width: "25px",
        }}
      >
        {props.no}
      </p>
      <p
        style={{
          display: "inline-block",
          fontFamily: "Helvetica, 'Lato', Arial",
          fontSize: "20px",
          color: "#1d1c1d",
          lineHeight: "20px",
          fontWeight: "700",
          margin: 0,
          marginLeft: "5px",
          marginTop: "2px",
          whiteSpace: "nowrap",
        }}
      >
        {props.title}
      </p>
    </div>
  );
};

export default SectionTitle;
