import { FC, useCallback, useMemo, useState } from "react";
import { Modal } from "../components/modal";
import { SectionLabel } from "../components/label";
import {
  Button,
  InputCheckbox,
  InputContainer,
  InputSwitch,
  Label,
} from "../components/form";
import useStore from "../store";
import shallow from "zustand/shallow";
import { ILenderResult } from "../result";
import axios from "axios";
import { FUNCTIONS_BASE_URL } from "../../constant";
import { LoadingCircle } from "../icons";

interface IPdfPayload {
  reportId: string;
  currentLoanAmount: number;
  lenders: Array<string>;
  includeWarningsErrors: boolean;
  notes: string;
}

const PdfExportOptionsModal: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const [isMultipleLoans, activeLenders, reportId] = useStore(
    useCallback(
      (state) => [
        state.Report.Cases.length === 1,
        state.activeLenders,
        state.Report.Id,
      ],
      []
    ),
    shallow
  );
  const [selectedApproveLenders, setSelectedApproveLenders] = useState<
    Array<string>
  >([]);
  const [selectedDeclineLenders, setSelectedDeclineLenders] = useState<
    Array<string>
  >([]);
  const [includeWarningsErrors, setIncludeWarningsErrors] = useState(true);
  const [notes, setNotes] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { Pass, Fail } = useMemo(() => {
    setSelectedApproveLenders(
      activeLenders?.Pass && activeLenders?.Pass?.length > 0
        ? activeLenders?.Pass?.map((_i) => _i.Lender.Name)
        : []
    );
    setSelectedDeclineLenders(
      activeLenders?.Fail && activeLenders?.Fail?.length > 0
        ? activeLenders?.Fail?.map((_i) => _i.Lender.Name)
        : []
    );
    return {
      Pass:
        activeLenders?.Pass && activeLenders?.Pass?.length > 0
          ? activeLenders?.Pass
          : [],
      Fail:
        activeLenders?.Fail && activeLenders?.Fail?.length > 0
          ? activeLenders?.Fail
          : [],
    };
  }, [activeLenders]);

  const checkSelectedLender = (
    lenders: ILenderResult[],
    selected: Array<string>
  ): boolean => {
    return Boolean(lenders.length === selected.length);
  };

  const checkPassSelected = (lender: string): boolean => {
    return Boolean(selectedApproveLenders.find((_i: string) => _i === lender));
  };

  const handleSelectedAllPass = (): void => {
    if (Pass.length === selectedApproveLenders.length) {
      setSelectedApproveLenders([]);
    } else if (Pass.length > selectedApproveLenders.length) {
      setSelectedApproveLenders(
        Pass && Pass?.length > 0 ? Pass?.map((_i) => _i.Lender.Name) : []
      );
    }
  };

  const handlePassCheckboxChange = (lender: string): void => {
    let newList = [...selectedApproveLenders, lender];
    if (selectedApproveLenders.includes(lender)) {
      newList = newList.filter((_lender) => _lender !== lender);
    }
    setSelectedApproveLenders(newList);
  };

  const checkDeclineSelected = (lender: string): boolean => {
    return Boolean(selectedDeclineLenders.find((_i: string) => _i === lender));
  };

  const handleSelectedAllDecline = (): void => {
    if (Fail.length === selectedDeclineLenders.length) {
      setSelectedDeclineLenders([]);
    } else if (Fail.length > selectedDeclineLenders.length) {
      setSelectedDeclineLenders(
        Fail && Fail?.length > 0 ? Fail?.map((_i) => _i.Lender.Name) : []
      );
    }
  };

  const handleDeclineCheckboxChange = (lender: string): void => {
    let newList = [...selectedDeclineLenders, lender];
    if (selectedDeclineLenders.includes(lender)) {
      newList = newList.filter((_lender) => _lender !== lender);
    }
    setSelectedDeclineLenders(newList);
  };

  const requestExportPDF = (payload: IPdfPayload) => {
    axios
      .request({
        method: "post",
        url: `${FUNCTIONS_BASE_URL}/httpsReportsExportReportToPDF-exportReportToPDF`,
        data: payload,
      })
      .then(({ data }) => {
        if (data?.url) {
          onClose();
          window.open(data.url, "_blank");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleExportPDF = () => {
    if (isLoading || !reportId) return;
    setIsLoading(true);
    const data = {
      reportId,
      currentLoanAmount: !isMultipleLoans ? activeLenders?.LoanAmount ?? 0 : 0,
      lenders: [...selectedApproveLenders, ...selectedDeclineLenders],
      includeWarningsErrors,
      notes,
    };
    requestExportPDF(data);
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        if (!isLoading) onClose();
      }}
      closeWithBackdrop
      containerClassName="w-[545px]"
    >
      <SectionLabel className="text-base desktop:text-lg">
        PDF Export Options
      </SectionLabel>
      <div className="mt-4 bg-[#F5F1F8] px-[14px] py-[17px]">
        <SectionLabel className="text-[11px] desktop:text-sm">
          Select Lenders
        </SectionLabel>
        <Label htmlFor="">Select which lenders' results to include</Label>

        {Pass && Pass?.length > 0 && (
          <div className="mt-2 bg-white px-4 pb-2 pt-4">
            <div className="mt-1 flex items-center">
              <InputCheckbox
                id="lenders-approve"
                className="h-[16px] w-[16px]"
                style={{
                  backgroundSize: "60%",
                }}
                checked={checkSelectedLender(Pass, selectedApproveLenders)}
                onChange={handleSelectedAllPass}
                isDisabled={isLoading}
              />
              <label
                htmlFor="lenders-approve"
                className="ml-1.5 font-helvetica text-[11px] font-bold text-[#6F5F7B] desktop:text-sm"
              >
                Lenders that may <span className="text-[#4EC0C1]">APPROVE</span>
              </label>
            </div>
            <div className="mt-2 grid grid-cols-2 gap-2 border-t border-[#C7BDCD]/50 py-[8px]">
              {Pass.map((_lender, idx) => (
                <div className="mt-1 flex items-center" key={idx}>
                  <InputCheckbox
                    id={_lender?.Lender?.Name}
                    className="h-[16px] w-[16px]"
                    style={{
                      backgroundSize: "60%",
                    }}
                    checked={checkPassSelected(_lender?.Lender?.Name)}
                    onChange={() =>
                      handlePassCheckboxChange(_lender?.Lender?.Name)
                    }
                    isDisabled={isLoading}
                  />
                  <label
                    htmlFor={_lender?.Lender?.Name}
                    className="ml-1.5 inline-block flex cursor-pointer items-center text-[13px] text-[#7B5C8D]"
                  >
                    <img
                      src={_lender?.Lender?.Logo}
                      alt={_lender?.Lender?.Name}
                      width={38}
                      height={21}
                      className="mr-[4px] h-[21px] w-[38px] object-contain object-center"
                    />
                    {_lender?.Lender?.Name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}

        {Fail && Fail?.length > 0 && (
          <div className="mt-2 bg-white px-4 pb-2 pt-4">
            <div className="mt-1 flex items-center">
              <InputCheckbox
                id="lenders-decline"
                className="h-[16px] w-[16px] checked:border-[#F18383] checked:bg-[#F18383]"
                style={{
                  backgroundSize: "60%",
                }}
                checked={checkSelectedLender(Fail, selectedDeclineLenders)}
                onChange={handleSelectedAllDecline}
                isDisabled={isLoading}
              />
              <label
                htmlFor="lenders-decline"
                className="ml-1.5 font-helvetica text-[11px] font-bold text-[#6F5F7B] desktop:text-sm"
              >
                Lenders that may <span className="text-[#F18383]">DECLINE</span>
              </label>
            </div>
            <div className="mt-2 grid grid-cols-2 gap-2 border-t border-[#C7BDCD]/50 py-[8px]">
              {Fail.map((_lender, idx) => (
                <div className="mt-1 flex items-center" key={idx}>
                  <InputCheckbox
                    id={_lender?.Lender?.Name}
                    className="h-[16px] w-[16px] checked:border-[#F18383] checked:bg-[#F18383]"
                    style={{
                      backgroundSize: "60%",
                    }}
                    checked={checkDeclineSelected(_lender?.Lender?.Name)}
                    onChange={() =>
                      handleDeclineCheckboxChange(_lender?.Lender?.Name)
                    }
                    isDisabled={isLoading}
                  />
                  <label
                    htmlFor={_lender?.Lender?.Name}
                    className="ml-1.5 inline-block flex cursor-pointer items-center text-[13px] text-[#7B5C8D]"
                  >
                    <img
                      src={_lender?.Lender?.Logo}
                      alt={_lender?.Lender?.Name}
                      width={38}
                      height={21}
                      className="mr-[4px] h-[21px] w-[38px] object-contain object-center"
                    />
                    {_lender?.Lender?.Name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="mt-6 border-t border-[#C7BDCD]/50 py-[15px]">
          <SectionLabel className="mb-4 text-[11px] desktop:text-sm">
            Additional Settings
          </SectionLabel>
          <InputContainer className="gap-0 border-transparent py-0">
            <Label htmlFor="" className="">
              Include error and warning messages
            </Label>
            <InputSwitch
              value={includeWarningsErrors}
              onChange={(value) => {
                if (!isLoading) setIncludeWarningsErrors(value);
              }}
              className={`min-w-[242px] ${isLoading ? "opacity-50" : ""}`}
            >
              <InputSwitch.InputSwitchOption
                value={true}
                className={isLoading ? "hover:cursor-default" : ""}
              >
                Yes
              </InputSwitch.InputSwitchOption>
              <InputSwitch.InputSwitchOption
                value={false}
                className={isLoading ? "hover:cursor-default" : ""}
              >
                No
              </InputSwitch.InputSwitchOption>
            </InputSwitch>
          </InputContainer>
        </div>

        <div className="border-t border-[#C7BDCD]/50 pt-[15px]">
          <SectionLabel className="text-[11px] desktop:text-sm">
            Notes
          </SectionLabel>
          <Label htmlFor="">Add any notes you want to include in the PDF</Label>
          <textarea
            name="notes"
            id="notes"
            className={`mt-3 w-full resize-none rounded-[5px] border border-[#C2B6CB] bg-white px-3 py-2 text-sm text-[#6F5F7B] focus:outline-none ${
              isLoading ? "opacity-50" : ""
            }`}
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            disabled={isLoading}
          ></textarea>
        </div>
      </div>

      <div className="mt-6 flex justify-center">
        <Button
          size="small"
          className={`w-max bg-transparent px-16 py-2 text-[#7B5C8D] ${
            isLoading ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={() => {
            if (!isLoading) onClose();
          }}
        >
          Close
        </Button>
        <Button
          size="small"
          className={`w-max py-2 ${isLoading ? "px-6" : "px-12"}`}
          onClick={handleExportPDF}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <LoadingCircle /> <span className="ml-2">Exporting PDF</span>{" "}
            </>
          ) : (
            <>Export PDF</>
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default PdfExportOptionsModal;
