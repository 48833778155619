import { FC, SVGProps } from "react";

const Percentage: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#9A86A8",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      {...props}
    >
      <title>icon-lvr-blue</title>
      <g
        id="Errors"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Overview" transform="translate(-1077.000000, -439.000000)">
          <g id="icon-lvr-blue" transform="translate(1077.000000, 439.000000)">
            <circle id="Oval" stroke={fill} fill={fill} cx="8" cy="8" r="7.5" />
            <g
              id="Group-14"
              transform="translate(2.947732, 2.666667)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path
                d="M2.50908828,4.88209877 C3.59780622,4.88209877 4.48344725,4.06124074 4.48344725,3.05216049 C4.48344725,2.04308025 3.59780622,1.22222222 2.50908828,1.22222222 C1.42037033,1.22222222 0.534729301,2.04308025 0.534729301,3.05216049 C0.534729301,4.06124074 1.42037033,4.88209877 2.50908828,4.88209877 Z M2.50908828,2.26790123 C2.9772934,2.26790123 3.35524212,2.6182037 3.35524212,3.05216049 C3.35524212,3.48611728 2.9772934,3.83641975 2.50908828,3.83641975 C2.04088315,3.83641975 1.66293443,3.48611728 1.66293443,3.05216049 C1.66293443,2.6182037 2.04088315,2.26790123 2.50908828,2.26790123 Z"
                id="Shape-Copy-10"
              />
              <polygon
                id="Rectangle-Copy-74"
                transform="translate(5.049431, 5.403090) rotate(-45.000000) translate(-5.049431, -5.403090) "
                points="-0.57704992 4.64681177 10.63465 5.07243613 10.6759127 6.15936778 -0.535787258 5.73374342"
              />
              <path
                d="M7.58601135,5.92777778 C6.4972934,5.92777778 5.61165238,6.7486358 5.61165238,7.75771605 C5.61165238,8.7667963 6.4972934,9.58765432 7.58601135,9.58765432 C8.6747293,9.58765432 9.56037033,8.7667963 9.56037033,7.75771605 C9.56037033,6.7486358 8.6747293,5.92777778 7.58601135,5.92777778 Z M7.58601135,8.54197531 C7.11780622,8.54197531 6.73985751,8.19167284 6.73985751,7.75771605 C6.73985751,7.32375926 7.11780622,6.97345679 7.58601135,6.97345679 C8.05421648,6.97345679 8.4321652,7.32375926 8.4321652,7.75771605 C8.4321652,8.19167284 8.05421648,8.54197531 7.58601135,8.54197531 Z"
                id="Shape-Copy-9"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Percentage;
