import { FC, SVGProps } from "react";

const Triangle: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#9A86A8",
  ...props
}) => {
  return (
    <svg
      width="6"
      viewBox="0 0 6 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40017 0.799767C2.73148 0.358024 3.26629 0.354894 3.59994 0.799767L5.40017 3.20007C5.73148 3.64182 5.54908 3.99992 5.00928 3.99992H0.990836C0.443644 3.99992 0.266288 3.64495 0.599942 3.20007L2.40017 0.799767Z"
        fill={fill}
      />
    </svg>
  );
};

export default Triangle;
