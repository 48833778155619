import {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button } from "../components/form";
import { Modal } from "../components/modal";
import { Close, LoadingCircle, ZoomIn, ZoomOut, ZoomReset } from "../icons";
// @ts-ignore
import { MapInteraction } from "../components/react-map-interaction";
import useStore from "../store";
import { Save } from "../icons";
import shallow from "zustand/shallow";
import { Alert } from "../components/alert";
import { Timestamp } from "firebase/firestore";

type MapDimension = {
  scale: number;
  translation: {
    x: number;
    y: number;
  };
  translationBounds?: {
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
  };
  scrollbar?: {
    verticalTop: number;
    verticalBottom: number;
    horizontalLeft: number;
    horizontalRight: number;
  };
};

type Props = {
  open: boolean;
  handleClose?: () => void;
  lender: string;
  sectionID?: string;
  spreadsheetImage?: string;
};

type TransformedRect = {
  -readonly [k in keyof DOMRect]: DOMRect[k];
};

const getTransformedRect = (
  imgViewer: HTMLDivElement,
  prevMapDimension: MapDimension,
  newMapDimension: MapDimension
) => {
  const rect = imgViewer.getBoundingClientRect();
  const { scale: prevScale, translation: prevTranslation } = prevMapDimension;
  const { scale: newScale, translation: newTranslation } = newMapDimension;
  const { x: prevX, y: prevY } = prevTranslation;
  const { x: newX, y: newY } = newTranslation;

  const transformedRect = {
    width: rect.width / (prevScale / newScale),
    height: rect.height / (prevScale / newScale),
    x: rect.x + Math.round(newX - prevX),
    y: rect.y + Math.round(newY - prevY),
  } as TransformedRect;

  transformedRect.left = transformedRect.x;
  transformedRect.top = transformedRect.y;
  transformedRect.right = transformedRect.x + transformedRect.width;
  transformedRect.bottom = transformedRect.y + transformedRect.height;

  return transformedRect;
};

const LenderSpreadsheetModal: FC<Props> = ({
  open,
  handleClose,
  lender,
  sectionID,
  spreadsheetImage,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [tooltips, setTooltips] = useState<
    "zoomin" | "zoomout" | "reset" | "save"
  >();
  const timeoutTooltipRef = useRef<NodeJS.Timeout>();

  const imgViewerContainerRef = useRef<HTMLDivElement>(null);
  const imgViewerRef = useRef<HTMLDivElement>(null);
  const reactMapRef = useRef<any>();
  const scrollbarMouseRef = useRef<{
    clientX: number | null;
    clientY: number | null;
    scrollbarHead: number | null;
    scrollbarTail: number | null;
  }>({
    clientX: null,
    clientY: null,
    scrollbarHead: null,
    scrollbarTail: null,
  });

  const [currentAdmin, setPreviewSetting, getPreviewSetting] = useStore(
    useCallback(
      (state) => [
        state.currentAdmin,
        state.setPreviewSetting,
        state.getPreviewSetting,
      ],
      []
    ),
    shallow
  );
  const initialPreviewSetting = useMemo(() => {
    const noInitial: MapDimension = { scale: 1, translation: { x: 0, y: 0 } };

    if (!sectionID) return noInitial;

    const previewSetting = getPreviewSetting(lender, sectionID);

    if (!previewSetting) return noInitial;

    return {
      scale: previewSetting.setting.zoom,
      translation: {
        x: previewSetting.setting.x,
        y: previewSetting.setting.y,
      },
    } as MapDimension;
  }, [getPreviewSetting, lender, sectionID]);

  const [mapDimension, setMapDimension] = useState<MapDimension>(
    initialPreviewSetting
  );

  const handleChange = useCallback(
    (value: MapDimension) => {
      const imgViewer = imgViewerRef.current;
      const imgViewerContainer = imgViewerContainerRef.current;

      if (!imgViewer || !imgViewerContainer) return;

      const w = getTransformedRect(imgViewer, mapDimension, value).width;
      const containerW = imgViewerContainer.getBoundingClientRect().width;
      if (w < containerW) {
        value.scale = value.scale / (w / containerW);
      }

      const { width, height, top, bottom, left, right } = getTransformedRect(
        imgViewer,
        mapDimension,
        value
      );
      const {
        width: containerWidth,
        height: containerHeight,
        top: containerTop,
        bottom: containerBottom,
        left: containerLeft,
        right: containerRight,
      } = imgViewerContainer.getBoundingClientRect();

      const translationBounds: MapDimension["translationBounds"] = {
        xMax: 0,
        yMax: 0,
        xMin: containerWidth - width - 0,
        yMin: containerHeight - height - 0,
      };

      const scrollbar: MapDimension["scrollbar"] = {
        horizontalLeft: ((containerLeft - left) / width) * 100,
        horizontalRight: ((right - containerRight) / width) * 100,
        verticalTop: ((containerTop - top) / height) * 100,
        verticalBottom: ((bottom - containerBottom) / height) * 100,
      };

      // if (scrollbar.horizontalLeft < 0) {
      //   if (scrollbar.horizontalRight > 0) {
      //     scrollbar.horizontalRight =
      //       scrollbar.horizontalRight + scrollbar.horizontalLeft;
      //     scrollbar.horizontalLeft = 0;
      //   }

      //   scrollbar.horizontalLeft = 0;
      // }

      // Object.keys(scrollbar).forEach((key) => {
      //   const k = key as keyof typeof scrollbar;
      //   if (scrollbar[k] <= 0) {
      //     scrollbar[k] = 0;
      //   }
      // });

      setMapDimension({ ...value, translationBounds, scrollbar });
    },
    [mapDimension]
  );

  const handleLoad = useCallback(() => {
    const imgViewer = imgViewerRef.current;
    const imgViewerContainer = imgViewerContainerRef.current;

    if (!imgViewer || !imgViewerContainer) return;

    const { width, height, top, bottom, left, right } =
      imgViewer.getBoundingClientRect();
    const {
      width: containerWidth,
      height: containerHeight,
      top: containerTop,
      bottom: containerBottom,
      left: containerLeft,
      right: containerRight,
    } = imgViewerContainer.getBoundingClientRect();

    if (!mapDimension.translationBounds) {
      const translationBounds: MapDimension["translationBounds"] = {
        xMax: 0,
        yMax: 0,
        xMin: containerWidth - width,
        yMin: containerHeight - height,
      };

      setMapDimension((state) => ({ ...state, translationBounds }));
    }

    if (!mapDimension.scrollbar) {
      const scrollbar: MapDimension["scrollbar"] = {
        horizontalLeft: ((containerLeft - left) / width) * 100,
        horizontalRight: ((right - containerRight) / width) * 100,
        verticalTop: ((containerTop - top) / height) * 100,
        verticalBottom: ((bottom - containerBottom) / height) * 100,
      };

      Object.keys(scrollbar).forEach((key) => {
        const k = key as keyof typeof scrollbar;
        if (scrollbar[k] <= 0) {
          scrollbar[k] = 0;
        }
      });

      setMapDimension((state) => ({ ...state, scrollbar }));
    }
  }, [mapDimension.scrollbar, mapDimension.translationBounds]);

  const mouseDown = useCallback(
    (axis: "x" | "y"): MouseEventHandler =>
      (e) => {
        const scrollbarMouse = scrollbarMouseRef.current;

        if (!scrollbarMouse || !mapDimension.scrollbar) return;

        if (axis === "x") {
          scrollbarMouse.clientX = e.clientX;
          scrollbarMouse.scrollbarHead = mapDimension.scrollbar.horizontalLeft;
          scrollbarMouse.scrollbarTail = mapDimension.scrollbar.horizontalRight;
        }

        if (axis === "y") {
          scrollbarMouse.clientY = e.clientY;
          scrollbarMouse.scrollbarHead = mapDimension.scrollbar.verticalTop;
          scrollbarMouse.scrollbarTail = mapDimension.scrollbar.verticalBottom;
        }
      },
    [mapDimension.scrollbar]
  );

  const mouseUp = useCallback((e: MouseEvent) => {
    e.preventDefault();
    const scrollbarMouse = scrollbarMouseRef.current;

    if (!scrollbarMouse) return;
    scrollbarMouse.clientX = null;
    scrollbarMouse.clientY = null;
    scrollbarMouse.scrollbarHead = null;
    scrollbarMouse.scrollbarTail = null;
  }, []);

  const mouseMove = useCallback((e: MouseEvent) => {
    e.preventDefault();
    const scrollbarMouse = scrollbarMouseRef.current;
    const imgViewerContainer = imgViewerContainerRef.current;
    const imgViewer = imgViewerRef.current;

    if (!scrollbarMouse || !imgViewerContainer || !imgViewer) return;
    const { width, height } = imgViewer.getBoundingClientRect();

    if (scrollbarMouse.clientX) {
      const dragX = e.clientX - scrollbarMouse.clientX;
      let horizontalLeft =
        scrollbarMouse.scrollbarHead! +
        (dragX / imgViewerContainer.clientWidth) * 100;
      let horizontalRight =
        scrollbarMouse.scrollbarTail! -
        (dragX / imgViewerContainer.clientWidth) * 100;

      if (horizontalLeft < 0) {
        horizontalRight += horizontalLeft;
        horizontalLeft = 0;
      }

      if (horizontalRight < 0) {
        horizontalLeft += horizontalRight;
        horizontalRight = 0;
      }
      const translationX = (horizontalLeft / 100) * width;

      setMapDimension((prev) => {
        if (!prev.scrollbar) return prev;

        return {
          ...prev,
          scrollbar: {
            ...prev.scrollbar,
            horizontalLeft,
            horizontalRight,
          },
          translation: {
            ...prev.translation,
            x: -translationX,
          },
        };
      });
    }
    if (scrollbarMouse.clientY) {
      e.preventDefault();

      const dragY = e.clientY - scrollbarMouse.clientY;
      let verticalTop =
        scrollbarMouse.scrollbarHead! +
        (dragY / imgViewerContainer.clientHeight) * 100;
      let verticalBottom =
        scrollbarMouse.scrollbarTail! -
        (dragY / imgViewerContainer.clientHeight) * 100;

      if (verticalTop < 0) {
        verticalBottom += verticalTop;
        verticalTop = 0;
      }

      if (verticalBottom < 0) {
        verticalTop += verticalBottom;
        verticalBottom = 0;
      }

      const translationY = (verticalTop / 100) * height;

      setMapDimension((prev) => {
        if (!prev.scrollbar) return prev;

        return {
          ...prev,
          scrollbar: {
            ...prev.scrollbar,
            verticalTop,
            verticalBottom,
          },
          translation: {
            ...prev.translation,
            y: -translationY,
          },
        };
      });
    }
  }, []);

  useEffect(() => {
    if (open) {
      window.addEventListener("mouseup", mouseUp);
      window.addEventListener("mousemove", mouseMove);
    } else {
      window.removeEventListener("mouseup", mouseUp);
      window.removeEventListener("mousemove", mouseMove);
    }

    return () => {
      window.removeEventListener("mouseup", mouseUp);
      window.removeEventListener("mousemove", mouseMove);
    };
  }, [mouseMove, mouseUp, open]);

  useEffect(() => {
    if (!open) return;
    setMapDimension(initialPreviewSetting);
  }, [initialPreviewSetting, open]);

  const addZoomAnimation = useCallback(() => {
    const imgViewer = imgViewerRef.current;

    if (!imgViewer) return;

    imgViewer.classList.add(
      "transition-transform",
      "duration-200",
      "ease-in-out"
    );
  }, []);

  const removeZoomAnimation = useCallback(() => {
    const imgViewer = imgViewerRef.current;

    if (!imgViewer) return;

    imgViewer.classList.remove(
      "transition-transform",
      "duration-200",
      "ease-in-out"
    );
  }, []);

  const zoomIn = useCallback(() => {
    const reactMap = reactMapRef.current;

    if (!reactMap) return;
    addZoomAnimation();
    reactMap.changeScale(0.2);
  }, [addZoomAnimation]);

  const zoomOut = useCallback(() => {
    const reactMap = reactMapRef.current;

    if (!reactMap) return;
    addZoomAnimation();
    reactMap.changeScale(-0.2);
  }, [addZoomAnimation]);

  const resetZoom = useCallback(() => {
    addZoomAnimation();
    handleChange(initialPreviewSetting);
  }, [addZoomAnimation, handleChange, initialPreviewSetting]);

  const saveView = useCallback(async () => {
    try {
      setIsSaving(true);
      if (!sectionID) throw Error("sectionID not defined");
      if (!currentAdmin) throw Error("You're not allowed to do this action");

      await setPreviewSetting(lender, sectionID, {
        x: mapDimension.translation.x,
        y: mapDimension.translation.y,
        zoom: mapDimension.scale,
        updated_uid: currentAdmin.uid,
        updated_time: Timestamp.fromDate(new Date()),
      });
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      console.log(error);
    }
  }, [
    lender,
    mapDimension.scale,
    mapDimension.translation.x,
    mapDimension.translation.y,
    sectionID,
    setPreviewSetting,
    currentAdmin,
  ]);

  const showTooltip = useCallback(
    (tooltip: typeof tooltips) => () => {
      timeoutTooltipRef.current = setTimeout(() => {
        setTooltips(tooltip);
        if (timeoutTooltipRef.current) {
          clearTimeout(timeoutTooltipRef.current);
        }
      }, 800);
    },
    []
  );

  const hideTooltip = useCallback(() => {
    setTooltips(undefined);
    if (timeoutTooltipRef.current) {
      clearTimeout(timeoutTooltipRef.current);
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      backdropAnimation={{
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
        },
        exit: {
          opacity: 0,
        },
      }}
      backdropClassName="bg-[#1B1439CC]"
      overrideContainer
    >
      <section className="mx-auto mt-[40px] w-[80%]  desktop:max-w-[1280px]">
        <header className="mb-6 flex items-center justify-end">
          <Button
            size="medium"
            className=" w-max bg-transparent"
            withIcon={<Close />}
            onClick={handleClose}
          >
            Close
          </Button>
        </header>
        <div className="flex">
          <aside className="mr-3">
            <Alert
              open={tooltips === "zoomin"}
              onClose={hideTooltip}
              backgroundColor="#1E1642"
              className="w-max min-w-0 py-1 font-helvetica text-xs font-medium text-white"
              position="right"
              below={
                <Button
                  whileTap={{ scale: 1 }}
                  disabled={!spreadsheetImage}
                  className="h-[54px] w-[54px] rounded-none bg-white hover:bg-[#EBE7F0] focus:outline-none"
                  onClick={zoomIn}
                  onHoverStart={showTooltip("zoomin")}
                  onHoverEnd={hideTooltip}
                  withIcon={
                    <ZoomIn
                      fill="currentColor"
                      className="fill-current text-accent"
                    />
                  }
                />
              }
            >
              Zoom in
            </Alert>
            <Alert
              open={tooltips === "zoomout"}
              onClose={hideTooltip}
              backgroundColor="#1E1642"
              className="w-max min-w-0 py-1 font-helvetica text-xs font-medium text-white"
              position="right"
              below={
                <Button
                  whileTap={{ scale: 1 }}
                  disabled={!spreadsheetImage}
                  className="h-[54px] w-[54px] rounded-none bg-white hover:bg-[#EBE7F0] focus:outline-none"
                  onClick={zoomOut}
                  onHoverStart={showTooltip("zoomout")}
                  onHoverEnd={hideTooltip}
                  withIcon={
                    <ZoomOut
                      fill="currentColor"
                      className="fill-current text-accent"
                    />
                  }
                />
              }
            >
              Zoom Out
            </Alert>
            <Alert
              open={tooltips === "reset"}
              onClose={hideTooltip}
              backgroundColor="#1E1642"
              className="w-max min-w-0 py-1 font-helvetica text-xs font-medium text-white"
              position="right"
              below={
                <Button
                  whileTap={{ scale: 1 }}
                  disabled={!spreadsheetImage}
                  className="h-[54px] w-[54px] rounded-none bg-white hover:bg-[#EBE7F0] focus:outline-none"
                  onClick={resetZoom}
                  onHoverStart={showTooltip("reset")}
                  onHoverEnd={hideTooltip}
                  withIcon={
                    <ZoomReset
                      fill="currentColor"
                      className="fill-current text-accent"
                    />
                  }
                />
              }
            >
              Reset
            </Alert>
            {currentAdmin?.uid && (
              <Alert
                open={tooltips === "save"}
                onClose={hideTooltip}
                backgroundColor="#1E1642"
                className="w-max min-w-0 py-1 font-helvetica text-xs font-medium text-white"
                position="right"
                below={
                  <Button
                    whileTap={{ scale: 1 }}
                    disabled={!spreadsheetImage}
                    className="h-[54px] w-[54px] rounded-none border-t border-[#3B3254]/10 bg-white hover:bg-[#EBE7F0] focus:outline-none"
                    onClick={saveView}
                    onHoverStart={showTooltip("save")}
                    onHoverEnd={hideTooltip}
                    withIcon={
                      isSaving ? (
                        <LoadingCircle className="h-5 w-5 animate-spin stroke-current text-accent" />
                      ) : (
                        <Save
                          className="stroke-current text-accent"
                          stroke="currentColor"
                        />
                      )
                    }
                  />
                }
              >
                Save
              </Alert>
            )}
          </aside>
          <main className="relative w-full min-w-0">
            {spreadsheetImage && (
              <>
                <MapInteraction
                  value={mapDimension}
                  onChange={handleChange}
                  maxScale={10}
                  minScale={0.5}
                  translationBounds={mapDimension.translationBounds}
                  instanceRef={(ref: any) => (reactMapRef.current = ref)}
                >
                  {
                    //@ts-ignore
                    ({ translation, scale }) => {
                      const transform = `translate(${translation.x}px, ${translation.y}px) scale(${scale})`;
                      return (
                        <div
                          style={{
                            width: "100%",
                            position: "relative", // for absolutely positioned children
                            overflow: "hidden",
                            touchAction: "none", // Not supported in Safari :(
                            msTouchAction: "none",
                            cursor: "all-scroll",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none",
                            msUserSelect: "none",
                          }}
                        >
                          <div
                            className="bg-white"
                            ref={imgViewerContainerRef}
                            onMouseEnter={removeZoomAnimation}
                            style={{
                              width: "100%",
                              height: 0,
                              paddingBottom: "58%",
                            }}
                          >
                            <div
                              ref={imgViewerRef}
                              style={{
                                width: "2000px",
                                transform: transform,
                                transformOrigin: "0 0 ",
                              }}
                            >
                              <img
                                src={spreadsheetImage}
                                alt={`${lender} spreadsheet screenshoot`}
                                onLoad={handleLoad}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                </MapInteraction>
                <div className="absolute -right-4 top-0 flex h-full w-[3px] flex-col items-center bg-[#D8D8D8]/[15%]">
                  <span
                    onMouseDown={mouseDown("y")}
                    className="block w-[7px] cursor-row-resize rounded-full bg-gradient-to-br from-[#907AA0] to-[#A491B0]"
                    style={{
                      position: "absolute",
                      top: `${mapDimension.scrollbar?.verticalTop}%`,
                      bottom: `${mapDimension.scrollbar?.verticalBottom}%`,
                    }}
                  />
                </div>
                <div className="absolute -bottom-4 flex h-[3px] w-full items-center bg-[#D8D8D8]/[15%]">
                  <span
                    onMouseDown={mouseDown("x")}
                    className="block h-[7px] cursor-col-resize rounded-full bg-gradient-to-br from-[#907AA0] to-[#A491B0]"
                    style={{
                      position: "absolute",
                      left: `${mapDimension.scrollbar?.horizontalLeft}%`,
                      right: `${mapDimension.scrollbar?.horizontalRight}%`,
                    }}
                  />
                </div>
              </>
            )}
            {!spreadsheetImage && (
              <div className="h-54 grid h-[70vh] w-full place-items-center">
                <div className="text-center font-helvetica text-base text-white/80">
                  <LoadingCircle className="mx-auto h-16 w-16 animate-spin text-white/80" />
                  <p className="mt-4">Loading the image</p>
                </div>
              </div>
            )}
          </main>
        </div>
      </section>
    </Modal>
  );
};

export default LenderSpreadsheetModal;
