import { FC } from "react";
import {
  Button,
  FormErrors,
  InputContainer,
  InputNumber,
  InputText,
  Label,
  useForm,
} from "../components/form";

type Value = {
  name?: string;
  partner?: string;
  dependents?: number;
  vehicles?: number;
};

const ApplicantsForm: FC<{
  onSave?: (value: Value) => void;
  onCancel?: (value: Value) => void;
  initialValue?: Value;
}> = ({ onSave, initialValue, onCancel }) => {
  const { values, hasErrors, handleChange, handleSubmit } = useForm({
    initialValues: initialValue || {
      name: "",
      partner: "",
      dependents: 0,
      vehicles: 0,
    },
    onSubmit: onSave,
    validate: (values) => {
      const errors: FormErrors<Value> = {};

      if (!values.name) {
        errors.name = "Applicant's name cannot be blank";
      }

      return errors;
    },
  });

  const handleCancel = () => {
    onCancel && onCancel(values);
  };

  return (
    <form
      className="rounded-[3px] border border-[#CDC3D2]/[78%]"
      onSubmit={handleSubmit}
    >
      <InputContainer className="mt-3 grid grid-cols-2 border-0 px-[10px] py-0">
        <Label htmlFor="name" className="required">
          Applicant Name
        </Label>
        <InputText
          id="name"
          value={values.name}
          onChange={(event) => handleChange("name", event.target.value)}
          required
        />
      </InputContainer>
      <InputContainer className="mt-3 grid grid-cols-2 border-0 px-[10px] py-0">
        <Label htmlFor="partner">Spouse/Partner</Label>
        <InputText
          id="partner"
          value={values.partner}
          onChange={(event) => handleChange("partner", event.target.value)}
        />
      </InputContainer>
      <InputContainer className="mt-3 grid grid-cols-2 border-0 px-[10px] py-0">
        <Label htmlFor="dependents"># of Dependents</Label>
        <InputNumber
          id="dependents"
          value={values.dependents}
          onChange={(value) => handleChange("dependents", value)}
          min={0}
          buttons
        />
      </InputContainer>
      <InputContainer className="mt-3 grid grid-cols-2 border-0 px-[10px] py-0">
        <Label htmlFor="vehicles"># of Owned Vehicles</Label>
        <InputNumber
          id="vehicles"
          value={values.vehicles}
          onChange={(value) => handleChange("vehicles", value)}
          min={0}
          buttons
        />
      </InputContainer>
      <div className="mt-3 flex items-center space-x-2 border-t border-[#DCD4DF] px-[10px] py-3">
        <Button
          type="button"
          size="medium"
          className="w-full border border-transparent bg-transparent text-[#7B5C8D] hover:border-[#7B5C8D] desktop:text-sm"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="w-full desktop:text-sm"
          size="medium"
          disabled={hasErrors}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default ApplicantsForm;
