import { FC, MouseEventHandler, ReactNode, useMemo, useState } from "react";
import { Button } from "../components/form";
import { Search, WarningBubble, WarningCircleSm } from "../icons";
import { SectionLabel } from "../components/label";
import { ILenderResultError, ILenderResultWarning } from "./report";
import { Alert } from "../components/alert";

const ResultFinancial: FC<{
  title: ReactNode;
  containerClassName?: string;
  className?: string;
  badge?: ReactNode;
  warning?: ILenderResultWarning[];
  error?: ILenderResultError[];
  onSearch?: () => void;
  withoutHeader?: boolean;
}> = ({
  title,
  children,
  containerClassName = "",
  className = "",
  badge,
  onSearch,
  warning,
  error,
  withoutHeader = false,
}) => {
  const [showPopup, setShowPopup] = useState<"error" | "warning">();
  const closePopup = () => setShowPopup(undefined);
  const togglePopup =
    (type: "error" | "warning"): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.stopPropagation();
      setShowPopup((prev) => (prev ? undefined : type));
    };

  const errorReference = useMemo(() => {
    if (!error || error.length === 0) return "";

    const ref = error[0].Reference;
    if (!ref) return "";

    return ref.split(".")?.[1] ?? "";
  }, [error]);

  const warningReference = useMemo(() => {
    if (!warning || warning.length === 0) return "";

    const ref = warning[0].Reference;
    if (!ref) return "";

    return ref.split(".")?.[1] ?? "";
  }, [warning]);

  return (
    <section className={`initial:bg-[#F5F1F8] ${containerClassName}`}>
      {!withoutHeader && (
        <header className="flex items-center justify-between bg-[#E9E3EE] p-[15px] pr-7">
          <SectionLabel className="text-xs leading-[8px] desktop:text-sm">
            {title}
          </SectionLabel>
          {onSearch && (
            <Button
              withIcon={<Search />}
              size="small"
              onClick={onSearch}
              className="w-min bg-transparent"
            />
          )}
        </header>
      )}
      <article className={`initial:px-[14px] initial:py-[15px] ${className}`}>
        {children}
      </article>
      {(error || warning || badge) && (
        <div className="flex items-center justify-between pb-3 pl-[26px] pr-[50px]">
          {(error || warning) && (
            <div className="space-y-2">
              {error && error.length > 0 && (
                <div className="flex max-w-[390px] items-start gap-x-2">
                  <WarningCircleSm
                    width={18}
                    height={18}
                    className="flex-shrink-0"
                    fill="#FF6E6E"
                  />
                  <span className="mt-0.5 block font-helvetica text-[11px] text-[#FF6E6E] desktop:text-xs">
                    {error.length > 1 ? (
                      <div className="flex items-center">
                        {errorReference} Failed.
                        <Alert
                          open={showPopup === "error"}
                          onClose={closePopup}
                          className="left-1/2 -translate-x-1/2"
                          backgroundColor="#1E1642"
                          below={
                            <>
                              <Button
                                className="ml-1 w-max bg-transparent p-0 text-[11px] text-accent underline desktop:text-xs"
                                onClick={togglePopup("error")}
                              >
                                Learn more.
                              </Button>
                            </>
                          }
                        >
                          <div className="space-y-3">
                            {error.map((err) => (
                              <p
                                key={err.Message}
                                className="flex items-start space-x-[10px] text-[11px] text-white desktop:text-xs"
                              >
                                <WarningCircleSm
                                  width={16}
                                  height={16}
                                  fill="#FF6E6E"
                                  className="flex-shrink-0"
                                />
                                <span>{err.Message}</span>
                              </p>
                            ))}
                          </div>
                        </Alert>
                      </div>
                    ) : (
                      error[0].Message
                    )}
                  </span>
                </div>
              )}
              {warning && warning.length > 0 && (
                <div className="flex max-w-[390px] items-start gap-x-2">
                  <WarningBubble className="flex-shrink-0" />
                  <span className="block font-helvetica text-[11px] text-[#E37920] desktop:text-xs">
                    {warning.length > 1 ? (
                      <div className="flex items-center">
                        {warningReference} Failed.
                        <Alert
                          open={showPopup === "warning"}
                          onClose={closePopup}
                          className="left-1/2 -translate-x-1/2"
                          backgroundColor="#1E1642"
                          below={
                            <>
                              <Button
                                className="ml-1 w-max bg-transparent p-0 text-[11px] text-accent underline desktop:text-xs"
                                onClick={togglePopup("warning")}
                              >
                                Learn more.
                              </Button>
                            </>
                          }
                        >
                          <div className="space-y-3">
                            {warning.map((warn) => (
                              <p
                                key={warn.Message}
                                className="flex items-start space-x-[10px] text-[11px] text-white desktop:text-xs"
                              >
                                <WarningCircleSm
                                  width={16}
                                  height={16}
                                  fill="#FF6E6E"
                                  className="flex-shrink-0"
                                />
                                <span>{warn.Message}</span>
                              </p>
                            ))}
                          </div>
                        </Alert>
                      </div>
                    ) : (
                      warning[0].Message
                    )}
                  </span>
                </div>
              )}
            </div>
          )}
          {badge && (
            <span
              style={{
                backgroundImage: "linear-gradient(180deg, #907AA0, #A491B0)",
              }}
              className="ml-auto block w-max rounded-full px-3 py-[3px] font-helvetica text-[11px] font-bold text-white desktop:text-xs"
            >
              {badge}
            </span>
          )}
        </div>
      )}
    </section>
  );
};

export default ResultFinancial;
