// function download(filename, textInput) {

//   var element = document.createElement('a');
//   element.setAttribute('href','data:text/plain;charset=utf-8, ' + encodeURIComponent(textInput));
//   element.setAttribute('download', filename);
//   document.body.appendChild(element);
//   element.click();
//   //document.body.removeChild(element);
// }

const download = (URI: string, filename: string = "") => {
  const onBeforeUnload = window.onbeforeunload;
  const link = document.createElement("a");
  link.download = filename;
  link.href = URI;
  document.body.appendChild(link);
  window.onbeforeunload = null;
  link.click();
  document.body.removeChild(link);
  setTimeout(() => {
    window.onbeforeunload = onBeforeUnload;
  }, 500);
};

export default download;
