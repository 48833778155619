import { FC, useState } from "react";
import { Button } from "../components/form";
import { AttachFile, Trash, WarningCircleSm } from "../icons";
import { Progress } from "../components/progress";

type State = "DONE" | "ERROR" | "LOADING";

type Illion = {
  name: string;
  content: string | null;
};

const ExpensesUploadFile: FC<{
  illion: Illion;
  onDelete?: () => void;
}> = ({ illion, onDelete }) => {
  const [uploadState] = useState<State>(
    illion.name.endsWith(".csv") && illion.content ? "DONE" : "ERROR"
  );
  const [loadingProgress] = useState(100);

  return (
    <div className="flex border-b border-[#C7BDCD]/50 py-1.5">
      <span className="block">
        {uploadState !== "ERROR" ? (
          <AttachFile />
        ) : (
          <WarningCircleSm className="mt-0.5" />
        )}
      </span>
      <div className="ml-2 w-full font-helvetica text-[11px]">
        <p className="text-[#6F5F7B]">{illion.name}</p>
        {uploadState === "ERROR" && (
          <p className="text-[9px] text-[#6F5F7B]">
            File failed to upload. Please try again
          </p>
        )}
        {uploadState === "LOADING" && (
          <div className="mt-2">
            <Progress value={loadingProgress} />
            <p className="font-helvetica text-[9px] text-[#6F5F7B]">
              {loadingProgress}% done
            </p>
          </div>
        )}
      </div>
      {uploadState !== "LOADING" && (
        <Button
          withIcon={<Trash />}
          size="small"
          className="ml-auto mt-0.5 h-3 w-3 bg-transparent"
          onClick={onDelete}
        />
      )}
    </div>
  );
};

export default ExpensesUploadFile;
