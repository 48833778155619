import axios from "axios";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  Expenses,
  ExpensesImport,
  ExpensesImportLoading,
  ExpensesUpload,
  IExpense,
  IExpenseTransaction,
} from ".";
import { useStore, IModelId } from "..";
import { FUNCTIONS_BASE_URL } from "../../constant";
import { ExpenseView } from "../financial/FinancialModal";
import { generateId } from "../util";
import ExpenseImportError from "./ExpenseImportError";

type ModalState = "UPLOAD" | "IMPORT" | "SHOW" | "LOADING" | "ERROR";

type Response = {
  result: IExpenseTransaction[];
};

type Illion = {
  id: number;
  name: string;
  content: string | null;
};

const ExpensesContainer: FC<{
  applicantPartyId: IModelId;
  expenseView: ExpenseView;
  onClose: () => void;
  onChangeModal?: (activeModal: ModalState) => void;
  onChangeExpenseView: (view: ExpenseView) => void;
}> = ({
  applicantPartyId,
  onClose,
  onChangeModal,
  expenseView,
  onChangeExpenseView,
}) => {
  const [modalState, setModalState] = useState<ModalState>("SHOW");

  useEffect(() => onChangeModal?.(modalState), [modalState, onChangeModal]);

  const [loadingProgress, setLoadingProgress] = useState(0);

  const addFinancial = useStore(useCallback((state) => state.addFinancial, []));

  const showModalImport = useCallback(() => setModalState("IMPORT"), []);
  const showModalUpload = useCallback(() => setModalState("UPLOAD"), []);
  const showModalExpenses = useCallback(() => setModalState("SHOW"), []);
  const showModalLoading = useCallback(() => setModalState("LOADING"), []);
  const showModalError = useCallback(() => setModalState("ERROR"), []);

  const illions = useRef<Illion[]>([]);
  const expenses = useRef<IExpenseTransaction[]>([]);

  const handleImport = useCallback(
    (expenses: IExpense[]) => {
      expenses.forEach((expense) => {
        addFinancial(applicantPartyId, {
          type: "Expense",
          value: expense,
        });
      });
      illions.current = [];
      showModalExpenses();
    },
    [addFinancial, applicantPartyId, showModalExpenses]
  );

  const handleUpload = useCallback(
    (ill: Illion[]) => {
      setLoadingProgress(0);
      showModalLoading();
      illions.current = ill;

      axios
        .request<Response>({
          method: "post",
          url: `${FUNCTIONS_BASE_URL}/httpsIllionDataToExpenses`,
          data: {
            files: illions.current.map((ill) => ({
              name: ill.name,
              content: ill.content,
            })),
          },
          onUploadProgress: (p) => {
            setLoadingProgress((p.loaded / p.total) * 100);
          },
        })
        .then(({ data }) => {
          if (data.result.length <= 0) {
            throw Error("Empty expenses");
          }
          expenses.current = data.result.map((value) => ({
            Expense: {
              ...value.Expense,
              Amount: value.Expense.Amount,
              _id: generateId(),
            },
            Transaction: value.Transaction.map((transaction) => ({
              ...transaction,
              _id: generateId(),
            })),
          }));

          showModalImport();
          setLoadingProgress(100);
        })
        .catch((e) => {
          console.error(e);
          setLoadingProgress(100);
          showModalError();
        });
    },
    [showModalError, showModalImport, showModalLoading]
  );

  if (modalState === "ERROR")
    return (
      <ExpenseImportError
        onCancel={() => {
          illions.current = [];
          showModalExpenses();
        }}
        onTryAgain={() => handleUpload(illions.current)}
      />
    );

  if (modalState === "LOADING")
    return (
      <ExpensesImportLoading
        progress={loadingProgress}
        label="Importing expenses"
      />
    );

  if (modalState === "UPLOAD")
    return (
      <ExpensesUpload
        initialIllion={illions.current}
        onButtonContinueClick={handleUpload}
      />
    );

  if (modalState === "IMPORT")
    return (
      <ExpensesImport
        onImport={handleImport}
        expensesTransaction={expenses.current}
      />
    );

  return (
    <Expenses
      onClose={onClose}
      onImportButtonClick={showModalUpload}
      applicantPartyId={applicantPartyId}
      view={expenseView}
      onChangeView={onChangeExpenseView}
    />
  );
};

export default ExpensesContainer;
