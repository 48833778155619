import { FC, useMemo, useState } from "react";
import {
  ResultFinancial,
  ResultFinancialTableData,
  ResultFinancialTableRow,
  ResultLendersDetails,
} from ".";
import { Button } from "../components/form";
import { Search } from "../icons";
import { SectionLabel } from "../components/label";
import { Modal } from "../components/modal";
import {
  ILVR,
  ILenderResult,
  ILenderResultError,
  ILenderResultWarning,
  IResource,
} from "./report";
import { decimalToPercentage } from "../util";
import ResultFinancialTable from "./ResultFinancialTable";
import { twMerge } from "tailwind-merge";

const LenderStatsLVR: FC<{
  lenderResult: ILenderResult;
}> = ({ lenderResult }) => {
  const { Errors, Result, Warnings, Resources, Lender } = lenderResult;
  const { LVR } = Result || {};

  const resources = useMemo(
    () =>
      Resources.filter((resource) =>
        resource.Reference.includes("Result.LVR.LVRLimit")
      ),
    [Resources]
  );
  const [activeResource, setActiveResource] = useState<IResource | null>(null);
  const isResourceModalOpen = useMemo(() => !!activeResource, [activeResource]);

  if (!LVR) return null;

  const isNA = LVR.reduce(
    (isPass, lvrItem) => isPass && lvrItem.Status === "N/A",
    true
  );
  const isPass = isNA
    ? false
    : LVR.reduce(
        (isPass, lvrItem) => isPass && lvrItem.Status === "Pass",
        true
      );

  function getLVRErrors(LoanId: number) {
    return Errors.filter(
      (error) =>
        error.Reference === "Result.LVR" &&
        error.ReferenceObjectType === "Loan" &&
        error.ReferenceObjectId === LoanId
    );
  }

  function getLVRWarnings(LoanId: number) {
    return Warnings.filter(
      (warning) =>
        warning.Reference === "Result.LVR" &&
        warning.ReferenceObjectType === "Loan" &&
        warning.ReferenceObjectId === LoanId
    );
  }

  return (
    <div
      className={twMerge(
        "mx-4 rounded-[3px] bg-white px-[14px] py-[11px]",
        isPass && !isNA && "border border-accent",
        !isPass && !isNA && "border border-[#FF6E6E]"
      )}
    >
      <Modal
        open={isResourceModalOpen}
        withCloseButton
        onClose={() => setActiveResource(null)}
        closeWithBackdrop
        containerClassName="max-w-[70%] w-max"
      >
        {activeResource && (
          <>
            <SectionLabel>
              {Lender.Name}{" "}
              {activeResource.URL.includes("UsageType")
                ? "Usage Type"
                : "Property Type"}
            </SectionLabel>
            <img
              src={activeResource.URL}
              alt={`${Lender.Name} ${
                activeResource.Reference.includes("UsageType")
                  ? "Usage Type"
                  : "Property Type"
              }`}
              className="mt-[27px] w-full rounded-[3px] border border-[#CDC3D2]"
            />
          </>
        )}
      </Modal>
      <ResultLendersDetails title={`LVR`} pass={isPass} className="space-y-2.5">
        {LVR.map((lvrItem, index) => (
          <LVRTable
            key={index}
            loanId={lvrItem.LoanId}
            lvrItem={lvrItem}
            resources={resources}
            errors={getLVRErrors(lvrItem.LoanId)}
            warnings={getLVRWarnings(lvrItem.LoanId)}
            onResourceButtonClick={(resource) => setActiveResource(resource)}
          />
        ))}
      </ResultLendersDetails>
    </div>
  );
};

function LVRTable({
  lvrItem,
  resources,
  loanId,
  errors,
  warnings,
  onResourceButtonClick,
}: {
  loanId: number;
  lvrItem: ILVR;
  resources: IResource[];
  errors: ILenderResultError[];
  warnings: ILenderResultWarning[];
  onResourceButtonClick: (resource: IResource) => void;
}) {
  function getResource(reference: string) {
    return resources.find((resource) => resource.Reference === reference);
  }

  return (
    <ResultFinancial
      title={
        <>
          <span className="mr-[9px] inline-block">Security {loanId} LVR</span>
          <span
            className={twMerge(
              "rounded-[11px] px-2 py-[3px] font-helvetica text-xs text-white",
              lvrItem.Status === "Pass" && "bg-[#4EC0C1]",
              lvrItem.Status === "Fail" && "bg-[#FF8581]",
              lvrItem.Status === "N/A" && "bg-[#907AA0]"
            )}
          >
            {typeof lvrItem.Value === "number"
              ? decimalToPercentage(lvrItem.Value)
              : lvrItem.Value}
          </span>
        </>
      }
      badge={
        ["N/A", "Refer"].includes(lvrItem.RequiredMaxLVR as any)
          ? `Max LVR: ${lvrItem.RequiredMaxLVR}`
          : `Max LVR: ${decimalToPercentage(lvrItem.RequiredMaxLVR as number)}`
      }
      className="pb-3 pt-1"
      error={errors}
      warning={warnings}
    >
      <ResultFinancialTable withoutHead>
        {lvrItem.LVRLimit.map((lvrLimitItem, index) => {
          const resource = getResource(`Result.LVR.LVRLimit[${index}]`);

          return (
            <ResultFinancialTableRow key={lvrLimitItem.Label}>
              <ResultFinancialTableData className="col-span-2">
                <div className="grid grid-cols-[100px,1fr]">
                  <span className="font-bold">{lvrLimitItem.Label}:</span>
                  <span>
                    {typeof lvrLimitItem.Description === "string"
                      ? lvrLimitItem.Description || "Unknown"
                      : lvrLimitItem.Description.join(", ") || "Unknown"}
                  </span>
                </div>
              </ResultFinancialTableData>
              <ResultFinancialTableData>
                <div className="flex h-full items-center justify-end">
                  <span className="mr-2 inline-block">
                    {["N/A", "Refer"].includes(lvrLimitItem.MaxLVR as any)
                      ? lvrLimitItem.MaxLVR
                      : `Max LVR: ${decimalToPercentage(
                          lvrLimitItem.MaxLVR as number
                        )}`}
                  </span>
                </div>
              </ResultFinancialTableData>
              <ResultFinancialTableData>
                {resource && (
                  <Button
                    size="small"
                    withIcon={<Search />}
                    onClick={() => onResourceButtonClick(resource)}
                    className="ml-auto h-full w-min bg-transparent"
                  />
                )}
              </ResultFinancialTableData>
            </ResultFinancialTableRow>
          );
        })}
      </ResultFinancialTable>
    </ResultFinancial>
  );
}

export default LenderStatsLVR;
