import currency from "currency.js";
import { useCallback, useMemo, useRef, useState } from "react";
import shallow from "zustand/shallow";
import { DepositeSlider } from ".";
import { SectionLabel } from "../components/label";
import { DollarBag, Percentage } from "../icons";
import useStore from "../store";
import { decimalToPercentage } from "../util";

const toCurrency = (amount: number, abbreviate: boolean = true) => {
  const cents = currency(amount).cents();
  const precision = cents ? 2 : 0;
  const k = 1000;
  const m = 1000000;
  const b = 1000000000;

  if (abbreviate) {
    if (amount > b) {
      return `${currency(amount / b, { precision }).format()}B`;
    }

    if (amount >= m) {
      return `${currency(amount / m, { precision }).format()}M`;
    }

    if (amount >= k) {
      return `${currency(amount / k, { precision }).format()}K`;
    }
  }

  return currency(amount, { precision }).format();
};

const DepositeAmount = () => {
  const [SecurityValue, NewLoanAmount, setActiveLenders, loanAmountApproval] =
    useStore(
      useCallback((state) => {
        const newLoan = Array.from(state.Loans.values())[0];

        return [
          newLoan?.Security.Value || 0,
          newLoan?.LoanAmount || 0,
          state.setActiveLenders,
          state.loanAmountApproval,
        ];
      }, []),
      shallow
    );

  const SecurityValueRef = useRef(SecurityValue);
  const NewLoanAmountRef = useRef(NewLoanAmount);

  const step = useMemo(
    () =>
      (loanAmountApproval[1]?.LoanAmount || 0) -
      (loanAmountApproval[0]?.LoanAmount || 0),
    [loanAmountApproval]
  );

  const { max, min } = useMemo(() => {
    const maxLoanAmount = loanAmountApproval[0]?.LoanAmount || 0;
    const minLoanAmount =
      loanAmountApproval[loanAmountApproval.length - 1]?.LoanAmount || 0;

    const SecurityValue = SecurityValueRef.current;
    const max = SecurityValue - maxLoanAmount;
    const min = SecurityValue - minLoanAmount;

    return { max, min };
  }, [loanAmountApproval]);

  const initialDepositAmount = useMemo(() => {
    return SecurityValueRef.current - NewLoanAmountRef.current;
  }, []);

  const [depositAmount, setDepositAmount] = useState(initialDepositAmount);

  const loanAmount = useMemo(
    () => SecurityValueRef.current - depositAmount,
    [depositAmount]
  );

  const LVRValue = useMemo(() => {
    return loanAmount / SecurityValueRef.current;
  }, [loanAmount]);

  const handleChange = useCallback(
    (depositAmount) => {
      setDepositAmount(depositAmount);
      const loanAmount = SecurityValueRef.current - depositAmount;

      const activeLenders = loanAmountApproval.find(
        (value) => value.LoanAmount === loanAmount
      );

      activeLenders && setActiveLenders(activeLenders.Id);
    },
    [loanAmountApproval, setActiveLenders]
  );

  return (
    <section className="rounded-[3px] bg-white px-6 py-4 shadow-[0px_0px_8px_#CDD7E2]">
      <header className="flex items-center justify-between">
        <div>
          <SectionLabel className="text-xs text-[#607D8B] desktop:text-sm">
            Deposit / Equity
          </SectionLabel>
          <p className="font-helvetica text-[10px] text-[#607D8B] desktop:text-xs">
            Drag the slider to adjust the deposit amount
          </p>
        </div>
        <div className="flex items-center justify-end font-helvetica text-xs text-white desktop:text-sm">
          <div className="mr-2 flex items-center space-x-[5px] rounded-[12px] border border-[#579BCB] py-[3px] px-[11px] font-bold text-[#579BCB]">
            <DollarBag width={14} height={15} fill="#579BCB" />
            <span>
              Amount: {currency(depositAmount, { precision: 0 }).format()}
            </span>
          </div>
          <div className="flex items-center  space-x-[5px] rounded-[12px] border border-[#579BCB] py-[3px] px-[11px] font-bold text-[#579BCB]">
            <Percentage fill="#579BCB" />
            <span>LVR: {decimalToPercentage(LVRValue)}</span>
          </div>
        </div>
      </header>
      <form className="mt-4 flex items-center space-x-[10px] font-helvetica text-[10px] text-[#AEAEAE]">
        <span>{toCurrency(min)}</span>
        <DepositeSlider
          initialValue={depositAmount}
          max={max}
          min={min}
          onChange={handleChange}
          step={step}
        />
        <span>{toCurrency(max)}</span>
      </form>
    </section>
  );
};

export default DepositeAmount;
