import { FC, SVGProps } from "react";

const Pencil: FC<SVGProps<SVGSVGElement>> = ({
  fill = "#42B4B4",
  ...props
}) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.805 1.75476C12.065 2.01472 12.065 2.43466 11.805 2.69463L10.5852 3.91446L8.08554 1.4148L9.30537 0.194973C9.56534 -0.064991 9.98528 -0.064991 10.2452 0.194973L11.805 1.75476ZM0 12V9.50035L7.37231 2.12804L9.87196 4.62769L2.49965 12H0Z"
        fill={fill}
      />
    </svg>
  );
};

export default Pencil;
