import { FC } from "react";
import { twMerge } from "tailwind-merge";
import style from "../pdf-style.module.scss";
import cx from "classnames";

const TableList: FC<{
  title: string;
  data: Array<any>;
  column: any;
  style?: any;
  hideHead?: boolean;
  headClassname?: string;
  badge?: string;
  badgeClassname?: string;
}> = (props) => {
  return (
    <table className={cx(style["table-list"])} style={props.style}>
      <tbody>
        {props.title && (
          <tr className={style["table-list-header"]}>
            <td align="left" colSpan={4}>
              <p className={style["title"]}>
                {props.title}{" "}
                {props?.badge && (
                  <span
                    className={twMerge(
                      style["badge"],
                      style[props?.badgeClassname ?? ""]
                    )}
                  >
                    {props?.badge}
                  </span>
                )}
              </p>
            </td>
          </tr>
        )}
        {!props?.hideHead && (
          <tr
            className={twMerge(
              style["head"],
              style[props?.headClassname ?? ""]
            )}
          >
            {props.column?.map((col: any, colIndex: number) => (
              <td
                align={col?.align ? col?.align : "left"}
                key={colIndex}
                className={twMerge(
                  !col?.noRightSpace && col?.align
                    ? style[col?.align ?? ""]
                    : ""
                )}
                style={{
                  width: col?.width ? col?.width : "",
                  maxWidth: col?.maxWidth ? col?.maxWidth : "",
                }}
              >
                <p>{col.title}</p>
              </td>
            ))}
          </tr>
        )}
        {props.data?.map((_data: any, rowIndex: number) => (
          <tr key={rowIndex}>
            {props.column?.map((col: any, colIndex: number) => (
              <td
                align={col?.align ? col?.align : "left"}
                key={colIndex}
                className={twMerge(
                  !col?.noRightSpace && col?.align
                    ? style[col?.align ?? ""]
                    : ""
                )}
                style={{
                  width: col?.width ? col?.width : "",
                  maxWidth: col?.maxWidth ? col?.maxWidth : "",
                  ...col.style,
                }}
              >
                <p>{_data[col?.key]}</p>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableList;
