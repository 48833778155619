import { FC, SVGProps } from "react";

const Sparkle: FC<SVGProps<SVGSVGElement>> = ({ fill = "#fff", ...props }) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>icon sparkle</title>
      <g
        id="Changelog"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="New-updates"
          transform="translate(-406.000000, -49.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="button" transform="translate(398.000000, 45.000000)">
            <path
              d="M23.8333333,11.5 L24.875,9.20833333 L27.1666667,8.16666667 L24.875,7.125 L23.8333333,4.83333333 L22.7916667,7.125 L20.5,8.16666667 L22.7916667,9.20833333 L23.8333333,11.5 Z M17.5833333,11.9166667 L15.5,7.33333333 L13.4166667,11.9166667 L8.83333333,14 L13.4166667,16.0833333 L15.5,20.6666667 L17.5833333,16.0833333 L22.1666667,14 L17.5833333,11.9166667 Z M23.8333333,16.5 L22.7916667,18.7916667 L20.5,19.8333333 L22.7916667,20.875 L23.8333333,23.1666667 L24.875,20.875 L27.1666667,19.8333333 L24.875,18.7916667 L23.8333333,16.5 Z"
              id="icon"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Sparkle;
