import React, { SVGProps } from "react";

export default function Link({
  fill = "#43357F",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9999 16.9999L10.4999 18.4999C9.83684 19.1629 8.93758 19.5353 7.99994 19.5353C7.0623 19.5353 6.16304 19.1629 5.49994 18.4999C4.83701 17.8368 4.4646 16.9376 4.4646 15.9999C4.4646 15.0623 4.83701 14.163 5.49994 13.4999L8.49994 10.4999C9.16304 9.83701 10.0623 9.4646 10.9999 9.4646C11.9376 9.4646 12.8368 9.83701 13.4999 10.4999"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 6.99994L13.5 5.49994C14.1631 4.83701 15.0624 4.4646 16 4.4646C16.9376 4.4646 17.8369 4.83701 18.5 5.49994C19.1629 6.16304 19.5353 7.0623 19.5353 7.99994C19.5353 8.93758 19.1629 9.83684 18.5 10.4999L15.5 13.4999C14.8369 14.1629 13.9376 14.5353 13 14.5353C12.0624 14.5353 11.1631 14.1629 10.5 13.4999"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
