import { FC } from "react";
import TableList from "./TableList";
import {
  lenderDTIBuilderUI,
  lenderLEMBuilderUI,
  lenderLVRBuilderUI,
  lenderUMIBuilderUI,
  lenderUMICalculatorBuilderUI,
} from "../lib/builder";
import * as DateFns from "date-fns";
import currency from "currency.js";
import { DTIIcon, WarningCircleSm } from "../../../icons";
import UMIIcon from "../../../icons/UMI";
import LVRIcon from "../../../icons/LVR";
import LEMIcon from "../../../icons/LEM";
import LenderErrors from "./LenderErrors";
import { twMerge } from "tailwind-merge";
import style from "../pdf-style.module.scss";
import LenderDetailTableList from "./LenderDetailTableList";
import { decimalToPercentage } from "../../../util";
import { isNumber } from "lodash";

const LenderTable: FC<{
  data: any;
  type: string;
  sectionNo: any;
  lenderIdx?: number;
  includeWarningsErrors: boolean;
}> = (props) => {
  const { data } = props;
  const releaseDate = DateFns.isValid(new Date(data?.version?.releaseDate))
    ? DateFns.format(new Date(data?.version?.releaseDate), "d MMM yyyy")
    : "";
  const info = `${data?.version?.versionNumber ?? ""} ${
    releaseDate ? ` (released ${releaseDate})` : ""
  }`;

  const hasTopError = !!data?.errors?.find((_i: any) => _i.reference === "Top")
    ?.message;

  const hasLVRErrors = Boolean(
    data?.errors?.filter((_i: any) => _i.reference === "Result.LVR")?.length ||
      data?.warnings?.filter((_i: any) => _i.reference === "Result.LVR")?.length
  );

  const servicingValue =
    data?.result?.servicing.type === "NSR"
      ? decimalToPercentage(data?.result?.servicing?.value)
      : currency(data?.result?.servicing?.value).format();

  const servicingTitle =
    !data?.result?.servicing || data?.result?.servicing?.type === "N/A"
      ? !data?.result?.UMI || data?.result?.UMI?.status === "N/A"
        ? "N/A"
        : `${currency(data?.result?.UMI.value).format()} UMI`
      : `${servicingValue} ${data?.result?.servicing?.type}`;

  const hasUMIErrors =
    (data?.result?.servicing?.type === "NSR" &&
      data?.result?.servicing?.status !== "Pass") ||
    (data?.result?.servicing?.type !== "NSR" &&
      data?.result?.UMI?.status !== "Pass");

  return (
    <table
      className={twMerge(
        style["lender-table"],
        style["avoid-break"],
        !hasTopError && style["break-before"]
      )}
      style={{ marginBottom: "20px" }}
    >
      <tbody className={style["avoid-break"]}>
        {props?.lenderIdx === 0 && (
          <tr className={style["lender-title"]}>
            <td colSpan={2}>
              <div
                style={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  verticalAlign: "bottom",
                  width: "100%",
                }}
              >
                <p
                  style={{
                    display: "inline-block",
                    fontFamily: "Helvetica, 'Lato', Arial",
                    fontSize: "17px",
                    color: "#ffffff",
                    lineHeight: "17px",
                    fontWeight: "700",
                    margin: 0,
                    background: "#1d1c1d",
                    borderRadius: "50%",
                    paddingTop: "2px",
                    paddingBottom: "6px",
                    paddingLeft: "8px",
                    paddingRight: "10px",
                    width: "25px",
                  }}
                >
                  {props.sectionNo}
                </p>
                <p
                  style={{
                    display: "inline-block",
                    fontFamily: "Helvetica, 'Lato', Arial",
                    fontSize: "20px",
                    color: "#1d1c1d",
                    lineHeight: "20px",
                    fontWeight: "700",
                    margin: 0,
                    marginLeft: "5px",
                    marginTop: "2px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Lenders That May{" "}
                  <span
                    style={{
                      color: props.type === "Approve" ? "#2DBFC1" : "#FF6E6E",
                    }}
                  >
                    {props.type}
                  </span>
                </p>
              </div>
            </td>
          </tr>
        )}
        {hasTopError ? (
          <>
            <tr className={style["lender-header"]}>
              <td align="left">
                <p
                  className={style["lender-name"]}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <img src={data?.lender?.logo} alt="data?.Lender?.Name" />
                  <span>{data?.lender?.name}</span>
                </p>
              </td>
              <td align="right">
                <p className={style["info"]}>{info}</p>
              </td>
            </tr>
            <tr>
              <td
                className={twMerge(style["lender-type"], style["error"])}
                colSpan={2}
              >
                {data?.errors?.map((_err: any, idx: number) => (
                  <p className={style["error"]} key={idx}>
                    <WarningCircleSm />
                    <span>{_err.message}</span>
                  </p>
                ))}
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr>
              <td
                className={twMerge(
                  style["lender-type"],
                  hasUMIErrors && style["has-error"]
                )}
                width={120}
                align="center"
              >
                <p
                  className={style["lender-name"]}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <img src={data?.lender?.logo} alt="data?.Lender?.Name" />
                  <span>{data?.lender?.name}</span>
                </p>
                <UMIIcon
                  className="mb-2 mt-6"
                  fill={hasUMIErrors ? "#ff6e6e" : "#41b4b4"}
                  style={{
                    width: "23px",
                    height: "23px",
                  }}
                />
                <p>{servicingTitle}</p>
              </td>
              <td>
                <p className={style["lender-info"]}>{info}</p>
                <div
                  className={style["lender-details"]}
                  style={{ borderTop: "1px solid #1d1c1d" }}
                >
                  {/* UMI Incomes */}
                  {data?.result?.assessedIncome
                    ?.filter(
                      (value: any, index: any, self: any) =>
                        index ===
                        self.findIndex((t: any) => t.group === value.group)
                    )
                    .map((_item: any, idx: any) => {
                      return (
                        <LenderDetailTableList
                          key={idx}
                          title={`${_item?.group}`}
                          column={[
                            {
                              title: "",
                              key: "label",
                            },
                            {
                              title: "",
                              key: "entered",
                              isAmount: true,
                            },
                            {
                              title: "",
                              key: "assessed",
                              isAmount: true,
                            },
                          ]}
                          data={lenderUMIBuilderUI(
                            data?.result?.assessedIncome.filter(
                              (_i: any) => _i.group === _item.group
                            )
                          )}
                        />
                      );
                    })}
                  <LenderErrors
                    reference="Result.Income"
                    errors={data?.errors}
                    warnings={data?.warnings}
                    includeWarningsErrors={props?.includeWarningsErrors}
                  />
                  {/* UMI Expenses */}
                  {data?.result?.assessedExpense
                    ?.filter(
                      (value: any, index: any, self: any) =>
                        index ===
                        self.findIndex((t: any) => t.group === value.group)
                    )
                    .map((_item: any, idx: any) => {
                      return (
                        <LenderDetailTableList
                          key={idx}
                          title={`${_item?.group}`}
                          column={[
                            {
                              title: "",
                              key: "label",
                            },
                            {
                              title: "",
                              key: "entered",
                              isAmount: true,
                            },
                            {
                              title: "",
                              key: "assessed",
                              isAmount: true,
                            },
                          ]}
                          data={lenderUMIBuilderUI(
                            data?.result?.assessedExpense.filter(
                              (_i: any) => _i.group === _item.group
                            )
                          )}
                        />
                      );
                    })}
                  <LenderErrors
                    reference="Result.Expenses"
                    errors={data?.errors}
                    warnings={data?.warnings}
                    includeWarningsErrors={props?.includeWarningsErrors}
                  />
                  <LenderDetailTableList
                    title={`SERVICING CALCULATOR`}
                    column={[
                      {
                        title: "",
                        key: "label",
                      },
                      {
                        title: "",
                        key: "",
                      },
                      {
                        title: "",
                        key: "value",
                        align: "right",
                        isAmount: true,
                      },
                    ]}
                    data={lenderUMICalculatorBuilderUI(data?.result)}
                    hideHead={true}
                  />
                  <TableList
                    title={``}
                    column={[
                      {
                        title:
                          data?.result?.servicing?.type === "N/A"
                            ? "Minimum UMI"
                            : `Maximum NSR`,
                        key: "type",
                      },
                      {
                        title:
                          !data?.result?.servicing ||
                          data?.result?.servicing?.type === "N/A"
                            ? isNumber(data?.result?.UMI?.required)
                              ? `${currency(
                                  data?.result?.UMI?.required || 0
                                ).format()}+ p.m`
                              : "N/A"
                            : `${decimalToPercentage(
                                data?.result?.servicing?.required
                              )}`,
                        key: "maxLVR",
                        align: "right",
                        noRightSpace: true,
                      },
                    ]}
                    data={[]}
                  />
                  <LenderErrors
                    reference="Result.Servicing"
                    errors={data?.errors}
                    warnings={data?.warnings}
                    includeWarningsErrors={props?.includeWarningsErrors}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td
                className={twMerge(
                  style["lender-type"],
                  hasLVRErrors && style["has-error"]
                )}
                width={120}
                align="center"
              >
                <LVRIcon
                  className="mb-2 mt-6"
                  fill={hasLVRErrors ? "#ff6e6e" : "#41b4b4"}
                  style={{
                    width: "23px",
                    height: "23px",
                  }}
                />
                <p>LVR</p>
              </td>
              <td>
                <div className={style["lender-details"]}>
                  {data?.result?.LVR?.map((_lvr: any, idx: any) => {
                    return (
                      <div key={idx}>
                        <TableList
                          title={`Security ${idx + 1} - LVR`}
                          badge={`${_lvr?.value * 100}%`}
                          badgeClassname={
                            hasLVRErrors ? "orange-badge" : "blue-badge"
                          }
                          column={[
                            {
                              title: "",
                              key: "type",
                            },
                            {
                              title: "",
                              key: "description",
                            },
                            {
                              title: "",
                              key: "maxLVR",
                              align: "right",
                              noRightSpace: true,
                              style: {
                                whiteSpace: "nowrap",
                              },
                            },
                          ]}
                          data={lenderLVRBuilderUI(_lvr?.lvrLimit)}
                          hideHead={true}
                        />
                        <TableList
                          title={``}
                          column={[
                            {
                              title: "Max LVR",
                              key: "type",
                            },
                            {
                              title: `${_lvr?.requiredMaxLVR * 100}%`,
                              key: "maxLVR",
                              align: "right",
                              noRightSpace: true,
                            },
                          ]}
                          data={[]}
                        />
                        <LenderErrors
                          reference="Result.LVR"
                          errors={data?.errors}
                          warnings={data?.warnings}
                          loanId={_lvr.loanId}
                          includeWarningsErrors={props?.includeWarningsErrors}
                        />
                      </div>
                    );
                  })}
                </div>
              </td>
            </tr>
            <tr>
              <td
                className={style["lender-type"]}
                width={120}
                align="center"
                style={
                  Boolean(
                    data?.result?.DTI.status !== "N/A" ||
                      (data?.result?.DTI.status === "N/A" &&
                        data?.lender?.key === "bnz")
                  )
                    ? {}
                    : { paddingTop: "10px", paddingBottom: "10px" }
                }
              >
                {data?.result?.DTI?.status !== "N/A" ||
                (data?.result?.DTI?.status === "N/A" &&
                  data?.lender?.key === "bnz") ? (
                  <DTIIcon
                    className="mb-2 mt-6"
                    fill="#41b4b4"
                    style={{
                      width: "23px",
                      height: "23px",
                    }}
                  />
                ) : (
                  <></>
                )}
                <p
                  style={
                    Boolean(
                      data?.result?.DTI.status !== "N/A" ||
                        (data?.result?.DTI.status === "N/A" &&
                          data?.lender?.key === "bnz")
                    )
                      ? { color: "#2dbfc1" }
                      : { color: "#1d1c1d" }
                  }
                >
                  DTI
                </p>
              </td>
              <td>
                <div className={style["lender-details"]}>
                  {data?.result?.DTI?.status !== "N/A" ||
                  (data?.result?.DTI?.status === "N/A" &&
                    data?.lender?.key === "bnz") ? (
                    <>
                      <TableList
                        title={`DTI`}
                        column={[
                          {
                            title: "",
                            key: "label",
                          },
                          {
                            title: "",
                            key: "value",
                            align: "right",
                            noRightSpace: true,
                          },
                        ]}
                        data={lenderDTIBuilderUI(data?.result?.DTI)}
                        hideHead={true}
                      />
                      <TableList
                        title={``}
                        column={[
                          {
                            title: "Max DTI",
                            key: "label",
                          },
                          {
                            title: data?.result?.DTI?.requiredMaxDTI,
                            key: "value",
                            align: "right",
                            noRightSpace: true,
                          },
                        ]}
                        data={[]}
                      />
                      <LenderErrors
                        reference="Result.DTI"
                        errors={data?.errors}
                        warnings={data?.warnings}
                        includeWarningsErrors={props?.includeWarningsErrors}
                      />
                    </>
                  ) : (
                    <p
                      style={{
                        padding: "10px",
                      }}
                    >
                      N/A
                    </p>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td
                className={twMerge(style["lender-type"], style["lender-lem"])}
                width={120}
                align="center"
                style={
                  Boolean(data?.result?.LEM?.length > 0)
                    ? {}
                    : { paddingTop: "10px", paddingBottom: "10px" }
                }
              >
                {data?.result?.LEM?.length > 0 && (
                  <LEMIcon
                    className="mb-2 mt-6"
                    fill="#1d1c1d"
                    style={{
                      width: "23px",
                      height: "23px",
                    }}
                  />
                )}
                <p>LEM</p>
              </td>
              <td>
                <div className={style["lender-details"]}>
                  {data?.result?.LEM?.length ? (
                    data?.result?.LEM?.map((_lem: any, idx: any) => (
                      <div key={idx}>
                        <TableList
                          title={`Security ${idx + 1} - LEM`}
                          badge={`${
                            _lem.amount
                              ? currency(_lem.amount, {
                                  precision: 2,
                                }).format()
                              : ""
                          }`}
                          column={[
                            {
                              title: "",
                              key: "label",
                            },
                            {
                              title: "",
                              key: "value",
                              align: "right",
                              noRightSpace: true,
                            },
                          ]}
                          data={lenderLEMBuilderUI(_lem)}
                          hideHead={true}
                        />
                        <TableList
                          title={``}
                          column={[
                            {
                              title: "LEM Amount",
                              key: "label",
                            },
                            {
                              title: _lem.amount
                                ? `${currency(_lem.amount ?? 0, {
                                    precision: 2,
                                  }).format()}`
                                : "N/A",
                              key: "value",
                              align: "right",
                              noRightSpace: true,
                            },
                          ]}
                          data={[]}
                        />
                      </div>
                    ))
                  ) : (
                    <p
                      style={{
                        padding: "10px",
                      }}
                    >
                      N/A
                    </p>
                  )}
                </div>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default LenderTable;
